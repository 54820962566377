import { alertConstant, directoryConstant, loaderConstant } from "../constants";
import { directoryService } from "../services";
import { baseAction } from "./baseAction";

const { request, success, failure } = baseAction;

// Export All Auth Actions Functions
export const directoryAction = {
  search,
  getAll,
  remove,
  addDirectories,
  editDirectories,
  getOne,
  getDirectoryFrame
};

/**
* Get All  directory for i frame
*/
function getDirectoryFrame(params) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(directoryConstant.DIRECTORY_FRAME_GET_REQUEST));
    directoryService.getDirectoryFrame(params).then((data) => {
      dispatch(success(directoryConstant.DIRECTORY_FRAME_GET_SUCCESS, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }, (error) => {
      dispatch(failure(directoryConstant.DIRECTORY_FRAME_GET_FAILURE, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** 
* Get All  directory
*/
function search(skip, limit, sortBy, sort, sFields, sValues) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(directoryConstant.DIRECTORY_SEARCH_REQUEST));
    directoryService.search(skip, limit, sortBy, sort, sFields, sValues).then(
      (data) => {
        dispatch(success(directoryConstant.DIRECTORY_SEARCH_SUCCESS, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(directoryConstant.DIRECTORY_SEARCH_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * All directoey details
 */
function getAll(params) {
  return (dispatch) => {
    if (params && !params["loader"]) {
      dispatch(request(loaderConstant.LOADING_TRUE));
    } else {
      dispatch(request(loaderConstant.LAZY_LOADING_TRUE));
    }
    dispatch(request(directoryConstant.DIRECTORY_GETALL_REQUEST));
    directoryService.getAll(params).then(
      (data) => {
        dispatch(success(directoryConstant.DIRECTORY_GETALL_SUCCESS, data));
        if (params && !params["loader"]) {
          dispatch(request(loaderConstant.LOADING_FALSE));
        } else {
          dispatch(request(loaderConstant.LAZY_LOADING_FALSE));
        }
      },
      (error) => {
        dispatch(failure(directoryConstant.DIRECTORY_GETALL_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        if (params && !params["loader"]) {
          dispatch(request(loaderConstant.LOADING_FALSE));
        } else {
          dispatch(request(loaderConstant.LAZY_LOADING_FALSE));
        }
      }
    );
  };
}

/**
* Delete particular service
*/
function remove(data, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(directoryConstant.DIRECTORY_DELETE_REQUEST));
    directoryService.remove(data).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(success(directoryConstant.DIRECTORY_DELETE_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        successCallback()
      },
      (error) => {
        dispatch(failure(directoryConstant.DIRECTORY_DELETE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
* add directories
*/
function addDirectories(data, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(directoryConstant.DIRECTORY_ADD_REQUEST));
    directoryService.addDirectories(data).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(success(directoryConstant.DIRECTORY_ADD_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        successCallback()
      },
      (error) => {
        dispatch(failure(directoryConstant.DIRECTORY_ADD_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
* edit directories
*/
function editDirectories(data, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(directoryConstant.DIRECTORY_EDIT_REQUEST));
    directoryService.editDirectories(data).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(success(directoryConstant.DIRECTORY_EDIT_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        successCallback()
      },
      (error) => {
        dispatch(failure(directoryConstant.DIRECTORY_EDIT_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
* edit directories
*/
function getOne(data, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(directoryConstant.DIRECTORY_GET_REQUEST));
    directoryService.getOne(data).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(success(directoryConstant.DIRECTORY_GET_SUCCESS, data));
        successCallback()
      },
      (error) => {
        dispatch(failure(directoryConstant.DIRECTORY_GET_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}
