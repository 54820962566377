export const page_container_heights = {
    HEADER_HEIGHT: 55
};

export const errorsConstants = {
    required: "This is a required field",
    inValidEmail: "Invalid Email",
    requiredEmail: "Email is mandatory",
    requiredPassword: "Password is mandatory",
    invalidPassword: "Incorrect Password",
    passwordCriteria: "Provide the Criteria",
    isPasswordMatch: "The passwords do not match"
}

export const common_constants = {
    BANNER_TEXT: "Alliance 2020",
    EMAIL_ID: "Email ID",
    PASSWORD: "Password",
    CONFIRM_PASSWORD: "Confirm Password",
    SIGNIN_HEADER: "LOGIN",
    FORGOT_PASSWORD: "FORGOT PASSWORD",
    REQUEST_ACCESS: "Restore Access",
    CREATE_PASSWORD: "Create Password",
    RESET_PASSWORD: "Reset Password",
    BACK_TO_SIGNIN: "Back to Login",
    STAY_SIGNED_IN: "Stay signed in",
    COPYRIGHT: "Copyright © Alliance 2020. All Right Reserved",
    SEND_BTN_TEXT: "SEND",
    SIGNIN_BTN_TEXT: "LOGIN",
    RESET_BTN_TEXT: "Reset",
    ADDRESS: "304 Main Ave. South, Suite 101 Renton, Washington 98057",
    PHONE: "425-271-8065 | 425-227-9246"
};

export const contactus_page_constants = {
    CONTACT_US_HEADER: "Contact Us",
    CONTACT_US_LINK_BTN_TEXT: "Contact Us",
    CONTACT_US_TEXT: "If you want to reach our customer support team, have any questions, concerns, drop us a note.",
    CONTACT_US_BTN: "Send",
    CONTACT_US_NAME: "Name",
    CONTACT_US_EMAIL_ID: "Email ID",
    CONTACT_US_SUBJECT: "Subject",
    CONTACT_US_MESSAGE: "Message"
};

export const audit_page_constants = {
    AUDIT: "Audit Logs",
    CANCEL: "Cancel",
    COLUMN_SETTINGS: "Column Settings",
    APPLY: "Apply",
    HEADER_MAP_CONSTANT: {
        "Model": "model",
        "Model ID": "model_id",
        "User IP address": "ip_address",
        "Field": "field",
        "Time": "stamp",
        "Old Value": "old_value",
        "New Value": "new_value",
        "Log Item": "action",
        "Email ID": "email",
    },
    HEADER_DEFAULT: ["Log Item", "Model", "Model ID", "Field", "Old Value", "New Value", "Email ID", "User IP address", "Time"],
    HEADER_DEFAULT_SELECTED: ["Log Item", "Model", "Model ID", "Field", "Email ID", "User IP address", "Time"]
};

export const user_page_constants = {
    PASSWORD: "Password",
    CONFIRM_PASSWORD: "Confirm Password",
    RESETPASSWORD: "Change Password",
    USERS: "Users",
    ADD_USER: "+ Add User",
    ROLE: "Role",
    FULLNAME: "Full Name",
    COMPANY_NAME: "Company Name",
    CLIENT_NAME: "Client",
    EMAIL: "Email",
    PHONE: "Phone",
    CODE: "Client Code",
    SALESRATE: "Sales Rate",
    USER_NAME: "User name",
    PWWD: "Password",
    NOTIFI: "Notification",
    GRPING: "Grouping",
    ADDRESS: "Address",
    STREET: "Street",
    CITY: "City",
    STATE: "State",
    ZIPCODE: "Zip Code",
    STATUS: "Status",
    COMMON: 'Common',
    SENDPASSWORD: "Send Password",
    SUBMIT: "Submit",
    CANCEL: "Cancel",
    COLUMN_SETTINGS: "Column Settings",
    APPLY: "Apply",
    DELETE_CONTENT: "Are you sure?",
    FILTER: "Filter",
    UPDATEFEE: "Update Fee",
    ADD_SERVICE: "+ Add Service",
    ADDA_SERVICE: "Add Service",
    EDITA_SERVICE: "Edit Service",
    SELECT_CLIENT: "Select Client",
    SEARCH_SELECT_CLIENT: "Search and Select a Client",
    SELECT_TEMPLATE: "Select Template",
    ADDSERVICE: "ADD SERVICE",
    ADDCLIENT: "CHOOSE A CLIENT",
    ENTER_PRODUCT: "Enter Product",
    ENTER_FEE: "Enter Fee",
    //Adde Template
    ADD_A_TEMPLATE: "ADD A TEMPLATE",
    ADD_A_SERVICE: "ADD A SERVICE",
    SELECTED_AGREEMENT: "Selected Agreement",
    TEMPLATE_NAME: "Template Name",
    DESCRIPTION: "Description",
    DEFAULT_EMAIL_TEMPLATE: "Default Email Template",
    EMAIL_TEMPLATE_NAME: "Email Template Name",
    SUBJECT: "Subject",
    EMAIL_CONTENT: "Email Content",
    //Add Agreement
    ADD_AN_AGREEMENT: "ADD AN AGREEMENT",
    DOCUMENTS: "Documents",
    AGREEMENT_NAME: "Agreement Name",
    //Add Docuemnts
    ADD_DOCUMENTS: "ADD A DOCUMENTS",
    GENERIC: "Generic",
    TITLE: "Title",
    SIGNATURE: "Signature",
    AUTHORIZATION: "Authorization",
    CONDITION: "Condition",
    CONTENT: "Content",
    LAYOUT: "Layout",
    SHOW_ON_DISCLOSURE_PAGE: "Show on document page",
    CHOICES: "Choices",
    SHOW_DOCUMENT_PAGE: "Show on document page",
    POSITION: "Position",
    REQUIRED: "Required",
    ADD_VARIATION: "+ Add Another Variation",
    ADD_ANOTHER_CHOICES: "+ Add Another Choices",
    ADD_ANOTHER_DOCUMENT: "+ Add Another Document",
    OOPS: "Oops! There is no service created yet",
    DO_U_CREATE_TEMPLATE: "Do you wanna create a template now and add a service",
    CREATE_TEMPLATE: "Create a Template",
    SUCCESSFULLY_ADDED: "Service Added Successfully",
    SUCCES_TEXT: 'Created service can be viewed in the service section',
    SERVICE_LIST: "Go to Service List",
    EDIT_USER_PAGE_TITLE: "Edit User",
    FEE: "Fee",
    USER_LIST_HEADER: ["Code", "Company", "Full Name", "Role", "Phone", "Email", "Last login", 'Created', "Active"],
    USER_LIST_FIELDS: ["code", "company", "full_name", "role", "phone", "email", "login_time", 'create_time', "status"],
    USER_LIST_MAP: {
        "Code": "code",
        "Company": "company",
        "Full Name": "full_name",
        "Role": "role",
        "Phone": "phone",
        "Email": "email",
        "Last login": "login_time",
        "Active": "status",
        "Created": 'create_time'
    }
};

export const submissions_page_constants = {
    SUBMISSIONS: "SUBMISSIONS",
    RETRY_SUBMISSION: "RETRY SUBMISSION",
    RETRY_SUBMIT: "Submit",
    RETRY_CANCEL: "Cancel",
    APPLY: "APPLY",
    CANCEL: "CANCEL",
    COLUMN_SETTINGS: "Column Settings",
    DOWNLOAD_CRITERIA: "Download Criteria",
    DOWNLOAD_DOCUMENT: "Download Document",
    DOWNLOAD_APPLICATION: "Download Application",
    DOWNLOAD_ATTACHMENT: "Download Attachment",
    SUBMISSION_LIST_MAP: {
        "Application": "application_name",
        "Manager": "manager_name",
        "Property": "property",
        "Applicant": "applicant",
        "Phone": "phone",
        "Email": "email",
        "Transaction": "transaction",
        "Response": "response",
        "Card": "card_number",
        "Created": "created_date_formatted",
        "Status": "status",
        "Payment": "payment_status",
        "Report": "report_status",
        "Refund": "refund",
        "Refund time": "refund_date_formatted"
    },
    HEADER_DEFAULT: ["Application", "Manager", "Property", "Applicant", "Phone", "Email", "Transaction", "Response", "Card", "Created", "Status", "Payment", "Report", "Refund", "Refund time"],
    HEADER_DEFAULT_SELECTED: ["Application", "Manager", "Property", "Applicant", "Phone", "Email", "Transaction", "Card", "Created", "Status", "Payment", "Report", "Refund", "Refund time"],
};

export const services_page_constants = {
    SERVICES: "SERVICES",
    DOWNLOAD: "DOWNLOAD",
    UPLOAD: "UPLOAD",
    UPDATE_FEE: "UPDATE FEE",
    ADD_SERVICE: "+ ADD SERVICE",
    UPDATEFEE: "UPDATE FEE",
    FEE: "FEE",
    APPLY: "APPLY",
    CANCEL: "CANCEL",
    DIRECTORIES: "Directories"
};

export const dirctory_page_constants = {
    DIRECTORIES: "DIRECTORIES",
    ADD_LISTING: "+ ADD LISTING",
    APPLY: "APPLY",
    CANCEL: "CANCEL",
    COLUMN_SETTINGS: "Column Settings"
};
export const directory_page_constants = {
    DIRECTORY: "Directory",
    DIRECTORIES: "DIRECTORIES",
    ADD_LISTING: "+ ADD LISTING",
    ADD_DIRECTORY: "+ Add Directory",
    COMPANY_NAME: "Company",
    STATUS: "Status",
    SUBMIT: "Submit",
    CANCEL: "Cancel",
    COLUMN_SETTINGS: "Column Settings",
    FIRSTNAME: "First Name",
    LASTNAME: "Last Name",
    HOLOGRAM: "Hologram",
    ASSOCIATE: "Associate",
    CARD_ID: "Card ID",
    EXP_DATE: "Expiration Date",
    EDIT_USER_PAGE_TITLE: "Edit User",
    COLUMN_SETTINGS: "Column Settings",
    APPLY: "Apply",
    HEADER_MAP_CONSTANT: {
        "First Name": "first_name",
        "Last Name": "last_name",
        "Company": "company",
        "Hologram": "hologram",
        "Associate": "category",
        "Card Id": "card_id",
        "Card ID": "card_id",
        "Expiration Date": "expiry_date",
        "Active": "status",
        "Created": "create_time"
    },
    HEADER_DEFAULT: ["First Name", "Last Name", "Company", "Hologram", "Associate", "Card ID", "Expiration Date", "Active", "Created"],
    HEADER_DEFAULT_SELECTED: ["First Name", "Last Name", "Company", "Hologram", "Associate", "Card ID", "Expiration Date", "Active", "Created"]
};

export const tenant_screening_constants = {
    TITLE: "TENANT SCREENING APPLICATION",
    CONTINUE: "CONTINUE"
};

export const settings_page_constants = {
    INTRODUCTION: "Introduction",
    PAYMENT_DESCRIPTION: "Payment Description",
    COMPANY_INFORMATION: "Company Information",
    EMAIL_CONTACTS: "Email Contacts",
    TAZWORKS: "Tazworks",
    BLUEFIN: "BlueFin",
    NAME: "Name",
    FAX: "Fax",
    ADDRESS: "Address",
    PHONE: "Phone",
    SUPPORT_NAME: "Support name",
    SUPPORT_EMAIL: "Support email",
    COMPLIANCE_NAME: "Compliance name",
    COMPLIANCE_EMAIL: "Compliance email",
    ACCOUNT: "Account",
    APIKEY: "API Key",
    SUBDOMAIN: "Subdomain",
    MANAGER: "Manager",
    FIRST_NAME: "First name",
    LAST_NAME: "Last name",
    MIDDLE_NAME: "Middle name",
    GENERATION: "Generation",
    EMAIL: "Email",
    PROPERTY_NAME: "Property name",
    UNIT: "Unit",
    RENTAL_PAYMENT: "Rental payment",
    GROUP: "Group",
    CERT: "CERT Credentials",
    DISCLAIMER : "Disclaimer"

};

export const templates_page_constants = {
    GENERIC: 'Generic',
    TITLE: 'Title',
    DOC_DESCRIPTION: 'Description',
    DOC_POSITION: 'Position',
    DOC_REQUIRED: 'Required',
    ADD_VARIATION: '+ Add Variation',
    ADD_ANOTHER_CHOICE: '+ Add Another Choice',
    ADD_DOCUMENTS: '+ ADD DOCUMENTS',
    DOC_SIGNATURE: 'Signature',
    DOC_AUTHORISATION: 'Authorization',
    DOC_CONDITION: 'Condition',
    CONTENT: 'Content',
    LAYOUT: 'Layout',
    CHOICES: 'Choices',
    TEMPLATES: "TEMPLATES",
    APPLICATION_TEMPLATE: "Application Templates",
    ADD_APPLICATION_TEMPLATE: "+ ADD APPLICATION TEMPLATE",
    EMAIL_TEMPLATE: "Email Templates",
    AGREEMENTS: "Agreements",
    DOCUMENTS: "Documents",
    SEARCH_ADD_CONTENT: 'Search and add document',
    CRITERIA_TEMPLATE: "Crieteria Templates",
    ADD_EMAILTEMPLATE: "+ Add Email Template",
    ADD_APP_TEMPLATE: "ADD APPLICATION TEMPLATES",
    ADD_EMAIL_TEMPLATE: "ADD EMAIL TEMPLATES",
    TEMPLATE_NAME: "Template Name",
    SUBJECT: "Subject",
    EMAIL_CONTENT: "Email Content",
    UPDATE: "UPDATE",
    CANCEL: "CANCEL",
    //add application templates
    GENERAL: "General",
    NAME: "Name",
    DESCRIPTION: "Description",
    SELECT_AGREEMENT: "Select Agreement",
    SELECT_EMAIL_TEMPLATE: "Select Email Template",
    CONFIRM_MESSAGE: "Confirm Message",
    IS_VISIBLE: "Is Visible",
    EDIT_XML: "XML",
    EDIT_LAYOUT: "Layout",
    LAYOUT_TEXT: `Guidelines to define objects:</br>
     1. Correct Format  { "key" : "value" }</br>
     2. Remove the , at the end of following line eg :"business name":"Wong's Garden"},]</br>
     3. Backspace to be replaced with &bsol;b </br>
     4. Form feed to be replaced with &bsol;f </br>
     5. Newline to be replaced with &bsol;n </br>
     6. Carriage return to be replaced with &bsol;r </br>
     7. Tab to be replaced with &bsol;t  </br>
     8. Double quote to be replaced with &bsol;" </br>
     9. Singe quote to be replaced with &bsol;'  </br>
     10. Backslash to be replaced with &bsol;&bsol;`,
    DISCLAIMER_TEXT:"The disclaimer content defined will be captured in the application review section"
}


export const application_page_constants = {
    APPLICATION: "APPLICATION",
    ADD_APPLICATION_BTN: "+ ADD APPLICATION",
    ADD_APPLICATION: "ADD APPLICATION",
    EDIT_APPLICATION: "EDIT APPLICATION",
    UPDATE: "UPDATE",
    CANCEL: "CANCEL",
    CRITERIA: "Criteria",
    PAYMENT: "Payment",
    INTRODUCTION: "Introduction",
    APPLICATIONlOW: "Application",
    GENERIC: "Generic",
    MAPS: "Maps",
    QUESTIONS: "Questions",
    SERVICE: "Service",
    NAME: "Name",
    SHOW_APPLICATION_NAME: "Show Application Name",
    INSTANT: "Instant",
    SHOW_CONFIRMATION : "Show Confirmation message",
    INTRODUCTION_MAP: "Introduction Map",
    CRITERIA_MAP: "Criteria Map",
    APPLICATION_MAP: "Application Map",
    IDENTIFICATION_MAP: "Identification Map",
    DISCLOSURE_MAP: "Document Map",
    PAYMENT_MAP: "Payment Map",
    APPLICATION_REVIEW_MAP:"Application Review",
    TEXT: "Text",
    TYPE: "Type",
    ADD_QUESTION: "ADD QUESTION",
    APP_LIST_MAP: {
        "Application Name": "name",
        "Service": "service",
        "Instant": "instant",
        "Created": "cdt"
    },
    QuestionTypes: [
        { "value": "confirm", "label": "Confirmation" },
        { "value": "text", "label": "Text" },
        { "value": "single", "label": "Single Choice" },
        { "value": "multiple", "label": "Multiple Choices" },
    ],
    DISCLAIMER:"Disclaimer"
}