const styles = (theme) => ({


    autoComplete: {
        '& .MuiOutlinedInput-root': {
            padding: '0 9px',
            height: 34,
            minHeight: 34
            
        }
    },
    option: {
        // Selected
        '&[aria-selected="true"]': {
          backgroundColor: `#f1f1f1 !important`
        },
    }


})

export default styles