import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid'; // Grid component from MUI v5
import Backdrop from '@mui/material/Backdrop'; // Backdrop component from MUI v5
import IconButton from '@mui/material/IconButton'; // IconButton component from MUI v5
import { withStyles } from "@mui/styles"; // withStyles function from MUI v5
import Drawer from '@mui/material/Drawer'; // Drawer component from MUI v5
import moment from "moment"

//images and Styles
import AllianceLogo from '../../assets/images/logo.png'
import { HomeIcon, MenuIcon, TelephoneIcon, LocationIcon } from '../../assets/svg'
import TenantApplicationStyles from "./TenantApplicationStyles"

// Import Components
import { Typography, SesionTime, AlertDialog, Button as Btn, CountDown } from "../../components";
import ApplicationTabs from './ApplicationTabs/ApplicationTabs';
import ApplicationTabsEmp from './ApplicationTabs/ApplicationTabsEmp';
import InvalidURLPage from "./InvalidURLPage"

// Import Cofig Constants
import { tenantAppAction } from "../../redux/actions";
import history from "../../config/history";

import { isSafari } from '../../utils/appHelper';

const TenantApplication = (props) => {
  // Define Dispatch
  const dispatch = useDispatch()

  //get props
  const { classes, match: { params, path } } = props;

  const urlPath = path && path.indexOf("/view/") > -1 ? "view" : path && path.indexOf("/link/") > -1 ? "link" : "a";
  const applicationURL = params && params.appId ? params.appId : ""

  //set state gor mobile view
  const [drawer, setDrawer] = useState(false);
  const [alertPopup, setAlertPopup] = useState(null)
  const [alertContent, setAlertContent] = useState(true);
  const [invalidPage, setInvalidPage] = useState(false);
  const [userAge, setUserAge] = useState(DOB && new Date(DOB) != "Invalid Date" ? moment().diff(DOB, 'years', false) : 50)
  const [safariPopup, setSafariPopup] = useState(true);
  const [safariKnow, setSafariKnow] = useState(false);

  //get reducer data
  const { appData, appData: { appId, logoData, application, appName, appType, DOB } } = useSelector(s => (s.tenantApp));
  const { loading } = useSelector(s => s.UI);

  //open close compnay details in mobile view
  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setDrawer(open);
  };

  useEffect(() => {
    dispatch(tenantAppAction.getApplicationData(params))
  }, [dispatch])

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setInvalidPage(true)
      }, 800);
    }
  }, [loading])

  //update DOB
  useEffect(() => {
    setUserAge(DOB && new Date(DOB) != "Invalid Date" ? moment().diff(DOB, 'years', false) : 50)
  }, [appData])

  const openPopup = () => {
    setAlertPopup(true)
  }

  return (
    <React.Fragment>
      {!loading &&
        <React.Fragment>
          {appId && logoData && logoData["active"] === 1 && (application.mangerList && application.mangerList.length > 0) ?
            <React.Fragment>
              <SesionTime timeOut={840000} onTimeout={openPopup} />
              <Grid container>
                <Grid xs={12} className={classes.tenantHeader}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} md={6} className={classes.headerLogoAddress}>
                      <img className={`${classes.logoImage} ${"pr-2 smShow"}`} src={logoData && logoData["useLogo"] && logoData["logo"] ? logoData["logo"] : AllianceLogo} alt="Logo" />
                      <Grid container direction="column" className={`${"addressContainer"} ${classes.addressContainer}`}>
                        <Grid item xs={12} className={`${classes.mobileHeader} ${"mobileShow xs-pb-1 "}`}>
                          <img className={`${classes.logoImage}`} src={logoData && logoData["useLogo"] ? logoData["logo"] && logoData["logo"] : AllianceLogo} alt="Logo" />
                          <IconButton onClick={toggleDrawer(true)} className={classes.padding5} size="large"><MenuIcon /></IconButton>
                        </Grid>
                        <Grid className="sm-pl-1 smShow">
                          <Grid container className={classes.topName}>
                            <Grid className="pr-2">
                              <Typography variant="body1" className={"flex alignCenter"}><HomeIcon />{logoData && logoData["company"] ? logoData["company"] : ""}</Typography>
                            </Grid>
                            <Grid>
                              <Typography variant="body1" className={"flex alignCenter"}><TelephoneIcon />{logoData && logoData["phone"] ? logoData["phone"] : ""}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid className="sm-pl-1 smShow">
                          {logoData && logoData["common"] === 0 && logoData["address"] && logoData["address"].trim() && <Grid container alignItems="center">
                            <Typography variant="body1" className={"flex alignCenter"}><LocationIcon />{logoData["address"] || ""} </Typography>
                          </Grid>}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6} className={"appTitle"}>
                      <Typography variant="h3" className="tenantTitle">{appName ? appName : ""}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} className={classes.tenantBody}>

                  {appType === "employment" ?
                    <ApplicationTabsEmp urlPath={urlPath} applicationURL={applicationURL} />
                    :
                    <ApplicationTabs urlPath={urlPath} applicationURL={applicationURL} />
                  }

                </Grid>
              </Grid>
              <Backdrop className={classes.backDrop} onClick={toggleDrawer(false)} open={drawer} />
              <Grid className={`${"addressDrawer"} ${classes.addressDrawer}`}>
                <Drawer anchor='right' role="presentation" variant="persistent" open={drawer} onClose={toggleDrawer(false)} className={classes.allianceDrawer}>

                  <Grid className="sm-pl-1">
                    <Grid container className={classes.topName}>
                      <Grid><Typography variant="body1" className={"flex alignCenter mb-1"}><HomeIcon />{logoData && logoData["company"] ? logoData["company"] : ""}</Typography></Grid>
                      <Grid className=""><Typography variant="body1" className={"flex alignCenter mb-1"}><TelephoneIcon />{logoData && logoData["phone"] ? logoData["phone"] : ""}</Typography></Grid>
                    </Grid>
                  </Grid>
                  <Grid className="sm-pl-1">
                    {logoData && logoData["common"] === 0 && logoData["address"] && logoData["address"].trim() && <Grid container alignItems="baseline">
                      <Typography variant="body1" className={"flex flexStart"}><LocationIcon />{logoData["address"] || ""} </Typography>
                    </Grid>}
                  </Grid>

                </Drawer>
              </Grid>
              {alertPopup &&
                <AlertDialog
                  fullScreen={false}
                  open={true}
                >
                  {alertContent ? <Grid className={'p-4'}>
                    <SesionTime timeOut={300000} onTimeout={() => { setAlertContent(false) }} />
                    <CountDown />
                    <Typography variant="h6">{`Are you still there?`}</Typography>
                    <Grid className={'pt-2'} align={"center"}>
                      <Btn
                        color={"primary"}
                        className={"mr-1"}
                        onClick={() => { setAlertContent(true); setAlertPopup(null); }}
                        title={"Yes"}
                      />
                      <Btn
                        color={"secondary"}
                        onClick={() => { setAlertPopup(null); setAlertContent(true); window.location.reload(false); }}
                        title={"No"}
                      />
                    </Grid>
                  </Grid> :
                    <Grid className={'p-4'}>
                      <Typography variant="h6">{`Your session has expired`}</Typography>
                      <Typography variant="h6">{`Click on the button below to start again`}</Typography>
                      <Grid className={'pt-2'} align={"center"}>
                        <Btn
                          color={"primary"}
                          className={"mr-1"}
                          onClick={() => { setAlertPopup(null); setAlertContent(true); window.location.reload(false); }}
                          title={"Start"}
                        />
                      </Grid>
                    </Grid>}
                </AlertDialog>
              }
            </React.Fragment>
            : <React.Fragment>{invalidPage && <InvalidURLPage />}</React.Fragment>
          }
        </React.Fragment>
      }
      {appType && appType === "resident" && userAge < 18 &&
        <AlertDialog
          fullScreen={false}
          open={true}
        >
          <Grid className={'p-4'}>
            <Typography variant="h6">{`Applicants under 18 are not eligible for Tenant Application kindly confirm your date of birth entered`}</Typography>
            <Grid className={'pt-2'} align={"center"}>
              <Btn
                color={"secondary"}
                onClick={() => { setUserAge(50) }}
                title={"Close"}
              />
            </Grid>
          </Grid>
        </AlertDialog>}
      {(isSafari() && safariPopup) &&
        <AlertDialog
          fullScreen={false}
          open={true}
        >
          <Grid className={'p-4'}>
            <Typography align={'center'} variant="h5">{`Please turn off autofill before proceeding with the application to avoid incorrect information in text fields.`}</Typography>
            <Typography align={'center'} variant="h5">{`Autofill can be turned off in Safari > Preferences > AutoFill`}</Typography>

            {safariKnow && <Typography className="pt-2" align={'center'} variant="h6">{`Safari autofills your information for anyone who uses your Mac with your user login. It also autofill’s your information for anyone who uses your other devices, if those devices and your Mac have iCloud Keychain set up`}</Typography>}
            <Grid className={'pt-2'} align={"center"}>
              {!safariKnow && <Btn
                color={"secondary"}
                onClick={() => { setSafariKnow(true) }}
                title={"Know More"}
              />}
              <Btn
                className="ml-1"
                color={"primary"}
                onClick={() => { setSafariPopup(false) }}
                title={"OKAY"}
              />
            </Grid>
          </Grid>
        </AlertDialog>}
    </React.Fragment>
  );
}

// default props
TenantApplication.defaultProps = {
  classes: {}
};

// prop types
TenantApplication.propTypes = {
  classes: PropTypes.object
};

export default withStyles(TenantApplicationStyles)(TenantApplication);
