import React from "react";
import { StepConnector } from '@mui/material';
import { withStyles } from "@mui/styles";

//connector styles
const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#3abeff',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#3abeff',
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

export default QontoConnector;
