import React from "react";
import { useForm } from 'react-hook-form';
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import Lottie from 'react-lottie'

//Images
import animationData from '../../../assets/images/TickAnimation.json'

//Styles
import ServicesStyles from "../ServicesStyles"

// Import Components
import { Button, TextField, Typography, CheckBox, Link } from "../../../components";

// Import Helpers
import validations from "../../../utils/validations";

// Import Cofig Constants
import { user_page_constants } from "../../../config/constants";

const AddedSuccessfully = (props) => {

    const { classes, title, handleGoToService } = props;

    const animateProps = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Grid container alignItems={"center"} alignContent="baseline" justifyContent="center" className={classes.SuccessImg}>
            <Grid item xs={12} align="center">
                <Lottie options={animateProps} height={280} width={280} />
                <Typography variant="h4" align="center" className={"pb-1"}>{user_page_constants.SUCCESSFULLY_ADDED}</Typography>
                <Typography variant="h6" align="center" className={"pb-2"}>{user_page_constants.SUCCES_TEXT}</Typography>
                <Grid item className="mt-1">
                    <Button size="small" color="primary" title={title} onClick={handleGoToService}></Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
AddedSuccessfully.defaultProps = {
    classes: {}
};

// prop types
AddedSuccessfully.propTypes = {
    classes: PropTypes.object
};

export default withStyles(ServicesStyles)(AddedSuccessfully);
