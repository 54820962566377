import { combineReducers } from "redux";

// Import Reducers
import alertReducer from "./alertReducer";
import authReducer from "./authReducer";
import userReducer from './userReducer';
import serviceReducer from "./serviceReducer"
import uiReducer from './uiReducer';
import templateReducer from "./templateReducer";
import submissionReducer from "./submissionReducer";
import applicationReducer from './applicationReducer'
import auditReducer from './auditReducer';
import tenantAppReducer from './tenantAppReducer';
import settingsReducer from './settingsReducer';
import directoryReducer from './directoryReducer'

export default combineReducers({
  alert: alertReducer,
  auth:authReducer,
  user:userReducer,
  service:serviceReducer,
  template:templateReducer,
  submission:submissionReducer,
  UI:uiReducer,
  application:applicationReducer,
  audit:auditReducer,
  tenantApp:tenantAppReducer,
  settings:settingsReducer,
  directory:directoryReducer
});
