import * as apihelper from "../../utils/apiHelper";

/**
 * Export All Authentication Service Functions
 */
export const directoryService = {
  search,
  getAll,
  remove,
  addDirectories,
  editDirectories,
  getOne,
  getDirectoryFrame
};

/**
 * Call directory for iframe
 */
 async function getDirectoryFrame(data) {
  const response = await apihelper.postRequest(`directory/frames`, data);
  return response;
}

/**
 * Call directory Get All Search Service
 */
async function search(skip, limit, sortBy, sort, sFields, sValues) {
  const response = await apihelper.postRequest(`directory/search?skip=${skip}&limit=${limit}&sortBy=${sortBy}&sort=${sort}`, { sFields, sValues, filter: false });
  return response;
}

/**
* Get All directory for chord view
*/
async function getAll(data) {
  const response = await apihelper.postRequest(`directory/getAll`, data);
  return response;
}

/**
* Delete directory
* @param id
*/
async function remove(data) {
  const response = await apihelper.deleteRequest("directory", data);
  return response;
}

/**
* add directory
*/
async function addDirectories(data) {
  const response = await apihelper.postRequest("directory/add", data);
  return response;
}

/**
* edit directory
*/
async function editDirectories(data) {
  const response = await apihelper.postRequest("directory/edit", data);
  return response;
}

/**
* getone directory
*/
async function getOne(data) {
  const response = await apihelper.postRequest("directory/getOne", data);
  return response;
}

