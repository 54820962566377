import '../../assets/theme/palette';
import palette from '../../assets/theme/palette';
const DirectoryStyles = () => ({
  searchIcon: { padding: 6, marginRight: 10 },
  marginBottom15: { marginBottom: 15 },
  btnBlue: {
    backgroundColor: `${palette.primary.dary} !important`,
    color: '#fff !important',
    '&.MuiButton-root.Mui-disabled': {
      backgroundColor: `${palette.primary.dary} !important`,
      color: '#fff !important'
    },
    color: "#fff",
    '&:hover': {
      color: "#fff",
      backgroundColor: '#2279a5 !important',
    }
  },
  directorysTable: {
    paddingLeft: '16px',
    paddingRight: '16px'
  },
  addAServiceCenter: {
    // height: 'calc(100% - 130px)',
    maxWidth: 1000,
    margin: "auto"
  },
  toggleButton: {
    background: '#fff',
    '& .Mui-selected': {
      backgroundColor: palette.primary.dary,
      '& svg path': {
        fill: '#fff !important'
      },
      '& rect': {
        fill: '#fff !important'
      }
    }
  },
  titlerow: {
    height: '34px',
    padding: '0 16px',
    marginBottom: 4,
    // backgroundColor: 'blue',
    // padding: '10px 0',
    '& .MuiToggleButton-root': {
      padding: 7
    }
  },
  addAServiceTop: {
    height: 65
  },

  cardSection: {
    height: '100%',
    width: "100%",
    borderRadius: '4px',
    boxShadow: '0px 0px 8px #e4e4e4',
    padding: '4px 16px',
    '& .MuiStep-completed': {
      '& .MuiSvgIcon-root': {
        fill: "#3abeff"
      },
      '& .StepperCircle g': {
        stroke: '#3abeff !important'
      },
    }
  },

  addServiceDialog: {
    height: '100%',
    '& .MuiStepper-root': {
      padding: 3,
      width: '50%'
    }
  },

  settingsTileBgnoneAddDoc: {
    padding: '10px',
    border: `1px solid ${palette.greyshades.light2}`,
    borderRadius: 4,
    backgroundColor: '#fff',
    marginBottom: 12,
    '& .MuiTypography-root': {
      width: '70%',
    },
    '& .MuiSvgIcon-root': {
      width: '10%',
    },
    '& .dragIcon': {
      cursor: "move"
    }
  },

  dragIcon: {
    marginRight: 5,
    cursor: 'pointer',
    fill: palette.greyshades.light1
  },
  widthAuto: {
    '& .MuiSvgIcon-root': {
      width: "auto !important"
    }
  },

  choicesContainer: {
    padding: 20,
    background: palette.greyshades.light3,
    border: `1px solid ${palette.greyshades.light2}`,
  },
  addServiceDialogRoot: {
    '& .MuiDialog-paper': {
      backgroundColor: palette.greyshades.light3
    }
  },

  //Card
  directoryTile: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 8px #d4d1d1',
    borderRadius: 4,
    paddingTop: 1,
    // height: 190,
    // padding: 20,
    '& .MuiCheckbox-root': {
      padding: 0
    },
    '& .MuiFormControlLabel-root': {
      marginLeft: 0
    },
  },
  topContainerDiv: {
    maxWidth: 'calc(100% - 74px)'
  },
  directoryTileContainer: {
    margin: '0 20px 20px 20px',
    overflow: "hidden",
    height: 120
  },
  servicePriceChip: {
    background: '#F7F7F7',
    borderRadius: 30,
    padding: "2px 10px",
    marginRight: 5
  },

  //Drawer
  backDrop: {
    zIndex: 3
  },
  allianceDrawer: {
    '& .MuiDrawer-paper': {
      width: 400
    }
  },
  usersRightTiles: {
    height: 'calc(100vh - 125px)',
    overflowY: 'auto',
    flexGrow: 1,
    transition: 'all 0.5s ease'
  },

  searchBoxGrid: {
    marginRight: '-10px',
    transition: 'all 0.4s ease',
    '& .searchBox': {
      // transition: '0.5s',
      // width: 200,
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: '8px'
      }
    },
    '& .MuiOutlinedInput-root': {
      height: '32px',
      minHeight: 32,
    }
  },

  servicesSettingsTile: {
    padding: '8px 13px 8px 7px',
    border: `1px solid ${palette.greyshades.light2}`,
    borderRadius: 4,
    backgroundColor: palette.greyshades.light3,
    // marginBottom: 15,
    minHeight: 42,
    '& .MuiFormControlLabel-root': {
      marginRight: '0px !important'
    },
    '& .dragIcon': {
      cursor: "move"
    },
    '& .settingSTileCheck': {
      marginRight: 0,
      padding: 0,
      '& .MuiFormControlLabel-root': {
        marginRight: '0px !important'
      }
    }
  },
  uploadImgPopup: {
    height: "175px",
    width: "175px",
    objectFit: 'contain',
    cursor: 'pointer'
  },
  uploadImgOpacity: {
    opacity: 0,
    height: "175px",
    width: "175px",
  },
  cardSectionDialogAddTemplate: {
    background: '#fff',
    boxShadow: '0px 0px 8px #d4d1d1',
    borderRadius: '6px',
    minHeight: 400,
    // height: 420,
    height: 'calc(100vh - 260px)',
    padding: 20,
    overflowY: 'auto !important'
  },
  cardSectionDialog:
  {
    background: '#fff',
    boxShadow: '0px 0px 8px #d4d1d1',
    borderRadius: '6px',
    minHeight: 400,
    // height: 420,
    height: 'calc(100vh - 260px)',
    padding: 20,
    overflowY: 'hidden !important',
    '& .pageScrollVerticle > div ': {
      height: '330px !important'
    },
    '& .pageScrollVerticle > div > div': {
      height: '330px !important'
    },
    '& .pageScrollVerticle > div > div > div': {
      height: '330px !important',
      background: '#f7f7f7',
      padding: 15,
      overflowY: 'auto'
    },
    '& .pageScrollVerticle': {
      '& .MuiAppBar-colorDefault': {
        backgroundColor: '#fff !important'
      }
    },
    '& .MuiOutlinedInput-multiline': {
      borderRadius: '3px !important'
    },
    '& .createTemChoice': {
      backgroundColor: '#fff !important',
      // margin: 5
    },
    '& .createTempChoiceTextField': {
      width: 'calc(100% - 80px)',
      height: 'auto !important'
    },
    createTempChoiceText: {
      width: 'calc(100% - 150px)'
    }
  },
  contentTab: {
    '& .rdw-editor-main': {
      height: '250px !important'
    },
    '& .rdw-editor-toolbar': {
      backgroundColor: 'transparent'
    },
    '& .rdw-option-wrapper, .rdw-dropdown-wrapper': {
      backgroundColor: 'transparent'
    }
  },
  grow: {
    flexGrow: 1
  },
  notifyIcon: {
    width: 28,
    height: 28,
    background: palette.greyshades.light2
  },
  borderNone: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px !important'
    }
  },
  padding5: { padding: 5 },
  backDrop: {
    zIndex: 3
  },
  allianceDrawer600: {
    '& .MuiDrawer-paper': {
      width: 600
    }
  },

  //Add User
  selectBox: {
    width: "100%",
    '& .MuiSelect-outlined': {
      padding: 10,
      textAlign: 'left',
      borderRadius: 30
    },
    '& .MuiOutlinedInput-notchedOutline legend': {
      display: "none !important"
    },
    '& .MuiOutlinedInput-notchedOutline': {
      marginTop: "5px !important"
    },
  },
  profileImgContainer: {
    height: 176,
    width: 176,
    cursor: "pointer",
    background: "#F7F7F7",
    '& svg': {
      width: '100%',
        cursor: "pointer",
        height: 'auto'
    },
    '& .deleteIcon': {
      height: 12,
      width: 12
    }
  },
  uploadImgPopup: {
    height: 175,
    width: 175,
    objectFit: 'contain',
    cursor: 'pointer'
  },
  uploadImgOpacity: {
    opacity: 0,
    height: 175,
    width: 175,
  },
  allianceDrawer: {
    '& .MuiDrawer-paper': {
      width: 400
    }
  },
  grow: {
    flexGrow: 1
  },
  cardSectionProfileImg: {
    height: '100%',
    width: "100%",
    borderRadius: '4px',
    boxShadow: '0px 0px 8px #d4d1d1',
    padding: '8px',
    '& .closeIconBtn': {
      position: 'absolute',
      background: '#fff',
      borderRadius: '30px',
      top: '12px',
      right: '12px',
      width: '18px',
      height: '18px',
      padding: 2,
      '&:hover': {
        background: 'red',
        '& path': {
          fill: '#fff'
        }
      }
    }
  },
  uploadText: {
    position: 'absolute',
    bottom: '7px',
    padding: '7px',
    width: '176px',
    background: '#e8e8e88c',
    textAlign: 'center',
    color: '#b0b0b0',
    cursor: 'pointer'
  },
  addDirectoryContainer: {
    padding: '8px 16px 16px 16px'
  },
  usersCardSection: {
    height: '100%',
    width: "100%",
    borderRadius: '4px',
    boxShadow: '0px 0px 8px #d4d1d1',
    padding: '8px 16px',
    maxHeight: 'calc(100vh - 128px)',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  titleContainer: {
    paddingLeft: '3.5% !important',
    paddingRight: '3.5% !important',
    paddingLeft:"56px !important"
  },
  groupTitle: {
    background: palette.primary.main,
    color: '#fff',
    borderLeft: '5px solid black',
    padding: '7px 10px'
  },
  addUSerAction: {
    paddingLeft: '2.6%'
  },
  activeCheckBox: {
    paddingLeft: '59px !important'
  },
  userProfileDoNot: {
    textAlign: 'center',
    marginTop: '15px'
  },
  seachIcon: {
    fill: palette.greyshades.light1
  },
  checkBoxCls: {
    '& .MuiIconButton-root': {
      marginRigh: 12
    }
  },

  padding6: { padding: 6 },

  moreContainerTop: {
    marginBottom: -18
  },

  tileMoreIcon: {
    marginRight: -3,
    marginTop: -3,
    padding: 5,
    // width: 20,
    // height: 20
  },
  servicesTable: {
    paddingRight: 16,
    paddingLeft: 16,

  },
  userActiveGreen: {
    background: "#2AE133",
    height: 8,
    width: 8,
    position: 'absolute',
    borderRadius: '100%',
    top: 3,
    right: 2,
    border: '1.5px solid #fff'
  },
  userActiveGrey: {
    background: "gray",
    height: 8,
    width: 8,
    position: 'absolute',
    borderRadius: '100%',
    top: 3,
    right: 2,
    border: '1.5px solid #fff'
  },
  tileTop: {
    padding: '0px 20px 16px 20px',
    marginTop: -18,
    height: 83
  },
  tileBottom: {
    borderTop: `1px solid ${palette.greyshades.light2}`
  },
  tileBottomLt: {
    padding: '10px 20px 10px 20px',
    borderRight: `1px solid ${palette.greyshades.light2}`,
    height: 66
  },
  tileBottomRt: {
    padding: '10px 20px 10px 20px',
    height: 66
  },
  selectTemplate: {
    backgroundColor: palette.greyshades.light3,
    border: `1px solid ${palette.greyshades.light2}`,
    padding: 20
  },
  settingsTileBgnone: {
    padding: '13px',
    border: `1px solid ${palette.greyshades.light2}`,
    borderRadius: 4,
    backgroundColor: '#fff',
    '& .MuiTypography-root': {
      width: '70%',
      marginRight: 5
    },
    '& .MuiSvgIcon-root': {
      width: '10%',
    },
    '& .dragIcon': {
      cursor: "move",
      marginRight: 5
    }
  },
  checkIcon: {
    fill: palette.primary.dary
  },
  settingsTile: {
    padding: '8px 13px 8px 7px',
    border: `1px solid ${palette.greyshades.light2}`,
    borderRadius: 4,
    backgroundColor: palette.greyshades.light3,
    marginBottom: 15,
    height: 42,
    '& .MuiFormControlLabel-root': {
      marginRight: '0px !important'
    },
    '& .dragIcon': {
      cursor: "move"
    },
    '& .settingSTileCheck': {
      marginRight: 0,
      padding: 0,
      '& .MuiFormControlLabel-root': {
        marginRight: '0px !important'
      }
    }
  },
  dragContainer: {
    height: 24
  },
  DirectoryUser: {
    position: "relative",
    background: palette.greyshades.light2,
    borderRadius: '100%',
    height: 44,
    width: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  // serviceSettingTileWidth: {
  //   maxWidth: '46% !important',
  //   flexBasis: '46% !important'
  // }
  error: {
    '& .editor-class': {
      border: '1px solid red !important'
    }
  },
  ColumnSettingsBody: {
    maxHeight: 'calc(100vh - 138px)',
    overflowY: 'auto'
  },
  hintText:{
    fontSize: "12px",
    margin: "0px",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontStyle: "italic",
    textAlign: "left",
  },
  // Directory list container
  DirectoryListContainer: {
    padding: 24,
    boxShadow: '0px 0px 8px #d4d1d1',
    borderRadius: '4px',
    background: '#fff',
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 24px)'
  },
  DirectoryListDiv: {
    padding: 24
  },
  searchListContainer: {
    padding: 16,
    // border: '1px solid #ddd',
    borderRadius: 4,
    marginBottom: '8px',
    boxShadow:' 0 0 8px #ddd',
    '& .resultLabel': {
      paddingBottom: '7px',
      fontWeight: '500',
      color: '#424242'
  }
  },
  userImage: {
    width: 180,
    height: 180,
    borderRadius: 4,
    marginTop: 3,
    boxShadow: '0px 0px 8px #cccccc',
  },
  

})

export default DirectoryStyles;