import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { Grid } from '@mui/material';

//import Styles and images
import AuditStyles from "./AuditStyles"

// Import Components
import { Typography } from "../../components";
import ColumnSettings from './ColumnSettings';
import AuditList from './AuditList';

// Import Cofig Constants
import { audit_page_constants } from "../../config/constants";

const Audit = (props) => {

  //get props
  const { classes, match} = props;

  return (
    <React.Fragment>
      <Grid item xs={12} >
        <Grid container direction="row" justifyContent="space-between" alignItems="center" className={`${classes.titlerow}`}>
          <Grid item>
            <Typography variant="h3" component="h3"> {audit_page_constants.AUDIT} </Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <ColumnSettings />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AuditList {...props} />
    </React.Fragment>
  );
}

// default props
Audit.defaultProps = {
  classes: {}
};

// prop types
Audit.propTypes = {
  classes: PropTypes.object
};

export default withStyles(AuditStyles)(Audit);
