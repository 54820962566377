import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid';
import arrayMove from "array-move";
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import { withStyles } from "@mui/styles";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";

//Styles
import ServicesStyles from "../ServicesStyles"

//Images
import { DragIcon, CheckSelectedIcon, DeleteIcon, BellIcon } from '../../../assets/svg'


// Import Components
import { Button, TextFieldWithoutRef, Typography, CheckBox, Link, ToolTip } from "../../../components";

// Import Helpers
import validations from "../../../utils/validations";

// Import Constants
import { serviceConstant } from "../../../redux/constants";
import { user_page_constants } from "../../../config/constants";

// Import Actions
import { serviceAction, updateReducerAction } from "../../../redux/actions";

//Sortable Item
const SortableItem = SortableElement(props => {
  return (
    props.children
  );
});

//Drag Handle
const DragHandle = SortableHandle((props) => {
  const { classes } = props;

  return (<DragIcon className={"cla"} draggable={false}></DragIcon>);
})

//create sortable list for drag and drop
const SortableList = SortableContainer(props => {

  //define dispatch
  const dispatch = useDispatch();

  //get props
  const { data, classes, checkedData, updateCheckedData } = props;

  return (
    <React.Fragment>
      <Grid container spacing={2} justifyContent={"space-between"}>
        {data && data.map((item, index) => (
          <SortableItem
            key={`sort-item-${index}`}
            index={index}
          >
            <Grid item sm={12} className={classes.serviceSettingTileWidth}>
              <Tooltip arrow="top" title={item.name}>
                <Grid container alignItems="center" className={`${'bg-white'} ${classes.servicesSettingsTile}`}>
                  <Grid className={`${"pr-1"} ${classes.dragContainer}`}><DragHandle /></Grid>
                  <Grid style={{ width: 'calc(100% - 35px)' }}>
                    <Grid container>
                      <Typography variant={"body2"} title={item.name} className={`${"oneLineEllip textFieldLabel"} ${classes.agreementDargText}`}></Typography>
                      <CheckBox
                        className={"settingSTileCheck"}
                        checked={checkedData.indexOf(item) > -1}
                        handleChange={() => { updateCheckedData(item, index) }}
                        icon
                        checkedIcon
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Tooltip>

            </Grid>
          </SortableItem>
        ))}
      </Grid>
    </React.Fragment>
  );
})

const AddAgreement = (props) => {

  //define dispatch
  const dispatch = useDispatch();

  const { classes } = props;

  const { createFromScratch, createFromScratchDocuments, createFromScratchErrorFlags: errorFlags } = useSelector((state) => state.service);

  const [data, setData] = useState(createFromScratchDocuments.data);
  const [checkedData, setCheckedData] = useState(createFromScratchDocuments.checkedData);

  // Set Form Values
  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {}
  });

  const handleChange = (e) => {
    createFromScratch[e.target.name] = e.target.value
    dispatch(updateReducerAction.update(serviceConstant.ADD_NEW_PAGE_DOCUMENT, createFromScratch, () => { }));
  }

  // Submit Form
  const onSubmit = (data) => {
    console.log(data)
  }

  //chakne row index on sort
  const onSortEnd = (e) => {
    const { oldIndex, newIndex } = e;
    const resultData = arrayMove(data, oldIndex, newIndex);
    setData(resultData)
    createFromScratchDocuments.data = resultData
    dispatch(updateReducerAction.update(serviceConstant.UPDATE_AGREEMENT_DOCUMENT, createFromScratchDocuments, () => { }));
  }

  //update checked data
  const updateCheckedData = (com, ind) => {
    const compIndex = checkedData.indexOf(com);
    let updateData = []
    if (compIndex === -1) {
      updateData = [...checkedData, com]
    } else {
      updateData = [...checkedData]
      updateData.splice(compIndex, 1)
    }
    setCheckedData(updateData)
    createFromScratchDocuments.checkedData = updateData
    dispatch(updateReducerAction.update(serviceConstant.UPDATE_AGREEMENT_DOCUMENT, createFromScratchDocuments, () => { }));
  }

  return (

    <Grid item xs={12} className={classes.contentcontainer} >
      <Typography variant="h4" className="mt-3 mb-3" align="center">{user_page_constants.ADD_AN_AGREEMENT}</Typography>
      <Paper elevation={3} className={classes.cardSectionDialogAddTemplate} >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid item sm={12}>
            <Grid container spacing={3}>
              <Grid item sm={7} align="left">
                <Grid item sm={6} className="mb-2">
                  <Typography className="textFieldLabel" title={user_page_constants.AGREEMENT_NAME} variant={"body1"}><span className={"reqStar"}>*</span></Typography>
                  <TextFieldWithoutRef
                    name={"agreement_name"}
                    margin="none"
                    size={"small"}
                    value={createFromScratch["agreement_name"] ? createFromScratch["agreement_name"] : ""}
                    handleChange={(e) => handleChange(e)}
                    error={errorFlags.agreement_name ? true : false}
                    helperText={errorFlags.agreement_name ? "Agreement name cannot be blank" : ""}
                  />
                </Grid>
                <Grid item sm={6}></Grid>
              </Grid>
              <Grid item sm={5} align="right">
                <Grid className={classes.selectTemplate}>
                  <Typography variant={"h6"} title={user_page_constants.DOCUMENTS} className="mb-2" align="center"></Typography>
                  <SortableList
                    useDragHandle
                    axis="xy"
                    enableUserSelectHack={false}
                    helperClass="sortable-list-tab"
                    data={data}
                    onSortEnd={onSortEnd}
                    checkedData={[...checkedData]}
                    updateCheckedData={updateCheckedData}
                    classes={classes}
                  ></SortableList>
                  {errorFlags.checkedDocuments &&
                    <span style={{ color: 'red' }}>{"Select atleast one document."}</span>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </form>
      </Paper>
    </Grid>

  );
}

// default props
AddAgreement.defaultProps = {
  classes: {}
};

// prop types
AddAgreement.propTypes = {
  classes: PropTypes.object
};

export default withStyles(ServicesStyles)(AddAgreement);
