import React ,{ useEffect }from "react";
import { useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid'; // Grid from v5
import Box from '@mui/material/Box'; // Box from v5
import { withStyles } from "@mui/styles"; // withStyles from v5, assuming you're using the v5 styling solution
import AppBar from '@mui/material/AppBar'; // AppBar from v5
import Tabs from '@mui/material/Tabs'; // Tabs from v5
import Tab from '@mui/material/Tab'; // Tab from v5

//Styles
import UsersStyles from "../../UsersStyles"

// Import Config Layout
import { getChildContainerTabsHeight } from "../../../../config/layout"

// Import Components
import { Typography, PageContainer } from "../../../../components";
import GeneralTab from './GeneralTab';
import Services from '../services/Services';
import AuditLogs from '../AuditLogs/AuditLogs';

//import action
import { userAction } from "../../../../redux/actions";

//user tab component
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className="h-100">
                    {children}
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

//add edit user panel
const AddUserContainer = (props) => {
    // Define Dispatch
    const dispatch = useDispatch();

    //get props
    const { classes, match } = props;

    //set edit flag true for new user
    const isEdit=match.params && match.params.id ? true : false ;


    //get reducerData
    const { getUser } = useSelector(state => state.user);
    let serviceTabflag = true
    if(getUser && getUser.ownership_id === 3){
        serviceTabflag = false
    }

    useEffect(()=>{
        if(isEdit && getUser && Object.keys(getUser).length===0){
            dispatch(userAction.getUser({ id: match.params.id || null }))
        }
    },[dispatch])

    //Get container height
    const height = getChildContainerTabsHeight();

    //set state for user tabs
    const [tabValue, setTabValue] = React.useState(0);

    //Tab container props binding
    const getProps=(index)=>{
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    
    const updateTabValue = (newValue) =>{
        if(isEdit && (newValue===0 || newValue==="0")){
            dispatch(userAction.getUser({ id: match.params.id || null }, () => {
                setTabValue(newValue)
            }))
        }else{
            setTabValue(newValue)
        }
    }

    return (
        <React.Fragment>
            <Grid item xs={12} className={classes.bodycontainer}>
                <Grid item xs={12} >
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.titlerow}>
                        <Grid item>
                            <Typography variant="h3" component="h3">{isEdit?"Edit user" :'ADD Users'} </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <AppBar position="static" color="default" className={classes.appBarCls}>
                        <Tabs
                            value={tabValue}
                            onChange={(e,newValue)=>{updateTabValue(newValue)}}
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="full width tabs example"
                        >
                            <Tab style ={{minWidth :'160px' , padding:"7px 12px 10px"}} label={<Typography variant="h6" color="TextSecondary">{"General"}</Typography>} {...getProps(0)} />
                            { isEdit && serviceTabflag && <Tab style ={{minWidth :'160px' , padding:"7px 12px 10px"}} label={<Typography variant="h6" color="TextSecondary">{"Services"}</Typography>} {...getProps(1)} />}
                            { isEdit && <Tab style ={{minWidth :'160px' , padding:"7px 12px 10px"}} label={<Typography variant="h6" color="TextSecondary" >{"Audit Logs"}</Typography>} {...getProps(2)} />}
                        </Tabs>
                    </AppBar>

                    <Grid item xs={12}>
                        <TabPanel value={tabValue} index={0}>
                            <PageContainer height={height} paddingRight={12}>
                                {(!isEdit || (isEdit && Object.keys(getUser).length>0)) && <GeneralTab  {...props}/>}
                            </PageContainer>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1} className="h-100">
                            {serviceTabflag? <Services /> : <AuditLogs />}
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <PageContainer height={height} paddingRight={12}>
                                <AuditLogs />
                            </PageContainer>
                        </TabPanel>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

// default props
AddUserContainer.defaultProps = {
    classes: {}
};

// prop types
AddUserContainer.propTypes = {
    classes: PropTypes.object
};

export default withStyles(UsersStyles)(AddUserContainer);
