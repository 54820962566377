// particpants reducer types
export const submissionConstant = {

    // To Search submission Details
    SUBMISSION_SEARCH_REQUEST: "SUBMISSION_SEARCH_REQUEST",
    SUBMISSION_SEARCH_SUCCESS: "SUBMISSION_SEARCH_SUCCESS",
    SUBMISSION_SEARCH_FAILURE: "SUBMISSION_SEARCH_FAILURE",

    // DELETE SUBMISSION
    SUBMISSION_DELETE_REQUEST: "SUBMISSION_DELETE_REQUEST",
    SUBMISSION_DELETE_SUCCESS: "SUBMISSION_DELETE_SUCCESS",
    SUBMISSION_DELETE_FAILURE: "SUBMISSION_DELETE_FAILURE",

    // Refund payment SUBMISSION
    SUBMISSION_REFUND_PAYMENT_REQUEST: "SUBMISSION_REFUND_PAYMENT_REQUEST",
    REFUND_PAYMENT_UPDATED_SUCCESS: "REFUND_PAYMENT_UPDATED_SUCCESS",
    SUBMISSION_REFUND_PAYMENT_FAILURE: "SUBMISSION_REFUND_PAYMENT_FAILURE",

    //Download submission attachment
    SUBMISSION_ATTACHMENT_DOWNLOAD_REQUEST: "SUBMISSION_ATTACHMENT_DOWNLOAD_REQUEST",
    SUBMISSION_ATTACHMENT_DOWNLOAD_SUCCESS: "SUBMISSION_ATTACHMENT_DOWNLOAD_SUCCESS",
    SUBMISSION_ATTACHMENT_DOWNLOAD_FAILURE: "SUBMISSION_ATTACHMENT_DOWNLOAD_FAILURE",

    // To get retry submission data
    GET_RETRY_SUBMISSION_REQUEST: "GET_RETRY_SUBMISSION_REQUEST",
    GET_RETRY_SUBMISSION_SUCCESS: "GET_RETRY_SUBMISSION_SUCCESS",
    GET_RETRY_SUBMISSION_FAILURE: "GET_RETRY_SUBMISSION_FAILURE",

    //Retry submission to TAZWorks
    RETRY_SUBMISSION_REQUEST: "RETRY_SUBMISSION_REQUEST",
    RETRY_SUBMISSION_SUCCESS: "RETRY_SUBMISSION_SUCCESS",
    RETRY_SUBMISSION_FAILURE: "RETRY_SUBMISSION_FAILURE",

    ATTACHMENT_SEARCH_REQUEST: "ATTACHMENT_SEARCH_REQUEST",
    ATTACHMENT_SEARCH_SUCCESS: "ATTACHMENT_SEARCH_SUCCESS",
    ATTACHMENT_SEARCH_FAILURE: "ATTACHMENT_SEARCH_FAILURE",
};