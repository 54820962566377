import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { TextField as Core} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';

//import actions
import { alertAction } from "../../redux/actions";

/**
 * Import Component
 * @param {*} props
 */
function ImportComponent({ classes, name, id, className, value, handleChange, accept, ...rest }) {

  // Define Dispatch
  const dispatch = useDispatch();

  //handle image upload
  const imageHandler = (e) => {
    const regEx = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;

    if (e.target.files && e.target.files[0]) {
      let fileSize = e.target.files[0]["size"];
      let fNamee = e.target.files[0].name ? e.target.files[0].name.toString() : "";

      if (fileSize > (5 * 1024 * 1024)) {
        dispatch(alertAction.error("File Size should be less than 5MB"))
      } else if (!regEx.exec(fNamee)) {
        dispatch(alertAction.error("File type not allowed"))
      } else {
        const reader = new FileReader();
        let val = e.target.files[0].name ? e.target.files[0].name.toString() : ""
        reader.onload = () => {
          if (reader.readyState === 2) {
            handleChange({ name: name, value: reader.result, val: val })
          }
        }
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  }

  const clickRoute = () => {
    try {
      if (document.getElementsByName(name) && document.getElementsByName(name)[0]) {
        document.getElementsByName(name)[0].click()
      } else {
        console.log("upload comp else");
      }
    } catch (err) {
      console.log("upload comp err");
    }
  }

  return (
    <React.Fragment>
      <Core
        id={"sa" + id}
        name={name + "tet"}
        className={"importField mt3"}
        onClick={(e) => { clickRoute() }}
        InputProps={{
          endAdornment:
            <React.Fragment>
              <FolderIcon />

            </React.Fragment>
        }}
        value={value}
        {...rest}
      />
      <label>
        <input
          name={name}
          id={id}
          className={`${classes.uploadImgPopup} ${classes.uploadImgOpacity}`}
          id="file-input"
          onChange={(e) => { imageHandler(e) }}
          type="file"
          style={{ display: "none" }}
          accept={accept}
        />
      </label>
    </React.Fragment>
  );
}

// default props
ImportComponent.defaultProps = {
  classes: {},
  className: "",
  handleChange: () => { },
  accept: ".png,.jpeg,.jpg,application/pdf",
  value: "",
  name: "",
  id: "",
  fullWidth: true,
  variant: 'outlined'
};

// prop types
ImportComponent.propTypes = {
  children: PropTypes.any,
  handleChange: PropTypes.func,
  classes: PropTypes.object,
  className: PropTypes.string,
  accept: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,

};

/** Export Component */
export const ImportFile = ImportComponent;
