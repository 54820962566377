import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@mui/styles"; // For withStyles
import { useTheme } from '@mui/material/styles'; // For useTheme
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import _ from "lodash"
import moment from "moment"

//Styles and images
import TenantApplicationStyles from '../TenantApplicationStyles'
import AllianceLogo from '../../../assets/images/logo.png'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import { CloseIcon } from '../../../assets/svg'

// Import Components
import { Typography, AlertDialog } from "../../../components";
import QontoConnector from './Connector'
import Introduction from "./Introduction";
import Criteria from './Criteria'
import Application from './Application'
import Disclosure from './Disclosure'
import Payment from './Payment';
import SquareForm from './SquareForm'
import ApplicationValidation from "./ApplicationValidation"
import ApplicationReview from "./ApplicationReview";

//import actions
import { updateReducerAction, tenantAppAction, alertAction } from "../../../redux/actions";

//import constants
import { tenantAppConstant } from "../../../redux/constants";

//import validations
import { stepperValidation, allStepperValidation } from "./StepValidation";

const ApplicationTabsEmp = (props) => {
    // Define Dispatch
    const dispatch = useDispatch();

    //stepper themes
    const theme = useTheme();

    //set state
    const [appValidation, setAppValidation] = useState(false);
    const [validPopup, setValidPopup] = useState(false);

    //get props
    const { classes, urlPath, applicationURL } = props;

    //state
    const [paymentPop, setPaymentPop] = useState(false)

    //get reducer data
    const { activeStep, completedSteps, steps, stepsDispName, appData: { disclosureConditions, settings }, appData } = useSelector(s => (s.tenantApp))
    const { loading } = useSelector(s => s.UI)

    const appID = settings && settings["keyData"] && settings["keyData"]["s_account"] ? settings["keyData"]["s_account"] : ""
    const locID = settings && settings["keyData"] && settings["keyData"]["s_loc_key"] ? settings["keyData"]["s_loc_key"] : ""

    //define variables
    const totalSteps = steps.length;
    const invalidDOB = appData && appData['appType'] === "resident" && ((appData["DOB"] && new Date(appData["DOB"]) == "Invalid Date") || (appData["DOB"] && new Date(appData["DOB"]) != "Invalid Date" && moment().diff(appData["DOB"], 'years', false) < 18))

    const updateStepReducer = (s, tkn) => {
        console.log(s, activeStep, tkn)
        if (activeStep === totalSteps - 1 || s - 1 === totalSteps - 1) {
            let updateData = {}
            try {
                updateData = _.cloneDeep({ ...appData, token: appData.token ? appData.token : tkn });
            } catch (err) {
                updateData = appData;
            }

            updateData["applicationURL"] = applicationURL;
            for (let key in updateData) {
                if (key === "introduction" && updateData[key] && updateData[key]["sign"]) {
                    updateData[key]["sign"] = {}
                }
                if (key === "criteria" && updateData[key] && updateData[key]["sign"]) {
                    updateData[key]["sign"] = {}
                }
                if (key === "application" && updateData[key] && updateData[key]["sign"]) {
                    updateData[key]["sign"] = {}
                }
                if (key === "disclosure" && updateData[key] && Array.isArray(updateData[key])) {
                    for (let k = 0; k < updateData[key].length; k++) {
                        if (updateData[key][k] && updateData[key][k]["sign"]) {
                            updateData[key][k]["sign"] = {}
                        }
                    }
                }
            }
            const { isValid, validationError } = allStepperValidation(activeStep, completedSteps, steps, appData, s)
            if (isValid) {
                dispatch(tenantAppAction.submitApplication({ data: JSON.stringify(updateData) }))
            } else {
                dispatch(alertAction.error(validationError))
            }
        } else {
            dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_ACTIVE_STEP, s))
        }
    }


    const updateCurrentDisclosure = (tmpData) => {
        if (tmpData && disclosureConditions && tmpData["conditions"] && tmpData["conditions"].length && disclosureConditions.length) {
            let trueCount = 0;
            for (let c = 0; c < tmpData["conditions"].length; c++) {
                let keyName = tmpData["conditions"][c] && typeof tmpData["conditions"][c] === "object" && Object.keys(tmpData["conditions"][c]).length ? Object.keys(tmpData["conditions"][c])[0] : ""
                for (let c1 = 0; c1 < disclosureConditions.length; c1++) {
                    if (keyName && disclosureConditions[c1] && disclosureConditions[c1][keyName] && disclosureConditions[c1]["value"] && (disclosureConditions[c1][keyName] === tmpData["conditions"][c][keyName] || keyName === "DateOfBirth")) {
                        let keyArray = Object.keys(disclosureConditions[c1]);
                        let keyArrVal = keyArray && keyArray[0] && keyArray[0] !== "value" ? keyArray[0] : keyArray && keyArray[1] && keyArray[1] !== "value" ? keyArray[1] : "";
                        if (keyArrVal && tmpData["conditions"][c] && tmpData["conditions"][c].hasOwnProperty(keyArrVal)) {
                            if (keyArrVal === "DateOfBirth") {
                                if (disclosureConditions[c1][keyArrVal] && disclosureConditions[c1]["value"] && new Date(disclosureConditions[c1]["value"]) != "Invalid Date" && Number(disclosureConditions[c1][keyArrVal]) && moment().diff(disclosureConditions[c1]["value"], 'years', false) < Number(disclosureConditions[c1][keyArrVal])) {
                                    trueCount = trueCount + 1;
                                }
                            } else {
                                if (disclosureConditions[c1][keyArrVal] && disclosureConditions[c1]["value"] && disclosureConditions[c1][keyArrVal] === disclosureConditions[c1]["value"]) {
                                    trueCount = trueCount + 1;
                                }
                            }
                        }
                    }
                }
            }
            if (trueCount > 0) {
                tmpData["conditionTrue"] = true
            } else {
                if (tmpData["conditionLayoutTrue"]) {
                    tmpData["conditionTrue"] = true
                } else {
                    tmpData["conditionTrue"] = false
                }
            }
            return tmpData;
        } else {
            return tmpData;
        }
    }

    //update step
    const setActiveStep = async (s) => {
        // dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_ACTIVE_STEP, s))
        const { isValid, validationError } = stepperValidation(activeStep, completedSteps, steps, appData, s)
        if (isValid) {
            if (steps[activeStep] === "application") {
                if (!appValidation) {
                    let stepCount = 0;
                    // if (steps[s] === "disclosure") {
                    let disclosure = appData["disclosure"];

                    for (let d = 0; d < disclosure.length; d++) {
                        let tmpData = await updateCurrentDisclosure(disclosure[d]);
                        disclosure[d] = tmpData;
                    }

                    let firstIndex = 0;
                    let conditionMet = 0;
                    for (let d = disclosure.length - 1; d >= 0; d--) {
                        if (!disclosure[d].layout && disclosure[d].conditions && disclosure[d].hasOwnProperty("conditionTrue") && disclosure[d].conditionTrue === false) {
                            conditionMet = conditionMet + 1;
                        } else {
                            firstIndex = d;
                        }
                    }

                    for (let d = 0; d < disclosure.length; d++) {
                        if (d === firstIndex) {
                            disclosure[d]["isVisible"] = true
                        } else {
                            disclosure[d]["isVisible"] = false
                        }
                    }

                    if (disclosure.length > 0 && conditionMet === disclosure.length) {
                        dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_ACTIVE_STEP, s))
                        stepCount = 1;
                    }

                    dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_DISCLOSURE_STEP, disclosure, () => { }))
                    // }

                    dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_APPLICATION_VALIDATION, !isValid, () => {
                        updateStepReducer(s + stepCount)
                    }))
                } else {
                    setValidPopup(true)
                }
            } else if (steps[activeStep] === "disclosure") {
                let disclosure = appData["disclosure"];
                let stepCount = 0;
                for (let d = 0; d < disclosure.length; d++) {
                    let tmpData = await updateCurrentDisclosure(disclosure[d]);
                    disclosure[d] = tmpData;
                }
                const activeIndex = disclosure.findIndex(s => (s["isVisible"] === true))

                if ((s > activeStep) && activeIndex > -1 && (activeIndex < (disclosure.length - 1))) {

                    let tmpData = disclosure[activeIndex];
                    disclosure[activeIndex] = { ...tmpData, "isVisible": false }

                    let firstIndex = activeIndex + 1;
                    let conditionMet = 0;
                    let indexUpdated = false;
                    for (let d = disclosure.length - 1; d >= 0; d--) {
                        if (!disclosure[d].layout && disclosure[d].conditions && disclosure[d].hasOwnProperty("conditionTrue") && disclosure[d].conditionTrue === false) {
                            conditionMet = conditionMet + 1;
                        } else {
                            if (d > activeIndex) {
                                firstIndex = d;
                                indexUpdated = true;
                            }
                        }
                    }

                    if (indexUpdated && firstIndex < (disclosure.length)) {
                        let tmpData1 = disclosure[firstIndex];
                        disclosure[firstIndex] = { ...tmpData1, "isVisible": true }
                    } else {
                        let tmpData1 = disclosure[0];
                        disclosure[0] = { ...tmpData1, "isVisible": true }

                        if (disclosure.length > 0 && conditionMet === disclosure.length) {
                            dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_ACTIVE_STEP, s))
                            stepCount = 1
                        }
                    }
                    dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_DISCLOSURE_STEP, disclosure), () => {
                        if (stepCount) {
                            updateStepReducer(s + 1);
                        }
                    })
                } else {
                    updateStepReducer(s);
                }
            } else if (steps[s] === "disclosure") {
                let disclosure = appData["disclosure"];

                for (let d = 0; d < disclosure.length; d++) {
                    let tmpData = await updateCurrentDisclosure(disclosure[d]);
                    disclosure[d] = tmpData;
                }

                let stepCount = 0;
                let firstIndex = 0;
                let conditionMet = 0;
                for (let d = disclosure.length - 1; d >= 0; d--) {
                    if (!disclosure[d].layout && disclosure[d].conditions && disclosure[d].hasOwnProperty("conditionTrue") && disclosure[d].conditionTrue === false) {
                        conditionMet = conditionMet + 1;
                    } else {
                        firstIndex = d;
                    }
                }

                for (let d = 0; d < disclosure.length; d++) {
                    if (d === firstIndex) {
                        disclosure[d]["isVisible"] = true
                    } else {
                        disclosure[d]["isVisible"] = false
                    }
                }

                if (conditionMet === disclosure.length) {
                    dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_ACTIVE_STEP, s))
                    stepCount = 1;
                }

                dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_DISCLOSURE_STEP, disclosure, () => {
                    updateStepReducer(s + stepCount)
                }))
            } else if (steps[activeStep] === "payment") {
                dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_PAYMENT_VALIDATION, !isValid, () => {
                    setPaymentPop({ type: true, next: s })
                }))
            } else {
                updateStepReducer(s)
            }

            let compSteps = completedSteps;
            if (compSteps.indexOf(activeStep) === -1) {
                compSteps.push(activeStep)
                dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_COMPLETED_STEP, compSteps))
            }

        } else {
            if (steps[activeStep] === "application") {
                dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_APPLICATION_VALIDATION, !isValid, () => {
                    dispatch(alertAction.error(validationError))
                }))
            }
            if (steps[activeStep] === "payment") {
                dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_PAYMENT_VALIDATION, !isValid, () => {
                    dispatch(alertAction.error(validationError))
                }))
            }
            else {
                dispatch(alertAction.error(validationError))
            }

            let compSteps = completedSteps;
            if (compSteps.indexOf(activeStep) > -1) {
                compSteps.splice(compSteps.indexOf(activeStep), 1)
                dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_COMPLETED_STEP, compSteps))
            }
        }
    }

    //mobile next button
    const handleNext = () => {
        if (invalidDOB) {
            dispatch(alertAction.error("Please Enter a Date Of Birth greater than 18 years"))
        } else {
            if (activeStep === totalSteps - 1) {
                if (steps[activeStep] === "disclosure") {
                    let disclosure = appData["disclosure"];
                    const activeIndex = disclosure.findIndex(s => (s["isVisible"] === true))
                    if (activeIndex > -1 && (activeIndex < (disclosure.length - 1))) {
                        setActiveStep(activeStep + 1);
                    } else {
                        //submit application
                        let updateData = {}
                        try {
                            updateData = _.cloneDeep(appData);
                        } catch (err) {
                            updateData = appData;
                        }
                        updateData["applicationURL"] = applicationURL;
                        for (let key in updateData) {
                            if (key === "introduction" && updateData[key] && updateData[key]["sign"]) {
                                updateData[key]["sign"] = {}
                            }
                            if (key === "criteria" && updateData[key] && updateData[key]["sign"]) {
                                updateData[key]["sign"] = {}
                            }
                            if (key === "application" && updateData[key] && updateData[key]["sign"]) {
                                updateData[key]["sign"] = {}
                            }
                            if (key === "disclosure" && updateData[key] && Array.isArray(updateData[key])) {
                                for (let k = 0; k < updateData[key].length; k++) {
                                    if (updateData[key][k] && updateData[key][k]["sign"]) {
                                        updateData[key][k]["sign"] = {}
                                    }
                                }
                            }
                        }
                        const { isValid, validationError } = allStepperValidation(activeStep, completedSteps, steps, appData, 0)
                        if (isValid) {
                            dispatch(tenantAppAction.submitApplication({ data: JSON.stringify(updateData) }))
                        } else {
                            dispatch(alertAction.error(validationError))
                        }
                    }
                } else {
                    setActiveStep(activeStep + 1);
                }
            } else {
                setActiveStep(activeStep + 1);
            }
        }
    };

    //go to previous step
    const handlePrev = async () => {
        if (steps[activeStep - 1] === "disclosure") {
            let disclosure = appData["disclosure"];

            for (let d = 0; d < disclosure.length; d++) {
                let tmpData = await updateCurrentDisclosure(disclosure[d]);
                disclosure[d] = tmpData;
            }

            let stepCount = 1;
            let firstIndex = 0;
            let conditionMet = 0;
            for (let d = disclosure.length - 1; d >= 0; d--) {
                if (!disclosure[d].layout && disclosure[d].conditions && disclosure[d].hasOwnProperty("conditionTrue") && disclosure[d].conditionTrue === false) {
                    conditionMet = conditionMet + 1;
                } else {
                    firstIndex = d;
                }
            }

            for (let d = 0; d < disclosure.length; d++) {
                if (d === firstIndex) {
                    disclosure[d]["isVisible"] = true
                } else {
                    disclosure[d]["isVisible"] = false
                }
            }

            if (disclosure.length > 0 && conditionMet === disclosure.length) {
                stepCount = 2;
            }

            dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_DISCLOSURE_STEP, disclosure, () => {
                dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_ACTIVE_STEP, ((activeStep - stepCount) > -1 ? (activeStep - stepCount) : activeStep)))
            }))
        } else {
            dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_ACTIVE_STEP, activeStep - 1))
        }
    }

    //stepper icon click
    const updateStep = async (index) => {
        if (index < activeStep) {
            if (steps[activeStep - 1] === "disclosure") {
                let disclosure = appData["disclosure"];

                for (let d = 0; d < disclosure.length; d++) {
                    let tmpData = await updateCurrentDisclosure(disclosure[d]);
                    disclosure[d] = tmpData;
                }

                let firstIndex = 0;
                let conditionMet = 0;
                for (let d = disclosure.length - 1; d >= 0; d--) {
                    if (!disclosure[d].layout && disclosure[d].conditions && disclosure[d].hasOwnProperty("conditionTrue") && disclosure[d].conditionTrue === false) {
                        conditionMet = conditionMet + 1;
                    } else {
                        firstIndex = d;
                    }
                }

                for (let d = 0; d < disclosure.length; d++) {
                    if (d === firstIndex) {
                        disclosure[d]["isVisible"] = true
                    } else {
                        disclosure[d]["isVisible"] = false
                    }
                }

                if (conditionMet === disclosure.length) {
                    if (index - 1 > -1) {
                        dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_ACTIVE_STEP, index - 1))
                    }
                } else {
                    dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_ACTIVE_STEP, index))
                }

            } else {
                dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_ACTIVE_STEP, index))
            }
        } else if (index === activeStep + 1 || completedSteps.indexOf(index) > -1) {
            if (invalidDOB) {
                dispatch(alertAction.error("Please Enter a valid Date Of Birth"))
            } else {
                setActiveStep(index);
            }
        }
    }

    const closeCardPopup = (tkn) => {
        if (paymentPop && paymentPop["next"]) {
            updateStepReducer(paymentPop["next"], tkn);
        }
        setPaymentPop(false);
    }

    useEffect(() => {
        if (activeStep === -1) {
            setActiveStep(0);
        }
    }, [dispatch, activeStep])

    //get data for current step
    const getStepContent = (step, stepsArr) => {
        const currentStepName = stepsArr[step];

        if (currentStepName === "introduction") {
            return <Introduction />
        } else if (currentStepName === "criteria") {
            return <Criteria />
        } else if (currentStepName === "application") {
            return <Application urlPath={urlPath} />
        } else if (currentStepName === "applicationReview") {
            return <ApplicationReview urlPath={urlPath} />
        } else if (currentStepName === "disclosure") {
            return <Disclosure />
        } else if (currentStepName === "payment") {
            return <Payment />
        } else {
            return null
        }
    }

    const closePopup = () => {
        setAppValidation(false)
        setValidPopup(false)
    }

    //handle next after state update
    // useEffect(() => {
    //     if (!appValidation) {
    //         handleNext()
    //     }
    // }, [appValidation])

    return (
        <React.Fragment>
            {(appValidation && validPopup && appData['appType'] === "employment") &&
                <ApplicationValidation
                    classes={classes}
                    closePopup={closePopup}
                />
            }
            <div className={classes.root}>
                <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />} className={`${"EnduserStepper"} ${classes.EnduserStepper}`} >
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel
                                onClick={() => updateStep(index)}
                                completed={completedSteps.indexOf(label) > -1}
                            >
                                {stepsDispName && stepsDispName[index] ? stepsDispName[index] : label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <MobileStepper
                    variant="progress"
                    steps={totalSteps}
                    position="static"
                    color="secondary"
                    activeStep={activeStep}
                    className={`${"smShow"} ${classes.stepperProgess}`}
                />

                <Typography className={`${classes.stepperTitle} ${"mobileShow transCapital"}`} variant="h5" align="center">{steps[activeStep]} ({activeStep + 1} / {steps.length})</Typography>

                <MobileStepper
                    className={`${classes.mobileStepper} ${"mobileShow"}`}
                    steps={totalSteps}
                    position="static"
                    variant="Null"
                    activeStep={activeStep}
                    nextButton={
                        <IconButton
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === totalSteps - 1}
                        >
                            {theme.direction === "rtl" ?
                                <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                        </IconButton>
                    }
                    backButton={
                        <IconButton
                            size="small"
                            onClick={handlePrev}
                            disabled={activeStep === 0}
                        >
                            {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                        </IconButton>
                    }
                />

                <Grid item xs={12} className={classes.endUserApplicationBody}>
                    <React.Fragment>
                        <Grid className={classes.instructions}>{getStepContent(activeStep, steps)}</Grid>
                    </React.Fragment>
                </Grid>

                <Grid xs={12} className={`${"tenantFooter"} ${classes.tenantFooter}`}>
                    <MobileStepper
                        variant="progress"
                        steps={totalSteps}
                        position="static"
                        color="secondary"
                        activeStep={activeStep}
                        className={`${"mobileShow"} ${classes.stepperProgess}`}
                    />
                    <Grid container justifyContent="space-between" alignItems="center" className={"tenantFooterDetail"}>
                        <Grid className="smShow"><img className={classes.FooterLogo} src={AllianceLogo} alt="Logo" /></Grid>
                        <Grid><Typography variant="h6" className={classes.percentageText}>{`${parseInt(activeStep * 100 / totalSteps)}% Completed`}</Typography></Grid>
                        <Grid>
                            {activeStep !== 0 && <Button variant="contained" onClick={() => handlePrev()} size="small" color="secondary">{"Back"}</Button>}
                            <Button className={"ml-1"} variant="contained" disabled={loading} onClick={() => handleNext()} size="small" color="primary">{(activeStep === totalSteps - 1) ? "Submit" : "Continue"}</Button>
                        </Grid>
                    </Grid>
                </Grid>

            </div>
            {paymentPop && paymentPop.type &&
                <AlertDialog
                    fullScreen={false}
                    open={true}
                >
                    <Grid className={'p-4'}>
                        <Grid className={classes.payTop}>
                            <Typography variant="h6">{`Enter Card Details`}</Typography>
                            <IconButton onClick={() => { setPaymentPop(false) }} size="large"><CloseIcon /></IconButton>
                        </Grid>
                        {(appID && locID) ?
                            <SquareForm
                                closeCardPopup={closeCardPopup}
                                locID={locID}
                                appID={appID}
                            />
                            :
                            <Typography variant="h6">{`Payment TO account Not Found, Please contact Alliance`}</Typography>
                        }
                    </Grid>
                </AlertDialog>
            }
        </React.Fragment>
    );
}

// default props
ApplicationTabsEmp.defaultProps = {
    classes: {},
    urlPath: "a"
};

// prop types
ApplicationTabsEmp.propTypes = {
    classes: PropTypes.object,
    urlPath: PropTypes.string,
};

export default withStyles(TenantApplicationStyles)(ApplicationTabsEmp);
