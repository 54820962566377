import * as apihelper from "../../utils/apiHelper";

/**
 * Export All Service Functions
 */
export const tenantAppService = {

    getApplicationData,
    submitApplication,

    checkPasscodeData
};

/**
 * Get Application By Id
 */
async function getApplicationData(params) {
    const response = await apihelper.postRequest(`tenantApp/getAppData`, params);
    return response;
}

/**
 * Get Application By Id
 */
async function submitApplication(params) {
    const response = await apihelper.postRequest(`tenantApp/submit`, params);
    return response;
}

async function checkPasscodeData(params) {
    const response = await apihelper.postRequest(`tenantApp/passCode`, params);
    return response;
}