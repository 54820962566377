import React, { useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Grid from '@mui/material/Grid';
import SignaturePad from 'react-signature-canvas'

import styles from './styles'
import { Typography } from '../Typography';
import { ReloadIcon, ReloadIconGrey } from '../../assets/svg';
import IconButton from '@mui/material/IconButton';

/**
 * SwitchComponent
 * @param {*} props
 */

function SignatureComponent(props) {

    const sigPad = useRef(props.sigPad)
    let { signImg, classes,index } = props;

    if (signImg && sigPad && sigPad.current) {
        sigPad.current.clear()
        sigPad.current.fromDataURL(signImg)
    }

    useEffect(()=>{
        if (signImg && sigPad && sigPad.current) {
            sigPad.current.clear()
            sigPad.current.fromDataURL(signImg)
        }
    },[sigPad,signImg])

    /**
      * Handle
      * Select Field Events
      */
    const handleChange = () => {
        let bs64 = sigPad.current.toDataURL('base64string')
        props.handleSignChange(sigPad.current, bs64,index);
    };

    /**
     * clear signature
     */
    const handleClearSign = () =>{
        sigPad.current.clear()
        props.handleSignChange({}, "",index);
    }

    return (
        <React.Fragment>
            <Typography variant="body1" className={"flex alignCenter mb-1"}>Signature :</Typography>
            <Grid className={`${classes.sigContainer}`}>
                <SignaturePad
                    canvasProps={{ className: classes.sigPad }}
                    ref={sigPad}
                    onEnd={handleChange}
                />
            </Grid>
            <Grid className={classes.tryAgainDiv}>
            <Grid onClick={()=>{handleClearSign()}} className={`${classes.tryAgainBth} ${"flex link cusrsorPointer alignCenter mb-1"}`}><IconButton className="" size="large"><ReloadIconGrey /></IconButton>Try again</Grid>
            </Grid>
        </React.Fragment>
    );
}

// default props
SignatureComponent.defaultProps = {
    sigPad: {},
    signImg: "",
    index:-1
};

// prop types
SignatureComponent.propTypes = {
    sigPad: PropTypes.object,
    signImg: PropTypes.string,
    index: PropTypes.number,
};

export const Signature = withStyles(styles)(SignatureComponent);
