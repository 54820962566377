import '../../assets/theme/palette';
import palette from '../../assets/theme/palette';
const AuditStyles = () => ({
    padding5: { padding: 5 },
    titlerow: {
        height: '34px',
        padding: '0 16px',
        marginBottom: 4,
    
        // backgroundColor: 'blue',
        // padding: '10px 0',
        '& .MuiToggleButton-root': {
          padding: 7
        }
      },
      usersTable: {
        paddingRight: 16,
        paddingLeft: 16
      },
      cardSectionList: {
        height: '100%',
        width: "100%",
        borderRadius: '4px',
        boxShadow: '0px 0px 8px #d4d1d1',
        padding: '8px 0px 8px 16px',
      },
      backDrop: {
        zIndex: 3
      },
      allianceDrawer: {
        '& .MuiDrawer-paper': {
          width: 400
        }
      },
      grow: {
        flexGrow: 1
      },
      settingsTile: {
        padding: '8px 13px 8px 7px',
        border: `1px solid ${palette.greyshades.light2}`,
        borderRadius: 4,
        backgroundColor: palette.greyshades.light3,
        marginBottom: 15,
        height: 42,
        '& .MuiFormControlLabel-root': {
          marginRight: '0px !important'
        },
        '& .dragIcon': {
          cursor: "move"
        },
        '& .settingSTileCheck': {
          marginRight: 0,
          padding: 0,
          '& .MuiFormControlLabel-root': {
            marginRight: '0px !important'
          }
        }
      },

      allianceTextarea: {
        borderRadius: 4,
        borderColor: '#D2D3D4',
        padding: '10px 15px',
        width: '100%',
        '&:hover': {
          borderColor: 'rgb(95 95 95 / 87%)',
        },
        '&:focus': {
          border: `1px solid ${palette.primary.main}`,
          outline: 'transparent !important'
        }
      },
      columnSttingsBody: {
        maxHeight: 'calc(100vh - 138px)',
        overflowY: 'auto'
      }
})

export default AuditStyles;