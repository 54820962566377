import palette from '../../assets/theme/palette';
const TableComponentStyle = theme => ({
  resize: {
    // resize: "horizontal",
    minWidth: "50px !important",
    display: "block",
    overflow: "hidden"
  },
  ellipsis: {
    textOverflow: "ellipsis",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: 150
  },
  TableContainer: {
    overflowY: "auto",
    '& .MuiTableCell-root': {
      padding: '7px 8px'
    },
    '& .MuiTableCell-head': {
      padding: '6px 8px !important'
    },
    '& .MuiTableCell-body': {
      color: '#0C0910',
      fontSize: 13,
      fontWeight: 400,
      padding: '6px 8px',
      maxWidth: 160,
      '& div': {
        whiteSpace: "nowrap",
        // maxWidth: 160,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      '& .checkBoxContainer': {
        maxWidth: '100% !important',
        marginLeft: '0px',
        display: 'flex'
      }

    },
    '& .pageSearchBox': {
      border: `1px solid ${palette.greyshades.light2}`,
      borderRadius: 30,
      height: 28,
      padding: '0 10px'
    },
    '& .MuiInputAdornment-root': {
      display: "none"
    },
    '& .MuiTableCell-head': {
      cursor: 'move'
    },
    '& .MuiTableCell-stickyHeader': {
      background: '#fff !important'
    },
    '& .MuiTableRow-head:first-child': {
        '& .resize': {
          overflow: "visible !important"
        }
    },
    '& .actions': {
      cursor: 'default !important'
    }
  },
  tablePageSelect: {
    minWidth: 63,
    '& .MuiOutlinedInput-root': {
      height: '32px !important',
      minHeight: '32px !important',
      borderColor: palette.greyshades.light2,
      borderRadius: 6,
      background: '#F5F7FA',
      '& legend': {
        display: "none !important"
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      marginTop: "5px !important"
    },
  },
  // tableActionBtn: {
  //   height: 26,
  //   width: 26
  // },
  actions: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "flex-end",
    '& .MuiIconButton-root': {
      padding: 3,
      margin: '0 2px'
    },
    '& .deleteIcon': {
      height: 16
    },
    '& .editIcon, & .loginIcon': {
      height: 15
    },
    '& .searchIcon': {
      width: 15
    },
    '& .EmailIcon': {
      '& path.path': {
        fill: '#12a8de'
      }
    }
  },
  checkbox: {
    display: 'flex',
    alignItems: "center"
  },
  status:{
    display: "inline-block",
    padding: "3px 15px",
    borderRadius: "30px",
    backgroundColor:"#DFFBE1",
    color: "#0BC45B"
  },
  statusActive:{
    display: "flex",
    alignItems: 'center',
    padding: "3px 15px",
    borderRadius: "30px",
    // backgroundColor:"#DFFBE1",
    // color: "#0BC45B",
    '& div': {
      margin: 'auto'
    },
    '& .StatusInfoIcon': {
      height: '16px',
    width: '16px',
    background: palette.primary.main,
    borderRadius: '30px',
    padding: '2px',
    marginLeft: '8px',
    fill: '#fff'
    }
  },
  statusInactive:{
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    padding: "3px 15px",
    // paddingLeft: 10,
    borderRadius: "30px",
    // backgroundColor:"#d2d3d4c9",
    color: "gray",
    '& .StatusInfoIcon': {
      height: '16px',
    width: '16px',
    border: "2px solid #FC626C",
    borderRadius: '30px',
    padding: '1px',
    marginRight: '8px',
    fill: '#FC626C'
    }
  },
  statusInactiveFailed: {
    display: "flex",
    alignItems: 'center',
    padding: "3px 15px",
    paddingLeft: 10,
    borderRadius: "30px",
    // backgroundColor:"#d2d3d4c9",
    // color: "#FC626C",
    '& .StatusInfoIcon': {
      height: '16px',
    width: '16px',
    border: "2px solid #FC626C",
    borderRadius: '30px',
    padding: '1px',
    marginRight: '8px',
    fill: '#FC626C'
    }
  },
  checkBoxContainer: {
    '& .MuiIconButton-root': {
      padding: 0
    },
    '& .MuiFormControlLabel-root': {
      marginRight: 10,
      marginLeft: 0
    },
    '& div': {
      width: 'calc(100% - 40px)'
    }
  },
  headerTextContainer: {
    display: 'flex',
    alignItems: "center",
    '& .MuiButtonBase-root': {
      padding: 0
    },
    '& .MuiFormControlLabel-root': {
      marginRight: 10,
      marginLeft: -8
    }
  },
  checkBoxClsContainer: {
    marginLeft: '-8px !important',
    alignItems: 'center',
    '& .checkBoxWithName': {
      maxWidth: 'calc(100% - 38px)'
    }
  }
});

export default TableComponentStyle;
