export const applicationConstant = {

  // To Get All Application Details
  APP_GETALL_REQUEST: "APP_GETALL_REQUEST",
  APP_GETALL_SUCCESS: "APP_GETALL_SUCCESS",
  APP_GETALL_FAILURE: "APP_GETALL_FAILURE",

  // To Search Application Details
  APP_SEARCH_REQUEST: "APP_SEARCH_REQUEST",
  APP_SEARCH_SUCCESS: "APP_SEARCH_SUCCESS",
  APP_SEARCH_FAILURE: "APP_SEARCH_FAILURE",

  // Get Application By Id
  APP_GET_ONE_REQUEST: "APP_GET_ONE_REQUEST",
  APP_GET_ONE_SUCCESS: "APP_GET_ONE_SUCCESS",
  APP_GET_ONE_UPDATE: "APP_GET_ONE_UPDATE",
  APP_GET_ONE_FAILURE: "APP_GET_ONE_FAILURE",

  // DELETE Application
  APP_DELETE_REQUEST: "APP_DELETE_REQUEST",
  APP_DELETE_SUCCESS: "APP_DELETE_SUCCESS",
  APP_DELETE_FAILURE: "APP_DELETE_FAILURE",

  //update chord filter reducer
  CHORD_FILTER_UPDATE: "CHORD_FILTER_UPDATE",

  //create new Application
  CREATE_APP_REQUEST: "CREATE_APP_REQUEST",
  CREATE_APP_SUCCESS: "CREATE_APP_SUCCESS",
  CREATE_APP_FAILURE: "CREATE_APP_FAILURE",

  //update existing Application
  UPDATE_APP_REQUEST: "UPDATE_APP_REQUEST",
  UPDATE_APP_SUCCESS: "UPDATE_APP_SUCCESS",
  UPDATE_APP_FAILURE: "UPDATE_APP_FAILURE",

  //update existing Application introduction
  UPDATE_APP_INTRO_REQUEST: "UPDATE_APP_INTRO_REQUEST",
  UPDATE_APP_INTRO_SUCCESS: "UPDATE_APP_INTRO_SUCCESS",
  UPDATE_APP_INTRO_FAILURE: "UPDATE_APP_INTRO_FAILURE",

  //update existing Application criteria
  UPDATE_APP_CRIT_REQUEST: "UPDATE_APP_CRIT_REQUEST",
  UPDATE_APP_CRIT_SUCCESS: "UPDATE_APP_CRIT_SUCCESS",
  UPDATE_APP_CRIT_FAILURE: "UPDATE_APP_CRIT_FAILURE",

  //update existing Application payment
  UPDATE_APP_PAY_REQUEST: "UPDATE_APP_PAY_REQUEST",
  UPDATE_APP_PAY_SUCCESS: "UPDATE_APP_PAY_SUCCESS",
  UPDATE_APP_PAY_FAILURE: "UPDATE_APP_PAY_FAILURE",

  //reset get one data
  RESET_APP_DATA: "RESET_APP_DATA",

  //update APP view
  UPDATE_VIEW_TYPE: 'UPDATE_VIEW_TYPE',

  //update view and search in chird view
  UPDATE_VIEW_TYPE_AND_SEARCH: 'UPDATE_VIEW_TYPE_AND_SEARCH',

  //GET_CRITERIA_NAME
  GET_CRITERIA_NAME_REQUEST: "GET_CRITERIA_NAME_REQUEST",
  GET_CRITERIA_NAME_SUCCESS: "GET_CRITERIA_NAME_SUCCESS",
  GET_CRITERIA_NAME_FAILURE: "GET_CRITERIA_NAME_FAILURE",

  //GET_SERVICE_NAME
  GET_SERVICE_NAME_REQUEST: "GET_SERVICE_NAME_REQUEST",
  GET_SERVICE_NAME_SUCCESS: "GET_SERVICE_NAME_SUCCESS",
  GET_SERVICE_NAME_FAILURE: "GET_SERVICE_NAME_FAILURE",

  //GET_MANAGER_NAME
  GET_MANAGER_NAME_REQUEST: "GET_MANAGER_NAME_REQUEST",
  GET_MANAGER_NAME_SUCCESS: "GET_MANAGER_NAME_SUCCESS",
  GET_MANAGER_NAME_FAILURE: "GET_MANAGER_NAME_FAILURE",

  //GET CLIENT COMMON FLAG
  GET_C_COMMON_REQUEST: "GET_C_COMMON_REQUEST",
  GET_C_COMMON_SUCCESS: "GET_C_COMMON_SUCCESS",
  GET_C_COMMON_FAILURE: "GET_C_COMMON_FAILURE",

  //update question
  UPDATE_QUESTION_REDUCER: "UPDATE_QUESTION_REDUCER",

  //SEND INVITATION
  SEND_INV_REQUEST: "SEND_INV_REQUEST",
  SEND_INV_SUCCESS: "SEND_INV_SUCCESS",
  SEND_INV_FAILURE: "SEND_INV_FAILURE",

  //get link
  GET_LINK_REQUEST: "GET_LINK_REQUEST",
  GET_LINK_SUCCESS: "GET_LINK_SUCCESS",
  GET_LINK_FAILURE: "GET_LINK_FAILURE",
};
