import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Paper, Grid } from '@mui/material';

// Import History
import history from "../../../config/history";

// Import Components
import { Table, AlertDialog, Typography, Button as Btn } from "../../../components";
import NoRecordsFound from "../NoRecordsFound";
// import EditOptions from '../AddEditApplication/EditOptions'
import SendInvite from "../LinkActions/SendInvite";
import GetLink from "../LinkActions/GetLink";
import AddEditApplication from "../AddEditApplication/AddEditApplication";

//Import Layouts & Styles
import ApplicationStyles from '../ApplicationStyles'
import { getTableHeight } from '../../../config/layout'

// Import Actions
import { applicationAction, userAction } from "../../../redux/actions";

//Constants
import { application_page_constants } from "../../../config/constants"

function ApplicationList(props) {
  //define dispatch
  const dispatch = useDispatch();

  //get props
  const { classes, match, location } = props;

  //set state
  const [deleteAlert, setDeleteAlert] = useState(null);
  const [editAnchor, setEditAnchor] = useState(null);
  const [invitePopup, setInvitePopup] = useState(false);
  const [getLinkPopup, setgetLinkPopup] = useState(false);
  const [appId, setAppID] = useState(null);
  const [editType,setEditType] =useState(false)

  //get page height
  const height = getTableHeight();

  //get reducer data
  const { search: { page, data }, common: { is_common } } = useSelector(state => state.application);

  const { currentUser: { preference, id: currentUID } } = useSelector(state => state.user);
  const { loading } = useSelector(s => s.UI)

  //is same page
  const isSamePage = location && location.search === "?page=true" ? true : false;

  //get selected app fields
  const selectHeaders = preference && preference.app && preference.app.select ? preference.app.select : [];
  const sortHeaders = preference && preference.app && preference.app.sort ? preference.app.sort : [];
  let selectedField = [];
  let selectedHeaders = [];
  if (selectHeaders && sortHeaders) {
    sortHeaders.map(i => {
      const isFound = selectHeaders.filter(h => (i === h))
      if (isFound.length) {
        selectedHeaders.push(i)
        selectedField.push(application_page_constants.APP_LIST_MAP[i])
      }
    })
  }

  useEffect(() => {
    if (!isSamePage) {
      dispatch(applicationAction.search(1, page.limit, "cdt", "desc", [], []));
    }
    dispatch(applicationAction.getClientCommon({ 'id': null, commonApi: true }))
  }, [dispatch]);

  useEffect(() => {
    let { sFields, sValues } = page;
    if (typeof sFields === 'undefined')
      sFields = []
    if (typeof sValues === 'undefined')
      sValues = []
    if (isSamePage) {
      dispatch(applicationAction.search(1, page.limit, "cdt", "desc", sFields, sValues));
      history.replace(location.pathname);
    }
  }, [isSamePage]);

  //Handle Table pagination Event
  const onChangePageTable = (skip, limit) => {
    const { sFields, sValues, sortBy, sort } = page;
    dispatch(applicationAction.search(skip, limit, sortBy, sort, sFields, sValues));
  };

  //Handle Table Sort Request Event
  const onSortRequest = (sortBy, sort) => {
    dispatch(applicationAction.search(page.skip, page.limit, sortBy, sort, page.sFields, page.sValues));
  };

  //handle detlete user on yes click in alert
  const deleteAction = () => {
    const { field, itemId, item } = deleteAlert
    dispatch(applicationAction.remove({ id: item.id }, () => {
      dispatch(applicationAction.search(page.skip, page.limit, page.sortBy, page.sort, page.sFields, page.sValues));
      setDeleteAlert(null)
    }));
  }

  //edit options
  const handelEditButtonClick = (event, itemId, item) => {
    //open edit menu
    // setEditAnchor({ event: event.currentTarget, itemId: itemId, item: item });
    //get application data
    dispatch(applicationAction.getApp({ id: item.id || null },()=>{
      setEditType(true)
    }))
  };

  //handle edit close
  const handelEditClose = () => {
    setEditAnchor(null);
  };

  //Button actions
  const handelActionButtonClick = (field, itemId, item) => {
    switch (field) {
      case "SEARCH":
        dispatch(applicationAction.getLink({ id: item.id ,type:"view"}, (resData) => {
          if (resData.data) {
            window.open(resData.data, "_blank")
          }
        }))
        break;
      case "GETLINK":
        setgetLinkPopup(true)
        setAppID(item.id)
        break;
      case "INVITE":
        setInvitePopup(true)
        setAppID(item.id)
        break;
      case "DELETE":
        setDeleteAlert({ field: field, itemId: itemId, item: item })
        break;
      default:
        break;
    }
  }

  //Handle Table Search Input Change Event
  const oninputChangeRequest = (searchField, searchKey) => {
    let { sFields, sValues } = page;
    if (sFields.indexOf(searchField) >= 0) {
      if (searchKey === "") {
        const index = sFields.indexOf(searchField);
        sValues.splice(index, 1);
        sFields.splice(index, 1);
      } else {
        sValues[sFields.indexOf(searchField)] = searchKey;
      }
    } else {
      sFields.push(searchField);
      sValues.push(searchKey);
    }
    if (!isSamePage) {
      dispatch(applicationAction.search(1, page.limit, page.sortBy, page.sort, page.sFields, page.sValues));
    }
  };

  //update preference on submit click
  const updatePreference = (sort) => {
    let updatedData = [...sort]
    const allHeaders = preference && preference.app && preference.app.sort ? preference.app.sort : [];
    allHeaders.map(i => {
      if (updatedData.indexOf(i) === -1) {
        updatedData.push(i)
      }
    })
    dispatch(userAction.updatePreference({ id: currentUID, preference: { ...preference, "app": { ...preference.app, "sort": updatedData } } }, () => { }));
  }

  return (
    <React.Fragment>
      <Grid item xs={12} className={`${"mt-15"} ${classes.usersTable}`}>
        {page.totalCount > 0 &&
          <Paper elevation={3} className={classes.cardSectionList} >
            <Table
              height={height}
              classes={classes}
              data={data}
              header={selectedHeaders}
              field={selectedField}
              search={selectedField}
              sortBy={page.sortBy}
              sort={page.sort}
              onInputChangeRequest={oninputChangeRequest}
              onChangePagination={onChangePageTable}
              onSortRequest={onSortRequest}
              handelActionButtonClick={handelActionButtonClick}
              updatePreference={updatePreference}
              handelEditButtonClick={handelEditButtonClick}
              page={Number(page.skip)}
              count={page.count}
              actions={is_common === 1 ? ['GETLINK', 'INVITE', 'EDITMANY', 'DELETE'] : ['SEARCH', 'INVITE', 'EDITMANY', 'DELETE']}
              sFields={page.sFields}
              sValues={page.sValues}
              rowsPerPage={page.limit}
              reset={page.sFields && page.sFields.length > 0 ? false : true}
              loading={loading}
            >
            </Table>
          </Paper>
        }
        {(!page.totalCount && !loading) && <NoRecordsFound />}
      </Grid>
      {deleteAlert &&
        <AlertDialog
          fullScreen={false}
          open={true}
        >
          <Grid className={'p-4'}>
            <Typography variant="h6">{`Are you sure you want to delete this Application`}</Typography>
            <Grid className={'pt-2'} align={"center"}>
              <Btn
                color={"primary"}
                className={"mr-1"}
                onClick={() => { deleteAction() }}
                title={"Yes"}
              />
              <Btn
                color={"secondary"}
                onClick={() => { setDeleteAlert(null) }}
                title={"No"}
              />
            </Grid>
          </Grid>
        </AlertDialog>
      }
      {/* <EditOptions editType={editType} setEditType={setEditType} editAnchor={editAnchor} handelEditClose={handelEditClose} /> */}
      {(invitePopup && appId) && <SendInvite appId={appId} invitePopup={invitePopup} setInvitePopup={setInvitePopup} />}
      {(getLinkPopup && appId) && <GetLink appId={appId} getLinkPopup={getLinkPopup} setgetLinkPopup={setgetLinkPopup} />}
      {editType && <AddEditApplication openApp={editType} setOpenApp={setEditType}  isEdit={true}/>}
    </React.Fragment>

  );
}
// default props
ApplicationList.defaultProps = {
  classes: {}
};

// prop types
ApplicationList.propTypes = {
  classes: PropTypes.object
};
export default withStyles(ApplicationStyles)(ApplicationList);
