import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


//Styles
import SubmissionsStyles from "./SubmissionsStyles"

//Images
import { TileIcon, ListIcon, SearchIcon } from '../../assets/svg'

// Import Components
import { Button, TextField, Typography, CheckBox, Link } from "../../components";
import ColumnSettings from './Components/ColumnSettings';
import SubmissionsList from './Components/SubmissionsList';

// Import Helpers
import validations from "../../utils/validations";

// Import Cofig Constants
import { submissions_page_constants } from "../../config/constants";

const Submissions = (props) => {

  const { classes, match } = props;


  return (
    <React.Fragment >
      <Grid item xs={12} >
        <Grid container direction="row" justifyContent="space-between" alignItems="center" className={`${classes.titlerow}`}>
          <Grid item>
            <Typography variant="h3" component="h3">{submissions_page_constants.SUBMISSIONS}</Typography>
          </Grid>
          <Grid item>
            <ColumnSettings />
          </Grid>
        </Grid>
      </Grid>
      <SubmissionsList />
    </React.Fragment>
  );
}

// default props
Submissions.defaultProps = {
  classes: {}
};

// prop types
Submissions.propTypes = {
  classes: PropTypes.object
};

export default withStyles(SubmissionsStyles)(Submissions);
