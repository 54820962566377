import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Grid from '@mui/material/Grid';
import { withStyles } from "@mui/styles";

//Styles
import TenantApplicationStyles from "../TenantApplicationStyles"

// Import Components
import { Button } from "../../../components";

//import actions
import { alertAction, updateReducerAction } from "../../../redux/actions";

import "./squareForm.css"
import { tenantAppConstant } from "../../../redux/constants";

const SquareForm = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes, closeCardPopup, appID, locID } = props;

    // const appId = 'sandbox-sq0idb-ClUU4TrtPtELlYJMNLNsbQ';
    // const locationId = 'LQTV1P0R964VS';

    let cardButton = document.getElementById('card-button') || {}
    let card;

    //initialise card form
    useEffect(async () => {
        let payments;
        if (window.Square) {
            try {
                payments = window.Square.payments(appID, locID);
            } catch {
                const statusContainer = document.getElementById('payment-status-container');
                statusContainer.className = 'missing-credentials';
                statusContainer.style.visibility = 'visible';
                return;
            }

            try {
                //append card details form
                card = await initializeCard(payments);
            } catch (e) {
                dispatch(alertAction.error("Payment not initialised error"))
                console.error('Initializing Card failed', e);
                return;
            }
        } else {
            dispatch(alertAction.error("Payment not initialised error"))
        }
    }, [dispatch])

    //append card details form
    async function initializeCard(payments) {
        const card = await payments.card();
        await card.attach('#card-container');
        return card;
    }

    //careate card token string
    async function tokenize(paymentMethod) {
        try {
            const tokenResult = await paymentMethod.tokenize();
            console.log("tokenResult", tokenResult)
            if (tokenResult.status === 'OK') {
                return tokenResult.token;
            } else {
                let errArr = tokenResult && tokenResult.errors ? tokenResult.errors : []
                let errArr1 = errArr[0] && errArr[0]["message"] ? errArr[0]["message"] : "Invalid card Details";
                dispatch(alertAction.error(errArr1))
            }
        } catch (err) {
            console.log(err)
            dispatch(alertAction.error("Payment error"))
        }

    }

    // status is either SUCCESS or FAILURE;
    function displayPaymentResults(status) {
        const statusContainer = document.getElementById('payment-status-container');

        if (status === 'SUCCESS') {
            statusContainer.classList.remove('is-failure');
            statusContainer.classList.add('is-success');
        } else {
            statusContainer.classList.remove('is-success');
            statusContainer.classList.add('is-failure');
        }

        statusContainer.style.visibility = 'visible';
    }

    async function handlePaymentMethodSubmission(event) {
        event.preventDefault();
        try {
            // disable the submit button as we await tokenization and make a payment request.
            cardButton.disabled = true;
            const token = await tokenize(card);
            if (token) {
                displayPaymentResults('SUCCESS');
                dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_CARD_TOKEN,token,()=>{
                    closeCardPopup(token)
                }))
            } else {
                displayPaymentResults('FAILURE');
            }
        } catch (e) {
            cardButton.disabled = false;
            displayPaymentResults('FAILURE');
            console.error(e.message);
        }
    }

    return (
        <Grid item xs={12} style={{ overflowX: "hidden" }} className={"paymentClass"}>
            <div>
                <form id="payment-form">
                    <div id="card-container"></div>
                    <Button variant="contained" color="primary" id="card-button" onClick={(e) => { handlePaymentMethodSubmission(e) }} type="button">Continue</Button>
                </form>
                <div id="payment-status-container"></div>
            </div>
        </Grid>
    );
}

// default props
SquareForm.defaultProps = {
    classes: {}
};

// prop types
SquareForm.propTypes = {
    classes: PropTypes.object
};

export default withStyles(TenantApplicationStyles)(SquareForm);
