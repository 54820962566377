import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import propTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from "@mui/styles";
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

//Import Styles and images
import UsersStyles from '../../UsersStyles'
import { ClockIcon, MoreIcon, AllianceAMaskIcon, ChevronTopIcon } from '../../../../assets/svg'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


// Import Components
import { Button, Typography, AlertDialog, ToolTip, LazyLoader, TopScroll } from "../../../../components";

// Import History
import history from "../../../../config/history";

// Import Actions
import { userAction, authAction } from "../../../../redux/actions";

// Import Config Layout
import { usersCardLimit } from "../../../../config/layout";

function UsersChords(props) {
    //define dispatch
    const dispatch = useDispatch();

    // Set Card Limit and Size
    let cardLimit = usersCardLimit();
    const cardSize = Math.round(12 / (cardLimit / 5))

    //set ref for scroll
    const scrollContent = useRef(null)

    //get props
    const { classes } = props;

    //get reducer data
    let { chordFilter: { rolesSel, companySel },chordFilter:filter, getAll: { data, page }, currentUser: { role: currentUserRole } } = useSelector((state) => state.user);
    let { lazyLoading } = useSelector((state) => state.UI);

    //open popup inside each chord
    const [anchorEl, setAnchorEl] = useState(null);
    const [editId, setEditId] = useState(null);
    const [deleteAlert, setDeleteAlert] = useState(null);
    const [managerMap, setmanagerMap] = useState(null);
    const [showScrollTopBTN, setShowScrollTopBTN] = useState(false)

    //open popup
    const handleClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setEditId(id)
    };

    //close popup
    const handleClose = () => {
        setAnchorEl(null);
        setEditId(null)
    };

    //edit user
    const handleEditUser = () => {
        if (editId) {
            dispatch(userAction.getUser({ id: editId }, () => {
                history.push({ pathname: `/users/edit/${editId}` });
                setAnchorEl(null);
            }))
        }
    }

    //edit user
    const handleSignIn = () => {
        if (editId) {
            let dataObj = data.filter(i => (i.id === editId))
            if (dataObj.length === 1) {
                localStorage.setItem("id", dataObj[0].id);
                localStorage.setItem("role", dataObj[0].role);
                dispatch(userAction.loginUser(dataObj[0]));
                dispatch(authAction.getProfileLogo())
            }
        }
    }

    //delete user
    const handleDelete = () => {
        if (editId) {
            let dataObj = data.filter(i => (i.id === editId))
            if (dataObj.length === 1) {
                dispatch(userAction.remove({ id: dataObj[0].id, role: dataObj[0].role, client_id: dataObj[0].c_id }, () => {
                    dispatch(userAction.getCompaniesAndRole(filter,(chordData)=>{
                    dispatch(userAction.getAll({ limit: cardLimit, skip: 1, rolesSel: chordData.rolesSel, companySel: chordData.companySel, search: page.search, sort: page.sort }));
                    setAnchorEl(null);
                    setDeleteAlert(null)
                    }))
                }));
            }
        }
    }

    const getManagerData = () => {
        if (editId) {
            let dataObj = data.filter(i => (i.id === editId))
            if (dataObj.length === 1 && dataObj[0]["role"] === "Manager") {
                dispatch(userAction.getManagerAppMap({ client_id: dataObj[0]["client_id"], id: dataObj[0]["manager_id"] }, (respData) => {
                    if (respData && respData["data"]) {
                        setmanagerMap(true)
                        setDeleteAlert(true)
                    } else {
                        setmanagerMap(null)
                        setDeleteAlert(true)
                    }
                }))
            } else {
                setDeleteAlert(true)
                setmanagerMap(null)
            }
        }
    }

    //scroll event
    const getScrollData = () => {
        const isBottom = parseInt(scrollContent.current.scrollHeight - scrollContent.current.scrollTop) === (scrollContent.current.clientHeight) || parseInt(scrollContent.current.scrollHeight - scrollContent.current.scrollTop) === (scrollContent.current.clientHeight+1) || parseInt(scrollContent.current.scrollHeight - scrollContent.current.scrollTop) === (scrollContent.current.clientHeight-1);
        // const currentVal=scrollContent.current.scrollTop;
        if (!lazyLoading && isBottom && data.length < page.totalCount) {
            //get neext data
            dispatch(userAction.getAll({ limit: page.limit, skip: page.skip + 1, rolesSel: rolesSel, companySel: companySel, search: page.search, sort: page.sort, loader: true }));
        }

        if (scrollContent.current.scrollTop > 150 && showScrollTopBTN === false) {
            setShowScrollTopBTN(true)
        }
        else if (scrollContent.current.scrollTop < 150 && showScrollTopBTN === true) {
            setShowScrollTopBTN(false)
        }
    }


    //disable signin option
    const getDisabledData = () => {
        if (editId) {
            let dataObj = data.filter(i => (i.id === editId))
            if (dataObj.length === 1 && !dataObj[0]["email"]) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    // show hide sign in option inside popup
    const showHideSignIN = () => {
        if (editId) {
            let dataObj = data.filter(i => (i.id === editId))
            if (dataObj.length === 1 && dataObj[0]["role"] !== "admin" && dataObj[0]["role"] !== "Admin" && currentUserRole !== "client" && currentUserRole !== "Client") {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    return (
        <React.Fragment>
            <Grid item className={classes.usersRightTiles} ref={scrollContent} onScroll={() => { getScrollData() }}>
                <Grid container>
                    <Grid item sm={12} >
                        <Grid container spacing={2}>
                            {data && data.map((itm, ind) => (
                                    <Grid item className="animate__animated animate__fadeInUp animate__delay-0.8s" sm={cardSize}  key={ind} style={{padding:"8px"}} >
                                        <Grid className={classes.userTile}>
                                            <Grid container className={classes.userTileTop}>
                                                <Grid item xs={12} align="right" className={classes.moreContainerTop}>
                                                    <IconButton
                                                        className={classes.tileMoreIcon}
                                                        onClick={(e) => { handleClick(e, itm.id) }}
                                                        size="large"><MoreIcon /></IconButton>
                                                </Grid>
                                                <Grid item className={classes.userImg}>
                                                    {itm.image !== '' ? <img src={itm.image} alt={"img"} /> : <AllianceAMaskIcon />}
                                                    <Grid className={itm.status && itm.status === "Active" ? classes.onlineIcon : classes.offlineIcon}></Grid>
                                                </Grid>
                                                <Grid item className={classes.nameContainer}>
                                                    <ToolTip title={itm.company ? itm.company : ''} className="cardToolTip">
                                                        <Grid>
                                                            <Typography variant="h5" className={`${"oneLineEllip transCapital userCardTitle"} ${classNames(classes.paddingBottom5, classes.usercardTitle)}`}>{itm.company ? itm.company : ''}</Typography>
                                                        </Grid>
                                                    </ToolTip>
                                                    {/* <Button className={classes.nameChip} variant="contained" color="secondary" size="small" title={itm.role ? itm.role : ''}></Button> */}
                                                    <Typography variant="body1" className={classes.nameChip}>{itm.role ? itm.role : ''}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid className={classes.UserInfo}>
                                                <Typography variant="body1" className="oneLineEllip UsersCardsTxtWidth" color="textSecondary" align="center">{itm.full_name ? itm.full_name : ''}</Typography>
                                                <Typography variant="body1" className={`${classes.cardEmailText} ${"oneLineEllip UsersCardsTxtWidth"}`} color="textSecondary" align="center">{itm.email ? itm.email : ''}</Typography>
                                                <Typography variant="body1" className="oneLineEllip UsersCardsTxtWidth" color="textSecondary" align="center">{itm.phone ? itm.phone : ''}</Typography>
                                            </Grid>
                                            <Grid className={classes.userTileBottom}>
                                                <ClockIcon className={classes.loginIcon} />
                                                <Typography className={`${"oneLineEllip"} ${classes.lastLogin}`} variant={"body2"} color="textSecondary" align="center">{`Last Login : ${itm.login_time ? itm.login_time : '-'}`}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                            ))}
                            <LazyLoader />
                        </Grid>
                        <Menu
                            id={`jdfn-iddi`}
                            key={`jdfn-n sdj`}
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            elevation={2}
                        >
                            {showHideSignIN() && <MenuItem disabled={getDisabledData()} onClick={() => { handleSignIn() }}>Sign In</MenuItem>}
                            <MenuItem onClick={() => { handleEditUser() }}>Edit User</MenuItem>
                            <MenuItem onClick={() => { getManagerData() }}>Delete User</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
                {showScrollTopBTN && <IconButton
                    onClick={() => { scrollContent.current.scrollTo(0, 0) }}
                    className={"toTop"}
                    size="large"><KeyboardArrowUpIcon /></IconButton>}
            </Grid>
            {deleteAlert &&
                <AlertDialog
                    fullScreen={false}
                    open={true}
                >
                    <Grid className={'p-4'}>
                        <Typography variant="h6">{managerMap ? `Application Mapped, You cannot  delete this ${data.filter(i => (i.id === editId))[0]['role'] || 'User'}` : `Are you sure you want to delete this ${data.filter(i => (i.id === editId))[0]['role'] || 'User'}`}</Typography>
                        <Grid className={'pt-2'} align={"center"}>
                            {!managerMap && <Button
                                color={"primary"}
                                className={"mr-1"}
                                onClick={() => { handleDelete() }}
                                title={"Yes"}
                            />}
                            <Button
                                color={"secondary"}
                                onClick={() => { setAnchorEl(null); setDeleteAlert(null) }}
                                title={managerMap? "Cancel" : "No"}
                            />
                        </Grid>
                    </Grid>
                </AlertDialog>
            }
        </React.Fragment>
    );
}

// default props
UsersChords.defaultProps = {
    classes: {}
};

// prop types
UsersChords.propTypes = {
    classes: propTypes.object
};

// export default UserList;
export default withStyles(UsersStyles)(UsersChords);