const styles = (theme) => ({
  searchBoxGrid: {
    marginRight: "10px",
    transition: 'all 0.4s ease',
    "& .searchBox": {
      // transition: "0.5s",
      // width: 200,
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: '8px'
      }
    },
    "& .MuiOutlinedInput-root": {
      height: "32px",
      minHeight: "32px",
    },
  },
  padding6: {
    padding: 6
  }
});
export default styles;
