import { tenantAppConstant } from "../constants";

// Set Initial State
const initialState = {
    activeStep: -1,
    completedSteps: [],
    steps: [],
    stepsDispName: [],
    appData: {
        introduction: {},
        criteria: {},
        payment: {},
        logoData: {},
        application: {},
        disclosure: [],
        token:""
    },
    isPassValid:false,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // GET ONE CLIENT BY ID
        case tenantAppConstant.APP_GET_ONE_REQUEST:
            return {
                ...state,
            };
        case tenantAppConstant.APP_GET_ONE_SUCCESS:

            const applicationData = action.data.data;

            return {
                ...state,
                activeStep: -1,
                completedSteps: [],
                appData: applicationData,
                steps: applicationData.steps ? applicationData.steps : [],
                stepsDispName: applicationData.stepsDispName ? applicationData.stepsDispName : []
            };
        case tenantAppConstant.APP_GET_ONE_FAILURE:
            return {
                ...state,
            };

        //update stepper
        case tenantAppConstant.UPDATE_ACTIVE_STEP:
            return {
                ...state,
                activeStep: action.data
            }

        //update stepper
        case tenantAppConstant.UPDATE_DISC_COND:
            return {
                ...state,
                appData: { ...state.appData, disclosureConditions: action.data }
            }

        case tenantAppConstant.UPDATE_CARD_TOKEN:
            return {
                ...state,
                appData: { ...state.appData, token: action.data }
            }

        //update completed
        case tenantAppConstant.UPDATE_COMPLETED_STEP:
            return {
                ...state,
                completedSteps: action.data
            }

        //update introduction
        case tenantAppConstant.UPDATE_INTRODUCTION:
            return {
                ...state,
                appData: { ...state.appData, introduction: action.data }
            }

        //update application
        case tenantAppConstant.UPDATE_APPLICATION_CONFIRM:
            return {
                ...state,
                appData: {
                    ...state.appData,
                    application: {
                        ...state.appData.application,
                        confirmCheckbox :action.data
                    },
                    applicationReview: {
                        ...state.appData.applicationReview,
                        confirmCheckbox :action.data
                    },
                }
            }

        //update criteria
        case tenantAppConstant.UPDATE_CRITERIA:
            return {
                ...state,
                appData: { ...state.appData, criteria: action.data }
            }

        //update payment
        case tenantAppConstant.UPDATE_PAYMENT:
            return {
                ...state,
                appData: { ...state.appData, payment: action.data }
            }

        //update application
        case tenantAppConstant.UPDATE_APPLICATION:
            return {
                ...state,
                appData: { ...state.appData, application: action.data }
            }
        //update application form data
        case tenantAppConstant.UPDATE_APPLICATION_VALIDATION:
            return {
                ...state,
                appData: { ...state.appData, application: { ...state.appData.application, "validataForm": action.data } }
            }

        //update payment form data
        case tenantAppConstant.UPDATE_PAYMENT_VALIDATION:
            return {
                ...state,
                appData: { ...state.appData, payment: { ...state.appData.payment, "validataForm": action.data } }
            }

        //update disclosure
        case tenantAppConstant.UPDATE_DISCLOSURE:
            return {
                ...state,
                appData: { ...state.appData, disclosure: action.data }
            }

        //update disclosure pages
        case tenantAppConstant.UPDATE_DISCLOSURE_STEP:
            return {
                ...state,
                appData: { ...state.appData, disclosure: action.data }
            }
        //update disclosure pages
        case tenantAppConstant.UPDATE_DISCLOSURE_DISABLE:

            let actionRes = action.data;
            let steps = state.steps;
            let appStep = state.appData.steps;

            if (actionRes && appStep.indexOf("disclosure") > -1) {
                steps = appStep
            } else {
                steps.splice(appStep.indexOf("disclosure"), 1)
            }

            return {
                ...state,
                steps: appStep
            }
        //submit form
        case tenantAppConstant.APP_SUBMIT_REQUEST:
        case tenantAppConstant.APP_SUBMIT_SUCCESS:
        case tenantAppConstant.APP_SUBMIT_FAILURE:
            return state;

        case tenantAppConstant.UPDATE_SETTINGS:
            return {
                ...state,
                appData: { ...state.appData, settings: action.data }
            }

        case tenantAppConstant.UPDATE_DOB:
            return {
                ...state,
                appData: { ...state.appData, DOB: action.data }
            }

        //submit form
        case tenantAppConstant.CHECK_PASS_REQUEST:
            return state;
        case tenantAppConstant.CHECK_PASS_SUCCESS:
            return {
                ...state,
                isPassValid : action.data.data,
            }
        case tenantAppConstant.CHECK_PASS_FAILURE:
            return {
                ...state,
                isPassValid:false
            };

        default:
            return state;
    }
}