const style = (theme) => ({
  required: {
    "&::before": {
      color: "#e32",
      content: "'* '",
      display: "inline"
    }
  }
});

export default style;
