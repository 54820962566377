import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

//import components
import Users from "./Users";
import AddEditUser from "./Components/addEditUser/AddEditUser";

// Import History
import history from "../../config/history";

export default function UsersRouter(props) {
  // get props
  const { match, location } = props;

  return (
    <Switch>
      <Route
        exact
        path={match.url}
        render={(props) => {
          const isReset = location && location.search === "?reset=true" ? true : false;
          if (isReset) {
            history.push({ pathname: `/users` });
            return;
          } else {
            return <Users match={match} {...props} />;
          }
        }}
      />
      <Route
        exact
        path={`${match.url}/add`}
        render={(props) => {
          return <AddEditUser match={match} {...props} />;
        }}
      />
      <Route
        exact
        path={`${match.url}/edit/:id`}
        render={(props) => {
          return <AddEditUser match={match} {...props} />;
        }}
      />
    </Switch>
  );
}

//default props
UsersRouter.defaultProps = {
  match: {},
  classes: {},
};

//define prop types
UsersRouter.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object,
};