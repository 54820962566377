import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import propTypes from 'prop-types';
import { withStyles } from "@mui/styles";
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Slide from '@mui/material/Slide';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


//Import Styles and images
import ApplicationStyles from '../ApplicationStyles'
import { ClockIcon, MoreIcon } from '../../../assets/svg'

// Import Components
import { Button, Typography, AlertDialog, ToolTip, LazyLoader } from "../../../components";
import AddEditApplication from "../AddEditApplication/AddEditApplication";
import SendInvite from "../LinkActions/SendInvite";
import GetLink from "../LinkActions/GetLink";

//import helpers
import { nameLogoGeneration } from '../../../utils'

// Import Actions
import { applicationAction } from "../../../redux/actions";
import NoRecordsFound from "../NoRecordsFound";

function ApplicationChords(props) {
    //define dispatch
    const dispatch = useDispatch();

    //set ref for scroll
    const appContent = useRef(null)

    //get props
    const { classes } = props;

    //get reducer data
    let { getAll: { data, page }, getApp: { appData }, common: { is_common } } = useSelector((state) => state.application);
    const { loading, lazyLoading } = useSelector(s => s.UI)


    //open popup inside each chord
    const [anchorEl, setAnchorEl] = useState(null);
    const [editId, setEditId] = useState(null);
    const [deleteAlert, setDeleteAlert] = useState(null);
    const [invitePopup, setInvitePopup] = useState(false);
    const [getLinkPopup, setgetLinkPopup] = useState(false);
    const [openApp, setOpenApp] = useState(false);
    const [showScrollTopBTN, setShowScrollTopBTN] = useState(false)

    useEffect(() => {
        dispatch(applicationAction.getClientCommon({ 'id': null, commonApi: true }))
    }, [dispatch])

    //open popup
    const handleClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setEditId(id)
    };

    //close popup
    const handleClose = () => {
        setAnchorEl(null);
        // setEditId(null)
    };

    //edit user
    const handleEditApp = () => {
        if (editId) {
            dispatch(applicationAction.getApp({ id: editId }, () => {
                //open edit application
                setOpenApp(true)
                setAnchorEl(null);
            }))
        }
    }

    //delete user
    const handleDelete = () => {
        if (editId) {
            let dataObj = data.filter(i => (i.id === editId))
            if (dataObj.length === 1) {
                dispatch(applicationAction.remove({ id: dataObj[0].id }, () => {
                    dispatch(applicationAction.getAll({ limit: 20, skip: 0, search: page.search, sort: page.sort }));
                    setAnchorEl(null);
                    setDeleteAlert(null)
                }));
            }
        }
    }

    //get link
    const getLink = () => {
        setgetLinkPopup(true)
        setInvitePopup(false)
        handleClose();
    }

    //view application
    const viewApplication = () => {
        dispatch(applicationAction.getLink({ id: editId, type: "view" }, (resData) => {
            if (resData.data) {
                window.open(resData.data, "_blank")
            }
            handleClose()
        }))
    }

    //invite user
    const inviteUser = () => {
        setgetLinkPopup(false)
        setInvitePopup(true)
        handleClose()
    }

    //scroll event
    const getScrollData = () => {
        const isBottom = parseInt(appContent.current.scrollHeight - appContent.current.scrollTop) === (appContent.current.clientHeight) || parseInt(appContent.current.scrollHeight - appContent.current.scrollTop) === (appContent.current.clientHeight-1) || parseInt(appContent.current.scrollHeight - appContent.current.scrollTop) === (appContent.current.clientHeight+1);
        if (!lazyLoading && isBottom && (data.length < page.totalCount)) {
            //get neext data
            dispatch(applicationAction.getAll({ limit: page.limit, skip: page.skip + 20, search: page.search, sort: page.sort, loader: true }));
        }

        if (appContent.current.scrollTop > 150 && showScrollTopBTN===false){
            setShowScrollTopBTN(true)
        }
        else if(appContent.current.scrollTop < 150 && showScrollTopBTN===true){
            setShowScrollTopBTN(false)
        }
    }

    //invote popup
    const setInvitePopup1 = (e) => {
        setInvitePopup(e)
    }

    //get link popup
    const setgetLinkPopup1 = (e) => {
        setgetLinkPopup(e)
    }

    console.log(invitePopup, getLinkPopup, editId)
    return (
        <React.Fragment>
            <Grid item className={classes.usersRightTiles} ref={appContent} onScroll={() => { getScrollData() }}>
                <Grid container>
                    <Grid item sm={12} >
                        <Grid container spacing={2} >
                            {data && data.map((itm, ind) => (
                                    <Grid sm={3}  className="animate__animated animate__fadeInUp animate__delay-0.8s" item key={ind}>
                                        <Grid className={classes.userTile}>
                                            <Grid container justifyContent="center" alignItems="center" className={classes.userTileTop}>
                                                <Grid item className={classes.userImg}>
                                                    <Typography color="textSecondary" variant="h3">{nameLogoGeneration(itm.name ? itm.name : "")}</Typography>
                                                </Grid>
                                                <Grid item align="right" className={classes.moreContainerTop}>
                                                    <IconButton
                                                        className={classes.tileMoreIcon}
                                                        onClick={(e) => { handleClick(e, itm.id) }}
                                                        size="large"><MoreIcon /></IconButton>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.nameContainer}>
                                                <ToolTip title={itm.name ? itm.name : ''} className="cardToolTip">
                                                    <Grid>
                                                        <Typography variant="h5" align="center" className={`${"oneLineEllip transCapital"}`}>{itm.name ? itm.name : ''}</Typography>
                                                    </Grid>
                                                </ToolTip>
                                            </Grid>
                                            <Grid className={classes.UserInfo}>
                                                <Typography variant="body1" className="oneLineEllip" color="textSecondary" align="center">{itm.service ? itm.service : ''}</Typography>
                                            </Grid>
                                            <Grid className={classes.userTileBottom}>
                                                <ClockIcon className={classes.loginIcon} />
                                                <Typography className={`${"oneLineEllip"} ${classes.lastLogin}`} variant={"body2"} color="textSecondary" align="center">{`Created : ${itm.cdt ? itm.cdt : '-'}`}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                            ))}
                            <LazyLoader />

                        </Grid>
                        <Menu
                            id={`jdfn-iddi`}
                            key={`jdfn-n sdj`}
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={() => handleClose()}
                            elevation={2}
                        >
                            {is_common === 1 && <MenuItem onClick={() => { getLink() }}>Get Link</MenuItem>}
                            {is_common === 0 && <MenuItem onClick={() => { viewApplication() }}>View Application</MenuItem>}
                            <MenuItem onClick={() => { inviteUser() }}>Invite</MenuItem>
                            <MenuItem onClick={() => { handleEditApp() }}>Edit</MenuItem>
                            <MenuItem onClick={() => { setDeleteAlert(true) }}>Delete</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
                {showScrollTopBTN && <IconButton
                    onClick={()=>{appContent.current.scrollTo(0, 0)}}
                    className={"toTop"}
                    size="large"><KeyboardArrowUpIcon /></IconButton>}
            </Grid>
            {deleteAlert &&
                <AlertDialog
                    fullScreen={false}
                    open={true}
                >
                    <Grid className={'p-4'}>
                        <Typography variant="h6">{`Are you sure you want to delete this Application`}</Typography>
                        <Grid className={'pt-2'} align={"center"}>
                            <Button
                                color={"primary"}
                                className={"mr-1"}
                                onClick={() => { handleDelete() }}
                                title={"Yes"}
                            />
                            <Button
                                color={"secondary"}
                                onClick={() => { setAnchorEl(null); setDeleteAlert(null) }}
                                title={"No"}
                            />
                        </Grid>
                    </Grid>
                </AlertDialog>
            }
            {(openApp && appData && Object.keys(appData).length > 0) && <AddEditApplication isEdit={true} openApp={openApp} setOpenApp={setOpenApp} />}
            {(invitePopup && editId) && <SendInvite appId={editId} invitePopup={invitePopup} setInvitePopup={setInvitePopup1} />}
            {(getLinkPopup && editId) && <GetLink appId={editId} getLinkPopup={getLinkPopup} setgetLinkPopup={setgetLinkPopup1} />}
            {(!page.totalCount && !loading) && <NoRecordsFound />}
        </React.Fragment>
    );
}

// default props
ApplicationChords.defaultProps = {
    classes: {}
};

// prop types
ApplicationChords.propTypes = {
    classes: propTypes.object
};

// export default UserList;
export default withStyles(ApplicationStyles)(ApplicationChords);