import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid'; 
import { withStyles } from "@mui/styles"; 

//Styles
import DirectoryStyles from "../DirectoriesStyles"

// Import Components
import { PageContainer } from "../../../components";
import DirectoryChord from "./DirectoryChord"
import NoRecordsFound from "../../Users/Components/NoRecordsFound";

// Import Cofig Constants
import { getChildContainerHeight } from "../../../config/layout"

// Import Actions
import { directoryAction } from "../../../redux/actions";

// Import Config Layout
import { directorysCardLimit } from "../../../config/layout";

const DirectoryTile = (props) => {

    const { editOpenDrawer } = props

    //define dispatch
    const dispatch = useDispatch();

    let cardLimit = directorysCardLimit();
    const cardSize = Math.floor(12 / (cardLimit / 5))

    const { classes } = props;

    const height = getChildContainerHeight();

    //get reducer data
    let { getAll: { data,page } } = useSelector((state) => state.directory);
    const { loading } = useSelector(s=>s.UI)

    //get user data
    useEffect(() => {
        dispatch(directoryAction.getAll({ limit: cardLimit, skip: page.skip, search: page.search, sort: page.sort }));
    }, [dispatch]);

    return (
        <Grid item xs={12}>
            <PageContainer height={height} paddingRight={12}>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        alignItems="stretch"
                        className={"h-100"}
                        spacing={2}
                        wrap="nowrap"
                    >
                        {data && data.length>0 ? <DirectoryChord classes={classes} editOpenDrawer={editOpenDrawer} /> :  !loading ? <NoRecordsFound/> : null}
                    </Grid>
                </Grid>
            </PageContainer>
        </Grid>
    );
}

// default props
DirectoryTile.defaultProps = {
    classes: {}
};

// prop types
DirectoryTile.propTypes = {
    classes: PropTypes.object
};

export default withStyles(DirectoryStyles)(DirectoryTile);
