/* eslint-disable camelcase */
import { userConstant } from "../constants";

// Set Initial State
const initialState = {
  viewType: 'left',
  filterToggle: false,
  search: {
    data: [],
    page: {
      limit: 10,
      sFields: [],
      sValues: [],
      count: 0,
      skip: 1,
      sortBy: 'full_name',
      sort: 'asc',
      fieldFilters: {
        headerCols: [],
        fieldCols: [],
        searchCols: []
      }
    }
  },
  getUser: {},
  getAll: {
    data: [],
    page: {
      totalCount: 0,
      limit: 10,
      skip: 1,
      search: '',
      sort: 'desc'
    }
  },
  chordFilter: {
    rolesArr: [],
    companyArr: [],
    rolesSel: [],
    companySel: [],
  },
  companyCount: {
    start: 0,
    end: 40
  },
  create: {},
  update: {},

  //user details
  logInUser: {},
  currentUser: {
    role: ""
  },
  company: [],
  region: [],
  managerMap: null,
  logInUserLogo:{logo:""}
};

export default function (state = initialState, action) {
  switch (action.type) {

    //update view type
    case userConstant.UPDATE_VIEW_TYPE:
      return {
        ...state,
        viewType: action.data
      };
    //update view type and search
    case userConstant.UPDATE_VIEW_TYPE_AND_SEARCH:
      return {
        ...state,
        viewType: action.data,
        getAll: { ...state.getAll, page: { ...state.getAll.page, search: '' } },
        search: { ...state.search, page: { ...state.search.page, sFields: [], sValues: [] } }
      };

    //update filter toggle
    case userConstant.UPDATE_FILTER_TOGGLE:
      return {
        ...state,
        filterToggle: action.data
      };

    //update company count
    case userConstant.UPDATE_COMPANY_COUNT:
      return {
        ...state,
        companyCount: action.data
      };

    //Logged user
    case userConstant.LOGIN_USER_REQUEST:
      return {
        ...state
      };
    case userConstant.LOGIN_USER_SUCCESS:
      return {
        ...state,
        logInUser: action.data.data
      };
    case userConstant.LOGIN_USER_FAILURE:
      return {
        ...state,
        logInUser: initialState.logInUser
      };

    //Logged user
    case userConstant.LOGIN_LOGO_REQUEST:
      return {
        ...state
      };
    case userConstant.LOGIN_LOGO_SUCCESS:
      return {
        ...state,
        logInUserLogo: action.data.data
      };
    case userConstant.LOGIN_LOGO_FAILURE:
      return {
        ...state,
        logInUserLogo: initialState.logInUser
      };

    //Current User Data
    case userConstant.CURRENT_USER_REQUEST:
      return {
        ...state
      };
    case userConstant.CURRENT_USER_SUCCESS:
      return {
        ...state,
        currentUser: action.data.data
      };
    case userConstant.CURRENT_USER_FAILURE:
      return {
        ...state,
        currentUser: initialState.currentUser
      };

    //Current User Data
    case userConstant.UPDATE_PREFERENCE_REQUEST:
      return {
        ...state
      };
    case userConstant.UPDATE_PREFERENCE_SUCCESS:
      return {
        ...state,
        currentUser: { ...state.currentUser, preference: action.data.data }
      };
    case userConstant.UPDATE_PREFERENCE_FAILURE:
      return {
        ...state,
      };

    //create user
    case userConstant.CREATE_USER_REQUEST:
      return {
        ...state
      };
    case userConstant.CREATE_USER_SUCCESS:
      return {
        ...state,
        getUser: action.data
      };
    case userConstant.CREATE_USER_FAILURE:
      return {
        ...state,
      };

    //create user
    case userConstant.GET_MANAGER_MAP_REQUEST:
      return {
        ...state
      };
    case userConstant.GET_MANAGER_MAP_SUCCESS:
      return {
        ...state,
        maangerMap: action.data.data
      };
    case userConstant.GET_MANAGER_MAP_FAILURE:
      return {
        ...state,
      };

    //delete user
    case userConstant.USER_DELETE_REQUEST:
      return {
        ...state
      };
    case userConstant.USER_DELETE_SUCCESS:
      return {
        ...state,
      };
    case userConstant.USER_DELETE_FAILURE:
      return {
        ...state,
      };

    //update user
    case userConstant.UPDATE_USER_REQUEST:
      return {
        ...state
      };
    case userConstant.UPDATE_USER_SUCCESS:

      let logo = state.logInUser && state.logInUser.logo ? state.logInUser.logo : ""
      if (logo) {
        logo = logo.split("?");
        logo = logo[0] ? `${logo[0]}?${new Date().getTime()}` : "";
      }

      return {
        ...state,
        logInUser: { ...state.logInUser, logo: logo }
      };
    case userConstant.UPDATE_USER_FAILURE:
      return {
        ...state,
      };

    //client companies
    case userConstant.GET_COMPANY_REQUEST:
      return {
        ...state
      };
    case userConstant.GET_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.data.data
      };
    case userConstant.GET_COMPANY_FAILURE:
      return {
        ...state,
        company: initialState.company
      };

    //client companies and role
    case userConstant.GET_COMPANY_ROLE_REQUEST:
      return {
        ...state
      };
    case userConstant.GET_COMPANY_ROLE_SUCCESS:
      return {
        ...state,
        chordFilter: action.data.data
      };
    case userConstant.GET_COMPANY_ROLE_FAILURE:
      return {
        ...state,
        chordFilter: initialState.company
      };

    // SEARCH USER
    case userConstant.USER_SEARCH_REQUEST:
      return {
        ...state
      };
    case userConstant.USER_SEARCH_SUCCESS:
      return {
        ...state,
        search: {
          data: action.data.data,
          page: action.data.page
        }
      };
    case userConstant.USER_SEARCH_FAILURE:
      return {
        ...state
      };

    // GET ALL USER
    case userConstant.USER_GETALL_REQUEST:
      return {
        ...state
      };
    case userConstant.USER_GETALL_SUCCESS:

      const page = action.data.page;
      let stateData = [];

      if (page.skip > 1) {
        stateData = [...state.getAll.data, ...action.data.data]
      } else {
        stateData = [...action.data.data]
      }

      return {
        ...state,
        getAll: {
          data: stateData,
          page: action.data.page
        }
      };
    case userConstant.USER_GETALL_FAILURE:
      return {
        ...state,
        getAll: initialState.getAll
      };

    //update chord filter reducer
    case userConstant.CHORD_FILTER_UPDATE:
      return {
        ...state,
        chordFilter: { ...state.chordFilter, ...action.data }
      }

    // GET ONE CLIENT BY ID
    case userConstant.USER_GET_ONE_REQUEST:
      return {
        ...state,
        getUser: {}
      };
    case userConstant.USER_GET_ONE_SUCCESS:
      return {
        ...state,
        getUser: action.data.data
      };
    case userConstant.USER_GET_ONE_FAILURE:
      return {
        ...state,
        getUser: {}
      };

    //reset user data  
    case userConstant.RESET_USER_DATA:
      return {
        ...state,
        getUser: {}
      };

    //LOGIN USER
    case userConstant.USER_LOGIN_REQUEST:
      return {
        ...state,
        viewType: 'left',
        getAll: { ...state.getAll, page: { ...state.getAll.page, search: "" } }
      };
    case userConstant.USER_LOGIN_SUCCESS:
      return {
        ...state,
        currentUser: action.data.data
      };
    case userConstant.USER_LOGIN_FAILURE:
      return {
        ...state
      };

    //GET REGION LIST
    case userConstant.GET_REGION_REQUEST:
      return {
        ...state
      };
    case userConstant.GET_REGION_SUCCESS:
      return {
        ...state,
        region: action.data.data
      };
    case userConstant.GET_REGION_FAILURE:
      return {
        ...state,
        region: []
      };


    //CUURRENT LOGIN REDUCER UPDATE
    case userConstant.CURRENT_LOGIN_UPDATE:
      return {
        ...state,
        viewType: 'left',
        currentUser: { ...action.data },
        getAll: { ...state.getAll, page: { ...state.getAll.page, search: '' } },
        search: { ...state.search, page: { ...state.search.page, sFields: [], sValues: [] } }
      }

    default:
      return state;
  }
}
