// Import API Helper
import { postRequest } from "../../utils/apiHelper";
import { removeToken, setToken } from "../../utils/appHelper";

/**
 * Export All Authentication Service Functions
 */
export const authService = {
  login,
  logout,
  forgotPassword,
  resetPassword,
  contactUs,
  getProfileData,
  getProfileDataCurrent,
  checkResetTokenExpiry,
  getProfileLogo,
  getCompanyAddress
};

/**
 * get uesr profile oninit
 */
 async function getProfileData() {
  const response = await postRequest("profile");
  return response;
}

/**
 * get uesr profile oninit
 */
async function getProfileLogo() {
  const response = await postRequest("profile/logo");
  return response;
}

/**
 * get uesr profile oninit
 */
async function getProfileDataCurrent() {
  const response = await postRequest("profile/current");
  return response;
}

/**
 * Call Login Service
 * @param username
 * @param password
 */
async function login(params) {
  const response = await postRequest("login", params);
  setToken(response.token);
  return response;
}

/**
 * Call Logout Service
 */
async function logout() {
  const response = await postRequest("logout");
  removeToken()
  return response;
}

/**
 * Call Forgot Password Service
 * @param username
 * @param password
 */
async function forgotPassword(params) {
  const response = await postRequest("forgotpassword", params);
  return response;
}

/**
 * Call Reset Passord Service
 * @param {*} params 
 */
async function resetPassword(params) {
  const response = await postRequest("resetpassword", params);
  return response;
}

/**
 * Check reset password token
 * @param {*} params 
 */
async function checkResetTokenExpiry(params) {
  const response = await postRequest("resetPasswordToken", params);
  return response;
}

/**
 * Call Contact Us Service
 * @param username
 */
async function contactUs(params) {
  const response = await postRequest("contactUs", params);
  return response;
}

/**
 * Get comapny address
 */
 async function getCompanyAddress() {
  const response = await postRequest("address");
  return response;
}