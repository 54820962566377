import palette from '../../assets/theme/palette';
const ApplicationStyles = () => ({

  padding5: { padding: 5 },
  padding6: { padding: 6 },
  paddingBottom5: { paddingBottom: 5 },
  titlerow: {
    height: '34px',
    padding: '0 16px',

    // backgroundColor: 'blue',
    // padding: '10px 0',
    '& .MuiToggleButton-root': {
      padding: 7
    }
  },
  allianceDrawer: {
    '& .MuiDrawer-paper': {
      width: 400
    }
  },
  grow: {
    flexGrow: 1
  },
  settingsTile: {
    padding: '8px 13px 8px 7px',
    border: `1px solid ${palette.greyshades.light2}`,
    borderRadius: 4,
    backgroundColor: palette.greyshades.light3,
    marginBottom: 15,
    height: 42,
    '& .dragIcon': {
      cursor: "move"
    }
  },
  dragIcon: {
    marginRight: 5,
    cursor: 'pointer',
    fill: palette.greyshades.light1
  },
  checkIcon: {
    fill: palette.primary.dary
  },
  backDrop: {
    zIndex: 3
  },
  SubmissionTable: {
    paddingLeft: 16,
    paddingRight: 16
  },
  cardSectionList: {
    height: '100%',
    width: "100%",
    borderRadius: '4px',
    boxShadow: '0px 0px 16px rgba(0,0,0,0.2)',
    padding: '8px 0px 8px 16px',
  },

  dialogHeader: {
    background: '#fff',
    // position: 'sticky',
    // top: 0,
    zIndex: '9999'
  },
  appTemplateDialog: {
    background: palette.greyshades.light3,
    height: '100vh',
    alignContent: 'baseline',
    position: 'relative'
  },

  // ........
  accordionConDiv: {
    maxWidth: 1000,
  },
  accordionContainer: {
    background: palette.greyshades.light3,
    paddingBottom: 20,
    height: 'calc(100vh - 74px)',
    overflowY: 'auto',
    '& .MuiAccordionSummary-root': {
      minHeight: 41,
      background: '#fff',
      '& .MuiIconButton-root': {
        padding: 5
      }
    },
    '& .MuiAccordionSummary-content': {
      margin: '5px 0'
    },
    '& .MuiPaper-elevation1': {
      boxShadow: '0px 0px 8px #d4d1d1',
      marginBottom: 20,
      width:"100%",
      // boxShadow: 'none',
      // borderBottom: `1px solid ${palette.greyshades.light2}`,
      borderRadius: 0,
      '&:before': {
        top: '0 !important'
      }
    },
    '& .MuiAccordion-root:before': {
      // backgroundColor: palette.primary.main
    },
    '& .MuiAccordion-root.Mui-expanded': {
      // margin: 0,
      marginBottom: 20,
      '& .MuiAccordionSummary-root': {
        '& .MuiTypography-root': {
          color: '#fff'
        },
      },
      '& .expandIcon': {
        fill: '#fff'
      }
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      borderBottom: `1px solid ${palette.greyshades.light2}`,
      backgroundColor: palette.primary.main
    },
    '& .MuiAccordionDetails-root': {
      padding: '15px 35px',
      background: '#fff',
      '& .searchIcon': {
        width: 15,
        '& path': {
          fill: "#D2D3D4"
        }
      }
    },
  },
  // .........
  allianceTextarea: {
    borderRadius: 4,
    borderColor: '#D2D3D4',
    width: '100%',
    '&:hover': {
      borderColor: 'rgb(95 95 95 / 87%)',
    },
    '&:focus': {
      border: `2px solid ${palette.primary.main}`,
      outline: 'transparent !important'
    }
  },
  //...................
  selectBox: {
    width: "100%",
    '& .MuiSelect-outlined': {
      padding: 10,
      textAlign: 'left',
      borderRadius: 30
    },
    '& .MuiOutlinedInput-notchedOutline legend': {
      display: "none !important"
    },
    '& .MuiOutlinedInput-notchedOutline': {
      marginTop: "5px !important"
    },
  },

  //..............
  greyContainer: {
    border: '1px solid #E6E5E5',
    padding: '20px',
    background: '#F7F7F7',
    marginBottom: 20
  },
  settingsTileBgnoneAddDoc: {
    padding: '10px',
    border: `1px solid ${palette.greyshades.light2}`,
    borderRadius: 4,
    backgroundColor: '#fff',
    marginBottom: 12,
    '& .MuiTypography-root': {
      width: '70%',
    },
    '& .MuiSvgIcon-root': {
      width: '10%',
    },
    '& .dragIcon': {
      cursor: "move"
    }
  },



  ///users styles css
  padding5: { padding: 5 },
  padding6: { padding: 6 },
  paddingBottom5: { paddingBottom: 5 },
  titlerow: {
    height: '34px',
    padding: '0 16px',
    marginBottom: 4,

    // backgroundColor: 'blue',
    // padding: '10px 0',
    '& .MuiToggleButton-root': {
      padding: 7
    }
  },
  // contentcontainer: {
  //   height: 'calc(100% - 70px)',
  // },
  cardSectionProfileImg: {
    height: '100%',
    width: "100%",
    borderRadius: '4px',
    boxShadow: '0px 0px 8px #d4d1d1',
    padding: '8px',
    '& .closeIconBtn': {
      position: 'absolute',
      background: '#fff',
      borderRadius: '30px',
      top: '12px',
      right: '12px',
      width: '18px',
      height: '18px',
      padding: 2
    }
  },
  listOneLine: {
    '& .MuiFormControlLabel-root': {
      maxWidth: '100%'
    }
  },
  usersCardSection: {
    height: '100%',
    width: "100%",
    borderRadius: '4px',
    boxShadow: '0px 0px 8px #d4d1d1',
    padding: '8px 16px',
    height: 'calc(100vh - 172px)',
    overflowX: 'hidden',
    overflowY: 'auto',

  },
  cardSection: {
    height: '100%',
    width: "100%",
    borderRadius: '4px',
    boxShadow: '0px 0px 8px #d4d1d1',
    padding: '8px 16px',
    '& .MuiStep-completed': {
      '& .MuiSvgIcon-root': {
        fill: "#3abeff"
      }
    }
  },
  cardSectionList: {
    height: '100%',
    width: "100%",
    borderRadius: '4px',
    boxShadow: '0px 0px 8px #d4d1d1',
    padding: '8px 0px 8px 16px',
    '& .MuiTableCell-body': {
      maxWidth: 160
    }
  },
  appBarCls: {
    padding: '0 16px 0px',
    backgroundColor: 'transparent'
  },
  usersTable: {
    paddingRight: 16,
    paddingLeft: 16
  },

  //Add User
  selectBox: {
    width: "100%",
    '& .MuiSelect-outlined': {
      padding: 10,
      textAlign: 'left',
      borderRadius: 30
    },
    '& .MuiOutlinedInput-notchedOutline legend': {
      display: "none !important"
    },
    '& .MuiOutlinedInput-notchedOutline': {
      marginTop: "5px !important"
    },
  },
  tablePageSelect: {
    '& .MuiSelect-outlined': {
      padding: '5px 10px',
    }
  },

  profileImgContainer: {
    height: 176,
    width: 176,
    cursor: "pointer",
    background: "#F7F7F7",
    '& svg': {
      width: '100%',
      height: 'auto'
    },
    '& .deleteIcon': {
      height: 12,
      width: 12
    }
  },
  uploadImgPopup: {
    height: 175,
    width: 175,
    objectFit: 'contain'
  },
  uploadImgOpacity: {
    opacity: 0,
    height: 175,
    width: 175,
  },
  allianceDrawer: {
    '& .MuiDrawer-paper': {
      width: 400
    }
  },
  grow: {
    flexGrow: 1
  },
  settingsTile: {
    padding: '8px 13px 8px 7px',
    border: `1px solid ${palette.greyshades.light2}`,
    borderRadius: 4,
    backgroundColor: palette.greyshades.light3,
    marginBottom: 15,
    height: 42,
    '& .MuiFormControlLabel-root': {
      marginRight: '0px !important'
    },
    '& .dragIcon': {
      cursor: "move"
    },
    '& .settingSTileCheck': {
      marginRight: 0,
      padding: 0,
      '& .MuiFormControlLabel-root': {
        marginRight: '0px !important'
      }
    }
  },
  dragContainer: {
    height: 24
  },
  serviceSettingTileWidth: {
    maxWidth: '46% !important',
    flexBasis: '46% !important'
  },
  servicesSettingsTile: {
    padding: '8px 13px 8px 7px',
    border: `1px solid ${palette.greyshades.light2}`,
    borderRadius: 4,
    backgroundColor: palette.greyshades.light3,
    maxHeight: 61,
    height: '100%',
    // marginBottom: 15,
    // height: 42,
    '& .MuiFormControlLabel-root': {
      marginRight: '0px !important'
    },
    '& .dragIcon': {
      cursor: "move"
    },
    '& .settingSTileCheck': {
      marginRight: 0,
      padding: 0,
      '& .MuiFormControlLabel-root': {
        marginRight: '0px !important'
      }
    }
  },
  dragIcon: {
    marginRight: 5,
    cursor: 'pointer',
    fill: palette.greyshades.light1
  },
  checkIcon: {
    fill: palette.primary.dary
  },
  backDrop: {
    zIndex: 3
  },
  toggleButton: {
    background: '#fff',
    '& .Mui-selected': {
      backgroundColor: palette.primary.dary,
      '& svg path': {
        fill: '#fff !important'
      },
      '& rect': {
        fill: '#fff !important'
      }
    }
  },

  //Tile
  iconLink: {
    display: "flex",
    cursor: 'pointer',
    alignItems: "center",
    padding: '5px 10px',
    borderBottom: `1px solid ${palette.greyshades.light2}`,
  },
  tileFilter: {
    height: 'calc(100vh - 132px)',
    overflowY: 'auto',
    border: `1px solid ${palette.greyshades.light2}`,
    background: '#fff',
    // height: "100%",
    // ..........
    '& .MuiAccordionSummary-root': {
      minHeight: 36,
      '& .MuiIconButton-root': {
        padding: 5
      }
    },
    '& .MuiAccordionSummary-content': {
      margin: '5px 0'
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
      borderBottom: `1px solid ${palette.greyshades.light2}`,
      borderRadius: 0,
    },
    '& .MuiAccordion-root:before': {
      backgroundColor: 'transparent'
    },
    '& .MuiAccordion-root.Mui-expanded': {
      margin: 0,
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      borderBottom: `1px solid ${palette.greyshades.light2}`
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
      '& .searchIcon': {
        width: 15,
        '& path': {
          fill: "#D2D3D4"
        }
      }
    },
    // ............
    '& .MuiList-root': {
      width: '100%'
    },
    '& .MuiListItem-root': {
      paddingTop: 5,
      paddingBottom: 5
    },
    '& .MuiCheckbox-root': {
      padding: '0 9px'
    },
  },
  seachIcon: {
    fill: palette.greyshades.light1
  },
  searchField: {
    marginBottom: 2,
    marginTop: 4,
    '& .MuiOutlinedInput-root': {
      height: 30,
      minHeight: 30,
      background: palette.greyshades.light3
    },
    '& .MuiInputBase-input': {
      fontSize: 13
    },
    '& .MuiSvgIcon-root': {
      fontSize: 20
    }
  },
  //Tile
  filterSection: {
    minWidth: 225,
    maxWidth: 225,
    transition: 'all 0.3s ease',
    overflowY: 'hidden',
    // height: '100%',
    height: '83vh'
  },
  filterOpenIcon: {
    display: 'flex',
    alignItems: "center",
    background: '#e2e2e2',
    marginLeft: 7,
    height: 'calc(100vh - 125px)',
    position: 'relative',
    marginRight: '7px',
    cursor: 'pointer',
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20
    },
    '&:hover': {
      '& .filterOpenIconBtn': {
        transition: '0.5s',
        opacity: 1,
        right: 8,
        position: 'absolute',
        marginTop: '-115px'
      }
    },
    '& .filterOpenIconBtn': {
      transition: '0.5s',
      opacity: 0,
      right: 25,
      position: 'absolute',
      marginTop: '-115px'
    },
    '& .MuiIconButton-root': {
      padding: '2px'
    }
  },
  filterOpenIconText: {
    transform: 'rotate(-90deg)',
    letterSpacing: 10,
    color: 'gray',
    marginRight: -32,
    marginLeft: -32
  },
  usersRightTiles: {
    height: 'calc(100vh - 125px)',
    overflowY: 'auto',
    flexGrow: 1,
    transition: 'all 0.5s ease'
  },
  searchBoxGrid: {
    marginRight: '-10px',
    '& .searchBox': {
      transition: '0.5s',
      width: 200,
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: '8px'
      }
    },
    '& .MuiOutlinedInput-root': {
      height: '32px',
      minHeight: 32,
    }
  },
  userTile: {
    background: '#fff',
    boxShadow: '0px 0px 10px #d4d1d1',
    borderRadius: 4,
    minWidth: 260,
    // flexBasis: '25%',
    // maxWidth: '25%',
    // flexGrow: 1,
    '& .MuiButton-containedSizeSmall': {
      height: '26px !important',
      padding: '7px 14px !important',
      marginTop: '-1px'
    },
    '& .MuiButton-contained': {
      textTransform: 'capitalize'
    }
  },
  userImg: {
    height: 50,
    width: 50,
    borderRadius: 30,
    position: "relative",
    // marginRight: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: palette.greyshades.light3,
    '& .MuiTypography-root': {
      color: '#C3BFC1 !important'
    },
    '& img': {
      height: 50,
      width: 50,
      borderRadius: 30,
      objectFit: 'cover',
      // padding: 10
    }
  },
  onlineIcon: {
    background: '#2AE133',
    height: 10,
    width: 10,
    borderRadius: '100%',
    bottom: '-5px',
    position: 'absolute',
    marginLeft: '20px'
  },
  offlineIcon: {
    background: '#9e9e9e',
    height: 10,
    width: 10,
    borderRadius: '100%',
    marginTop: '-11px',
    marginLeft: '20px'
  },
  nameContainer: {
    // width: 146
    // width: 'calc( 100% - 90px)',
    padding: '0 20px 20px 20px',
    height: 40,
    '& .MuiTooltip-tooltip': {
      marginTop: '-5px !important'
    },
    '& .MuiTooltip-popper': {
      marginTop: '-5px !important'
    },
    '& .MuiTooltip-touch': {
      marginTop: '-5px !important'
    }

  },
  userTileTop: {
    padding: 20,
    position: 'relative'
  },
  moreContainerTop: {
    // marginBottom: -18
    position: 'absolute',
    right: '8px',
    top: '8px'
  },
  tileMoreIcon: {
    // marginRight: -35,
    // marginTop: -50,
    // padding: 5,
    // width: 20,
    // height: 20
  },
  UserInfo: {
    padding: '0 20px 20px 20px',
    height: 40
  },
  userTileBottom: {
    borderTop: `1px solid ${palette.greyshades.light2}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px'
  },
  lastLogin: {
    fontStyle: 'italic',
    marginLeft: 10,
    paddingRight: 2
  },
  loginIcon: {
    fill: palette.primary.dary,
    width: 14,
    height: 14,
    marginRight: 8
  },
  activeWhite: {
    fill: "#fff"
  },
  //Add a service
  allianceDrawer600: {
    '& .MuiDrawer-paper': {
      width: 600
    }
  },
  leftFilterPanel: {
    width: 240
  },
  filterOpen: {
    marginLeft: 240
  },

  //Dialog
  widthAuto: {
    '& .MuiSvgIcon-root': {
      width: "auto !important"
    }
  },
  addAServiceTop: {
    height: 75
  },
  addServiceDialog: {
    height: '100%',
    '& .MuiStepper-root': {
      padding: 3
    }
  },
  addServiceDialogRoot: {
    '& .MuiDialog-paper': {
      backgroundColor: palette.greyshades.light3
    }
  },
  cardSectionDialog:
  {
    background: '#fff',
    boxShadow: '0px 0px 8px #d4d1d1',
    borderRadius: '6px',
    minHeight: 400,
    // height: 420,
    height: 'calc(100vh - 260px)',
    padding: 20,
    overflowY: "hidden !important",
    '& .pageScrollVerticle > div > div': {
      height: '340px !important'
    },
    '& .pageScrollVerticle > div > div > div': {
      height: '330px !important'
    },
  },
  addAServiceCenter: {
    // height: 'calc(100% - 130px)',
    maxWidth: 1000,
    margin: "auto"
  },
  addAServiceBottom: {
    paddingBottom: 15
  },
  selectTemplate: {
    backgroundColor: palette.greyshades.light3,
    border: `1px solid ${palette.greyshades.light2}`,
    padding: 20
  },
  settingsTileBgnone: {
    padding: '13px',
    border: `1px solid ${palette.greyshades.light2}`,
    borderRadius: 4,
    backgroundColor: '#fff',
    '& .MuiTypography-root': {
      width: '70%',
      marginRight: 5
    },
    '& .MuiSvgIcon-root': {
      width: '10%',
    },
    '& .dragIcon': {
      cursor: "move",
      marginRight: 5
    }
  },
  settingsTileBgnoneAddDoc: {
    padding: '10px',
    border: `1px solid ${palette.greyshades.light2}`,
    borderRadius: 4,
    backgroundColor: '#fff',
    marginBottom: 12,
    '& .MuiTypography-root': {
      width: '70%',
    },
    '& .MuiSvgIcon-root': {
      width: '10%',
    },
    '& .dragIcon': {
      cursor: "move"
    }
  },
  borderNone: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px !important'
    }
  },
  choicesContainer: {
    padding: 20,
    background: palette.greyshades.light3,
    border: `1px solid ${palette.greyshades.light2}`,
  },
  whiteBgContainer: {
    height: "100%",
    borderRadius: 4,
    padding: 16,
    alignContent: 'center',
    background: '#fff',
    boxShadow: '0px 0px 8px #50576614',
  },
  CreateTemplateCont: {
    background: '#fff',
    padding: '20px',
    height: 'calc(100vh - 176px)',
    boxShadow: '0px 0px 8px #50576614',
    borderRadius: '6px'
  },
  notifyIcon: {
    width: 28,
    height: 28,
    background: palette.greyshades.light2
  },
  serviceActionBtn: {
    padding: '16px 16px 0px',
  },
  addUSerAction: {
    paddingLeft: '2.6%'
  },
  groupTitle: {
    background: palette.primary.main,
    color: '#fff',
    borderLeft: '5px solid black',
    padding: '7px 10px'
  },
  titleContainer: {
    paddingLeft: '3.5% !important',
    paddingRight: '3.5% !important'
  },
  nameChip: {
    fontWeight: 400,
    backgroundColor: '#f7f7f7',
    display: "inline-block",
    color: "#878083",
    padding: '3px 15px',
    borderRadius: '30px',
    marginTop: '-2px'
  },
  userProfileDoNot: {
    textAlign: 'center',
    marginTop: '15px'
  },
  createTemplateContainer: {
    height: 'calc(100vh - 145px)',
    padding: '15px'
  },
  chipInput: {
    "& .MuiInputBase-root": {
      lineHeight: "20px",
      padding: "1rem",
    },
    "& .MuiChip-root": {
      background: "#EDF9FF",
      maxWidth:"400px",
      marginRight: 5,
      marginBottom: 5
    },
    "& .MuiChip-deleteIcon": {},
  },
  frequencyWidth: {
    minWidth: "50%",
    maxWidth: "100%",
  },
  errorChipReport: {
    background: "#F64242 !important",
    color: "white",
    "& .MuiChip-deleteIcon": {
      color: "white",
      opacity: "0.75",
      "&:focus": {
        opacity: "1"
      }
    }
  },
  multiSelect: {
    '& .MuiInputBase-input, .MuiOutlinedInput-root': {
      height: '34px',
      padding: '0 9px',
      minHeight: '34px',
      '&::placeholder': {
        opacity: '1 !important'
      },
      '& input': {
        padding: '0px 4px !important'
      }
    }
  },
  inviteContainer: {
    maxHeight: 'calc(100vh - 127px)',
    overflowY: 'auto'
  },
  QuestEditDeleteIcon: {
    width: 'calc(100% - 25px)',
    textAlign: 'right'
  },
  getLink:{
    color: "#878083 !important",
    fontSize:"14px",
    textDecoration: "none",
    "&:hover":{
      color:"#12A8DE !important"
    }
  }

})

export default ApplicationStyles