import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import classNames from 'classnames';


//Styles
import ServicesStyles from '../../ServicesStyles'

//Images
import { DragIcon, CheckSelectedIcon, DeleteIcon, BellIcon } from '../../../../assets/svg'

// Import Components
import { Button, TextFieldWithoutRef, Info, Typography, CheckBox, Link, TextAreaWithLines } from "../../../../components";

// Import Helpers
import validations from "../../../../utils/validations";

// Import Cofig Constants
import { user_page_constants, templates_page_constants } from "../../../../config/constants";
import { serviceConstant } from "../../../../redux/constants";

// Import Actions
import { serviceAction, updateReducerAction } from "../../../../redux/actions";

const Layout = (props) => {

    //define dispatch
    const dispatch = useDispatch();

    const { classes, control, register, errors, createFromScratch, page } = props;

    const handleChange = (e) => {
        if (e.target.name === "is_layout") {
            createFromScratch.documentData[page][e.target.name] = e.target.checked
        } else {
            createFromScratch.documentData[page][e.target.name] = e.target.value
        }
        dispatch(updateReducerAction.update(serviceConstant.ADD_NEW_PAGE_DOCUMENT, createFromScratch, () => { }));
    }

    return (
        <div
            role="tabpanel"
            id={`simple-tabpanel-3`}
            aria-labelledby={`simple-tab-3`}
        >
            <Grid container alignItems="center" alignContent={"baseline"} style={{ alignContent: 'baseline', height: 300 }}>
                <Grid item sm={6} align="left">
                    <Info>
                        <p dangerouslySetInnerHTML={{ __html: `${templates_page_constants.LAYOUT_TEXT}` }} className={`${classes.borderline} pb-1`}></p>
                    </Info>
                </Grid>
                <Grid item sm={6} align="right">
                    <CheckBox
                        name={"is_layout"}
                        label={user_page_constants.SHOW_ON_DISCLOSURE_PAGE}
                        checked={createFromScratch.documentData[page]["is_layout"]}
                        color="secondary"
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item sm={12} className={"multiLineText"}>

                    {/* <TextFieldWithoutRef
                        name={"layout"}
                        margin="none"
                        value={createFromScratch.documentData[page]["layout"]}
                        size={"small"}
                        rows={14}
                        multiline={true}
                        handleChange={(e) => handleChange(e)}
                    /> */}
                    <TextAreaWithLines
                     minRow="12"
                        name={"layout"}
                        onChange={(e) => handleChange(e)}
                        value={createFromScratch.documentData[page]["layout"]}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

// default props
Layout.defaultProps = {
    classes: {}
};

// prop types
Layout.propTypes = {
    classes: PropTypes.object
};

export default withStyles(ServicesStyles)(Layout);
