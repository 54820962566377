//import helpers
import { IsEmail } from '../../../utils'

export const stepperValidation = (activeStep, completedSteps, steps, appData, movingStep) => {

    const { allSteps } = appData

    //introduction step validation
    if (steps[activeStep] === "introduction") {
        const introduction = appData["introduction"];
        //check signature is found or not
        if (allSteps.indexOf("introductionSign") > -1 && !introduction["introSignature"]) {
            return { isValid: false, validationError: "Signature cannot be blank" }
        }
        //check confirmantion checkbox
        if (introduction["isIntroConfirm"] > 0 && introduction["introCheckbox"] === false) {
            return { isValid: false, validationError: "checkbox must be checked to proceed" }
        }

        //default return
        return { isValid: true, validationError: "" }

    } else if (steps[activeStep] === "criteria") {
        const criteria = appData["criteria"];

        //check if criteriaData has signature
        if (allSteps.indexOf("criteriaSign") > -1 && !criteria["criteriaSignature"]) {
            return { isValid: false, validationError: "Signature cannot be blank" }
        }
        //check confirmantion checkbox
        else if (allSteps.indexOf("criteriaSign") === -1 && criteria["criteriaCheckbox"] === false) {
            return { isValid: false, validationError: "checkbox must be checked to proceed" }
        }

        //default return
        return { isValid: true, validationError: "" }
    }
    //cehck application step 
    else if (steps[activeStep] === "application" || steps[activeStep] === "applicationReview") {
        const application = appData["application"];
        const applicationForm = application["applicationForm"]
        let firstInvalid = "";
        let isValid = true

        //application signature check
        if (steps[activeStep] === "application" && allSteps.indexOf("applicationSign") > -1 && !application["applicationSignature"]) {
            return { isValid: false, validationError: "Signature cannot be blank" }
        }

         //application review confirmation check
         if (steps[activeStep] === "applicationReview" && allSteps.indexOf("applicationReview") > -1 && !application["confirmCheckbox"]) {
            return { isValid: false, validationError: "Confirmation checkbox cannot be blank" }
        }

        //application field validation
        for (let i = 0; i < applicationForm.length; i++) {
            if (applicationForm[i]["elements"] && (!applicationForm[i]["visible"] || (applicationForm[i]["visible"] && applicationForm[i]["vis"]))) {
                const elements = Object.keys(applicationForm[i]["elements"])
                for (let j = 0; j < elements.length; j++) {
                    //required field validation
                    if (!firstInvalid && applicationForm[i]["elements"][elements[j]]["required"] && !applicationForm[i]["elements"][elements[j]]["value"]) {
                        firstInvalid = applicationForm[i]["elements"][elements[j]]["label"] ? `${applicationForm[i]["elements"][elements[j]]["label"]} cannot be empty` : "Please fill all required fields"
                        isValid = false
                    }

                    //required field validation for onRepeat
                    if (!firstInvalid && applicationForm[i]["elements"][elements[j]]["onrepeat"] && applicationForm[i]["elements"][elements[j]]["onrepeat"]["required"] && applicationForm[i]["elements"][elements[j]]["isNew"] && !applicationForm[i]["elements"][elements[j]]["value"]) {
                        firstInvalid = applicationForm[i]["elements"][elements[j]]["label"] ? `${applicationForm[i]["elements"][elements[j]]["label"]} cannot be empty` : "Please fill all required fields"
                        isValid = false
                    }

                    //date validation
                    if (!firstInvalid && applicationForm[i]["elements"][elements[j]]["type"] && applicationForm[i]["elements"][elements[j]]["type"].toLowerCase() === "date" && applicationForm[i]["elements"][elements[j]]["value"]) {
                        if (new Date(applicationForm[i]["elements"][elements[j]]["value"]) == "Invalid Date" || new Date(applicationForm[i]["elements"][elements[j]]["value"]) < new Date("01-01-1900")) {
                            firstInvalid = applicationForm[i]["elements"][elements[j]]["label"] ? `${applicationForm[i]["elements"][elements[j]]["label"]} is invalid` : "date is invalid"
                            isValid = false
                        }

                        if (!firstInvalid && applicationForm[i]["elements"][elements[j]]["limit"] === "past" && new Date(applicationForm[i]["elements"][elements[j]]["value"]) > new Date().setHours(23, 59, 59, 59)) {
                            firstInvalid = applicationForm[i]["elements"][elements[j]]["label"] ? `${applicationForm[i]["elements"][elements[j]]["label"]} should be past date` : "date should be past date"
                            isValid = false
                        }

                        if (!firstInvalid && applicationForm[i]["elements"][elements[j]]["limit"] === "future" && new Date(applicationForm[i]["elements"][elements[j]]["value"]) < new Date().setHours(0, 0, 0, 0)) {
                            firstInvalid = applicationForm[i]["elements"][elements[j]]["label"] ? `${applicationForm[i]["elements"][elements[j]]["label"]} should be future date` : "date should be future date"
                            isValid = false
                        }

                    }

                    //phone number validation
                    if (!firstInvalid && applicationForm[i]["elements"][elements[j]]["type"] && applicationForm[i]["elements"][elements[j]]["type"].toLowerCase() === "phone" && applicationForm[i]["elements"][elements[j]]["value"] && applicationForm[i]["elements"][elements[j]]["value"].indexOf("_") > -1) {
                        firstInvalid = applicationForm[i]["elements"][elements[j]]["label"] ? `${applicationForm[i]["elements"][elements[j]]["label"]} is invalid` : "Phone number should be valid"
                        isValid = false
                    }

                    //zip code validation
                    if (!firstInvalid && applicationForm[i]["elements"][elements[j]]["type"] && applicationForm[i]["elements"][elements[j]]["type"].toLowerCase() === "zip" && applicationForm[i]["elements"][elements[j]]["value"] && (!(/^\d+$/.test(applicationForm[i]["elements"][elements[j]]["value"])) || applicationForm[i]["elements"][elements[j]]["value"].length !== 5)) {
                        firstInvalid = applicationForm[i]["elements"][elements[j]]["label"] ? `${applicationForm[i]["elements"][elements[j]]["label"]} is invalid` : "Zip Code should be valid"
                        isValid = false
                    }

                    //ssn validation
                    if (!firstInvalid && (elements[j] === "SSN" || applicationForm[i]["elements"][elements[j]]["mask"]) && applicationForm[i]["elements"][elements[j]]["value"] && applicationForm[i]["elements"][elements[j]]["value"].indexOf("_") > -1) {
                        firstInvalid = applicationForm[i]["elements"][elements[j]]["label"] ? `${applicationForm[i]["elements"][elements[j]]["label"]} is invalid` : "SSN should be valid"
                        isValid = false
                    }

                    //email validation
                    if (!firstInvalid && applicationForm[i]["elements"][elements[j]]["type"] && applicationForm[i]["elements"][elements[j]]["type"].toLowerCase() === "email" && applicationForm[i]["elements"][elements[j]]["value"] && !IsEmail(applicationForm[i]["elements"][elements[j]]["value"])) {
                        firstInvalid = applicationForm[i]["elements"][elements[j]]["label"] ? `${applicationForm[i]["elements"][elements[j]]["label"]} is invalid` : "Email should be valid"
                        isValid = false
                    }

                    //money validation
                    // if (!firstInvalid && applicationForm[i]["elements"][elements[j]]["money"] && applicationForm[i]["elements"][elements[j]]["value"]) {
                    //     const vale = applicationForm[i]["elements"][elements[j]]["value"]
                    //     if (/^[0-9]+(\.[0-9]{1,2})?$/.test(vale) === false) {
                    //         firstInvalid = applicationForm[i]["elements"][elements[j]]["label"] ? `${applicationForm[i]["elements"][elements[j]]["label"]} must be a number` : "Money must be a number"
                    //         isValid = false
                    //     }
                    // }

                }
            }
        }
        return { isValid: isValid, validationError: firstInvalid }

    }
    //checking disclosure step
    else if (steps[activeStep] === "disclosure") {
        const disclosure = appData["disclosure"];

        let activeIndex = disclosure.findIndex(s => (s["isVisible"] === true));
        let activeData = disclosure[activeIndex];
        //check layout values
        if (activeData["layout"] && typeof activeData["layout"] === "object") {
            let firstInvalid = "";
            let isValid = true
            for (let i = 0; i < activeData["layout"].length; i++) {
                if (activeData["layout"][i]["elements"] && (!activeData["layout"][i]["visible"] || (activeData["layout"][i]["visible"] && activeData["layout"][i]["vis"]))) {
                    const elements = Object.keys(activeData["layout"][i]["elements"])
                    for (let j = 0; j < elements.length; j++) {
                        if (!firstInvalid && activeData["layout"][i]["elements"][elements[j]]["required"] && !activeData["layout"][i]["elements"][elements[j]]["value"]) {
                            firstInvalid = activeData["layout"][i]["elements"][elements[j]]["label"] ? `${activeData["layout"][i]["elements"][elements[j]]["label"]} cannot be empty` : "Please fill all required fields"
                            isValid = false
                        }

                        if (!firstInvalid && activeData["layout"][i]["elements"][elements[j]]["limit"] === "past" && new Date(activeData["layout"][i]["elements"][elements[j]]["value"]) > new Date().setHours(23, 59, 59, 59)) {
                            firstInvalid = activeData["layout"][i]["elements"][elements[j]]["label"] ? `${activeData["layout"][i]["elements"][elements[j]]["label"]} should be past date` : "date should be past date"
                            isValid = false
                        }

                        if (!firstInvalid && activeData["layout"][i]["elements"][elements[j]]["limit"] === "future" && new Date(activeData["layout"][i]["elements"][elements[j]]["value"]) < new Date().setHours(0, 0, 0, 0)) {
                            firstInvalid = activeData["layout"][i]["elements"][elements[j]]["label"] ? `${activeData["layout"][i]["elements"][elements[j]]["label"]} should be future date` : "date should be future date"
                            isValid = false
                        }
                    }
                }
            }
            if (isValid === false) {
                return { isValid: isValid, validationError: firstInvalid }
            }
        }

        if (!activeData.hasOwnProperty("conditionTrue") || activeData["conditionTrue"] === true) { //choice and variation conditions
            if (activeData["choiceData"] && activeData["choiceData"].length) {
                let choiceLen = 0;
                let count = 0
                for (let c = 0; c < activeData["choiceData"].length; c++) {
                    if (activeData["choiceData"][c]["required"] && activeData["choiceData"][c]["variation"]) {
                        choiceLen = choiceLen + 1;
                        for (let v = 0; v < activeData["choiceData"][c]["variation"].length; v++) {
                            if (activeData["choiceData"][c]["variation"][v] && activeData["choiceData"][c]["variation"][v]["value"]) {
                                count = count + 1
                            }
                        }
                    }
                }
                if (count < choiceLen) {
                    return { isValid: false, validationError: "checkbox must be checked to proceed" }
                }
            }

            if (activeData["is_signature"] === 1 && !(activeData["signature"]) && ((!activeData["layout"] && !activeData["conditions"]) || (activeData["layout"] && typeof activeData["layout"] === "object" && activeData["conditions"] && typeof activeData["conditions"] === "object" && activeData["conditionTrue"] === true))) {
                return { isValid: false, validationError: "Signature cannot be blank" }
            }
        }

        return { isValid: true, validationError: "" }

    } else if (steps[activeStep] === "payment") {

        const payment = appData["payment"];
        const paymentForm = payment["paymentForm"] ? Object.keys(payment["paymentForm"]) : [];

        let isValid = true;
        let firstInval = "";

        for (let i = 0; i < paymentForm.length; i++) {
            if (isValid && payment["paymentForm"][paymentForm[i]] && !payment["paymentForm"][paymentForm[i]]["value"]) {
                isValid = false;
                firstInval = payment["paymentForm"][paymentForm[i]]["label"] ? `${payment["paymentForm"][paymentForm[i]]["label"]} cannot be empty` : "All fields are required"
            }
        }

        if (payment["paymentCheckbox"] === false) {
            isValid = false;
            firstInval = "You have to confirm that you agree to the card being charged"
        }

        return { isValid: isValid, validationError: firstInval }

    } else {
        return { isValid: true, validationError: "" }
    }

}

export const allStepperValidation = (activeStep, completedSteps, steps, appData, movingStep) => {

    let validation = true;
    let validationErr = ""
    for (let i = 0; i < steps.length; i++) {

        if (steps[activeStep] === "disclosure") {
            const disclosure = appData["disclosure"];

            for (let ds = 0; ds < disclosure.length; ds++) {
                //check layout values
                let activeData = disclosure[ds];

                if (activeData["layout"] && typeof activeData["layout"] === "object") {
                    let firstInvalid = "";
                    let isValid = true
                    for (let i = 0; i < activeData["layout"].length; i++) {
                        if (activeData["layout"][i]["elements"] && (!activeData["layout"][i]["visible"] || (activeData["layout"][i]["visible"] && activeData["layout"][i]["vis"]))) {
                            const elements = Object.keys(activeData["layout"][i]["elements"])
                            for (let j = 0; j < elements.length; j++) {
                                if (!firstInvalid && activeData["layout"][i]["elements"][elements[j]]["required"] && !activeData["layout"][i]["elements"][elements[j]]["value"]) {
                                    firstInvalid = activeData["layout"][i]["elements"][elements[j]]["label"] ? `${activeData["layout"][i]["elements"][elements[j]]["label"]} cannot be empty` : "Please fill all required fields"
                                    isValid = false
                                }

                                if (!firstInvalid && activeData["layout"][i]["elements"][elements[j]]["limit"] === "past" && new Date(activeData["layout"][i]["elements"][elements[j]]["value"]) > new Date().setHours(23, 59, 59, 59)) {
                                    firstInvalid = activeData["layout"][i]["elements"][elements[j]]["label"] ? `${activeData["layout"][i]["elements"][elements[j]]["label"]} should be past date` : "date should be past date"
                                    isValid = false
                                }

                                if (!firstInvalid && activeData["layout"][i]["elements"][elements[j]]["limit"] === "future" && new Date(activeData["layout"][i]["elements"][elements[j]]["value"]) < new Date().setHours(0, 0, 0, 0)) {
                                    firstInvalid = activeData["layout"][i]["elements"][elements[j]]["label"] ? `${activeData["layout"][i]["elements"][elements[j]]["label"]} should be future date` : "date should be future date"
                                    isValid = false
                                }
                            }
                        }
                    }
                    if (!isValid && validation) {
                        validation = false;
                        validationErr = firstInvalid + ` in ${appData["stepsDispName"][i]}`
                    }
                }

                if (!activeData.hasOwnProperty("conditionTrue") || activeData["conditionTrue"] === true) { //choice and variation conditions
                    if (activeData["choiceData"] && activeData["choiceData"].length) {
                        let choiceLen = 0;
                        let count = 0
                        for (let c = 0; c < activeData["choiceData"].length; c++) {
                            if (activeData["choiceData"][c]["required"] && activeData["choiceData"][c]["variation"]) {
                                choiceLen = choiceLen + 1;
                                for (let v = 0; v < activeData["choiceData"][c]["variation"].length; v++) {
                                    if (activeData["choiceData"][c]["variation"][v] && activeData["choiceData"][c]["variation"][v]["value"]) {
                                        count = count + 1
                                    }
                                }
                            }
                        }
                        if (count < choiceLen && validation) {
                            validation = false;
                            validationErr = "checkbox must be checked to proceed" + ` in ${appData["stepsDispName"][i]}`
                        }
                    }

                    if (activeData["is_signature"] === 1 && !(activeData["signature"]) && ((!activeData["layout"] && !activeData["conditions"]) || (activeData["layout"] && typeof activeData["layout"] === "object" && activeData["conditions"] && typeof activeData["conditions"] === "object" && activeData["conditionTrue"] === true))) {
                        validation = false;
                        validationErr = "Signature cannot be blank" + ` in ${appData["stepsDispName"][i]}`
                    }
                }
            }

        } else {
            const validResult = stepperValidation(i, completedSteps, steps, appData, movingStep);
            const { isValid, validationError } = validResult;

            if (!isValid && validation) {
                validation = false;
                validationErr = validationError + ` in ${appData["stepsDispName"][i]}`
            }
        }
    }

    return { "isValid": validation, "validationError": validationErr }
}
