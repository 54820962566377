/* eslint-disable camelcase */
import { auditConstant } from "../constants";

// Set Initial State
const initialState = {
  filterToggle: false,
  search: {
    data: [],
    page: {
      limit: 10,
      sFields: [],
      sValues: [],
      count: 0,
      skip: 1,
      sortBy: 'full_name',
      sort: 'asc',
      fieldFilters: {
        headerCols: [],
        fieldCols: [],
        searchCols: []
      }
    }
  },
  log: {},
};

export default function (state = initialState, action) {
  switch (action.type) {

    //update view type and search
    case auditConstant.UPDATE_SEARCH_TYPE:
      return {
        ...state,
        search: { ...state.search, page: { ...state.search.page, sFields: [], sValues: [] } }
      };

    // AUDIT SEARCH
    case auditConstant.AUDIT_SEARCH_REQUEST:
      return {
        ...state
      };
    case auditConstant.AUDIT_SEARCH_SUCCESS:
      return {
        ...state,
        search: {
          data: action.data.data,
          page: { ...action.data.page },
        }
      };
    case auditConstant.AUDIT_SEARCH_FAILURE:
      return {
        ...state,
        search: initialState.search
      };

    //get unique logs
    case auditConstant.LOG_SEARCH_REQUEST:
      return {
        ...state
      };
    case auditConstant.LOG_SEARCH_SUCCESS:
      return {
        ...state,
        log: action.data.data
      };
    case auditConstant.LOG_SEARCH_FAILURE:
      return {
        ...state,
        log: initialState.log
      };

    default:
      return state;
  }
}
