/* eslint-disable camelcase */
import { directoryConstant } from "../constants";

// Set Initial State
const initialState = {
  search: {
    data: [],
    page: {
      limit: 10,
      sFields: [],
      sValues: [],
      count: 0,
      skip: 1,
      sortBy: '',
      sort: '',
      fieldFilters: {
        headerCols: [],
        fieldCols: [],
        searchCols: []
      }
    }
  },
  getAll: {
    data: [],
    page: {
      limit: 20,
      skip: 1,
      sort: "desc",
      search: ''
    }
  },
  pageViewType: "left",
  directory: {},
  directoryData: {
    data: [],
    page: {
      first_name: "",
      last_name: "",
      hologram: "",
      card_id: "",
      sort: "",
      sortField: "",
      count:0,
      skip:0,
      limit:25,
    }
  }
};

export default function (state = initialState, action) {
  switch (action.type) {

    //update view type and search
    case directoryConstant.UPDATE_VIEW_TYPE_AND_SEARCH:
      return {
        ...state,
        pageViewType: action.data,
        getAll: { ...state.getAll, page: { ...state.getAll.page, search: '' } },
        search: { ...state.search, page: { ...state.search.page, sFields: [], sValues: [] } }
      };

    // DIRECTORY SEARCH
    case directoryConstant.DIRECTORY_SEARCH_REQUEST:
      return {
        ...state
      };
    case directoryConstant.DIRECTORY_SEARCH_SUCCESS:
      return {
        ...state,
        search: {
          data: action.data.data,
          page: action.data.page
        }
      };
    case directoryConstant.DIRECTORY_SEARCH_FAILURE:
      return {
        ...state
      };

    // GET ALL DIRECTORY
    case directoryConstant.DIRECTORY_GETALL_REQUEST:
      return {
        ...state
      };
    case directoryConstant.DIRECTORY_GETALL_SUCCESS:

      const page = action.data.page;
      let stateData = [];

      if (page.skip > 1) {
        stateData = [...state.getAll.data, ...action.data.data]
      } else {
        stateData = [...action.data.data]
      }

      return {
        ...state,
        getAll: {
          data: stateData,
          page: action.data.page
        }
      };
    case directoryConstant.DIRECTORY_GETALL_FAILURE:
      return {
        ...state,
        getAll: initialState.getAll
      };

    //delete DIRECTORY
    case directoryConstant.DIRECTORY_DELETE_REQUEST:
      return {
        ...state
      };
    case directoryConstant.DIRECTORY_DELETE_SUCCESS:
      return {
        ...state,
      };
    case directoryConstant.DIRECTORY_DELETE_FAILURE:
      return {
        ...state,
      };

    //ADD DIRECTORY
    case directoryConstant.DIRECTORY_ADD_REQUEST:
      return {
        ...state
      };
    case directoryConstant.DIRECTORY_ADD_SUCCESS:
      return {
        ...state,
      };
    case directoryConstant.DIRECTORY_ADD_FAILURE:
      return {
        ...state,
      };

    //EDIT DIRECTORY
    case directoryConstant.DIRECTORY_EDIT_REQUEST:
      return {
        ...state,
      };
    case directoryConstant.DIRECTORY_EDIT_SUCCESS:
      return {
        ...state,
      };
    case directoryConstant.DIRECTORY_EDIT_FAILURE:
      return {
        ...state,
      };

    //get DIRECTORY
    case directoryConstant.DIRECTORY_GET_REQUEST:
      return {
        ...state,
        directory: {}
      };
    case directoryConstant.DIRECTORY_GET_SUCCESS:
      return {
        ...state,
        directory: action.data.data
      };
    case directoryConstant.DIRECTORY_GET_FAILURE:
      return {
        ...state,
        directory: {}
      };

    //get DIRECTORY
    case directoryConstant.DIRECTORY_FRAME_GET_REQUEST:
      return {
        ...state,
        // directoryData: initialState.directoryData
      };
    case directoryConstant.DIRECTORY_FRAME_GET_SUCCESS:
      return {
        ...state,
        directoryData: action.data.data
      };
    case directoryConstant.DIRECTORY_FRAME_GET_FAILURE:
      return {
        ...state,
        directoryData: initialState.directoryData
      };

    //update alignment reducer
    case directoryConstant.UPDATE_DIRECTORY_ALIGNMENT:
      return {
        ...state,
        pageViewType: action.data
      }

    case directoryConstant.UPDATE_FRAME_STATE:
      return {
        ...state,
        directoryData: { ...state.directoryData, page: action.data }
      }

    case directoryConstant.UPDATE_FRAME_DATA:
      return {
        ...state,
        directoryData: { ...state.directoryData, data: [] }
      }

      
    default:
      return state;
  }
}
