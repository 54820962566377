// DIRECTORY reducer types
export const directoryConstant = {

  // To Get All DIRECTORY Details
  DIRECTORY_GETALL_REQUEST: "DIRECTORY_GETALL_REQUEST",
  DIRECTORY_GETALL_SUCCESS: "DIRECTORY_GETALL_SUCCESS",
  DIRECTORY_GETALL_FAILURE: "DIRECTORY_GETALL_FAILURE",

  // To Search DIRECTORY Details
  DIRECTORY_SEARCH_REQUEST: "DIRECTORY_SEARCH_REQUEST",
  DIRECTORY_SEARCH_SUCCESS: "DIRECTORY_SEARCH_SUCCESS",
  DIRECTORY_SEARCH_FAILURE: "DIRECTORY_SEARCH_FAILURE",

  // DELETE DIRECTORY
  DIRECTORY_DELETE_REQUEST: "DIRECTORY_DELETE_REQUEST",
  DIRECTORY_DELETE_SUCCESS: "DIRECTORY_DELETE_SUCCESS",
  DIRECTORY_DELETE_FAILURE: "DIRECTORY_DELETE_FAILURE",

  //update Alignment reducer
  UPDATE_DIRECTORY_ALIGNMENT: "UPDATE_DIRECTORY_ALIGNMENT",

  //update view and search in chird view
  UPDATE_VIEW_TYPE_AND_SEARCH: 'UPDATE_VIEW_TYPE_AND_SEARCH',

  //ADD DIRECTORY
  DIRECTORY_ADD_REQUEST: "DIRECTORY_ADD_REQUEST",
  DIRECTORY_ADD_SUCCESS: "DIRECTORY_ADD_SUCCESS",
  DIRECTORY_ADD_FAILURE: "DIRECTORY_ADD_FAILURE",

  //EDIT DIRECTORY
  DIRECTORY_EDIT_REQUEST: "DIRECTORY_EDIT_REQUEST",
  DIRECTORY_EDIT_SUCCESS: "DIRECTORY_EDIT_SUCCESS",
  DIRECTORY_EDIT_FAILURE: "DIRECTORY_EDIT_FAILURE",

  //GET DIRECTORY
  DIRECTORY_GET_REQUEST: "DIRECTORY_GET_REQUEST",
  DIRECTORY_GET_SUCCESS: "DIRECTORY_GET_SUCCESS",
  DIRECTORY_GET_FAILURE: "DIRECTORY_GET_FAILURE",

  //GET DIRECTORY FRAMES
  DIRECTORY_FRAME_GET_REQUEST: "DIRECTORY_FRAME_GET_REQUEST",
  DIRECTORY_FRAME_GET_SUCCESS: "DIRECTORY_FRAME_GET_SUCCESS",
  DIRECTORY_FRAME_GET_FAILURE: "DIRECTORY_FRAME_GET_FAILURE",

  UPDATE_FRAME_STATE:"UPDATE_FRAME_STATE",
  UPDATE_FRAME_DATA:"UPDATE_FRAME_DATA"

}