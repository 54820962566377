/* eslint-disable camelcase */
import { submissionConstant } from "../constants";

// Set Initial State
const initialState = {
    search: {
        data: [],
        page: {
            limit: 10,
            sFields: [],
            sValues: [],
            count: 0,
            skip: 1,
            sortBy: 'id',
            sort: 'desc',
            fieldFilters: {
                headerCols: [],
                fieldCols: [],
                searchCols: []
            }
        }
    },
    attachmentLen:{},
    getRetrySubmission: "",
    getApplicantName: '',
    getSubmisionId: '',
    retryError: { error: "" }
};

export default function (state = initialState, action) {
    switch (action.type) {
        // SEARCH Submission
        case submissionConstant.SUBMISSION_SEARCH_REQUEST:
            return {
                ...state
            };
        case submissionConstant.SUBMISSION_SEARCH_SUCCESS:
            return {
                ...state,
                search: {
                    data: action.data.data,
                    page: action.data.page
                }
            };
        case submissionConstant.SUBMISSION_SEARCH_FAILURE:
            return {
                ...state
            };

        //get unique logs
        case submissionConstant.ATTACHMENT_SEARCH_REQUEST:
            return {
                ...state,
                attachmentLen: initialState.attachmentLen
            };
        case submissionConstant.ATTACHMENT_SEARCH_SUCCESS:
            return {
                ...state,
                attachmentLen: action.data.data
            };
        case submissionConstant.ATTACHMENT_SEARCH_FAILURE:
            return {
                ...state,
                attachmentLen: initialState.attachmentLen
            };

        //delete Submission
        case submissionConstant.SUBMISSION_DELETE_REQUEST:
            return {
                ...state
            };
        case submissionConstant.SUBMISSION_DELETE_SUCCESS:
            return {
                ...state,
            };
        case submissionConstant.SUBMISSION_DELETE_FAILURE:
            return {
                ...state,
            };

        //Retry Submission
        case submissionConstant.SUBMISSION_REFUND_PAYMENT_REQUEST:
            return {
                ...state
            };
        case submissionConstant.REFUND_PAYMENT_UPDATED_SUCCESS:
            return {
                ...state,
            };
        case submissionConstant.SUBMISSION_REFUND_PAYMENT_FAILURE:
            return {
                ...state,
            };

        //SUBMISSION_ATTACHMENT DOWNLOAD
        case submissionConstant.SUBMISSION_ATTACHMENT_DOWNLOAD_REQUEST:
            return {
                ...state
            };
        case submissionConstant.SUBMISSION_ATTACHMENT_DOWNLOAD_SUCCESS:
            return {
                ...state,
            };
        case submissionConstant.SUBMISSION_ATTACHMENT_DOWNLOAD_FAILURE:
            return {
                ...state,
            };

        //SUBMISSION GET DETAILS
        case submissionConstant.GET_RETRY_SUBMISSION_REQUEST:
            return {
                ...state,
                getRetrySubmission: "",
                applicantName: '',
                submisionId: '',
                retryError: initialState.retryError
            };
        case submissionConstant.GET_RETRY_SUBMISSION_SUCCESS:
            return {
                ...state,
                getRetrySubmission: action.data.data.xml,
                getApplicantName: action.data.data.applicant,
                getSubmisionId: action.data.data.id,
            };
        case submissionConstant.GET_RETRY_SUBMISSION_FAILURE:
            return {
                ...state,
            };

        //Retry Submission
        case submissionConstant.RETRY_SUBMISSION_REQUEST:
            return {
                ...state,
                retryError: initialState.retryError
            };
        case submissionConstant.RETRY_SUBMISSION_SUCCESS:
            console.log(action)
            return {
                ...state,
                retryError: action.data.data
            };
        case submissionConstant.RETRY_SUBMISSION_FAILURE:
            return {
                ...state,
                retryError: initialState.retryError
            };


        default:
            return state;
    }
}