/**
 * Get Token
 */
export const getToken = () => {
  const token = localStorage.getItem("key");
  if (token) {
    return token;
  }
  return null;
};

export const getLoggedUserData = () => {
  const id = localStorage.getItem("id");
  const role = localStorage.getItem("role");
  if (id && role) {
    return { id, role };
  }
  return { id: null, role: null };
}

/**
 * Set Token
 * @param data
 */
export const setToken = (data) => {
  localStorage.setItem("key", data);
};

/**
 * Remove Token
 */
export const removeToken = () => {
  localStorage.removeItem("key");
};

export const addEllipsis = (input, charCount = 10) => {
  if (input.length > charCount) {
    return input.substring(0, charCount) + "...";
  }
  return input;
};

export const capitaliseFirst = (input) => {
  if (input) {
    return input.charAt(0).toUpperCase() + input.slice(1);
  } else {
    return input;
  }
}

export const isEmail = (input) => {
  if (input) {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const result = regex.test(String(input).toLowerCase());
    return result ? true : false
  } else {
    return false
  }
}

//generate logo from name
export const nameLogoGeneration = (name) => {
  try {
    let charString = name.replace(/ *\([^)]*\) */g, "");
    charString = charString.replace(/ *\"[^)]*\" */g, "");
    charString = charString.replace(/ *\'[^)]*\' */g, "");
    charString = charString.replace(/[0-9]/g, '');
    charString = charString.replace(/  +/g, ' ');
    charString = charString.replace(/[^a-zA-Z ]/g, "")
    let charArr = charString.split(' ')

    let returnStr = ``
    if (charArr.length > 0) {
      if (charArr[0] === "") {
        if (charArr[1] && charArr[1].charAt(0)) {
          returnStr = `${charArr[1].charAt(0)}`
        }
        if (charArr[2] && charArr[2].charAt(0)) {
          if (charArr[2].charAt(0) === '-' && charArr[3] && charArr[3].charAt(0) && charArr[3].charAt(0) !== '-') {
            returnStr = `${returnStr}${charArr[3].charAt(0)}`
          } else {
            returnStr = `${returnStr}${charArr[2].charAt(0)}`
          }
        }
      } else {
        if (charArr[0] && charArr[0].charAt(0)) {
          returnStr = `${charArr[0].charAt(0)}`
        }
        if (charArr[1] && charArr[1].charAt(0)) {
          if (charArr[1].charAt(0) === '-' && charArr[2] && charArr[2].charAt(0) && charArr[2].charAt(0) !== '-') {
            returnStr = `${returnStr}${charArr[2].charAt(0)}`
          } else {
            returnStr = `${returnStr}${charArr[1].charAt(0)}`
          }
        }
      }
      return returnStr
    } else {
      return ''
    }
  } catch (err) {
    console.log(err)
    return ''
  }
}

export const isEmptyHTML = (html) => {
  try {
    if (!html || !(html.replace(/(<([^>]+)>)/ig, ""))) {
      return true
    } else {
      return false
    }
  } catch (err) {
    return true
  }
}

// Email Validation
export const IsEmail = function (email) {
  if (email) {
    const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
    return regex.test(email)
  } else {
    return false
  }
}

export const trimTrue = (str) => {
  if (str) {
    let regX = /^\s+$/
    if (regX.test(str)) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

export const richTextTester = (str) => {
  if (str) {
    let result = str.replace(/(<([^>]+)>)/ig, "");
    result = result.replace(/&nbsp;/ig, " ");
    let regX = /^\s+$/
    if (regX.test(result)) {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}

export const dataPopulator = (settings = {}, string = "") => {
  try {
    if (string) {
      if (string.indexOf("{") > -1 && string.indexOf("}") > -1 && settings["keyData"] && settings["valueData"]) {

        for (let key in settings["keyData"]) {
          if (key && string.indexOf(`{${key}}`) > -1 && settings["valueData"].hasOwnProperty(settings["keyData"][key])) {
            if (key === "applicant") {
              string = string.replace(new RegExp(`{${key}}`, 'g'), `${settings["valueData"][settings["keyData"]["first_name"]]} ${settings["valueData"][settings["keyData"]["middle_name"]]} ${settings["valueData"][settings["keyData"]["last_name"]]} ${settings["valueData"][settings["keyData"]["generation"]]}`)
            } else if (key === "applicantAddress") {
              string = string.replace(new RegExp(`{${key}}`, 'g'), `${settings["valueData"][settings["keyData"]["address"]]} ${settings["valueData"][settings["keyData"]["city"]]} ${settings["valueData"][settings["keyData"]["state"]]} ${settings["valueData"][settings["keyData"]["zip"]]}`)
            } else if (key === "property") {
              string = string.replace(new RegExp(`{${key}}`, 'g'), `${settings["valueData"][settings["keyData"]["property_name"]]} ${settings["valueData"][settings["keyData"]["unit"]]}`)
            } else {
              string = string.replace(new RegExp(`{${key}}`, 'g'), settings["valueData"][settings["keyData"][key]])
            }
          }
        }
        return string;
      } else {
        return string
      }
    } else {
      return string
    }
  } catch (err) {
    return string
  }
}

export const layoutValidation = (str) => {
  try {
    if (str && str.trim()) {
      try {
        str = str
      } catch (err) {
        console.log(err)
        return false
      }
    } else {
      return true
    }
  } catch (err) {
    console.log(err)
    return false;
  }
}

export const isSafari = () => {
  try {
    return ((/constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification))) || (navigator && navigator.userAgent.match(/(iPod|iPhone|iPad)/) && !navigator.userAgent.match(/CriOS/i)))
  } catch (err) {
    return false;
  }
}