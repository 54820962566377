import * as apihelper from "../../utils/apiHelper";

/**
 * Export All Authentication Service Functions
 */
export const serviceService = {
    search,
    getAll,
    remove,
    getApplicationCount,
    userServiceSearch,
    getTemplateList,
    updateService,
    createService,
    updateFee,
    downloadExcel,
    uploadExcel,
    addDocumentsFromSearch,
    addAgreementFromScratch,
    addTemplateFromScratch
};

/**
 * Call service Get All Search Service
 */
async function search(skip, limit, sortBy, sort, sFields, sValues) {
    const response = await apihelper.postRequest(`service/search?skip=${skip}&limit=${limit}&sortBy=${sortBy}&sort=${sort}`, { sFields, sValues, filter: false });
    return response;
}

/**
 * Get All service for chord view
 */
async function getAll(data) {
    const response = await apihelper.postRequest(`service/getAll`, data);
    return response;
}

/**
* Delete Service
* @param id
*/
async function remove(data) {
    const response = await apihelper.deleteRequest("service", data);
    return response;
}

/**
 * Get All service for chord view
 */
async function getApplicationCount(data) {
    const response = await apihelper.postRequest(`service/getApplicationCount`, data);
    return response;
}

/**
 * Call service Get All Search Service
 */
async function userServiceSearch(skip, limit, sortBy, sort, sFields, sValues, client_id) {
    const response = await apihelper.postRequest(`service/search?skip=${skip}&limit=${limit}&sortBy=${sortBy}&sort=${sort}`, { sFields, sValues, client_id, filter: true });
    return response;
}

/**
 * Get Template List
 */
async function getTemplateList(data) {
    const response = await apihelper.postRequest(`service/getTemplateList`, data);
    return response;
}

/**
 * create service
 */
async function createService(data) {
    const response = await apihelper.postRequest("service/createService", data);
    return response;
}

/**
 * update service
 */
async function updateService(data) {
    const response = await apihelper.postRequest("service/updateService", data);
    return response;
}

/**
 * update fee service
 */
async function updateFee(data) {
    const response = await apihelper.postRequest("service/updateServiceFee", data);
    return response;
}

/**
 * download Excel service
 */
async function downloadExcel(data) {
    const response = await apihelper.postRequest("excel/downloadExcel", data);
    if (response.data && response.data.filePath) {
        const responseOne = await apihelper.postRequestExcel('excel/exportExcel', response.data, false, "arraybuffer");
        return responseOne;
    } else {
        return response;
    }
}

/* upload Excel
 */
async function uploadExcel(file) {
    const response = await apihelper.postRequest('excel/uploadExcel', file);
    return response;
}

/* create documents from scratch
 */
async function addDocumentsFromSearch(data) {
    const response = await apihelper.postRequest('service/createDocumentFromScratch', data);
    return response;
}

/* create documents from scratch
 */
async function addAgreementFromScratch(data) {
    const response = await apihelper.postRequest('service/addAgreementFromScratch', data);
    return response;
}

/* create template from scratch
 */
async function addTemplateFromScratch(data) {
    const response = await apihelper.postRequest('service/addTemplateFromScratch', data);
    return response;
}