import { baseAction } from "./baseAction";

const { success } = baseAction;

// Export All Base Action Functions
export const updateReducerAction = {
  update
};

/**
 * Update Reducer Data
 * @param type
 * @param data
 */
function update (type, data, successCallback = ()=>{}) {
  return (dispatch) => {
    dispatch(success(type, data));
    successCallback()
  }
};