import { alertConstant, auditConstant, loaderConstant } from "../constants";
import { auditService } from "../services";
import { baseAction } from "./baseAction";

const { request, success, failure } = baseAction;

// Export All Auth Actions Functions
export const auditAction = {
  search,
  getValues
};

/**
 * Get All  AUDIT
 */
function search(skip, limit, sortBy, sort, sFields, sValues, currentUID, currentPage) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(auditConstant.AUDIT_SEARCH_REQUEST));
    auditService.search(skip, limit, sortBy, sort, sFields, sValues, currentUID, currentPage).then(
      (data) => {
        dispatch(success(auditConstant.AUDIT_SEARCH_SUCCESS, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(auditConstant.AUDIT_SEARCH_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Get old and new Values
 */
 function getValues(params,cb = ()=>{}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(auditConstant.LOG_SEARCH_REQUEST));
    auditService.getValues(params).then(
      (data) => {
        dispatch(success(auditConstant.LOG_SEARCH_SUCCESS, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        cb(data)
      },
      (error) => {
        dispatch(failure(auditConstant.LOG_SEARCH_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}
