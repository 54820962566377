import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid'; // Grid component from MUI v5
import { withStyles } from "@mui/styles"; // For CSS-in-JS styling with JSS in MUI v5
import Tooltip from '@mui/material/Tooltip'; // Tooltip component from MUI v5
import Slide from '@mui/material/Slide'; // Slide component from MUI v5
import IconButton from '@mui/material/IconButton'; // IconButton component from MUI v5
import Menu from '@mui/material/Menu'; // Menu component from MUI v5
import MenuItem from '@mui/material/MenuItem'; // MenuItem component from MUI v5
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'; // KeyboardArrowUpIcon from MUI v5

//Import Styles and images
import TemplatesStyles from "../TemplatesStyles"
import { SortIcon, MoreIcon } from '../../../assets/svg'
import EmailDefaultImg from '../../../assets/images/Email_Default.png'

// Import Config Layout
import { getTemplatesHeight, emailCardLimit } from "../../../config/layout";

// Import Components
import { Typography, AlertDialog, Button, SearchBar, LazyLoader } from "../../../components";
import AddEditEmail from './AddEditEmail';
import NoRecordsFound from "../../Users/Components/NoRecordsFound";

// Import Actions
import { templateAction } from "../../../redux/actions";


const EmailTemplates = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    // Set Card Limit and Size
    const cardLimit = emailCardLimit();
    const cardSize = Math.floor(12 / (cardLimit / 5))

    //set ref for scroll
    const scrollEmail = useRef(null)

    //get height
    const height = getTemplatesHeight();

    //get props
    const { classes } = props;

    //get reducer data
    const { getAllEmail: { data, page } } = useSelector(state => (state.template))
    let { lazyLoading, loading } = useSelector((state) => state.UI);

    //get all templates
    useEffect(() => {
        dispatch(templateAction.getAllEmail({ limit: cardLimit, skip: 0, search: page.search, sort: page.sort }))
    }, [dispatch])

    //state 
    const [anchorEl, setAnchorEl] = useState(null);
    const [templateID, setTemplateID] = useState(null);
    const [deleteAlert, setDeleteAlert] = useState(null);
    const [showScrollTopBTN, setShowScrollTopBTN] = useState(false)

    //handle open/close more items
    const handleClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setTemplateID(id)
    };
    const handleClose = () => {
        setAnchorEl(null);
        setTemplateID(null)
    }

    //scroll event
    const getScrollData = () => {
        const isBottom = parseInt(scrollEmail.current.scrollHeight - scrollEmail.current.scrollTop) === (scrollEmail.current.clientHeight) || parseInt(scrollEmail.current.scrollHeight - scrollEmail.current.scrollTop) === (scrollEmail.current.clientHeight - 1) || parseInt(scrollEmail.current.scrollHeight - scrollEmail.current.scrollTop) === (scrollEmail.current.clientHeight + 1);
        if (!lazyLoading && isBottom && data.length < page.count) {
            //get next data
            dispatch(templateAction.getAllEmail({ limit: page.limit, skip: page.skip + page.limit, search: page.search, sort: page.sort, loader: true }))
        }

        if (scrollEmail.current.scrollTop > 150 && showScrollTopBTN === false) {
            setShowScrollTopBTN(true)
        }
        else if (scrollEmail.current.scrollTop < 150 && showScrollTopBTN === true) {
            setShowScrollTopBTN(false)
        }
    }

    //get sort data
    const getSortedData = (sorting) => {
        dispatch(templateAction.getAllEmail({ limit: cardLimit, skip: 0, search: page.search, sort: sorting }))
    }

    //edit template
    const editEmailTemplate = () => {
        if (templateID) {
            dispatch(templateAction.getOneEmail({ id: templateID }, () => {
                setAnchorEl(null);
            }))
        }
    }

    //delete template
    const deleteEmailTemplate = () => {
        if (templateID) {
            dispatch(templateAction.deleteEmail({ id: templateID }, () => {
                dispatch(templateAction.getAllEmail({ limit: cardLimit, skip: 0, search: page.search, sort: page.sort }))
                handleClose()
                setDeleteAlert(null)
            }))
        }
    }

    //get search data
    const intiSearch = (value) => {
        dispatch(templateAction.getAllEmail({ limit: cardLimit, skip: 0, search: value, sort: page.sort }))
    }

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between" className={classes.serviceActionBtn} alignItems="center">
                    <Grid item>
                        <Typography variant="h6" color="TextSecondary" component="h3"> {`Count ${page && page.count >= 0 ? `(${page.count})` : ""}`} </Typography>
                    </Grid>
                    <Grid>
                        <Grid container alignItems="center">
                            <SearchBar className={"mr-1"} loading={loading} value={page.search} intiSearch={(v) => { intiSearch(v) }} />
                            <Grid className={"mr-1"}>
                                {page && page.sort && page.sort === "asc" ?
                                    <Tooltip arrow title="Sort By Created Date"><IconButton
                                        className={classes.searchIcon}
                                        onClick={() => { getSortedData('desc') }}
                                        size="large"><SortIcon /></IconButton></Tooltip>
                                    :
                                    <Tooltip arrow title="Sort By Created Date"><IconButton
                                        className={classes.searchIcon}
                                        onClick={() => { getSortedData('asc') }}
                                        size="large"><SortIcon /></IconButton></Tooltip>
                                }
                            </Grid>
                            <AddEditEmail />
                        </Grid>
                    </Grid>
                </Grid>
                {data && data.length > 0 ? <Grid
                    container
                    spacing={0}
                    className={classes.containerScroll}
                    style={{ height: height, paddingRight: 12 }}
                    alignContent="baseline"
                    ref={scrollEmail}
                    onScroll={() => { getScrollData() }}
                >
                    <Grid container spacing={2}>
                        {data && data.map((itm, indx) => (
                            <Grid className="animate__animated animate__fadeInUp animate__delay-0.8s" item sm={cardSize} >
                                <Grid className={classes.EmailTile}>
                                    <Grid container justifyContent="flex-end" className={"pr-1 pt-1"}>
                                        <IconButton
                                            style={{ padding: 0 }}
                                            onClick={(e) => { handleClick(e, itm.id) }}
                                            size="large"><MoreIcon /></IconButton>
                                    </Grid>
                                    <Grid container className={classes.tileTop} alignItems="center">
                                        <img src={EmailDefaultImg} className={classes.emailTemplateImg} alt="imge" />
                                    </Grid>
                                    <Grid container className={classes.tileBottom} >
                                        <Grid item xs={12}><Typography variant="h6" align="left" className="mb-1 transCapital EmailCardTitle twoLineEllip maxHeight2_Lineh6">{itm.name ? itm.name : ""}</Typography></Grid>
                                        <Grid item xs={12}><Typography variant="body1" color="textSecondary" align="left" className={"EmailCardsTxtWidth threeLineEllip maxHeight3_Line_body1"}>{itm.content ? itm.content : ''}</Typography></Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        ))}
                        <LazyLoader />
                    </Grid>
                    <Menu
                        id="templste-email"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={() => { handleClose() }}
                    >
                        <MenuItem onClick={() => { editEmailTemplate() }}>Edit</MenuItem>
                        {templateID && ["32", "33"].indexOf(templateID.toString()) === -1 && <MenuItem onClick={() => { setDeleteAlert({ type: true, dataa: data.filter(i => (i.id === templateID)) }); }}>Delete</MenuItem>}
                    </Menu>
                </Grid>
                    : !loading ? <NoRecordsFound /> : null}
                {showScrollTopBTN && <IconButton
                    onClick={() => { scrollEmail.current.scrollTo(0, 0) }}
                    className={"toTop"}
                    size="large"><KeyboardArrowUpIcon /></IconButton>}
            </Grid>
            {deleteAlert &&
                <AlertDialog
                    fullScreen={false}
                    open={true}
                >
                    <Grid className={'p-4'}>
                        <Typography variant="h6">{deleteAlert.dataa && deleteAlert.dataa[0] && deleteAlert.dataa[0]['isAvail'] ? "The email template is mapped to a template, are you sure you want to delete the email template" : `Are you sure you want to delete`}</Typography>
                        <Grid className={'pt-2'} align={"center"}>
                            <Button
                                color={"primary"}
                                className={"mr-1"}
                                onClick={() => { deleteEmailTemplate() }}
                                title={"Continue"}
                            />
                            <Button
                                color={"secondary"}
                                onClick={() => { handleClose(); setDeleteAlert(null) }}
                                title={"Cancel"}
                            />
                        </Grid>
                    </Grid>
                </AlertDialog>
            }
        </React.Fragment>
    );
}

// default props
EmailTemplates.defaultProps = {
    classes: {}
};

// prop types
EmailTemplates.propTypes = {
    classes: PropTypes.object
};

export default withStyles(TemplatesStyles)(EmailTemplates);
