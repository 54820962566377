import * as apihelper from "../../utils/apiHelper";

/**
 * Export All Authentication Service Functions
 */
export const userService = {
  getUser,
  getAll,
  remove,
  search,
  getCompanies,
  createUser,
  updateUser,
  getCompaniesAndRole,
  loginUser,
  updatePreference,
  getRegion,
  getManagerAppMap
};

/**
 * Get User By Id
 */
async function updatePreference(params) {
  const response = await apihelper.postRequest(`users/updatePreference`,params);
  return response;
}

/**
 * Get User By Id
 */
async function getUser(params) {
  const response = await apihelper.postRequest(`users/getUser`, params);
  return response;
}

/**
 * Get All users for chord view
 */
async function getAll(data) {
  const response = await apihelper.postRequest(`users/getAll`, data);
  return response;
}

/**
 * Delete User Service
 * @param id
 */
async function remove(data) {
  const response = await apihelper.deleteRequest("users", data);
  return response;
}

/**
 * Call User Get All Search Service
 */
async function search(skip, limit, sortBy, sort, sFields, sValues) {
  const response = await apihelper.postRequest(`users/search?skip=${skip}&limit=${limit}&sortBy=${sortBy}&sort=${sort}`, { sFields, sValues });
  return response;
}

/**
 * Get client companies
 */
async function getCompanies() {
  const response = await apihelper.postRequest("users/getCompanies");
  return response;
}

/**
 * Get client companies
 */
async function getCompaniesAndRole(data) {
  const response = await apihelper.postRequest("users/getCompaniesAndRole",data);
  return response;
}

/**
 * create user
 */
async function createUser(data) {
  const response = await apihelper.postRequest("users/createUser", data);
  return response;
}

/**
 * update user
 */
async function updateUser(data) {
  const response = await apihelper.postRequest("users/updateUser", data);
  return response;
}

/**
 * login user
 * @param {*} data 
 */
async function loginUser(data) {
  const response = await apihelper.postRequest("users/loginUser", data);
  return response;
}

/**
 * get all regions
 */
async function getRegion(data) {
  const response = await apihelper.postRequest("users/getRegion", data);
  return response;
}

/**
 * getManagerAppMap
 */
 async function getManagerAppMap(data) {
  const response = await apihelper.postRequest("users/mangerMap", data);
  return response;
}