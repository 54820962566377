const styles = (theme) => ({

    root: {
        borderColor: '#cecece',
        borderRadius: '30px',
        backgroundColor: '#fff',
        border: '1px solid',
        padding: '0 10px',
        transition: 'all 0.8s ease',
        // width: 200
    }

})
export default styles