const styles = (theme) => ({

    option: {
        // Selected
        '&[aria-selected="true"]': {
          backgroundColor: `#f1f1f1 !important`
        },
    }


})

export default styles