/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

import Directories from "./Directories";
import AddEditDirectory from "./AddEdit/AddEditDirectory";

// Import History
import history from "../../config/history";

export default function DirectoriesRouter(props) {
  const { match, location } = props;
  return (
    <Switch>
      <Route exact path={`${match.url}`}
        render={(props) => {
          const isReset = location && location.search === "?reset=true" ? true : false;
          if (isReset) {
            history.push({ pathname: `/directory` });
            return;
          } else {
            return (
              <Directories
                match={match}
                {...props}
              />
            );
          }
        }} />
      <Route exact path={`${match.url}/add`} render={props => {
        return (
          <AddEditDirectory
            match={match}
            {...props}
          />
        );
      }} />
      <Route exact path={`${match.url}/edit/:id`} render={props => {
        return (
          <AddEditDirectory
            match={match}
            {...props}
          />
        );
      }} />
    </Switch>
  );
};

//default props
DirectoriesRouter.defaultProps = {
  match: {},
  classes: {}
};

/**
 * Bind Component Property Types
 */
DirectoriesRouter.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object
};
