import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

import Application from "./Application";
import AddEditApplication from './AddEditApplication/AddEditApplication'

function ApplicationRouter(props) {
  const { match } = props;
  return (
    <Switch>
      <Route
        exact
        path={`${match.url}`}
        render={props => {
          return (
            <Application
              match={match}
              {...props}
            />
          );
        }} 
      />
    </Switch>
  );
};

//default props
ApplicationRouter.defaultProps = {
  match: {},
  classes: {}
};

/**
 * Bind Component Property Types
 */
ApplicationRouter.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object
};

/**
 * Export Component
 */
export default Application;
