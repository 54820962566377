import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import classsNames from 'classnames';
import PropTypes from "prop-types";
import classNames from "classnames"
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//Styles
import SettingsStyles from "../SettingsStyles"

// Import History
import history from "../../../config/history";

// Import Components
import { Button, TextField, Typography, CheckBox, Link, RichTextEdit, Info } from "../../../components";

// Import Cofig Constants
import { settings_page_constants, templates_page_constants, user_page_constants } from "../../../config/constants";

// Import Actions
import { settingsAction, updateReducerAction } from "../../../redux/actions";

const ApplicationTab = (props) => {
    const dispatch = useDispatch()
    const { classes, handleTempRichTextChange, handleTempPayTextChange, application, updateButton, handleFlagChange } = props;

    const [errors, setErrors] = useState({});
    const [disable, setDisable] = useState(false);
    const [disable1, setDisable1] = useState(false);
    const [disable2, setDisable2] = useState(false);
    const [discCheck, setDiscCheck] = useState("1")
    const [expanded, setExpanded] = useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    /**
  *Handle Cancel Navigage to Tab based on Roles
  */
    const handleCancel = () => {
        history.go(-1)
    };

    const handleSubmit = (key) => {
        let errors = {}
        if (!application[key]) {
            errors[key] = true
        } else {
            let str = application[key]
            str = str.replace(/(<([^>]+)>)/ig, "")
            str = str.trim()
            if (!str) {
                errors[key] = true
            }
        }
        setErrors(errors)
        if (Object.keys(errors).length === 0) {
            dispatch(settingsAction.saveApplication({ key, value: application[key], discCheck }, () => {
                updateButton[key] = false;
                if (key === "payment") {
                    setDisable1(false)
                } else if (key === "disclaimer") {
                    setDisable2(false)
                    setDiscCheck("1")
                } else {
                    setDisable(false)
                }
            }));
        }
    };

    const handleTextChange = (v, key) => {
        updateButton[key] = true
        if (key === "disclaimer") {
            setDisable2(true)
        } else {
            setDisable(true)
        }
        handleTempRichTextChange(v, key)
    }

    const handlePayTextChange = (v, key) => {
        updateButton[key] = true;
        setDisable1(true)
        handleTempPayTextChange(v, key)
    }

    return (
        <React.Fragment>
            <Grid container className={`${"pt-2"} ${classsNames(classes.appTemplateDialog, classes.tempContainer)}`}>
                <Grid container className={classes.accordionContainer}>
                    <Grid item xs={12}>
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={"expandIcon"} />}
                                aria-label="Expand"
                                aria-controls="additional-actions1-content"
                                id="additional-actions1-header"
                            >
                                <Typography variant="h6">{settings_page_constants.INTRODUCTION}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <Grid item xs={12} className={"bgGray"}>
                                        <Grid className={errors["introduction"] ? classes.error : ""}>
                                            <RichTextEdit
                                                value={application.introduction ? application.introduction : ""}
                                                onChange={(v) => { handleTextChange(v, "introduction") }}
                                            />
                                        </Grid>
                                        {errors["introduction"] &&
                                            <span style={{ color: 'red' }}>{"Introduction cannot be blank"}</span>
                                        }
                                    </Grid>
                                    <Grid item xs={12} className="mt-4">
                                        <Button color="primary" disabled={!disable} size="small" title={"UPDATE"} className="mr-1" onClick={() => handleSubmit("introduction")}></Button>
                                        <Button color="secondary" size="small" title={user_page_constants.CANCEL} onClick={handleCancel}></Button>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={"expandIcon"} />}
                                aria-label="Expand"
                                aria-controls="additional-actions2-content"
                                id="additional-actions2-header"
                            >
                                <Typography variant="h6" title={settings_page_constants.DISCLAIMER}>
                                    <Info color={expanded === 'panel2' ? "#fff" : ""}>
                                        <p dangerouslySetInnerHTML={{ __html: `${templates_page_constants.DISCLAIMER_TEXT}` }} className={`${classes.borderline} pb-1`}></p>
                                    </Info>
                                </Typography>

                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <Grid item xs={12} className={"bgGray"}>
                                        <Grid className={errors["disclaimer"] ? classes.error : ""}>
                                            <RichTextEdit
                                                value={application.disclaimer ? application.disclaimer : ""}
                                                onChange={(v) => { handleTextChange(v, "disclaimer") }}
                                            />
                                        </Grid>
                                        {errors["disclaimer"] &&
                                            <span style={{ color: 'red' }}>{"Disclaimer cannot be blank"}</span>
                                        }
                                    </Grid>
                                    <Grid item xs={12} className="mt-2">
                                        <FormControlLabel disabled={!disable2 } control={<Checkbox checked={discCheck === "1"} onChange={(e) => { setDiscCheck(e.target.checked ? "1" : "") }} />} label="Update only upcoming applications" />
                                    </Grid>
                                    <Grid item xs={12} className="flex">
                                        <FormControlLabel disabled={!disable2} control={<Checkbox checked={discCheck === "2"} onChange={(e) => { setDiscCheck(e.target.checked ? "2" : "") }} />} label="Update upcoming & existing applications" />
                                    </Grid>
                                    <Grid item xs={12} className="mt-2">
                                        <Button color="primary" disabled={!disable2 || !discCheck} size="small" title={"UPDATE"} className="mr-1" onClick={() => handleSubmit("disclaimer")}></Button>
                                        <Button color="secondary" size="small" title={user_page_constants.CANCEL} onClick={handleCancel}></Button>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={"expandIcon"} />}
                                aria-label="Expand"
                                aria-controls="additional-actions2-content"
                                id="additional-actions2-header"
                            >
                                <Typography variant="h6" title={settings_page_constants.PAYMENT_DESCRIPTION}></Typography>

                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <Grid item xs={12} className={"bgGray"}>
                                        <Grid className={errors["payment"] ? classes.error : ""}>
                                            <TextareaAutosize
                                                className={"w-100"}
                                                minRows={8}
                                                value={application.payment ? application.payment : ""}
                                                onChange={(v) => { handlePayTextChange(v.target.value, "payment") }}
                                            />
                                        </Grid>
                                        {errors["payment"] &&
                                            <span style={{ color: 'red' }}>{"Content cannot be blank"}</span>
                                        }
                                    </Grid>
                                    <Grid item xs={12} className="mt-4">
                                        <Button color="primary" disabled={!disable1} size="small" title={"UPDATE"} className="mr-1" onClick={() => handleSubmit("payment")}></Button>
                                        <Button color="secondary" size="small" title={user_page_constants.CANCEL} onClick={handleCancel}></Button>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
// default props
ApplicationTab.defaultProps = {
    classes: {},
};

// prop types
ApplicationTab.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(SettingsStyles)(ApplicationTab);
