import React from "react";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid';
import { withStyles } from "@mui/styles";

// Layout Style
import style from "./style";

function PageContainerComponent(props) {

  // Get Props
  const { children, classes, height, spacing, paddingRight } = props;
  return (
    <Grid
      container
      spacing={spacing}
      className={`${classes.root}`}
      style={{ height: height, paddingRight: paddingRight }}
      alignContent="baseline">
      {children}
    </Grid>
  );
}

// Default props
PageContainerComponent.defaultProps = {
  classes: {},
  children: {},
  spacing: 0,
  height: 0,
  paddingRight: 0
};

// Define Props Types
PageContainerComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  spacing: PropTypes.number,
  paddingRight: PropTypes.number,
  height: PropTypes.number.isRequired
};

export const PageContainer = withStyles(style)(PageContainerComponent);
