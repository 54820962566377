const palette = {
	primary: {
		dary: '#12A8DE !important',
		main: '#1A385E',
		light: '#e60000 !important',
		contrastText: "#fff"
	},
	secondary: {
		dary: '#12A8DE',
		main: '#12A8DE',
		light: '#1d2c2f',
		contrastText: "#fff"
	},
	greyshades: {
		main: '#110308',
		dark: '#878083',
		light1: '#C3BFC1',
		light2: '#E6E5E5',
		light3: '#F7F7F7',
		light4: '#FFFFFF',
	},
	spacing :{
		padding: 0.5
	}
}

export default palette;