import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

//import components
import Audit from "./Audit";

// Import History
import history from "../../config/history";

export default function AuditRouter(props) {
  // get props
  const { match, location } = props;

  return (
    <Switch>
      <Route
        exact
        path={match.url}
        render={(props) => {
          const isReset = location && location.search === "?reset=true" ? true : false;
          if (isReset) {
            history.push({ pathname: `/audit` });
            return;
          } else {
            return <Audit match={match} {...props} />;
          }
        }}
      />
    </Switch>
  );
}

//default props
AuditRouter.defaultProps = {
  match: {},
  classes: {},
};

//define prop types
AuditRouter.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object,
};