import React from "react";
import PropTypes from "prop-types";
import { Grid, TextField } from "@mui/material";
import {withStyles} from "@mui/styles"
import Autocomplete from "@mui/material/Autocomplete";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import styles from './style'

/**
 * Autocomplete Component
 */
function AutoCompleteComponent(props) {
  /**
   * render html
   */
  const {
    suggestions,
    name,
    disabled,
    value,
    extraProps,
    classes,
    hasValue,
    showValidate,
    validationMsg,
    ...rest
  } = props;

  return (
    <Grid className={classes.autoComplete}>
      <Autocomplete
        classes={
          {
            option: classes.option,
            paper: classes.customPaper
          }
        }
        blurOnSelect
        id={`ac-id${name}`}
        name={`${name}`}
        variant={"outlined"}
        options={suggestions}
        disabled={disabled}
        hiddenLabel="true"
        disableClearable={true}
        popupIcon={<KeyboardArrowDownIcon />}
        autoHighlight={true}
        value={value && value.value ? value : {label:'',value:''}}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) =>option.value === value.value}
        onChange={(event, newValue) => {props.handleAutoComplete(newValue || {label:'',value:''} , name)}}
        onInputChange={(value, eValue, reason) => {props.onInputChange(value, name, reason)}}
        renderInput={(params) => {

          //clear fields if value should be empty
          // if(hasValue===false){
          //   params.inputProps.value=''
          // }

           return (
            <TextField
              {...params}
              value={value}
              variant="outlined"
              {...rest}
              error={showValidate ? true:false}
              helperText={showValidate? validationMsg:""}
            />
          )
        }}
        {...rest}
        {...extraProps}
      />
    </Grid>
  );
}

// default props
AutoCompleteComponent.defaultProps = {
  suggestions: [],
  value: "",
  disabled: false,
  name: "autocomplete",
  handleAutoComplete: () => {},
  onKeyDown: () => {},
  extraProps: {},
  hasValue:true,
  onInputChange:()=>{},
  showValidate:false,
  validationMsg:''
};

// prop types
AutoCompleteComponent.propTypes = {
  suggestions: PropTypes.any,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  handleAutoComplete: PropTypes.func,
  extraProps: PropTypes.object,
  onInputChange:PropTypes.func,
  hasValue: PropTypes.bool,
  showValidate:PropTypes.bool,
  validationMsg:PropTypes.string
};

/** Export Component */
export const AutoComplete = withStyles(styles)(AutoCompleteComponent);
