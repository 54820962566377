import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Grid from '@mui/material/Grid';

//Styles
import SettingsStyles from "../SettingsStyles"

// Import History
import history from "../../../config/history";

// Import Components
import { Button, TextFieldWithoutRef, Typography } from "../../../components";

// Import Cofig Constants
import { settings_page_constants, user_page_constants } from "../../../config/constants";
import { settingsConstant } from "../../../redux/constants";

// Import Actions
import { settingsAction, updateReducerAction } from "../../../redux/actions";

const TazWorks = (props) => {
    const dispatch = useDispatch()
    const { classes } = props;

    const { configuration: { tazworks } } = useSelector(state => state.settings);
    const [errors, setErrors] = useState({});
    const [updateButton, setUpdateButton] = useState(false)

    /**
  *Handle Cancel Navigage to Tab based on Roles
  */
    const handleCancel = () => {
        history.go(-1)
    };

    const handleChange = (e) => {
        setUpdateButton(true)
        tazworks[e.target.name] = e.target.value
        dispatch(updateReducerAction.update(settingsConstant.UPDATE_TAZWORKS_REQUEST, tazworks, () => { }));
    }

    const handleSubmit = () => {
        let errors = {}
        if (!(tazworks["email"].trim())) {
            errors["email"] = true
        }
        if (!(tazworks["first_name"].trim())) {
            errors["first_name"] = true
        }
        if (!(tazworks["generation"].trim())) {
            errors["generation"] = true
        }
        if (!(tazworks["last_name"].trim())) {
            errors["last_name"] = true
        }
        if (!(tazworks["manager"].trim())) {
            errors["manager"] = true
        }
        if (!(tazworks["middle_name"].trim())) {
            errors["middle_name"] = true
        }
        if (!(tazworks["phone"].trim())) {
            errors["phone"] = true
        }
        if (!(tazworks["property_name"].trim())) {
            errors["property_name"] = true
        }
        if (!(tazworks["rental_payment"].trim())) {
            errors["rental_payment"] = true
        }
        if (!(tazworks["subdomain"].trim())) {
            errors["subdomain"] = true
        }
        if (!(tazworks["unit"].trim())) {
            errors["unit"] = true
        }
        setErrors(errors)
        if (Object.keys(errors).length === 0) {
            dispatch(settingsAction.saveConfiguration({ key: "tazworks", value: tazworks }, () => { 
                setUpdateButton(false)
            }));
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12} className={classes.titleContainerSys}>
                        <Typography variant="h6" className={classes.groupTitle}>{"Generic"}</Typography>
                    </Grid>
                    <Grid item xs={6} align="left">
                        <Grid item xs={11}>
                            <Typography variant={"body1"} className={"textFieldLabel"}>{settings_page_constants.SUBDOMAIN}</Typography>
                            <TextFieldWithoutRef
                                name={"subdomain"}
                                size={"small"}
                                margin={"none"}
                                value={tazworks.subdomain ? tazworks.subdomain : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.subdomain ? true : false}
                                helperText={errors.subdomain ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.titleContainerSys}>
                        <Typography variant="h6" className={classes.groupTitle}>{"Mapping"}</Typography>
                    </Grid>
                    <Grid item xs={6} align="left">
                        <Grid item xs={11}>
                            <Typography variant={"body1"} className={"textFieldLabel"}>{settings_page_constants.MANAGER}</Typography>
                            <TextFieldWithoutRef
                                name={"manager"}
                                size={"small"}
                                margin={"none"}
                                value={tazworks.manager ? tazworks.manager : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.manager ? true : false}
                                helperText={errors.manager ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} align="right">
                        <Grid item xs={11}>
                            <Typography variant={"body1"} className={"textFieldLabel"}>{settings_page_constants.FIRST_NAME}</Typography>
                            <TextFieldWithoutRef
                                name={"first_name"}
                                size={"small"}
                                margin={"none"}
                                value={tazworks.first_name ? tazworks.first_name : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.first_name ? true : false}
                                helperText={errors.first_name ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} align="left">
                        <Grid item xs={11}>
                            <Typography variant={"body1"} className={"textFieldLabel"}>{settings_page_constants.LAST_NAME}</Typography>
                            <TextFieldWithoutRef
                                name={"last_name"}
                                size={"small"}
                                margin={"none"}
                                value={tazworks.last_name ? tazworks.last_name : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.last_name ? true : false}
                                helperText={errors.last_name ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} align="right">
                        <Grid item xs={11}>
                            <Typography variant={"body1"} className={"textFieldLabel"}>{settings_page_constants.MIDDLE_NAME}</Typography>
                            <TextFieldWithoutRef
                                name={"middle_name"}
                                size={"small"}
                                margin={"none"}
                                value={tazworks.middle_name ? tazworks.middle_name : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.middle_name ? true : false}
                                helperText={errors.middle_name ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} align="left">
                        <Grid item xs={11}>
                            <Typography variant={"body1"} className={"textFieldLabel"}>{settings_page_constants.GENERATION}</Typography>
                            <TextFieldWithoutRef
                                name={"generation"}
                                size={"small"}
                                margin={"none"}
                                value={tazworks.generation ? tazworks.generation : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.generation ? true : false}
                                helperText={errors.generation ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} align="right">
                        <Grid item xs={11}>
                            <Typography variant={"body1"} className={"textFieldLabel"}>{settings_page_constants.EMAIL}</Typography>
                            <TextFieldWithoutRef
                                name={"email"}
                                size={"small"}
                                margin={"none"}
                                value={tazworks.email ? tazworks.email : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.email ? true : false}
                                helperText={errors.email ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} align="left">
                        <Grid item xs={11}>
                            <Typography variant={"body1"} className={"textFieldLabel"}>{settings_page_constants.PHONE}</Typography>
                            <TextFieldWithoutRef
                                name={"phone"}
                                size={"small"}
                                margin={"none"}
                                value={tazworks.phone ? tazworks.phone : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.phone ? true : false}
                                helperText={errors.phone ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} align="right">
                        <Grid item xs={11}>
                            <Typography variant={"body1"} className={"textFieldLabel"}>{settings_page_constants.PROPERTY_NAME}</Typography>
                            <TextFieldWithoutRef
                                name={"property_name"}
                                size={"small"}
                                margin={"none"}
                                value={tazworks.property_name ? tazworks.property_name : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.property_name ? true : false}
                                helperText={errors.property_name ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} align="left">
                        <Grid item xs={11}>
                            <Typography variant={"body1"} className={"textFieldLabel"}>{settings_page_constants.UNIT}</Typography>
                            <TextFieldWithoutRef
                                name={"unit"}
                                size={"small"}
                                margin={"none"}
                                value={tazworks.unit ? tazworks.unit : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.unit ? true : false}
                                helperText={errors.unit ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} align="right">
                        <Grid item xs={11}>
                            <Typography variant={"body1"} className={"textFieldLabel"}>{settings_page_constants.RENTAL_PAYMENT}</Typography>
                            <TextFieldWithoutRef
                                name={"rental_payment"}
                                size={"small"}
                                margin={"none"}
                                value={tazworks.rental_payment ? tazworks.rental_payment : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.rental_payment ? true : false}
                                helperText={errors.rental_payment ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="mt-2">
                        <Grid item xs={4} align="left">
                            <Button color="primary" size="small" title={"UPDATE"} className="mr-1" onClick={handleSubmit} disabled={updateButton?false:true}></Button>
                            <Button color="secondary" size="small" title={user_page_constants.CANCEL} onClick={handleCancel}></Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

// default props
TazWorks.defaultProps = {
    classes: {},
};

// prop types
TazWorks.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(SettingsStyles)(TazWorks);