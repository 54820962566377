import { alertConstant, settingsConstant, loaderConstant } from "../constants";
import { settingsService } from "../services";
import { baseAction } from "./baseAction";

const { request, success, failure } = baseAction;

// Export All Auth Actions Functions
export const settingsAction = {
    getProfile,
    getApplication,
    getConfiguration,
    saveProfile,
    saveApplication,
    saveConfiguration
};

/**
 * Get profile
 */
function getProfile(id, callback) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(settingsConstant.GET_PROFILE_REQUEST));
        settingsService.getProfile(id).then(
            (data) => {
                dispatch(success(settingsConstant.GET_PROFILE_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                callback()
            },
            (error) => {
                dispatch(failure(settingsConstant.GET_PROFILE_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}

/**
 * Get application
 */
function getApplication(callback) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(settingsConstant.GET_APPLICATION_REQUEST));
        settingsService.getApplication().then(
            (data) => {
                dispatch(success(settingsConstant.GET_APPLICATION_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                callback()
            },
            (error) => {
                dispatch(failure(settingsConstant.GET_APPLICATION_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}

/**
 * Get Configuration
 */
function getConfiguration(callback) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(settingsConstant.GET_CONFIGURATION_REQUEST));
        settingsService.getConfiguration().then(
            (data) => {
                dispatch(success(settingsConstant.GET_CONFIGURATION_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                callback()
            },
            (error) => {
                dispatch(failure(settingsConstant.GET_CONFIGURATION_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}

/**
 * SAVE profile
 */
function saveProfile(data, callback) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(settingsConstant.SAVE_PROFILE_REQUEST));
        settingsService.saveProfile(data).then(
            (data) => {
                dispatch(success(settingsConstant.SAVE_PROFILE_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                callback()
            },
            (error) => {
                dispatch(failure(settingsConstant.SAVE_PROFILE_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}

/**
 * SAVE application
 */
function saveApplication(data, callback) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(settingsConstant.SAVE_APPLICATION_REQUEST));
        settingsService.saveApplication(data).then(
            (data) => {
                dispatch(success(settingsConstant.SAVE_APPLICATION_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                callback()
            },
            (error) => {
                dispatch(failure(settingsConstant.SAVE_APPLICATION_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}

/**
 * SAVE Configuration
 */
function saveConfiguration(data,callback) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(settingsConstant.SAVE_CONFIGURATION_REQUEST));
        settingsService.saveConfiguration(data).then(
            (data) => {
                dispatch(success(settingsConstant.SAVE_CONFIGURATION_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                callback()
            },
            (error) => {
                dispatch(failure(settingsConstant.SAVE_CONFIGURATION_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}
