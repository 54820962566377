import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

//import actions
import { authAction } from "./../redux/actions";

// Import Helper Function
import { getToken } from "./../utils";

// Import Private Layout
import PrivateLayout from "../layouts/PrivateLayout/PrivateLayout";

// Routes the page with authentication by the user
const PrivateRoute = ({ component: Component, ...rest }) => {
  // Get Logged In User Tocken
  const isLoggedIn = getToken();

  // Define Dispatch and State
  const dispatch = useDispatch();

  // Get Logged In User Details
  useEffect(() => {
    dispatch(authAction.getProfileData(() => {
      dispatch(authAction.getProfileDataCurrent(()=>{
        dispatch(authAction.getProfileLogo());  
      }));
    }));
  }, [dispatch]);

  return (
    <Route
      {...rest}
      render={(props) => (
        <React.Fragment>
          {isLoggedIn ? (
            <PrivateLayout {...props}>
              <Component {...props} />
            </PrivateLayout>
          ) : (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          )}
        </React.Fragment>
      )}
    />
  );
};

// Define Props Types
PrivateRoute.propTypes = {
  component: PropTypes.elementType,
};

export default PrivateRoute;
