import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Drawer from '@mui/material/Drawer';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import palette from '../../../assets/theme/palette';
import { Button as Core } from '@mui/material';

//Styles
import ServicesStyles from '../ServicesStyles'

//Images
import { CloseIcon } from '../../../assets/svg'

//Components
import { Button, TextField, Typography } from "../../../components";

//Constants
import { services_page_constants } from "../../../config/constants"

// Import Actions
import { serviceAction } from "../../../redux/actions";
import Alert from '@mui/material/Alert';

// Import Config Layout
import { servicesCardLimit } from "../../../config/layout";

function UpdateFee(props) {
    const dispatch = useDispatch();
    const { classes, alignment } = props;

    let cardLimit = servicesCardLimit();

    const [drawer, setDrawer] = useState(false);
    const { search: { page }, getAll: { page: getAllPage }, checkBoxSelect } = useSelector(state => state.service);

    // open/close drawer
    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setDrawer(open);
    };

    // Set Form Values
    const { register, handleSubmit, errors, reset } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            fee: null
        }
    });

    // Submit Form
    const onSubmit = (finaldata) => {
        if (Number(finaldata.fee) && Number(finaldata.fee) < 0) {
            finaldata.fee = 0
        } else if (!Number(finaldata.fee)) {
            finaldata.fee = 0
        }
        dispatch(serviceAction.updateFee(finaldata, () => {
            setDrawer(false)
            reset()
            if (alignment === "right") {
                dispatch(serviceAction.search(page.skip, page.limit, page.sortBy, page.sort, page.sFields, page.sValues));
            }
            else {
                dispatch(serviceAction.getAll({ limit: cardLimit, skip: 1, search: getAllPage.search, sort: getAllPage.sort }));
            }
        }))
    }

    //UpdateFee Alert
    const [alertMsg, openAlertMsg] = useState(false);

    const alertBox = () => {
        openAlertMsg(true);
    };

    const handleClose = () => {
        openAlertMsg(false);
    };

    return (
        <React.Fragment>
            <Backdrop className={classes.backDrop} onClick={toggleDrawer(false)} open={drawer} />
            <Core
                onClick={(Object.keys(checkBoxSelect).length > 0) ? toggleDrawer(true) : alertBox}
                // className={`${classes.btnBlue} ${"mr-1"}`}
                style={{borderRadius : "50px" , height:"34px",fontSize:"14px",padding:"8px 20px", backgroundColor:"#12A8DE",color:"#fff",marginRight:"10px"}}
            > UPDATE FEE</Core>
            {/* <Button
                // onClick={(Object.keys(checkBoxSelect).length > 0) ? toggleDrawer(true) : alertBox}
                // title={services_page_constants.UPDATE_FEE}
                // className={`${classes.btnBlue} ${"mr-1"}`}
                ></Button> */}
            <Drawer anchor='right' role="presentation" variant="persistent" open={drawer} onClose={toggleDrawer(false)} className={classes.allianceDrawer}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Grid container alignItems="center" justifyContent="space-between" className={"p-2"}>
                        <Typography variant="h4" title={services_page_constants.UPDATEFEE}></Typography>
                        <IconButton onClick={toggleDrawer(false)} className={classes.padding5} size="large"><CloseIcon /></IconButton>
                    </Grid>
                    <Grid container className="pl-2 pr-2 pb-2">
                        <Typography variant={"body1"}>{services_page_constants.FEE}</Typography>
                        <TextField
                            id={"fee"}
                            name={"fee"}
                            size={"small"}
                            margin={"none"}
                            inputRef={
                                register()
                            }
                        />
                    </Grid>
                    <Grid container className="p-2">
                        <Button color="primary" size="small" title={services_page_constants.APPLY} className="mr-1" type="submit"></Button>
                        <Button color="secondary" size="small" title={services_page_constants.CANCEL} onClick={toggleDrawer(false)}></Button>
                    </Grid>
                </form>
            </Drawer>
            <Snackbar
                className={classes.updateFeeAlert}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={alertMsg}
                autoHideDuration={5000}
                onClose={handleClose}
                message="Note archived">
                <Alert severity="error">Please select the template <IconButton className={"p5"} onClick={handleClose} size="large"><CloseIcon /></IconButton></Alert>
            </Snackbar>
        </React.Fragment>
    );
}

// default props
UpdateFee.defaultProps = {
    classes: {},
    drawer: false,
    toggleDrawer: () => { },
};

// prop types
UpdateFee.propTypes = {
    classes: PropTypes.object,
    drawer: PropTypes.bool,
    toggleDrawer: PropTypes.func
};

// export default UserList;
export default withStyles(ServicesStyles)(UpdateFee);