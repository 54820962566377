import React, { Component } from 'react';
import { _api } from "../../config/environment";

class NetworkDetectors extends Component {
    state = {
        isDisconnected: false
    }

    componentDidMount() {
        this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
    }


    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        if (condition === 'online') {
            const webPing = setInterval(
                () => {
                    fetch(_api.url, {
                        mode: 'no-cors',
                        method: 'GET'
                    })
                        .then(() => {
                            this.setState({ isDisconnected: false }, () => {
                                return clearInterval(webPing)
                            });
                        }).catch((err) => {
                            console.log("Error=>", err)
                            this.setState({ isDisconnected: true })
                        })
                }, 2000);
            return;
        }

        return this.setState({ isDisconnected: true });
    }

    render() {
        const { isDisconnected } = this.state;
        return (
            <>
                { isDisconnected ? (<div className="internet-erro">
                    <p>Internet connection lost</p>
                </div>) : null
                }
            </>
        );
    }
}

export const NetworkDetector = NetworkDetectors