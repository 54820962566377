import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames"
import Grid from '@mui/material/Grid';
import { withStyles } from "@mui/styles";
import FormControl from '@mui/material/FormControl';

//import helpers
import { IsEmail } from '../../../utils'

//Styles
import TenantApplicationStyles from "../TenantApplicationStyles"

// Import Components
import { TextField, InputMaskField, Select, CheckBox, DatePickr, ImportFile } from "../../../components";
import TextareaAutosize from '@mui/material/TextareaAutosize';

//import helpers
import { dataPopulator } from "../../../utils"

const FormFieldsGenerator = (props) => {

    //get props
    let { classes, handleChange, item, indx, indx0, contents, data, validataForm, managerList, regionList, urlPath, common, settings, managerDefault,managerListOrder } = props;

    //default drop down fields
    const dropdownTypes = ["manager", "month", "state", "confirm"];
    //types of dropdown fields
    const monthList = [{ value: "", label: "" },{ value: "Jan", label: "Jan" }, { value: "Feb", label: "Feb" }, { value: "Mar", label: "Mar" }, { value: "Apr", label: "Apr" }, { value: "May", label: "May" }, { value: "Jun", label: "Jun" }, { value: "Jul", label: "Jul" }, { value: "Aug", label: "Aug" }, { value: "Sep", label: "Sep" }, { value: "Oct", label: "Oct" }, { value: "Nov", label: "Nov" }, { value: "Dec", label: "Dec" }];
    const confirmList = [{ value: "", label: "" }, { value: "Yes", label: "Yes" }, { value: "No", label: "No" }];


    //dropdown types
    if ((contents["type"] && dropdownTypes.indexOf(contents["type"].toLowerCase()) > -1) || (contents["items"])) {

        const contentType = contents["type"] ? contents["type"].toLowerCase() : "";

        //convert item array to array of objects with label and value
        if (contents["items"]) {
            contents["items"] = contents["items"].map(a => {
                if (typeof a === "string") {
                    return { "value": a, "label": a }
                } else {
                    return a;
                }
            })
            const emptyValue = contents["items"].filter(i=>(i && i["value"]===""))
            if(emptyValue && emptyValue.length===0){
                contents["items"] = [{ value: "", label: "" },...contents["items"]]
            }
        }
        const emptyRegion = regionList.filter(i=>(i && i["value"]===""))
        if(emptyRegion && emptyRegion.length===0){
            regionList = [{ value: "", label: "" },...regionList]
        }

        //get drpodown values
        const dropdownValues = contents["items"] ? contents["items"] : (contentType === "month" ? monthList : contentType === "state" ? regionList : contentType === "confirm" ? confirmList : contentType === "manager" ? managerListOrder : [])

        //update mamager field if empty
        if (contentType === "manager" && managerList[0] && managerList[0]["value"] && !contents["value"] && managerDefault) {
            if (urlPath === "view") {
                if (common === 1) {
                    handleChange({ name: item, "value": managerList[0]["value"] }, indx0, indx)
                }
            } else {
                handleChange({ name: item, "value": managerList[0]["value"] }, indx0, indx)
            }
        }

        return (
            <Grid>
                <FormControl variant="outlined" className={classNames(classes.formControl, classes.selectBox)}>
                    <Select
                        options={dropdownValues}
                        handleChange={(e) => handleChange({ name: item, "value": e.value }, indx0, indx, contents["maxlength"], null, "DDN")}
                        value={contents["value"] ? contents["value"] : ''}
                        disabled={(contents["disable"]) ? true : false}
                        error={(contents["required"] || (contents["onrepeat"] && contents["onrepeat"]["required"] && contents["isNew"])) && !contents["value"] && validataForm ? true : false}
                        errorText={(contents["required"] || (contents["onrepeat"] && contents["onrepeat"]["required"] && contents["isNew"])) && !contents["value"] && validataForm ? "this field is required" : ""}
                    >
                    </Select>
                    {contents["hint"] && <p className={classes.hintText}>{`${'('} ${contents["hint"]} ${')'}`}</p>}
                </FormControl>
            </Grid>
        )

    }
    //masked fields
    else if (contents["mask"]) {
        return (
            <Grid>
                <InputMaskField
                    id={item}
                    name={item}
                    margin="none"
                    mask={contents["mask"]}
                    value={contents["value"] ? contents["value"] : ''}
                    disabled={false}
                    maskChar="_"
                    onChange={(e) => { handleChange({ "name": item, "value": e.target.value === "___-__-____" ? "" : e.target.value }, indx0, indx, contents["maxlength"], null) }}
                    error={(contents["required"] || (contents["onrepeat"] && contents["onrepeat"]["required"] && contents["isNew"])) && !contents["value"] && validataForm ? true : false}
                    helperText={(contents["required"] || (contents["onrepeat"] && contents["onrepeat"]["required"] && contents["isNew"])) && !contents["value"] && validataForm ? "this field is required" : ""}
                />
                {contents["hint"] && <p className={classes.hintText}>{`${'('} ${contents["hint"]} ${')'}`}</p>}
            </Grid>
        )
    }
    //file types
    else if (contents["type"] && contents["type"].toLowerCase() === "date") {

        const dateError = () => {
            if (validataForm && contents["value"]) {
                if (contents["limit"] && contents["limit"] === "past" && new Date(contents["value"]) > new Date().setHours(23, 59, 59, 59)) {
                    return (<p style={{ color: "red" }} className={classes.hintText}>{"Please enter a Past date"}</p>)
                } else if (contents["limit"] && contents["limit"] === "future" && new Date(contents["value"]) < new Date().setHours(0, 0, 0, 0)) {
                    return (<p style={{ color: "red" }} className={classes.hintText}>{"Please enter a Future date"}</p>)
                }
            } else if (validataForm && (contents["required"] || (contents["onrepeat"] && contents["onrepeat"]["required"] && contents["isNew"]))) {
                if(!contents["value"]){
                    return (<p style={{ color: "red" }} className={classes.hintText}>{"this field is required"}</p>)
                }else if(contents["value"] && new Date(contents["value"])=="Invald Date"){
                    return (<p style={{ color: "red" }} className={classes.hintText}>{"Please enter a valid date"}</p>)
                }
            }
        }

        return (
            <Grid>
                <DatePickr
                    id={item}
                    name={item}
                    value={contents["value"] ? contents["value"] : ''}
                    className={classes.textField}
                    format={contents["paymentDate"] ? "MM/YY" : contents["short"] && contents["short"] === true ? "MM/YYYY" : "MM/DD/YYYY"}
                    mask={contents["paymentDate"] ? "__/__" : contents["short"] && contents["short"] === true ? "__/____" : "__/__/____"}
                    placeholder={contents["paymentDate"] ? "__/__" : contents["short"] && contents["short"] === true ? "__/____" : "__/__/____"}
                    handleChange={(e) => { handleChange({ "name": item, "value": e.value }, indx0, indx, contents["maxlength"], null) }}
                    inputVariant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth={true}
                    disabled={false}
                    error={(contents["required"] || (contents["onrepeat"] && contents["onrepeat"]["required"] && contents["isNew"])) && !contents["value"] && validataForm ? true :false}
                    disableFuture={contents["limit"] ? contents["limit"] === "past" ? true : false : false}
                    disablePast={contents["limit"] ? contents["limit"] === "future" ? true : false : false}
                    errorMessages={"Date format is MM/DD/YYYY"}
                />
                {dateError()}
                {contents["hint"] && <p className={classes.hintText}>{`${'('} ${contents["hint"]} ${')'}`}</p>}
            </Grid>
        )
    }
    //file types
    else if (contents["type"] && contents["type"].toLowerCase() === "file") {
        return (
            <Grid>
                <ImportFile
                    name={item}
                    id={item}
                    indx={indx}
                    className={`${classes.uploadImgPopup} ${classes.uploadImgOpacity}`}
                    handleChange={(e) => { handleChange(e, indx0, indx, contents["maxlength"], null) }}
                    accept={".png,.jpeg,.jpg,application/pdf"}
                    value={contents["val"]}
                />
                {(contents["required"] || (contents["onrepeat"] && contents["onrepeat"]["required"] && contents["isNew"])) && !contents["value"] && validataForm ? <p style={{ color: "red" }} className={classes.hintText}>{`this field is required`}</p> : null}
            </Grid>
        )
    }
    //text area
    else if (contents["type"] && contents["type"].toLowerCase() === "textarea") {
        return (
            <Grid>
                <TextareaAutosize
                    className={`${"w-100 verticleAutoSize"} ${classes.allianceTextarea}`}
                    aria-label="minimum height"
                    name={item}
                    id={item}
                    minRows={6}
                    value={contents["value"] ? contents["value"] : ''}
                    onChange={(e) => { handleChange({ "name": item, "value": e.target.value }, indx0, indx, contents["maxlength"], null) }}
                />
                {(contents["required"] || (contents["onrepeat"] && contents["onrepeat"]["required"] && contents["isNew"])) && !contents["value"] && validataForm ? <p style={{ color: "red" }} className={classes.hintText}>{`this field is required`}</p> : null}
            </Grid>
        );
    }
    //email fields
    else if (contents["type"] && contents["type"].toLowerCase() === "email") {
        return (
            <Grid>
                <TextField
                    id={item}
                    name={item}
                    margin="none"
                    placeholder={contents["label"] || ""}
                    value={contents["value"] ? contents["value"] : ''}
                    onChange={(e) => { handleChange({ "name": item, "value": e.target.value }, indx0, indx, contents["maxlength"], null) }}
                    error={(contents["required"] || (contents["onrepeat"] && contents["onrepeat"]["required"] && contents["isNew"])) && (!contents["value"] || !IsEmail(contents["value"])) && validataForm ? true : false}
                    helperText={(contents["required"] || (contents["onrepeat"] && contents["onrepeat"]["required"] && contents["isNew"])) && validataForm ? !contents["value"] ? "this field is required" : !IsEmail(contents["value"]) ? "Please enter a valid email" : "" : ""}
                />
                {contents["hint"] && <p className={classes.hintText}>{`${'('} ${contents["hint"]} ${')'}`}</p>}
            </Grid>
        )
    }
    //money format
    else if (contents["money"]) {
        return (
            <Grid>
                <TextField
                    id={item}
                    name={item}
                    margin="none"
                    // type={"number"}
                    placeholder={contents["label"] || ""}
                    value={contents["value"] ? contents["value"] : ''}
                    onChange={(e) => { handleChange({ "name": item, "value": e.target.value }, indx0, indx, contents["maxlength"], null) }}
                    error={(contents["required"] || (contents["onrepeat"] && contents["onrepeat"]["required"] && contents["isNew"])) && !contents["value"] && validataForm ? true : false}
                    helperText={(contents["required"] || (contents["onrepeat"] && contents["onrepeat"]["required"] && contents["isNew"])) && !contents["value"] && validataForm ? "this field is required" : ""}
                />
                {contents["hint"] && <p className={classes.hintText}>{`${'('} ${contents["hint"]} ${')'}`}</p>}
            </Grid>
        )
    }
    //phone format
    else if (contents["type"] && contents["type"].toLowerCase() === "phone") {
        return (
            <Grid>
                <InputMaskField
                    id={item}
                    name={item}
                    margin="none"
                    mask={"999-999-9999"}
                    value={contents["value"] ? contents["value"] : ''}
                    disabled={false}
                    maskChar="_"
                    onChange={(e) => { handleChange({ "name": item, "value": e.target.value === "___-___-____" ? "" : e.target.value }, indx0, indx, contents["maxlength"], null) }}
                    error={(contents["required"] || (contents["onrepeat"] && contents["onrepeat"]["required"] && contents["isNew"])) && !contents["value"] && validataForm ? true : false}
                    helperText={(contents["required"] || (contents["onrepeat"] && contents["onrepeat"]["required"] && contents["isNew"])) && !contents["value"] && validataForm ? "this field is required" : ""}
                />
                {contents["hint"] && <p className={classes.hintText}>{`${'('} ${contents["hint"]} ${')'}`}</p>}
            </Grid>
        )
    }
    //checkbox
    else if (contents["type"] && contents["type"].toLowerCase() === "checkbox") {
        return (
            <Grid item sm={12} className="mb-2" >
                <CheckBox
                    id={item}
                    name={item}
                    color="secondary"
                    label={contents["label"] || ""}
                    checked={contents["value"] ? contents["value"] : false}
                    handleChange={(e) => handleChange({ name: item, value: e.target.checked }, indx0, indx, contents["maxlength"], null)}
                />
                {contents["hint"] && <p className={classes.hintText}>{`${'('} ${contents["hint"]} ${')'}`}</p>}
            </Grid>
        )
    }
    //multi checkbox
    else if (contents["multiple"] && contents["multiple"] === "checkbox" && contents["itemsData"]) {
        return (
            <Grid item sm={12} className="mb-2" >
                {contents["itemsData"] && contents["itemsData"].length > 0 && contents["itemsData"].map((im, ix) => (
                    <CheckBox
                        id={item}
                        name={item}
                        color="secondary"
                        label={im || ""}
                        checked={contents["value" + ix] ? contents["value" + ix] : false}
                        handleChange={(e) => handleChange({ name: item, value: e.target.checked }, indx0, indx, contents["maxlength"], ix)}
                    />
                ))}
                {contents["hint"] && <p className={classes.hintText}>{`${'('} ${contents["hint"]} ${')'}`}</p>}
            </Grid>
        )
    }
    //disclosure choices page
    else if (contents["choiceVariation"] && contents["choiceVariation"] === "choiceVariation") {
        return (
            <Grid item sm={12} className={`${classes.checkBoxContainer} ${"mb-2"}`} >
                {contents["variation"] && contents["variation"].length > 0 && contents["variation"].map((im, ix) => (
                    <React.Fragment>
                        <CheckBox
                            id={item}
                            name={item}
                            color="secondary"
                            label={im["text"] ? dataPopulator(settings, im["text"]) : ""}
                            checked={im["value"] ? im["value"] : false}
                            handleChange={(e) => handleChange({ name: item, value: e.target.checked }, indx0, indx, contents["maxlength"], ix)}
                        />
                        <br/>
                    </React.Fragment>
                ))}
                {contents["hint"] && <p className={classes.hintText}>{`${'('} ${contents["hint"]} ${')'}`}</p>}
            </Grid>
        )
    }
    //default format
    else {
        return (
            <Grid>
                <TextField
                    id={item}
                    name={item}
                    margin="none"
                    placeholder={contents["label"] || ""}
                    value={contents["value"] ? contents["value"] : ''}
                    onChange={(e) => { handleChange({ "name": item, "value": e.target.value }, indx0, indx, contents["maxlength"], null) }}
                    error={(contents["required"] || (contents["onrepeat"] && contents["onrepeat"]["required"] && contents["isNew"])) && !contents["value"] && validataForm ? true : false}
                    helperText={(contents["required"] || (contents["onrepeat"] && contents["onrepeat"]["required"] && contents["isNew"])) && !contents["value"] && validataForm ? "this field is required" : ""}
                />
                {contents["hint"] && <p className={classes.hintText}>{`${'('} ${contents["hint"]} ${')'}`}</p>}
            </Grid>
        )
    }

}
// default props
FormFieldsGenerator.defaultProps = {
    classes: {},
    item: "",
    indx: -1,
    contents: {},
    handleChange: () => { },
    data: {},
    indx0: -1,
    managerList: [],
    regionList: [],
    validataForm: false,
    settings: {},
    managerDefault:true,
    managerListOrder:[],
};

// prop types
FormFieldsGenerator.propTypes = {
    classes: PropTypes.object,
    item: PropTypes.string,
    indx: PropTypes.number,
    contents: PropTypes.object,
    handleChange: PropTypes.func,
    data: PropTypes.object,
    indx0: PropTypes.number,
    managerList: PropTypes.array,
    regionList: PropTypes.array,
    settings: PropTypes.object,
    validataForm: PropTypes.bool,
    managerDefault:PropTypes.bool,
    managerListOrder:PropTypes.array,
};

export default withStyles(TenantApplicationStyles)(FormFieldsGenerator);
