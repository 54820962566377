const style = (theme) => ({
    root: {
      height: 34,
      '& legend': {
        display: 'inline-block',
      },
      '& .MuiOutlinedInput-root': {
        height: 34,
        minHeight: 34
      }
    },
    VisibilityIcon: {
      opacity: 0
    }
  });
  
  export default style;
  