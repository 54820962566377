import { alertConstant, userConstant, loaderConstant } from "../constants";
import { userService } from "../services";
import { baseAction } from "./baseAction";

// Import History
import history from "../../config/history";

const { request, success, failure } = baseAction;

// Export All Auth Actions Functions
export const userAction = {
  search,
  getUser,
  getAll,
  remove,
  getCompanies,
  createUser,
  updateUser,
  getCompaniesAndRole,
  loginUser,
  resetUserData,
  updatePreference,
  getRegion,
  getManagerAppMap
};

/**
 * Get All  USER
 */
function search(skip, limit, sortBy, sort, sFields, sValues) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(userConstant.USER_SEARCH_REQUEST));
    userService.search(skip, limit, sortBy, sort, sFields, sValues).then(
      (data) => {
        dispatch(success(userConstant.USER_SEARCH_SUCCESS, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(userConstant.USER_SEARCH_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}
/**
 * Get user details
 */
function getUser(params, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(userConstant.USER_GET_ONE_REQUEST));
    userService.getUser(params).then(
      (data) => {
        dispatch(success(userConstant.USER_GET_ONE_SUCCESS, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        successCallback();
      },
      (error) => {
        dispatch(failure(userConstant.USER_GET_ONE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * All Clients details
 */
function getAll(params) {
  return (dispatch) => {
    if (params && !params["loader"]) {
      dispatch(request(loaderConstant.LOADING_TRUE));
    }else{
      dispatch(request(loaderConstant.LAZY_LOADING_TRUE));
    }
    dispatch(request(userConstant.USER_GETALL_REQUEST));
    userService.getAll(params).then(
      (data) => {
        dispatch(success(userConstant.USER_GETALL_SUCCESS, data));
        if (params && !params["loader"]) {
          dispatch(request(loaderConstant.LOADING_FALSE));
        }else{
          dispatch(request(loaderConstant.LAZY_LOADING_FALSE));
        }
      },
      (error) => {
        dispatch(failure(userConstant.USER_GETALL_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        if (params && !params["loader"]) {
          dispatch(request(loaderConstant.LOADING_FALSE));
        }else{
          dispatch(request(loaderConstant.LAZY_LOADING_FALSE));
        }
      }
    );
  };
}

/**
 * Delete particular user
 */
function remove(data, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(userConstant.USER_DELETE_REQUEST));
    userService.remove(data).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(success(userConstant.USER_DELETE_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        successCallback()
      },
      (error) => {
        dispatch(failure(userConstant.USER_DELETE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Get Client Companies
 */
function getCompanies() {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(userConstant.GET_COMPANY_REQUEST));
    userService.getCompanies().then((data) => {
      dispatch(success(userConstant.GET_COMPANY_SUCCESS, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }, (error) => {
      dispatch(failure(userConstant.GET_COMPANY_FAILURE, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/**
 * Get Client Companies
 */
function getCompaniesAndRole(chordFilter, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(userConstant.GET_COMPANY_ROLE_REQUEST));
    userService.getCompaniesAndRole(chordFilter).then((data) => {
      dispatch(success(userConstant.GET_COMPANY_ROLE_SUCCESS, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      successCallback(data.data)
    }, (error) => {
      dispatch(failure(userConstant.GET_COMPANY_ROLE_FAILURE, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/**
 * Create new user
 */
function createUser(params, callback = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(userConstant.CREATE_USER_REQUEST));
    userService.createUser(params).then(
      (data) => {
        dispatch(success(userConstant.CREATE_USER_SUCCESS, { ...params, ...data.data }));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callback(data)
      },
      (error) => {
        dispatch(failure(userConstant.CREATE_USER_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Update existing user
 */
function updateUser(params, callback = ()=>{}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(userConstant.UPDATE_USER_REQUEST));
    userService.updateUser(params).then(
      (data) => {
        dispatch(success(userConstant.UPDATE_USER_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callback()
      },
      (error) => {
        dispatch(failure(userConstant.UPDATE_USER_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * user login request
 * @param {*} item 
 */
function loginUser(item) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(userConstant.USER_LOGIN_REQUEST));
    userService.loginUser(item).then(
      (data) => {
        dispatch(success(userConstant.USER_LOGIN_SUCCESS, data));
        dispatch(request(loaderConstant.LOADING_FALSE));

        // Redirect to roles based screen
        if (data && data.data && data.data.role === "manager") {
          history.push(`/submissions`);
        } else {
          history.push(`/users?reset=true`);
        }
      },
      (error) => {
        dispatch(failure(userConstant.USER_LOGIN_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  }
}
/*
* Update existing user prefer
*/
function updatePreference(params, callbacks = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(userConstant.UPDATE_PREFERENCE_REQUEST));
    userService.updatePreference(params).then(
      (data) => {
        dispatch(success(userConstant.UPDATE_PREFERENCE_SUCCESS, data));
        // dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callbacks()
      },
      (error) => {
        dispatch(failure(userConstant.UPDATE_PREFERENCE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  }
}

//reset user data to init state
function resetUserData() {
  return { type: userConstant.RESET_USER_DATA }
}

/*
*get user region
*/
function getRegion() {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(userConstant.GET_REGION_REQUEST));
    userService.getRegion().then(
      (data) => {
        dispatch(success(userConstant.GET_REGION_SUCCESS, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(userConstant.GET_REGION_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  }
}

/*
*check if manager is mapped to application
*/
function getManagerAppMap(params, CB= ()=>{}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(userConstant.GET_MANAGER_MAP_REQUEST));
    userService.getManagerAppMap(params).then(
      (data) => {
        dispatch(success(userConstant.GET_MANAGER_MAP_SUCCESS, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        CB(data)
      },
      (error) => {
        dispatch(failure(userConstant.GET_MANAGER_MAP_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  }
}