import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";

//Import Images
import AllianceLogo from '../../assets/images/logo.png'

// // Import Styles
import AuthenticationLayoutStyles from "./AuthenticationLayoutStyles";

// Import Components
import { Typography } from "../../components";

// Import Cofig Constants
import { common_constants } from "../../config/constants";

// Import Actions
import { authAction } from "../../redux/actions";


function AuthenticationLayout(props) {
  //define dispatch
  const dispatch = useDispatch();

  //get props
  const { classes } = props;

  //get reducer data
  const { address } = useSelector(state => (state.auth))


  useEffect(() => {
    dispatch(authAction.getCompanyAddress())
  }, [dispatch])

  return (
    /* Authentication container */
    <Grid container className={"h-100 bg-white"} alignItems="stretch">
      <Grid item xs={6} className={"pl-10 pr-10"}>
        <Grid container spacing={4} justifyContent="space-between" alignItems="center" className={classes.container}>
          <Grid item xs={12} align="center"><img src={AllianceLogo} alt="Alliance 2020" /></Grid>
          <Grid item xs={12} className={classes.loginContainer}>

            {/* login page */}
            <Grid className={"w-100"}>{props.children}</Grid>

          </Grid>
          <Grid item sm={12}>
            <Typography align="center" variant={"subtitle2"} color="textSecondary">{address && address["address"] ? address["address"] : common_constants.ADDRESS}</Typography>
            <Typography align="center" variant={"subtitle2"} color="textSecondary">{address ? `${address["phone"] ? address["phone"] : ""} | ${address["fax"] ? address["fax"] : ""}` : common_constants.PHONE} </Typography>
            <Typography align="center" variant={"subtitle2"} color="textSecondary">{`Copyright © ${address ? address["name"] : 'Alliance 2020.'} All Right Reserved`}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} className={classes.bannerImg}>
      </Grid>
    </Grid>
  );
}

// prop types
AuthenticationLayout.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.element.isRequired
};

export default withStyles(AuthenticationLayoutStyles)(AuthenticationLayout);
