import { applicationConstant } from "../constants";

// Set Initial State
const initialState = {
    viewType: 'left',
    search: {
        data: [],
        page: {
            limit: 10,
            sFields: [],
            sValues: [],
            count: 0,
            skip: 1,
            sortBy: 'cdt',
            sort: 'desc',
        }
    },
    getApp: { question: [], appData: {} },
    appOldData: { question: [], appData: {} },
    getAll: {
        data: [],
        page: {
            limit: 10,
            skip: 1,
            search: '',
            sort: 'desc'
        }
    },
    create: {},
    update: {},
    appLink: "",

    serviceName: [],
    criteriaName: [],
    managerName: [],
    common: { is_common: 0, fee: '0', email: [], id: null }
};

export default function (state = initialState, action) {
    switch (action.type) {

        //update view type
        case applicationConstant.UPDATE_VIEW_TYPE:
            return {
                ...state,
                viewType: action.data
            };
        //update view type and search
        case applicationConstant.UPDATE_VIEW_TYPE_AND_SEARCH:
            return {
                ...state,
                viewType: action.data,
                getAll: { ...state.getAll, page: { ...state.getAll.page, search: '' } },
                search: { ...state.search, page: { ...state.search.page, sFields: [], sValues: [] } }
            };

        //create APP
        case applicationConstant.CREATE_APP_REQUEST:
            return {
                ...state
            };
        case applicationConstant.CREATE_APP_SUCCESS:
            return {
                ...state,
            };
        case applicationConstant.CREATE_APP_FAILURE:
            return {
                ...state,
            };

        //GET COMMON FLAG
        case applicationConstant.GET_C_COMMON_REQUEST:
            return {
                ...state,
                appLink: ""
            };
        case applicationConstant.GET_C_COMMON_SUCCESS:
            return {
                ...state,
                common: action.data.data
            };
        case applicationConstant.GET_C_COMMON_FAILURE:
            return {
                ...state,
                common: initialState.common
            };

        //delete APP
        case applicationConstant.APP_DELETE_REQUEST:
            return {
                ...state
            };
        case applicationConstant.APP_DELETE_SUCCESS:
            return {
                ...state,
            };
        case applicationConstant.APP_DELETE_FAILURE:
            return {
                ...state,
            };

        //update APP
        case applicationConstant.UPDATE_APP_REQUEST:
            return {
                ...state
            };
        case applicationConstant.UPDATE_APP_SUCCESS:
            return {
                ...state,
            };
        case applicationConstant.UPDATE_APP_FAILURE:
            return {
                ...state,
            };

        //update APP introduction
        case applicationConstant.UPDATE_APP_INTRO_REQUEST:
            return {
                ...state
            };
        case applicationConstant.UPDATE_APP_INTRO_SUCCESS:
            return {
                ...state,
            };
        case applicationConstant.UPDATE_APP_INTRO_FAILURE:
            return {
                ...state,
            };

        //update APP criteria
        case applicationConstant.UPDATE_APP_CRIT_REQUEST:
            return {
                ...state
            };
        case applicationConstant.UPDATE_APP_CRIT_SUCCESS:
            return {
                ...state,
            };
        case applicationConstant.UPDATE_APP_CRIT_FAILURE:
            return {
                ...state,
            };

        //update APP payment
        case applicationConstant.UPDATE_APP_PAY_REQUEST:
            return {
                ...state
            };
        case applicationConstant.UPDATE_APP_PAY_SUCCESS:
            return {
                ...state,
            };
        case applicationConstant.UPDATE_APP_PAY_FAILURE:
            return {
                ...state,
            };

        // SEARCH APP
        case applicationConstant.APP_SEARCH_REQUEST:
            return {
                ...state
            };
        case applicationConstant.APP_SEARCH_SUCCESS:
            return {
                ...state,
                search: {
                    data: action.data.data,
                    page: action.data.page
                }
            };
        case applicationConstant.APP_SEARCH_FAILURE:
            return {
                ...state
            };

        // GET ALL APP
        case applicationConstant.APP_GETALL_REQUEST:
            return {
                ...state
            };
        case applicationConstant.APP_GETALL_SUCCESS:

            const page = action.data.page;
            let stateData = [];

            if (page.skip > 0) {
                stateData = [...state.getAll.data, ...action.data.data]
            } else {
                stateData = [...action.data.data]
            }

            return {
                ...state,
                getAll: {
                    data: stateData,
                    page: action.data.page
                }
            };
        case applicationConstant.APP_GETALL_FAILURE:
            return {
                ...state,
                getAll: initialState.getAll
            };

        //update chord filter reducer
        case applicationConstant.CHORD_FILTER_UPDATE:
            return {
                ...state,
                chordFilter: { ...state.chordFilter, ...action.data }
            }

        // GET ONE CLIENT BY ID
        case applicationConstant.APP_GET_ONE_REQUEST:
            return {
                ...state,
            };
        case applicationConstant.APP_GET_ONE_SUCCESS:
            return {
                ...state,
                getApp: action.data.data,
                appOldData: JSON.parse(JSON.stringify(action.data.data))
            };
        case applicationConstant.APP_GET_ONE_FAILURE:
            return {
                ...state,
                getApp: initialState.getApp,
                appOldData: initialState.appOldData
            };

        //reset APP data  
        case applicationConstant.RESET_APP_DATA:
            return {
                ...state,
                getApp: { question: [], appData: {} },
                appOldData: { question: [], appData: {} },
            };

        // GET CRITERIA NAME
        case applicationConstant.GET_CRITERIA_NAME_REQUEST:
            return {
                ...state,
            };
        case applicationConstant.GET_CRITERIA_NAME_SUCCESS:
            return {
                ...state,
                criteriaName: action.data.data
            };
        case applicationConstant.GET_CRITERIA_NAME_FAILURE:
            return {
                ...state,
                criteriaName: initialState.criteriaName
            };


        // GET MANAGER NAME
        case applicationConstant.GET_MANAGER_NAME_REQUEST:
            return {
                ...state,
            };
        case applicationConstant.GET_MANAGER_NAME_SUCCESS:
            return {
                ...state,
                managerName: action.data.data
            };
        case applicationConstant.GET_MANAGER_NAME_FAILURE:
            return {
                ...state,
                managerName: initialState.managerName
            };

        // GET SERIVCE NAME
        case applicationConstant.GET_SERVICE_NAME_REQUEST:
            return {
                ...state,
            };
        case applicationConstant.GET_SERVICE_NAME_SUCCESS:
            return {
                ...state,
                serviceName: action.data.data
            };
        case applicationConstant.GET_SERVICE_NAME_FAILURE:
            return {
                ...state,
                serviceName: initialState.serviceName
            };

        //update question
        case applicationConstant.UPDATE_QUESTION_REDUCER:
            return {
                ...state,
                appOldData: state.appOldData,
                getApp: {
                    ...state.getApp,
                    question: action.data
                }
            }

        // GET SERIVCE NAME
        case applicationConstant.SEND_INV_REQUEST:
            return {
                ...state,
            };
        case applicationConstant.SEND_INV_SUCCESS:
            return {
                ...state,
            };
        case applicationConstant.SEND_INV_FAILURE:
            return {
                ...state,
            };

        // GET SERIVCE NAME
        case applicationConstant.GET_LINK_REQUEST:
            return {
                ...state,
                appLink: ""
            };
        case applicationConstant.GET_LINK_SUCCESS:
            return {
                ...state,
                appLink: action.data.data
            };
        case applicationConstant.GET_LINK_FAILURE:
            return {
                ...state,
                appLink: ""
            };

        default:
            return state;
    }
}
