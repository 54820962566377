import React from 'react';
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import {Select as Core} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Styles from './Styles'

/**
 * SwitchComponent
 * @param {*} props
 */

function SelectComponent (props) {
  const { variant, inputLabel,error,errorText, value, className,options, label, fullWidth, disabled, defaultItem,menuItemValue, menuItem, classes, labelNeeded  } = props;

 /**
   * Handle
   * Select Field Events
   */
  const handleChange = (event) => {
    props.handleChange({ value: event.target.value });
  };


  return (
    <FormControl variant={variant} className={`${className} ${classes.selectBox}`} classes={classes}>
        <Core
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={value}
          onChange={handleChange}
          label={label}
          className={error ? `${classes.borderColorRed}` : "" }
          disabled={disabled?disabled:false}
          fullWidth={fullWidth}
          IconComponent = {KeyboardArrowDownIcon}
          MenuProps={{
            PopoverClasses: {
              paper: 'check-class'
            },
            // anchorOrigin: {
            //   vertical: "bottom",
            //   horizontal: "left"
            // },
            getContentAnchorEl: null
          }}
        >
          {labelNeeded && <MenuItem value="0" align="left">-- Select --</MenuItem>}
            {options.map((item, keyIndex) => (
            <MenuItem key={keyIndex} value={item.value} >
              {item.label}
            </MenuItem>
          ))}
        </Core>
        {error && <FormHelperText className="Mui-error">{errorText}</FormHelperText>}
      </FormControl>
  );
}

// default props
SelectComponent.defaultProps = {
  variant: "outlined",
  inputLabel: "",
  value: "0",
  label: "",
  margin: "dense",
  className: "",
  classes: "",
  options: [],
  fullWidth: false,
  defaultItem: "",
  menuItemValue: "",
  menuItem: "",
  handleChange: () => { },
  disabled:false,
  labelNeeded:false,
  errorText:"",
  error:false,
  classes:{}
};

// prop types
SelectComponent.propTypes = {
  variant: PropTypes.string,
  defaultItem: PropTypes.string,
  inputLabel: PropTypes.string,
  menuItem: PropTypes.string,
  menuItemValue: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.any,
  classes: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  options: PropTypes.array,
  disabled:PropTypes.bool,
  labelNeeded:PropTypes.bool,
  errorText:PropTypes.string,
  error:PropTypes.bool,
  classes:PropTypes.object,
};

export const Select = withStyles(Styles)(SelectComponent);
