export const changeDetection = (oldData = {}, newData = {}) => {
    // console.log(oldData,newData);

    let edit = false;

    oldData["useLogo"] = oldData["useLogo"] === true || oldData["useLogo"] === '1' || oldData["useLogo"] === 1 ? 1 : 0
    newData["useLogo"] = newData["useLogo"] === true || newData["useLogo"] === '1' || newData["useLogo"] === 1 ? 1 : 0
    if (oldData["useLogo"] !== newData["useLogo"]) {
        edit = true;
    }

    if (oldData["userImg"] !== newData["userImg"]) {
        edit = true;
    }

    oldData["is_active"] = oldData["is_active"] === true || oldData["is_active"] === '1' || oldData["is_active"] === 1 ? 1 : 0
    newData["is_active"] = newData["is_active"] === true || newData["is_active"] === '1' || newData["is_active"] === 1 ? 1 : 0
    if (oldData["is_active"] !== newData["is_active"]) {
        edit = true;
    }

    //client role changes
    if (Number(oldData["ownership_id"]) === 1) {

        if (oldData["full_name"] !== newData["full_name"]) {
            edit = true;
        }

        if (oldData["company"] !== newData["company"]) {
            edit = true;
        }

        if (oldData["email"] !== newData["email"]) {
            edit = true;
        }

        if (oldData["phone"] !== newData["phone"]) {
            edit = true;
        }

        if (oldData["code"] !== newData["code"]) {
            edit = true;
        }

        if (oldData["salesrate"] !== newData["salesrate"]) {
            edit = true;
        }

        oldData["is_common"] = oldData["is_common"] === true || oldData["is_common"] === '1' || oldData["is_common"] === 1 ? 1 : 0
        newData["is_common"] = newData["is_common"] === true || newData["is_common"] === '1' || newData["is_common"] === 1 ? 1 : 0
        if (oldData["is_common"] !== newData["is_common"]) {
            edit = true;
        }

        let street = oldData.hasOwnProperty("street") ? oldData["street"] : oldData["address"]==="" || oldData["address"] ? oldData["address"] : "" 
        if (street !== newData["address"]) {
            edit = true;
        }

        if (oldData["city"] !== newData["city"]) {
            edit = true;
        }

        if (newData["region_id"] !== "0" && Number(oldData["region_id"]) !== Number(newData["region_id"])) {
            edit = true;
        }

        let postCode = oldData.hasOwnProperty("post_code") ? oldData["post_code"] : oldData["zipcode"]==="" || oldData["zipcode"] ? oldData["zipcode"] : "" 
        if (postCode !== newData["zipcode"]) {
            edit = true;
        }
    } else if (Number(oldData["ownership_id"]) === 2) {

        if (oldData["phone"] !== newData["phone"]) {
            edit = true;
        }

        let fName = oldData.hasOwnProperty("full_name") ? oldData["full_name"] : oldData["first_name"] + oldData["last_name"]
        if (fName !== newData["first_name"] + newData["last_name"]) {
            edit = true;
        }

        if (oldData["username"] !== newData["username"]) {
            edit = true;
        }

        if (oldData["password"] !== newData["password"]) {
            edit = true;
        }

        if (oldData["groupings"] !== newData["groupings"]) {
            edit = true;
        }

        if (oldData["notification"] !== newData["notification"].join('\n')) {
            edit = true;
        }

        let emailExists = oldData["email"] ? true :false
        if (emailExists !== newData["showEmail"]) {
            edit = true;
        }

        if (newData["showEmail"] && oldData["email"] !== newData["email"]) {
            edit = true;
        }

    }else if(Number(oldData["ownership_id"]) === 3){

        if (oldData["email"] !== newData["email"]) {
            edit = true;
        }

        if (oldData["phone"] != newData["phone"]) {
            edit = true;
        }

        if (oldData["full_name"] !== newData["full_name"]) {
            edit = true;
        }
    }

    return edit;
}