import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

// Import Public Layout
import PublicLayout from "../layouts/PublicLayout/PublicLayout";

// Routes the page without any authentication by the user
const PublicRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    <PublicLayout>
      <Component {...props} />
    </PublicLayout>
  )} />
);

// Define Props Types
PublicRoute.propTypes = {
  component: PropTypes.elementType
};

export default PublicRoute;
