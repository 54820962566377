import React, { useState } from 'react';
import Typography from '@mui/material/Typography';

function CountDownComponent(props) {
  const { minutes } = props;

  const [minTime, setMinuteTime] = useState(minutes ? minutes : 4)
  const [secTime, setSecTime] = useState(59)

  setTimeout(function () {
    if (minTime > -1) {
      if (secTime > 0) {
        setSecTime(secTime - 1)
      } else {
        if (minTime - 1 > -1) {
          setSecTime(59);
          setMinuteTime(minTime - 1)
        }
      }
    } else {
      setSecTime(0)
      setMinuteTime(0)
    }
  }, 1000);

  return (
    <React.Fragment>
      <Typography>{`The session will expire in ${minTime} Minutes ${secTime} Seconds  of total inactivity`}</Typography>
    </React.Fragment>
  );
}

// default props
CountDownComponent.defaultProps = {

};

// prop types
CountDownComponent.propTypes = {

};

export const CountDown = CountDownComponent;
