import React from "react";
import Grid from '@mui/material/Grid'; // Grid component from MUI v5
import Typography from '@mui/material/Typography'; // Typography component from MUI v5
import { makeStyles } from "@mui/styles"; // For makeStyles hook in MUI v5
import PropTypes from 'prop-types';

import Img404 from "../../assets/images/svg/404.svg";
import AppBar from '@mui/material/AppBar'; // AppBar component from MUI v5
import Toolbar from '@mui/material/Toolbar'; // Toolbar component from MUI v5

// Import Images
import AllianceLogoIcon from '../../assets/images/logo_icon.png';

const useStyles = makeStyles({
    root: {
        position: "relative",
        textAlign: "center",
        height: "100%",
    },
    logo: {
        cursor: 'pointer'
    },
    appBar: {
        position: 'absolute',
        top: 0
    }
});



function InvalidDOB() {
    const classes = useStyles();

    return (
        <Grid container alignItems="center" className={classes.root}>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                    <Grid>
                        <img src={AllianceLogoIcon} className={classes.logo} alt="Alliance 2020" />
                    </Grid>
                </Toolbar>
            </AppBar>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="height100"
            >
                <Grid item xs>

                    <img className="mb-3" src={Img404} alt="page 404"></img>
                    <Typography variant="h2" className="mb-2 txtBold">
                        OOPS! PAGE NOT FOUND
                    </Typography>

                    <Typography variant="h6" className="mb-2">
                        Maybe the page you are looking for has been removed, or you typed in the wrong URL
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
InvalidDOB.defaultProps = {
    classes: {},
    match: {},
    user: {},
};

// prop types
InvalidDOB.propTypes = {
    classes: PropTypes.object,
    match: PropTypes.object,
    user: PropTypes.object,
};


export default InvalidDOB;