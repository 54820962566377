// particpants reducer types
export const userConstant = {

  // To Get All Users Details
  USER_GETALL_REQUEST: "USER_GETALL_REQUEST",
  USER_GETALL_SUCCESS: "USER_GETALL_SUCCESS",
  USER_GETALL_FAILURE: "USER_GETALL_FAILURE",

  //update company count
  UPDATE_COMPANY_COUNT: "UPDATE_COMPANY_COUNT",

  // To Search Users Details
  USER_SEARCH_REQUEST: "USER_SEARCH_REQUEST",
  USER_SEARCH_SUCCESS: "USER_SEARCH_SUCCESS",
  USER_SEARCH_FAILURE: "USER_SEARCH_FAILURE",

  // Get User By Id
  USER_GET_ONE_REQUEST: "USER_GET_ONE_REQUEST",
  USER_GET_ONE_SUCCESS: "USER_GET_ONE_SUCCESS",
  USER_GET_ONE_UPDATE: "USER_GET_ONE_UPDATE",
  USER_GET_ONE_FAILURE: "USER_GET_ONE_FAILURE",

  // DELETE User
  USER_DELETE_REQUEST: "USER_DELETE_REQUEST",
  USER_DELETE_SUCCESS: "USER_DELETE_SUCCESS",
  USER_DELETE_FAILURE: "USER_DELETE_FAILURE",

  //update chord filter reducer
  CHORD_FILTER_UPDATE: "CHORD_FILTER_UPDATE",

  //logged in  user
  LOGIN_LOGO_REQUEST: "LOGIN_LOGO_REQUEST",
  LOGIN_LOGO_SUCCESS: "LOGIN_LOGO_SUCCESS",
  LOGIN_LOGO_FAILURE: "LOGIN_LOGO_FAILURE",

  //logged in  user
  LOGIN_USER_REQUEST: "LOGIN_USER_REQUEST",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILURE: "LOGIN_USER_FAILURE",

  //Current user
  CURRENT_USER_REQUEST: "CURRENT_USER_REQUEST",
  CURRENT_USER_SUCCESS: "CURRENT_USER_SUCCESS",
  CURRENT_USER_FAILURE: "CURRENT_USER_FAILURE",

  //client companies
  GET_COMPANY_ROLE_REQUEST: "GET_COMPANY_ROLE_REQUEST",
  GET_COMPANY_ROLE_SUCCESS: "GET_COMPANY_ROLE_SUCCESS",
  GET_COMPANY_ROLE_FAILURE: "GET_COMPANY_ROLE_FAILURE",

  //client companies and role
  GET_COMPANY_REQUEST: "GET_COMPANY_REQUEST",
  GET_COMPANY_SUCCESS: "GET_COMPANY_SUCCESS",
  GET_COMPANY_FAILURE: "GET_COMPANY_FAILURE",

  //create new user
  CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE: "CREATE_USER_FAILURE",

  //update existing user
  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

  //get region
  GET_REGION_REQUEST: "GET_REGION_REQUEST",
  GET_REGION_SUCCESS: "GET_REGION_SUCCESS",
  GET_REGION_FAILURE: "GET_REGION_FAILURE",

  // login User
  USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAILURE: "USER_LOGIN_FAILURE",

  //update currentLogin
  CURRENT_LOGIN_UPDATE: "CURRENT_LOGIN_UPDATE",

  //update existing user
  UPDATE_PREFERENCE_REQUEST: "UPDATE_PREFERENCE_REQUEST",
  UPDATE_PREFERENCE_SUCCESS: "UPDATE_PREFERENCE_SUCCESS",
  UPDATE_PREFERENCE_FAILURE: "UPDATE_PREFERENCE_FAILURE",

  //reset get one data
  RESET_USER_DATA: "RESET_USER_DATA",

  //update user view
  UPDATE_VIEW_TYPE: 'UPDATE_VIEW_TYPE',

  //update filter toggle
  UPDATE_FILTER_TOGGLE: 'UPDATE_FILTER_TOGGLE',

  //update view and search in chird view
  UPDATE_VIEW_TYPE_AND_SEARCH: 'UPDATE_VIEW_TYPE_AND_SEARCH',

  GET_MANAGER_MAP_REQUEST: "GET_MANAGER_MAP_REQUEST",
  GET_MANAGER_MAP_SUCCESS: "GET_MANAGER_MAP_SUCCESS",
  GET_MANAGER_MAP_FAILURE: "GET_MANAGER_MAP_FAILURE",

};
