import React from "react";
import { useForm } from 'react-hook-form';
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid';
import { withStyles } from "@mui/styles";

//Styles
import AuthenticationStyles from "../../layouts/AuthenticationLayout/AuthenticationLayoutStyles"

// Import Helpers
import validations from "../../utils/validations";

// Import Actions
import { authAction } from "../../redux/actions";

// Import Components
import { Button, TextField, Typography, Link } from "../../components";

// Import Cofig Constants
import { contactus_page_constants, common_constants } from "../../config/constants";

const ContactUs = (props) => {

  // Get Props
  const { classes } = props;

  // Set Form Values
  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: "",
      email: "",
      subject: "",
      message: ""
    }
  });

  // Define Dispatch
  const dispatch = useDispatch();

  // Submit Form
  const onSubmit = (data,e) => {
    if (data) {
      dispatch(authAction.contactUs(data,()=>{
        e.target.reset()
      }));
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" className="pb-1" title={contactus_page_constants.CONTACT_US_HEADER}></Typography>
          <Typography variant="body1" title={contactus_page_constants.CONTACT_US_TEXT}></Typography>
        </Grid>
        <Grid item xs={12} className={`${"contactForm"} ${classes.contactForm}`}>
          <TextField
            id={'txt_name'}
            name="name"
            margin="dense"
            placeholder={contactus_page_constants.CONTACT_US_NAME}
            error={errors.name ? true : false}
            helperText={errors.name?.message}
            inputRef={register({
              validate: {
                ...validations.isRequired()
              }
            })}
          />
        
          <TextField
            id={'txt_email'}
            name="email"
            margin="dense"
            placeholder={contactus_page_constants.CONTACT_US_EMAIL_ID}
            error={errors.email ? true : false}
            helperText={errors.email?.message}
            inputRef={register({
              validate: {
                ...validations.isRequired(),
                ...validations.isEmail()
              }
            })}
          />
        
          <TextField
            id={'txt_subject'}
            name="subject"
            margin="dense"
            placeholder={contactus_page_constants.CONTACT_US_SUBJECT}
            error={errors.subject ? true : false}
            helperText={errors.subject?.message}                            
            inputRef={register({
              validate: {
                ...validations.isRequired()
              }
            })}
          />
          <TextField
            id={'txt_message'}
            name="message"
            margin="dense"
            className={classes.messageContainer}
            multiline={true}
            error={errors.message ? true : false}
            helperText={errors.message?.message}                            
            placeholder={contactus_page_constants.CONTACT_US_MESSAGE}
            inputRef={register({
              validate: {
                ...validations.isRequired()
              }
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems={"center"} justifyContent="space-between">
            <Grid item>
              <Button
                title={contactus_page_constants.CONTACT_US_BTN}
                color={"primary"}
                size={"large"}
                type="submit"
                className={classes.loginBtn}
              />
            </Grid>
            <Grid item align="right">
              <Link
                href="#/login"
                variant="subtitle1"
                color="textSecondary">
                {common_constants.BACK_TO_SIGNIN}
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

// default props
ContactUs.defaultProps = {
  classes: {}
};

// prop types
ContactUs.propTypes = {
  classes: PropTypes.object
};

export default withStyles(AuthenticationStyles)(ContactUs);
