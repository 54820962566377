import React from "react";
import PropTypes from "prop-types";
import FormControlLabel from '@mui/material/FormControlLabel';
import {Switch as Core} from '@mui/material';

/**
 * SwitchComponent
 * @param {*} props
 */

function SwitchComponent({ name, checked, size, className, color, label, disabled, onChange }) {

  return (
    <FormControlLabel
      control={
        <Core
          checked={checked}
          className={className}
          size={size}
          onChange={onChange}
          name={name}
          color={color}
          disabled={disabled} />}
      label={label}
    />
  );
}

// default props
SwitchComponent.defaultProps = {
  name: "",
  checked: "",
  color: "primary",
  label: "",
  className: "",
  size: "small",
  disabled: false,
  onChange: () => { }
};

// prop types
SwitchComponent.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

export const Switch = SwitchComponent;
