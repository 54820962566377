import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@mui/styles";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import PropTypes from "prop-types";

// Import History
import history from "../../config/history";

// Import Components
import { Table, AlertDialog, Typography, Button as Btn, TextField } from "../../components";
import NoRecordsFound from './NoRecordsFound'

//Import Layouts & Styles
import AuditStyles from "./AuditStyles"
import { getTableHeight, getTableRowSize } from '../../config/layout'

// Import Actions
import { auditAction, userAction } from "../../redux/actions";

//Constants
import { audit_page_constants } from "../../config/constants"
import TextareaAutosize from '@mui/material/TextareaAutosize';

function AuditList(props) {
    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes, match, location } = props;

    //get page height
    const height = getTableHeight();
    const tableRowSize = getTableRowSize();

    //get reducer data
    const { currentUser: { preference, id: currentUID } } = useSelector(state => state.user);
    const { search: { page, data } } = useSelector(state => state.audit);
    const { loading } = useSelector(s => s.UI)
    const [openAlert, setOpenAlert] = useState(null)

    //is same page
    const isSamePage = location && location.search === "?page=true" ? true : false;

    //get selected user fields
    const selectHeaders = preference && preference.audit && preference.audit.select ? preference.audit.select : audit_page_constants.HEADER_DEFAULT_SELECTED
    const sortHeaders = preference && preference.audit && preference.audit.sort ? preference.audit.sort : audit_page_constants.HEADER_DEFAULT
    if (!sortHeaders.includes("Old Value")) {
        sortHeaders.push("Old Value")
    }
    if (!sortHeaders.includes("New Value")) {
        sortHeaders.push("New Value")
    }
    let selectedField = [];
    let selectedHeaders = [];
    if (selectHeaders && sortHeaders) {
        sortHeaders.map(i => {
            const isFound = selectHeaders.filter(h => (i === h))
            if (isFound.length) {
                selectedHeaders.push(i)
                selectedField.push(audit_page_constants.HEADER_MAP_CONSTANT[i])
            }
        })
    }

    useEffect(() => {
        if (!isSamePage) {
            dispatch(auditAction.search(1, tableRowSize, "id", "desc", [], [], null, null));
        }
    }, [dispatch]);

    useEffect(() => {
        let { sFields, sValues } = page;
        if (typeof sFields === 'undefined')
            sFields = []
        if (typeof sValues === 'undefined')
            sValues = []
        if (isSamePage) {
            dispatch(auditAction.search(1, tableRowSize, "id", "desc", sFields, sValues, null, null));
            history.replace(location.pathname);
        }
    }, [isSamePage]);

    //Handle Table pagination Event
    const onChangePageTable = (skip, limit) => {
        const { sFields, sValues, sortBy, sort } = page;
        dispatch(auditAction.search(skip, limit, sortBy, sort, sFields, sValues, null, null));
    };

    //Handle Table Sort Request Event
    const onSortRequest = (sortBy, sort) => {
        dispatch(auditAction.search(page.skip, page.limit, sortBy, sort, page.sFields, page.sValues, null, null));
    };

    //Button actions
    const handelActionButtonClick = (field, itemId, item) => {
        switch (field) {
            case "SEARCH":
                dispatch(auditAction.getValues({ id: item["auditID"] ? item["auditID"] : itemId }, (CbData) => {
                    const apiData = CbData["data"] ? CbData["data"] : {};
                    const tableData = { ...item, old_value: apiData["old_value"] || "", new_value: apiData["new_value"] || "" }
                    setOpenAlert({ field, itemId, item: tableData })
                }))
                break;
            default:
                break;
        }
    }

    //Handle Table Search Input Change Event
    const oninputChangeRequest = (searchField, searchKey) => {
        let { sFields, sValues } = page;
        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === "") {
                const index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }
        dispatch(auditAction.search(1, page.limit, page.sortBy, page.sort, page.sFields, page.sValues, null, null));
    };

    //update preference on submit click
    const updatePreference = (sort) => {
        let updatedData = [...sort]
        const allHeaders = preference && preference.audit && preference.audit.sort ? preference.audit.sort : [];
        allHeaders.map(i => {
            if (updatedData.indexOf(i) === -1) {
                updatedData.push(i)
            }
        })
        dispatch(userAction.updatePreference({ id: currentUID, preference: { ...preference, "audit": { ...preference.audit, "sort": updatedData } } }, () => { }));
    }

    return (
        <React.Fragment>
            {page.totalCount > 0 &&
                <Grid item xs={12} className={`${"mt-1"} ${classes.usersTable}`}>
                    <Paper elevation={3} className={classes.cardSectionList} >
                        <Table
                            height={height}
                            classes={classes}
                            data={data}
                            header={selectedHeaders}
                            field={selectedField}
                            search={selectedField}
                            sortBy={page.sortBy}
                            sort={page.sort}
                            onInputChangeRequest={oninputChangeRequest}
                            onChangePagination={onChangePageTable}
                            onSortRequest={onSortRequest}
                            handelActionButtonClick={handelActionButtonClick}
                            updatePreference={updatePreference}
                            page={Number(page.skip)}
                            count={page.count}
                            actions={['SEARCHAUDIT']}
                            sFields={page.sFields}
                            sValues={page.sValues}
                            rowsPerPage={page.limit}
                            reset={page.sFields && page.sFields.length > 0 ? false : true}
                            screenSizeRowPerPage={tableRowSize}
                            loading={loading}
                        >
                        </Table>
                    </Paper>
                </Grid>
            }
            {(page.totalCount === 0 && !loading) && <NoRecordsFound />}
            {openAlert &&
                <Grid className={"alertDialog"}>
                    <AlertDialog
                        fullScreen={false}
                        open={true}

                    >
                        <Grid className={'p-4'} style={{ minWidth: '600px' }}>
                            <Typography variant="h4" component="h3" className="mb-1"> {"Old value"} </Typography>
                            {/* <Typography variant="h6" color="TextSecondary"> {`${openAlert.item.old_value}`} </Typography> */}
                            <TextareaAutosize minRows="2" maxRows="6" className={`${"mb-2"} ${classes.allianceTextarea}`} value={openAlert.item.old_value} readOnly />
                            <Typography variant="h4" component="h3" className="mb-1"> {"New value"} </Typography>
                            <TextareaAutosize minRows="2" maxRows="6" className={classes.allianceTextarea} value={openAlert.item.new_value} readOnly />
                            {/* <Typography variant="h6" color="TextSecondary"> {`${openAlert.item.new_value}`} </Typography> */}
                            <Grid className={'pt-2'} align={"center"}>
                                <Btn
                                    color={"secondary"}
                                    onClick={() => { setOpenAlert(null) }}
                                    title={"Close"}
                                />
                            </Grid>
                        </Grid>
                    </AlertDialog>
                </Grid>
            }
        </React.Fragment>
    );
}
// default props
AuditList.defaultProps = {
    classes: {}
};

// prop types
AuditList.propTypes = {
    classes: PropTypes.object
};
export default withStyles(AuditStyles)(AuditList);
