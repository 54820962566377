import React, { useEffect, useState } from "react";
import propTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@mui/styles";
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import InputAdornment from '@mui/material/InputAdornment';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';

//Import Styles and images
import UsersStyles from '../../UsersStyles'
import { ChevronLeftIcon, ChevronTopIcon, SearchCloseIcon, SearchIcon } from '../../../../assets/svg'

// Import Components
import { TextField, Typography, CheckBox, ToolTip, Button } from "../../../../components";

// Import Actions
import { updateReducerAction, userAction } from "../../../../redux/actions";

//Constants
import { userConstant } from "../../../../redux/constants";
import { user_page_constants } from "../../../../config/constants"

function UserFilters(props) {
    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes, filter: { rolesArr, companyArr } } = props;

    //get reducer data
    let { chordFilter: { rolesSel: roles, companySel: companies }, getAll: { page }, filterToggle, companyCount: { start, end } } = useSelector((state) => state.user);

    //set state
    const [companyArray, setCompanyArray] = useState(companyArr);
    const [search, setSearch] = useState("");

    //filter panels
    const [rolePanel, setRolePanel] = useState('role');
    const [companyPanel, setCompanyPanel] = useState('company');

    //show/hide role panel
    const changeRolePanel = (panel) => (event, newExpanded) => {
        setRolePanel(newExpanded ? panel : false);
    };
    //show/hide company panel
    const changeCompanyPanel = (panel) => (event, newExpanded) => {
        setCompanyPanel(newExpanded ? panel : false);
    };

    //Update company data on length chnage
    useEffect(() => {
        if (companyArr && companyArr.length) {
            setCompanyArray(companyArr)
        }
    }, [companyArr]);

    //role chekbox change
    const handleRoleChange = (rol) => {
        const roleIndex = roles.indexOf(rol);
        if (roleIndex === -1) {
            const updatedData = { rolesSel: [...roles, rol], companySel: companies }
            dispatch(updateReducerAction.update(userConstant.CHORD_FILTER_UPDATE, updatedData, () => {
                dispatch(userAction.getAll({ limit: 20, skip: 1, ...updatedData, search: page.search, sort: page.sort }));
            }));
        } else {
            roles.splice(roleIndex, 1)
            const updatedData = { rolesSel: roles, companySel: companies }
            dispatch(updateReducerAction.update(userConstant.CHORD_FILTER_UPDATE, updatedData, () => {
                dispatch(userAction.getAll({ limit: 20, skip: 1, ...updatedData, search: page.search, sort: page.sort }));
            }))
        }
    }

    //companies checkbox change
    const handleCompanyChange = (com) => {
        const compIndex = companies.indexOf(com);
        if (compIndex === -1) {
            const updatedData = { rolesSel: roles, companySel: [...companies, com] }
            dispatch(updateReducerAction.update(userConstant.CHORD_FILTER_UPDATE, updatedData, () => {
                dispatch(userAction.getAll({ limit: 20, skip: 1, ...updatedData, search: page.search, sort: page.sort }));
            }));
        } else {
            companies.splice(compIndex, 1)
            const updatedData = { rolesSel: roles, companySel: companies }
            dispatch(updateReducerAction.update(userConstant.CHORD_FILTER_UPDATE, updatedData, () => {
                dispatch(userAction.getAll({ limit: 20, skip: 1, ...updatedData, search: page.search, sort: page.sort }));
            }))
        }
    }

    //handle company search
    const handleSearch = (e) => {
        if (e.target.value) {
            setCompanyArray(companyArr.filter(obj => obj && obj.toLowerCase().includes(e.target.value.toLowerCase())))
            setSearch(e.target.value)
        } else {
            setCompanyArray(companyArr)
            setSearch(e.target.value)
        }
        dispatch(updateReducerAction.update(userConstant.UPDATE_COMPANY_COUNT, { start: 0, end: 40 }));
    }

    //Filter toggle
    const handleClickFilter = () => {
        dispatch(updateReducerAction.update(userConstant.UPDATE_FILTER_TOGGLE, !filterToggle));
    }

    const hideSearchandClear = () => {
        setSearch("");
        setCompanyArray(companyArr)
        dispatch(updateReducerAction.update(userConstant.UPDATE_COMPANY_COUNT, { start: 0, end: 40 }));
    }

    //update company count
    const updateCompanyCount = (buttonType) => {
        if (buttonType === "prev") {
            let startVal = start - 40 === 1 ? 0 : start - 40;
            let endVal = end - 40;
            dispatch(updateReducerAction.update(userConstant.UPDATE_COMPANY_COUNT, { start: startVal, end: endVal }));
        } else if (buttonType === "next") {
            let startVal = end + 1;
            let endVal = end + 40;
            dispatch(updateReducerAction.update(userConstant.UPDATE_COMPANY_COUNT, { start: startVal, end: endVal }));
        }
    }

    return (
        <React.Fragment>
            {filterToggle &&
                <Grid item className={classes.filterSection} style={{padding:'8px'}}>
                    <Slide direction="right" in={filterToggle}>
                        <Grid className={classes.tileFilter}>
                            <Typography
                                variant="h6"
                                className={classes.iconLink}
                                onClick={() => { handleClickFilter() }}
                            >
                                <ChevronLeftIcon />
                                {user_page_constants.FILTER}
                            </Typography>
                            <Accordion expanded={rolePanel === 'role'} onChange={changeRolePanel('role')}>
                                <AccordionSummary
                                    expandIcon={<ChevronTopIcon className="expandIcon" />}
                                    aria-controls="panel11a-content"
                                    id="panel11a-header"
                                >
                                    <Typography className={classes.heading} variant={"body1"}>Role</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <List component="nav">
                                        {rolesArr && rolesArr.map((rol) => (
                                            <ListItem button key={rol}>
                                                <CheckBox
                                                    label={rol}
                                                    color="secondary"
                                                    checked={roles.indexOf(rol) > -1}
                                                    handleChange={() => { handleRoleChange(rol) }}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className={classes.stickyHeaders} expanded={companyPanel === 'company'} onChange={changeCompanyPanel('company')}>
                                <AccordionSummary
                                    expandIcon={<ChevronTopIcon className="expandIcon" />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                    className={'companyHeader'}
                                >
                                    <Typography className={classes.heading} variant={"body1"}>Company</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={classes.companyCls}>
                                    <List component="nav" className="searchList">
                                        <ListItem className="searchCompany">
                                            <TextField
                                                id="standard-start-adornment"
                                                variant="outlined"
                                                size="small"
                                                margin="none"
                                                fullWidth={true}
                                                placeholder="Search Company"
                                                className={classes.searchField}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">{search === "" ? <SearchIcon className={classes.seachIcon} /> : <IconButton
                                                        onClick={() => hideSearchandClear()}
                                                        style={{ padding: '0px' }}
                                                        size="large"><SearchCloseIcon /></IconButton>}</InputAdornment>,
                                                }}
                                                value={search}
                                                onChange={(e) => { handleSearch(e) }}
                                            />
                                        </ListItem>
                                        {companies && companies.map((com, ind) => (
                                            <React.Fragment key={com}>
                                                <ToolTip title={com}>
                                                    <ListItem button key={com} className={`${classes.listOneLine} ${"listOneLine"}`}>
                                                        <CheckBox
                                                            label={com}
                                                            color="secondary"
                                                            checked={companies.indexOf(com) > -1}
                                                            handleChange={() => { handleCompanyChange(com) }}
                                                        />
                                                    </ListItem>
                                                </ToolTip>
                                            </React.Fragment>
                                        ))}
                                        {companyArray && companyArray.map((com, ind) => (
                                            <React.Fragment key={com}>
                                                {(ind >= start && ind <= end) && companies.indexOf(com) === -1 &&
                                                    <ToolTip title={com}>
                                                        <ListItem button key={com} className={`${classes.listOneLine} ${"listOneLine"}`}>
                                                            <CheckBox
                                                                label={com}
                                                                checked={companies.indexOf(com) > -1}
                                                                handleChange={() => { handleCompanyChange(com) }}
                                                            />
                                                        </ListItem>
                                                    </ToolTip>
                                                }
                                            </React.Fragment>
                                        ))}
                                        <Grid container justifyContent="flex-end" className="p-1">
                                            {start > 0 && <Button onClick={() => { updateCompanyCount("prev") }} className={classNames(classes.filterNameChip)} variant="contained" color="primary" size="small" title={"previous"} />}
                                            {end < companyArray.length && <Button onClick={() => { updateCompanyCount("next") }} className={`${"ml-1"} ${classNames(classes.filterNameChip)}`} variant="contained" color="primary" size="small" title={"Next"} />}
                                        </Grid>
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Slide>
                </Grid>
            }

            {!filterToggle &&
                <Slide direction="right" in={!filterToggle}>
                    <Grid className={`${"filterOpenIcon"} ${classes.filterOpenIcon}`} onClick={() => { handleClickFilter() }}>
                        <IconButton
                            className={"filterOpenIconBtn"}
                            onClick={() => { handleClickFilter() }}
                            size="large">
                            <ChevronRightIcon />
                        </IconButton>
                        <Grid className={classes.filterOpenIconText} style={{fontSize:'14px'}}>
                            {"FILTER"}
                        </Grid>
                    </Grid>
                </Slide>
            }
        </React.Fragment>
    );
}

// default props
UserFilters.defaultProps = {
    classes: {},
    filter: {},
};

// prop types
UserFilters.propTypes = {
    classes: propTypes.object,
    filter: propTypes.object
};

// export default UserList;
export default withStyles(UsersStyles)(UserFilters);