import { alertConstant, templateConstant, loaderConstant } from "../constants";
import { templateService } from "../services";
import { baseAction } from "./baseAction";

const { request, success, failure } = baseAction;

// Export All service Actions Functions
export const templateAction = {
    getAllEmail,
    getOneEmail,
    deleteEmail,
    addOrUpdateEmail,

    getAllApplication,
    getOneAppTemplate,
    addOrUpdateAppTemplate,
    deleteAppTemplate,

    getEmailTemplateName,
    getAggrementName,
    getDisclosureName,

    getAllCriteria,
    getOneCriteria,
    deleteCriteria,
    addOrUpdateCriteria,

    getAllAgreement,
    getOneAgreement,
    deleteAgreement,
    addOrUpdateAgreement,

    getAllDocument,
    getOneDocument,
    deleteDocument,
    addOrUpdateDocument,
};

/**
* Get All email templates
*/
function getAllEmail(params) {
    return (dispatch) => {
        if (params && !params["loader"]) {
            dispatch(request(loaderConstant.LOADING_TRUE));
        } else {
            dispatch(request(loaderConstant.LAZY_LOADING_TRUE));
        }
        dispatch(request(templateConstant.GET_EMAIL_TEMPLATE_REQUEST));
        templateService.getAllEmail(params).then(
            (data) => {
                dispatch(success(templateConstant.GET_EMAIL_TEMPLATE_SUCCESS, data));
                if (params && !params["loader"]) {
                    dispatch(request(loaderConstant.LOADING_FALSE));
                } else {
                    dispatch(request(loaderConstant.LAZY_LOADING_FALSE));
                }
            }, (error) => {
                dispatch(failure(templateConstant.GET_EMAIL_TEMPLATE_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                if (params && !params["loader"]) {
                    dispatch(request(loaderConstant.LOADING_FALSE));
                } else {
                    dispatch(request(loaderConstant.LAZY_LOADING_FALSE));
                }
            }
        );
    };
}

/**
* Get selected email template to edit
*/
function getOneEmail(params, successCallback = () => { }) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(templateConstant.GET_ONE_EMAIL_REQUEST));
        templateService.getOneEmail(params).then(
            (data) => {
                dispatch(success(templateConstant.GET_ONE_EMAIL_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                successCallback()
            }, (error) => {
                dispatch(failure(templateConstant.GET_ONE_EMAIL_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}

/**
* Delete selected email template to edit
*/
function deleteEmail(params, successCallback = () => { }) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(templateConstant.DELETE_ONE_EMAIL_REQUEST));
        templateService.deleteEmail(params).then(
            (data) => {
                dispatch(success(templateConstant.DELETE_ONE_EMAIL_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
                successCallback()
            }, (error) => {
                dispatch(failure(templateConstant.DELETE_ONE_EMAIL_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}

/**
* add or update email template
*/
function addOrUpdateEmail(params, successCallback = () => { }) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(templateConstant.ADD_UPDATE_EMAIL_REQUEST));
        templateService.addOrUpdateEmail(params).then(
            (data) => {
                dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
                dispatch(success(templateConstant.ADD_UPDATE_EMAIL_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                successCallback()
            }, (error) => {
                dispatch(failure(templateConstant.ADD_UPDATE_EMAIL_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}

/**
* Get All application templates
*/
function getAllApplication(params) {
    return (dispatch) => {
        if (params && !params["loader"]) {
            dispatch(request(loaderConstant.LOADING_TRUE));
        } else {
            dispatch(request(loaderConstant.LAZY_LOADING_TRUE));
        }
        dispatch(request(templateConstant.GET_APP_TEMPLATE_REQUEST));
        templateService.getAllApplication(params).then(
            (data) => {
                dispatch(success(templateConstant.GET_APP_TEMPLATE_SUCCESS, data));
                if (params && !params["loader"]) {
                    dispatch(request(loaderConstant.LOADING_FALSE));
                } else {
                    dispatch(request(loaderConstant.LAZY_LOADING_FALSE));
                }
            }, (error) => {
                dispatch(failure(templateConstant.GET_APP_TEMPLATE_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                if (params && !params["loader"]) {
                    dispatch(request(loaderConstant.LOADING_FALSE));
                } else {
                    dispatch(request(loaderConstant.LAZY_LOADING_FALSE));
                }
            }
        );
    };
}

/**
* Get selected app template to edit
*/
function getOneAppTemplate(params, successCallback = () => { }) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(templateConstant.GET_ONE_APP_REQUEST));
        templateService.getOneAppTemplate(params).then(
            (data) => {
                dispatch(success(templateConstant.GET_ONE_APP_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                successCallback()
            }, (error) => {
                dispatch(failure(templateConstant.GET_ONE_APP_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}

/**
* Delete selected app template to edit
*/
function deleteAppTemplate(params, successCallback = () => { }) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(templateConstant.DELETE_ONE_APP_REQUEST));
        templateService.deleteAppTemplate(params).then(
            (data) => {
                dispatch(success(templateConstant.DELETE_ONE_APP_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
                successCallback()
            }, (error) => {
                dispatch(failure(templateConstant.DELETE_ONE_APP_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}

/**
* add or update app template
*/
function addOrUpdateAppTemplate(params, successCallback = () => { }) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(templateConstant.ADD_UPDATE_APP_REQUEST));
        templateService.addOrUpdateAppTemplate(params).then(
            (data) => {
                dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
                dispatch(success(templateConstant.ADD_UPDATE_APP_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                successCallback()
            }, (error) => {
                dispatch(failure(templateConstant.ADD_UPDATE_APP_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}

/**
* get all email tempalte name
*/
function getEmailTemplateName(successCallback = () => { }) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(templateConstant.GET_EMAIL_NAME_REQUEST));
        templateService.getEmailTemplateName().then(
            (data) => {
                dispatch(success(templateConstant.GET_EMAIL_NAME_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                successCallback()
            }, (error) => {
                dispatch(failure(templateConstant.GET_EMAIL_NAME_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}

/**
* get all aggrement name
*/
function getAggrementName(successCallback = () => { }) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(templateConstant.GET_AGGREMENT_NAME_REQUEST));
        templateService.getAggrementName().then(
            (data) => {
                dispatch(success(templateConstant.GET_AGGREMENT_NAME_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                successCallback()
            }, (error) => {
                dispatch(failure(templateConstant.GET_AGGREMENT_NAME_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}

/**
* get all disclosure name
*/
function getDisclosureName(successCallback = () => { }) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(templateConstant.GET_DISCLOSURE_NAME_REQUEST));
        templateService.getDisclosureName().then(
            (data) => {
                dispatch(success(templateConstant.GET_DISCLOSURE_NAME_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                successCallback()
            }, (error) => {
                dispatch(failure(templateConstant.GET_DISCLOSURE_NAME_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}




/**
* Get All criteria templates
*/
function getAllCriteria(params) {
    return (dispatch) => {
        if (params && !params["loader"]) {
            dispatch(request(loaderConstant.LOADING_TRUE));
        } else {
            dispatch(request(loaderConstant.LAZY_LOADING_TRUE));
        }
        dispatch(request(templateConstant.GET_CRITERIA_REQUEST));
        templateService.getAllCriteria(params).then(
            (data) => {
                dispatch(success(templateConstant.GET_CRITERIA_SUCCESS, data));
                if (params && !params["loader"]) {
                    dispatch(request(loaderConstant.LOADING_FALSE));
                } else {
                    dispatch(request(loaderConstant.LAZY_LOADING_FALSE));
                }
            }, (error) => {
                dispatch(failure(templateConstant.GET_CRITERIA_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                if (params && !params["loader"]) {
                    dispatch(request(loaderConstant.LOADING_FALSE));
                } else {
                    dispatch(request(loaderConstant.LAZY_LOADING_FALSE));
                }
            }
        );
    };
}

/**
* Get selected Criteria to edit
*/
function getOneCriteria(params, successCallback = () => { }) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(templateConstant.GET_ONE_CRITERIA_REQUEST));
        templateService.getOneCriteria(params).then(
            (data) => {
                dispatch(success(templateConstant.GET_ONE_CRITERIA_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                successCallback()
            }, (error) => {
                dispatch(failure(templateConstant.GET_ONE_CRITERIA_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}

/**
* Delete selected criteria template to edit
*/
function deleteCriteria(params, successCallback = () => { }) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(templateConstant.DELETE_ONE_CRITERIA_REQUEST));
        templateService.deleteCriteria(params).then(
            (data) => {
                dispatch(success(templateConstant.DELETE_ONE_CRITERIA_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
                successCallback()
            }, (error) => {
                dispatch(failure(templateConstant.DELETE_ONE_CRITERIA_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}

/**
* add or update criteria template
*/
function addOrUpdateCriteria(params, successCallback = () => { }) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(templateConstant.ADD_UPDATE_CRITERIA_REQUEST));
        templateService.addOrUpdateCriteria(params).then(
            (data) => {
                dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
                dispatch(success(templateConstant.ADD_UPDATE_CRITERIA_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                successCallback()
            }, (error) => {
                dispatch(failure(templateConstant.ADD_UPDATE_CRITERIA_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}




/**
* Get All Agreement templates
*/
function getAllAgreement(params) {
    return (dispatch) => {
        if (params && !params["loader"]) {
            dispatch(request(loaderConstant.LOADING_TRUE));
        } else {
            dispatch(request(loaderConstant.LAZY_LOADING_TRUE));
        }
        dispatch(request(templateConstant.GET_AGREEMENT_REQUEST));
        templateService.getAllAgreement(params).then(
            (data) => {
                dispatch(success(templateConstant.GET_AGREEMENT_SUCCESS, data));
                if (params && !params["loader"]) {
                    dispatch(request(loaderConstant.LOADING_FALSE));
                } else {
                    dispatch(request(loaderConstant.LAZY_LOADING_FALSE));
                }
            }, (error) => {
                dispatch(failure(templateConstant.GET_AGREEMENT_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                if (params && !params["loader"]) {
                    dispatch(request(loaderConstant.LOADING_FALSE));
                } else {
                    dispatch(request(loaderConstant.LAZY_LOADING_FALSE));
                }
            }
        );
    };
}

/**
* Get selected AGREEMENT to edit
*/
function getOneAgreement(params, successCallback = () => { }) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(templateConstant.GET_ONE_AGREEMENT_REQUEST));
        templateService.getOneAgreement(params).then(
            (data) => {
                dispatch(success(templateConstant.GET_ONE_AGREEMENT_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                successCallback()
            }, (error) => {
                dispatch(failure(templateConstant.GET_ONE_AGREEMENT_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}

/**
* Delete selected AGREEMENT template to edit
*/
function deleteAgreement(params, successCallback = () => { }) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(templateConstant.DELETE_ONE_AGREEMENT_REQUEST));
        templateService.deleteAgreement(params).then(
            (data) => {
                dispatch(success(templateConstant.DELETE_ONE_AGREEMENT_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
                successCallback()
            }, (error) => {
                dispatch(failure(templateConstant.DELETE_ONE_AGREEMENT_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}

/**
* add or update AGREEMENT template
*/
function addOrUpdateAgreement(params, successCallback = () => { }) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(templateConstant.ADD_UPDATE_AGREEMENT_REQUEST));
        templateService.addOrUpdateAgreement(params).then(
            (data) => {
                dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
                dispatch(success(templateConstant.ADD_UPDATE_AGREEMENT_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                successCallback()
            }, (error) => {
                dispatch(failure(templateConstant.ADD_UPDATE_AGREEMENT_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}



/**
* Get All Document templates
*/
function getAllDocument(params) {
    return (dispatch) => {
        if (params && !params["loader"]) {
            dispatch(request(loaderConstant.LOADING_TRUE));
        } else {
            dispatch(request(loaderConstant.LAZY_LOADING_TRUE));
        }
        dispatch(request(templateConstant.GET_DOCUMENT_REQUEST));
        templateService.getAllDocument(params).then(
            (data) => {
                dispatch(success(templateConstant.GET_DOCUMENT_SUCCESS, data));
                if (params && !params["loader"]) {
                    dispatch(request(loaderConstant.LOADING_FALSE));
                } else {
                    dispatch(request(loaderConstant.LAZY_LOADING_FALSE));
                }
            }, (error) => {
                dispatch(failure(templateConstant.GET_DOCUMENT_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                if (params && !params["loader"]) {
                    dispatch(request(loaderConstant.LOADING_FALSE));
                } else {
                    dispatch(request(loaderConstant.LAZY_LOADING_FALSE));
                }
            }
        );
    };
}

/**
* Get selected Document to edit
*/
function getOneDocument(params, successCallback = () => { }) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(templateConstant.GET_ONE_DOCUMENT_REQUEST));
        templateService.getOneDocument(params).then(
            (data) => {
                dispatch(success(templateConstant.GET_ONE_DOCUMENT_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                successCallback()
            }, (error) => {
                dispatch(failure(templateConstant.GET_ONE_DOCUMENT_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}

/**
* Delete selected Document template to edit
*/
function deleteDocument(params, successCallback = () => { }) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(templateConstant.DELETE_ONE_DOCUMENT_REQUEST));
        templateService.deleteDocument(params).then(
            (data) => {
                dispatch(success(templateConstant.DELETE_ONE_DOCUMENT_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
                successCallback()
            }, (error) => {
                dispatch(failure(templateConstant.DELETE_ONE_DOCUMENT_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}

/**
* add or update Document template
*/
function addOrUpdateDocument(params, successCallback = () => { }) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(templateConstant.ADD_UPDATE_DOCUMENT_REQUEST));
        templateService.addOrUpdateDocument(params).then(
            (data) => {
                dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
                dispatch(success(templateConstant.ADD_UPDATE_DOCUMENT_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                successCallback()
            }, (error) => {
                dispatch(failure(templateConstant.ADD_UPDATE_DOCUMENT_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}
