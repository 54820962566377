import palette from './palette';
const overrides = {
  MuiOutlinedInput: {
    input: {
      fontSize: 14,
      // padding: '15px 14px',
    },
    root: {
      borderRadius: "30px",
      borderColor: "rgba(0, 0, 0, 0.23) !important",
      backgroundColor: "#fff",
      border: '1px solid',
      '&:hover': {
        borderColor: 'rgb(95 95 95 / 87%)!important'
      },
      '&.Mui-error': {
        border: '1px solid #f44336',
        borderColor: '#f44336 !important'
      },
      '&.Mui-focused': {
        borderColor: `${palette.primary.main} !important` 
      },
      // '&:hover $notchedOutline': {
      //   borderColor: 'rgb(95 95 95 / 87%)'
      // },

      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      }
    }
  },
  MuiPaper: {
    root: {

    }
  },
  MuiFormHelperText: {
    root: {
      fontSize: '12px !important',
      marginTop: '2px !important',
      marginLeft: '0px !important'
    }
  },


  MuiButton: {
    root: {
      textTransform: "none",
      '&.Mui-disabled': {
        color: '#dbdbdb  !important',
        backgroundColor: '#f0f0f0 !important',
        cursor: 'no-drop',
        pointerEvents: 'initial'
      }
    },
    containedSizeLarge: {
      height: 45,
      fontSize: 16,
      padding: '8px 20px',
    },
    containedSizeSmall: {
      height: 34,
      fontSize: 14,
      padding: '8px 20px',
    },
    contained: {
      boxShadow: 'none',
      borderRadius: "50px",
      minWidth: '80px',
      lineHeight: '1.3',
      textTransform: "uppercase",
    },

    containedSecondary: {
      backgroundColor: '#E6E5E5',
      color: '#878083',
      '&:hover': {
        backgroundColor: '#cccccc',
        color: palette.greyshades.dark,
      }
    },

  },
  MuiIconButton: {
    root: {
      padding: '6px',
    }
  },
  MuiAppBar: {
    colorPrimary: {
      backgroundColor: palette.greyshades.main,
    },
    root: {
      boxShadow: 'none',
    },
  },
  MuiToolbar: {
    root: {
      minHeight: '50px!important',
    },
    gutters: {
      paddingLeft: '20px!important',
      paddingRight: '20px!important',
    },
  },
  MuiTableCell: {
    root: {
      fontSize: '14px',
      fontWeight: '500',
    },
  },
  MuiTabs: {
    root: {
      minHeight: '40px !important',
      height: '40px !important'
    },
    minHeight: '40px !important',
    height: '40px !important',
    scroller: {
      borderBottom: '1px solid #8080801c',
      '& .Mui-selected': {
        '& .MuiTypography-colorTextSecondary': {
          color: `${palette.primary.main} !important`
        }
      }
    }
  },
  MuiTab: {
    root: {
      minHeight: '40px !important',
      textTransform: 'capitalize'
    }
  },
  MuiSelect: {
    root: {
      '&:focus': {
        borderRadius: 30
      }
    }
  },
  MuiTypography: {
    textPrimary: palette.greyshades.main,
    textSecondary: palette.greyshades.dark,
    root: {
      // color: `${palette.greyshades.dark} !important`,
      '&.MuiTypography-colorTextSecondary': {
        color: `${palette.greyshades.dark} !important`
      }
    }
  },
  MuiLink: {
    underlineHover: {
      '&:hover': {
        textDecoration: 'none',
        color: `${palette.primary.dary} !important`
      }
    }
  },
  MuiInputAdornment: {
    positionStart: {
      color: '#AEAEAE',
    },
    positionEnd: {
      color: '#cccccc',
    },
  },
  MuiInputBase: {
    root: {
      color: '#6d6d6d'
    }
  }, 
  MuiStepLabel: {
    root: {
      flexDirection: 'column'
    }
  },
  MuiStepButton: {
    root: {
      padding: '15px 16px'
    }
  },
  MuiStepper: {
    root: {
      '& .MuiStepLabel-label': {
        marginTop: 10
      },
      '& .MuiStepConnector-lineHorizontal': {
        borderTopWidth: 6,
        borderColor: palette.greyshades.light2
      }
    }
  }
}

export default overrides;