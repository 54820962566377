
const validations = {

  isRequired: (text = "This field is required") => {
    return {
      required: (value) => {
        let regX = /^\s+$/
        if(value && regX.test(value)){
          return text    
        }else{
          return value ? true : text
        }
      }
    }
  },

  isEmail: (text = "Please enter valid email address") => {
    return {
      isEmail: (value) => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const result = regex.test(String(value).toLowerCase());
        return result ? true : text
      }
    }
  },

  isPasswordCriteriaMet: (text = "Password is insecure") => {
    return {
      isPasswordCriteria: (value) => {
        if (value.length < 6) {
          return "password length should be greater than 6";
        } else if (value.length > 50) {
          return "password length should be less than 50";
        } else if (value.search(/[!@#$^&*()_]/) === -1) {
          return "password should contain atleast one special character";
        } else if (value.search(/[a-z]/) === -1) {
          return "password should contain one lower case";
        } else if (value.search(/\d/) === -1) {
          return "password should contain one number";
        } else if (value.search(/[A-Z]/) === -1) {
          return "password should contain one upper case";
        } else { return true; }
      }
    }
  }
}

export default validations;