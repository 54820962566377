/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Grid } from '@mui/material';
import { withStyles } from "@mui/styles";

// Layout Style
import PrivateLayoutStyle from "./PrivateLayoutStyle.jsx";

// Import Child Container
import Header from "../../containers/Header/Header";

// Import Config Layout
import { getRootContainerHeight } from "../../config/layout";

const PrivateLayout = (props) => {
  const { match, classes } = props;

  // Set Container Height
  const height = getRootContainerHeight();

  /**
 * Handle Header Menu Action
 */
  return (
    <Grid container>
      <Grid item xs={12}>
        <Header match={match} {...props} />
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          className={`${classes.container}`}
          style={{ height: height }}
          alignContent="baseline">
          {props.children}
        </Grid>
      </Grid>
    </Grid>
  );
}

// Define Props Types
PrivateLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  user: PropTypes.object.isRequired,
  access: PropTypes.bool.isRequired
};

// Default props
PrivateLayout.defaultProps = {
  classes: {},
  children: {},
  user: {},
  access: false
};

export default withStyles(PrivateLayoutStyle)(PrivateLayout);
