import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Grid from '@mui/material/Grid';

//Styles
import SettingsStyles from "../SettingsStyles"

// Import Components
import { Button, TextFieldWithoutRef, Typography, CheckBox } from "../../../components";

// Import History
import history from "../../../config/history";

// Import Cofig Constants
import { settings_page_constants, user_page_constants } from "../../../config/constants";
import { settingsConstant } from "../../../redux/constants";

// Import Actions
import { settingsAction, updateReducerAction } from "../../../redux/actions";

const Square = (props) => {
    const dispatch = useDispatch()

    const { configuration: { square } } = useSelector(state => state.settings);
    const [errors, setErrors] = useState({});
    const [updateButton, setUpdateButton] = useState(false)

    /**
  *Handle Cancel Navigage to Tab based on Roles
  */
    const handleCancel = () => {
        history.go(-1)
    };

    const handleChange = (e) => {
        setUpdateButton(true)
        square[e.target.name] = e.target.value
        dispatch(updateReducerAction.update(settingsConstant.UPDATE_SQUARE_REQUEST, square, () => { }));
    }

    const handleSubmit = () => {
        let errors = {}
        if (!(square["s_account"].trim())) {
            errors["s_account"] = true
        }
        if (!(square["s_loc_key"].trim())) {
            errors["s_loc_key"] = true
        }
        if (!(square["s_api_key"].trim())) {
            errors["s_api_key"] = true
        }
        setErrors(errors)
        if (Object.keys(errors).length === 0) {
            dispatch(settingsAction.saveConfiguration({ key: "square", value: square }, () => {
                setUpdateButton(false)
            }));
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={6} align="left">
                        <Grid item xs={11}>
                            <Typography variant={"body1"} className={"textFieldLabel"}>{"Access Token"}</Typography>
                            
                            <TextFieldWithoutRef
                                name={"s_api_key"}
                                size={"small"}
                                margin={"none"}
                                value={square.s_api_key ? square.s_api_key : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.s_api_key ? true : false}
                                helperText={errors.s_api_key ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} align="right">
                        <Grid item xs={11}>
                            <Typography variant={"body1"} className={"textFieldLabel"}>{"Application ID"}</Typography>
                            <TextFieldWithoutRef
                                name={"s_account"}
                                size={"small"}
                                margin={"none"}
                                value={square.s_account ? square.s_account : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.s_account ? true : false}
                                helperText={errors.s_account ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} align="left">
                        <Grid item xs={11}>
                            <Typography variant={"body1"} className={"textFieldLabel"}>{"Location ID"}</Typography>
                            <TextFieldWithoutRef
                                name={"s_loc_key"}
                                size={"small"}
                                margin={"none"}
                                value={square.s_loc_key ? square.s_loc_key : ""}
                                handleChange={(e) => handleChange(e)}
                                error={errors.s_loc_key ? true : false}
                                helperText={errors.s_loc_key ? "This is required" : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="mt-1">
                        <Grid item xs={4} align="left">
                            <Button color="primary" size="small" title={"UPDATE"} className="mr-1" onClick={handleSubmit} disabled={updateButton ? false : true}></Button>
                            <Button color="secondary" size="small" title={user_page_constants.CANCEL} onClick={handleCancel}></Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

// default props
Square.defaultProps = {
    classes: {},
};

// prop types
Square.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(SettingsStyles)(Square);