import { alertConstant, tenantAppConstant, loaderConstant } from "../constants";
import { tenantAppService } from "../services";
import { baseAction } from "./baseAction";

// Import History
import history from "../../config/history";

const { request, success, failure } = baseAction;

// Export All Actions Functions
export const tenantAppAction = {
  getApplicationData,
  submitApplication,

  checkPasscodeData
};

/**
 * Get APPLICATION details
 */
function getApplicationData(params, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(tenantAppConstant.APP_GET_ONE_REQUEST));
    dispatch(request(loaderConstant.LOADING_TRUE));
    tenantAppService.getApplicationData(params).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(success(tenantAppConstant.APP_GET_ONE_SUCCESS, data));
        successCallback();
      },
      (error) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(failure(tenantAppConstant.APP_GET_ONE_FAILURE, error));
        if (error && error !== "Invalid URL") {
          dispatch(failure(alertConstant.ALERT_ERROR, error));
        }
      }
    );
  };
}

/**
 * submit APPLICATION details
 */
function submitApplication(params, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(tenantAppConstant.APP_SUBMIT_REQUEST));
    dispatch(request(loaderConstant.LOADING_TRUE));
    tenantAppService.submitApplication(params).then(
      (data) => {
        dispatch(success(tenantAppConstant.APP_SUBMIT_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        if (data.status === "success") {
          history.push("/submitSuccess")
        }
        if(data.status === "failed" && data.message === "Duplicate submission"){
          history.push("/submitSuccess?duplicate=true")
        }
        dispatch(request(loaderConstant.LOADING_FALSE));
        successCallback();
      },
      (error) => {
        successCallback();
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(failure(tenantAppConstant.APP_SUBMIT_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

/**
 * Check Passcode Data
 */
function checkPasscodeData(params, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(tenantAppConstant.CHECK_PASS_REQUEST));
    // dispatch(request(loaderConstant.LOADING_TRUE));
    tenantAppService.checkPasscodeData(params).then((data) => {
        // dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(success(tenantAppConstant.CHECK_PASS_SUCCESS, data));
        successCallback(data);
      },(error) => {
        // dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(failure(tenantAppConstant.CHECK_PASS_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}