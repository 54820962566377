import * as apihelper from "../../utils/apiHelper";

/**
 * Export All Authentication Service Functions
 */
export const applicationService = {
    getAll,
    search,
    getApp,
    createApp,
    remove,
    updateApp,
    updateIntro,
    updateCriteria,
    updatePayment,

    getServiceName,
    getCriteriaName,
    getManagerName,
    getClientCommon,

    sendInvitation,
    getLink
};

/**
 * Get All application for chord view
 */
async function getAll(data) {
    const response = await apihelper.postRequest(`application/getAll`, data);
    return response;
}

/**
 * Get All Application for list
 */
async function search(skip, limit, sortBy, sort, sFields, sValues) {
    const response = await apihelper.postRequest(`application/search?skip=${skip}&limit=${limit}&sortBy=${sortBy}&sort=${sort}`, { sFields, sValues });
    return response;
}

/**
 * Get Application By Id
 */
async function getApp(params) {
    const response = await apihelper.postRequest(`application/getApp`, params);
    return response;
}

/**
 * create Application
 */
async function createApp(data) {
    const response = await apihelper.postRequest("application/createApp", data);
    return response;
}

/**
 * update Application
 */
async function updateApp(data) {
    const response = await apihelper.postRequest("application/updateApp", data);
    return response;
}

/**
 * update Application introduction
 */
async function updateIntro(data) {
    const response = await apihelper.postRequest("application/updateIntro", data);
    return response;
}

/**
 * update Application critertia
 */
async function updateCriteria(data) {
    const response = await apihelper.postRequest("application/updateCriteria", data);
    return response;
}

/**
 * update Application payment
 */ 
async function updatePayment(data) {
    const response = await apihelper.postRequest("application/updatePayment", data);
    return response;
}

/**
 * Delete application Service
 */
async function remove(data) {
    const response = await apihelper.deleteRequest("application", data);
    return response;
}

/**
 * get Criteria Name
 */
async function getCriteriaName() {
    const response = await apihelper.postRequest("application/criteriaName");
    return response;
}

/**
 * get Manager Name
 */
async function getManagerName() {
    const response = await apihelper.postRequest("application/managerName");
    return response;
}

/**
 * get Service Name
 */
async function getServiceName() {
    const response = await apihelper.postRequest("application/serviceName");
    return response;
}

/**
 * get Client common
 */
async function getClientCommon(data) {
    const response = await apihelper.postRequest("application/clientCommon",data);
    return response;
}

/**
 *send email invitation
 */
async function sendInvitation(data) {
    const response = await apihelper.postRequest(`application/invite`, data);
    return response;
}

/**
 *get link
 */
async function getLink(data) {
    const response = await apihelper.postRequest(`application/getLink`, data);
    return response;
}