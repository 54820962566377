import * as apihelper from "../../utils/apiHelper";

/**
 * Export All Authentication Service Functions
 */
export const submissionService = {
    search,
    getAttachmentValues,
    remove,
    refundPayment,
    downloadAttachment,
    getRetrySubmission,
    retrySubmission
};

/**
 * Get the submission list
 */
async function search(skip, limit, sortBy, sort, sFields, sValues) {
    const response = await apihelper.postRequest(`submission/search?skip=${skip}&limit=${limit}&sortBy=${sortBy}&sort=${sort}`, { sFields, sValues, filter: false });
    return response;
}

/**
 * get attachment data
 */
 async function getAttachmentValues(data) {
    const response = await apihelper.postRequest("submission/attachPdf", data);
    return response;
}


/**
 * Delete submission
 * @param id
 */
async function remove(data) {
    const response = await apihelper.deleteRequest("submission/deleteSubmission", data);
    return response;
}

/**
 * Refund payment
 * @param id
 */
async function refundPayment(data) {
    const response = await apihelper.postRequest("submission/refundPayment", data);
    return response;
}

/**
 * Download attachment
 * @param id
 */
async function downloadAttachment(data) {
    const response = await apihelper.postRequest("submission/downloadAttachment", data);
    return response;
}

/**
 * Get Retry submision details
 */
async function getRetrySubmission(data) {
    const response = await apihelper.postRequest(`submission/getRetrySubmission`, data);
    return response;
}

/**
 *  Retry submision details - Pass XML to TAZworks
 */
async function retrySubmission(data) {
    const response = await apihelper.postRequest(`submission/retrySubmission`, data);
    return response;
}