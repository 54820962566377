import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

//import components
import EmailTemplates from "./EmailTemplate/EmailTemplate";
import CriteriaTemplates from "./CriteriaTemplate/CriteriaTemplates";
import ApplicationTemplates from "./ApplicationTemplate/ApplicationTemplates";
import Agreement from './Agreement/Agreement'
import Documents from "./Documents/Documents";

export default function TabRouter(props) {
  const { match } = props;
  return (
    <Switch>
      <Route
        exact
        path={`${match.url}`}
        render={(props) => {
          return <ApplicationTemplates match={match} {...props} />;
        }}
      />
      <Route
        exact
        path={`${match.url}/email`}
        render={(props) => {
          return <EmailTemplates match={match} {...props} />;
        }}
      />
      <Route
        exact
        path={`${match.url}/criteria`}
        render={(props) => {
          return <CriteriaTemplates match={match} {...props} />;
        }}
      />
      <Route
        exact
        path={`${match.url}/agreement`}
        render={(props) => {
          return <Agreement match={match} {...props} />;
        }}
      />
      <Route
        exact
        path={`${match.url}/document`}
        render={(props) => {
          return <Documents match={match} {...props} />;
        }}
      />
    </Switch>
  );
}

//default props
TabRouter.defaultProps = {
  match: {},
  classes: {},
};

//Component Property Types
TabRouter.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object,
};
