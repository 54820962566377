import { authConstant } from "../constants";

// Set Initial State
const initialState = {
  login: {
    loading: false
  },
  forgotPassword: {
    loading: false
  },
  resetPassword: {
    loading: false
  },
  contactUs: {
    loading: false
  },
  tokenExpiryMsg: null,
  address: {
    address: "304 Main Ave. South, Suite 101 Renton, Washington 98057",
    phone: "425-271-8065",
    fax: "425-227-9246",
    name:"Alliance 2020 Inc"
  }
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {

    // Login 
    case authConstant.LOGIN_REQUEST:
      return {
        ...state,
        login: {
          loading: true
        }
      };
    case authConstant.LOGIN_SUCCESS:
      return {
        ...state,
        login: {
          loading: false
        }
      };
    case authConstant.LOGIN_FAILURE:
      return {
        ...state,
        login: {
          loading: false
        }
      };

    // Logout
    case authConstant.LOGOUT_REQUEST:
      return {
        ...state
      };
    case authConstant.LOGOUT_SUCCESS:
      return {
        ...state
      };
    case authConstant.LOGOUT_FAILURE:
      return {
        ...state
      };

    // Reset Password
    case authConstant.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPassword: {
          loading: true
        }
      };
    case authConstant.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: {
          loading: false
        }
      };
    case authConstant.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPassword: {
          loading: false
        }
      };

    // Forgot Password
    case authConstant.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPassword: {
          loading: true
        }
      };
    case authConstant.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword: {
          loading: false
        }
      };
    case authConstant.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPassword: {
          loading: false
        }
      };

    // Contact Us
    case authConstant.CONTACT_US_REQUEST:
      return {
        ...state,
        contactUs: {
          loading: true
        }
      };
    case authConstant.CONTACT_US_SUCCESS:
      return {
        ...state,
        contactUs: {
          loading: false
        }
      };
    case authConstant.CONTACT_US_FAILURE:
      return {
        ...state,
        contactUs: {
          loading: false
        }
      };

    //reset password token check
    case authConstant.TOKEN_CHECK_REQUEST:
      return {
        ...state,
        tokenExpiryMsg: null
      };
    case authConstant.TOKEN_CHECK_SUCCESS:
      return {
        ...state,
        tokenExpiryMsg: action.data
      };
    case authConstant.TOKEN_CHECK_FAILURE:
      return {
        ...state,
        tokenExpiryMsg: null
      };

    //reset password token check
    case authConstant.GET_ADDRESS_REQUEST:
      return {
        ...state,
        address: initialState.address
      };
    case authConstant.GET_ADDRESS_SUCCESS:
      return {
        ...state,
        address: action.data.data
      };
    case authConstant.GET_ADDRESS_FAILURE:
      return {
        ...state,
        address: initialState.address
      };

    default:
      return state;
  }
}

export default authReducer;