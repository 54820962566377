import React, { useEffect, useState } from "react";
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from "@mui/styles";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Link as Core } from "@mui/material";

//Styles
import AuthenticationStyles from "../../layouts/AuthenticationLayout/AuthenticationLayoutStyles"

// Import Components
import { Button, TextField, Typography, CheckBox, Link } from "../../components";

// Import Helpers
import validations from "../../utils/validations";
import { isEmail } from "../../utils";

// Import Cofig Constants
import { common_constants, contactus_page_constants, errorsConstants } from "../../config/constants";

//Import Actions
import { authAction } from "../../redux/actions";

const Login = (props) => {

  // Get Props
  const { classes } = props;


  // Define Dispatch
  const dispatch = useDispatch();

  const { login: { loading } } = useSelector(s => (s.auth))

  // Set Form Values
  const { register, handleSubmit, control, errors, watch } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: "",
      password: "",
      stay_sign_in: false
    }
  });

  // Submit Form
  const onSubmit = (data) => {
    dispatch(authAction.login(data, () => { }));
  }

  const [passDis, setPassDis] = useState(false);

  const handleToggle = () => {
    setPassDis(passDis ? false : true)
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.paddingBottom5}>
          <Typography variant="h3">{common_constants.SIGNIN_HEADER}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id={'txt_email'}
            name={"email"}
            placeholder={common_constants.EMAIL_ID}
            error={errors.email ? true : false}
            helperText={errors.email?.message}
            withImage={true}
            imageType={'uemail'}
            inputRef={register({
              validate: {
                ...validations.isRequired(errorsConstants.requiredEmail),
                ...validations.isEmail(errorsConstants.inValidEmail)
              }
            })}
          />
        </Grid>
        <Grid item xs={12} className={classes.passwordContainer}>
          <TextField
            id={'txt_password'}
            name={"password"}
            type={passDis ? "text" : "password"}
            placeholder={common_constants.PASSWORD}
            error={errors.password ? true : false}
            helperText={errors.password?.message}
            withImage={true}
            passShow={true}
            imageType={'passwd'}
            inputRef={register({
              validate: {
                ...validations.isRequired(errorsConstants.requiredPassword)
              }
            })}
          />
          {passDis ? <Visibility onClick={handleToggle} className={classes.vsibleIcon} /> : <VisibilityOff className={classes.vsibleIcon} onClick={handleToggle} />}
        </Grid>
        <Grid item xs={12} align="center">
          <Controller
            name="stay_sign_in"
            control={control}
            defaultValue={false}
            render={props =>
              <CheckBox
                label={common_constants.STAY_SIGNED_IN}
                handleChange={(e) => { props.onChange(e.target.checked); localStorage.setItem("staySignIn", e.target.checked) }}
                checked={props.value}
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4} alignItems={"center"}>
            <Grid item xs={6} className="Btnloader">
              <Button
                color={"primary"}
                size={"large"}
                className={classes.loginBtn}
                type="submit"
                title={!loading && common_constants.SIGNIN_BTN_TEXT}
              >
                {loading && <CircularProgress size={14} />}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Grid container alignItems="flex-end">
                <Grid item xs={12} align="right" style={{marginTop: '-2px'}}>
                  <Core
                    href="#/requestAccess"
                    style={{
                      textDecoration: 'none',
                      color: '#878083',
                      fontSize: '14px',
                      fontFamily: 'Roboto',
                      lineHeight: '1.5',
                      letterSpacing: '0.0098em',
                      fontWeight: '500',
                      transition: 'color 0.3s ease',  // Adding transition for smooth color change
                    }}
                    onMouseOver={(e) => { e.target.style.color = '#12A8DE'; }}  // Change color on hover
                    onMouseOut={(e) => { e.target.style.color = '#878083'; }}   // Revert color on mouse out
                  >
                    Restore Access
                  </Core>

                  {/* <Link
                    style={{textDecoration:'none'}}
                    href="#/requestAccess"
                   // variant="body1"
                    variant="subtitle1"
                    color="textSecondary">
                    {common_constants.REQUEST_ACCESS}
                  </Link> */}
                </Grid>
                <Grid item xs={12} align="right">
                  {/* <Link
                    href="#/contactUs"
                    variant="subtitle1"
                    color="textSecondary">
                    {contactus_page_constants.CONTACT_US_LINK_BTN_TEXT}
                  </Link> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

// default props
Login.defaultProps = {
  classes: {}
};

// prop types
Login.propTypes = {
  classes: PropTypes.object
};

export default withStyles(AuthenticationStyles)(Login);
