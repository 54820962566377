// audit reducer types
export const settingsConstant = {

    // To get profile Details
    GET_PROFILE_REQUEST: "GET_PROFILE_REQUEST",
    GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
    GET_PROFILE_FAILURE: "GET_PROFILE_FAILURE",

    // To get application Details
    GET_APPLICATION_REQUEST: "GET_APPLICATION_REQUEST",
    GET_APPLICATION_SUCCESS: "GET_APPLICATION_SUCCESS",
    GET_APPLICATION_FAILURE: "GET_APPLICATION_FAILURE",

    // To get CONFIGURATION Details
    GET_CONFIGURATION_REQUEST: "GET_CONFIGURATION_REQUEST",
    GET_CONFIGURATION_SUCCESS: "GET_CONFIGURATION_SUCCESS",
    GET_CONFIGURATION_FAILURE: "GET_CONFIGURATION_FAILURE",

    //update profile data
    UPDATE_PROFILE_REQUEST:"UPDATE_PROFILE_REQUEST",

    //update company data
    UPDATE_COMPANY_REQUEST:"UPDATE_COMPANY_REQUEST",

    //update application data
    UPDATE_APPLICATION_REQUEST:"UPDATE_APPLICATION_REQUEST",

    //update tazworks data
    UPDATE_TAZWORKS_REQUEST:"UPDATE_TAZWORKS_REQUEST",

    //update email data
    UPDATE_EMAIL_REQUEST:"UPDATE_EMAIL_REQUEST",

    //update bluefin data
    UPDATE_BLUEFIN_REQUEST:"UPDATE_BLUEFIN_REQUEST",

    //update square data
    UPDATE_SQUARE_REQUEST:"UPDATE_SQUARE_REQUEST",

    // To SAVE profile Details
    SAVE_PROFILE_REQUEST: "SAVE_PROFILE_REQUEST",
    SAVE_PROFILE_SUCCESS: "SAVE_PROFILE_SUCCESS",
    SAVE_PROFILE_FAILURE: "SAVE_PROFILE_FAILURE",

    // To SAVE application Details
    SAVE_APPLICATION_REQUEST: "SAVE_APPLICATION_REQUEST",
    SAVE_APPLICATION_SUCCESS: "SAVE_APPLICATION_SUCCESS",
    SAVE_APPLICATION_FAILURE: "SAVE_APPLICATION_FAILURE",

    // To SAVE CONFIGURATION Details
    SAVE_CONFIGURATION_REQUEST: "SAVE_CONFIGURATION_REQUEST",
    SAVE_CONFIGURATION_SUCCESS: "SAVE_CONFIGURATION_SUCCESS",
    SAVE_CONFIGURATION_FAILURE: "SAVE_CONFIGURATION_FAILURE",


}