import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames"
import { IconButton } from '@mui/material';
import { withStyles } from "@mui/styles";
import Grid from '@mui/material/Grid';
import arrayMove from "array-move";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";

//Styles and Images
import TemplatesStyles from "../TemplatesStyles"
import { DragIcon, BellIcon, DeleteIcon, BellIconBlue } from '../../../assets/svg'

// Import Components
import { Button, TextField, Typography, AlertDialog } from "../../../components";

// Import Cofig Constants
import { templates_page_constants } from "../../../config/constants";

// Import Actions
import { updateReducerAction } from "../../../redux/actions";

//import constant
import { templateConstant } from '../../../redux/constants'

//Sortable Item
const SortableItem = SortableElement(props => {
    return (
        props.children
    );
});

//Drag Handle
const DragHandle = SortableHandle((props) => {
    const { classes } = props;

    return (<DragIcon className={"cla"} draggable={false}></DragIcon>);
})

//create sortable list for drag and drop
const SortableList = SortableContainer(props => {

    //get props
    const { data, classes, setDeleteAlert, updateVariationField } = props;

    return (
        <React.Fragment>
            <Grid container>
                {data && data.map((item, index) => (
                    <SortableItem
                        key={`sort-item-${index}`}
                        index={index}
                    >
                        <Grid item xs={12}>
                            <Grid container alignItems="center" className={classNames(classes.settingsTileBgnoneAddDoc, classes.widthAuto)}>
                                <DragHandle />
                                <TextField
                                    className={classes.textFieldChoices}
                                    id={`text-${index}`}
                                    name={`text-${index}`}
                                    margin="none"
                                    placeholder={'Text'}
                                    value={item.text ? item.text : ''}
                                    onChange={(e) => { updateVariationField({ name: 'text', value: e.target.value }, index) }}
                                />
                                <IconButton
                                    className={`${classes.notifyIcon} ${"mr-1 ml-1"}`}
                                    onClick={(e) => { updateVariationField({ name: 'is_notification', value: (Number(item.is_notification) === 1 ? 0 : 1) }, index) }}
                                    size="large">
                                    {item.is_notification === 0 ? <BellIcon /> : <BellIconBlue />}
                                </IconButton>
                                <IconButton onClick={(e) => { setDeleteAlert({ "delIndex": index }) }} size="large"><DeleteIcon /></IconButton>
                            </Grid>
                        </Grid>
                    </SortableItem>
                ))}
            </Grid>
        </React.Fragment>
    );
})

const VariationContainer = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes, index, choiceData, currentVariation } = props;

    //get reducer data
    const { document: { variation } } = useSelector(s => (s.template));

    //set state
    const [deleteAlert, setDeleteAlert] = useState(null);

    //add new variation to choices
    const addVariation = () => {
        const updatedVariation = [...variation, { index: index, id: null, choice_id: choiceData.id ? choiceData.id : null, text: '', is_notification: 0, position: currentVariation.length + 1 }]
        dispatch(updateReducerAction.update(templateConstant.UPDATE_DOCUMENT_VARIATION, updatedVariation))
    }

    //check row index on sort
    const onSortEnd = (e) => {
        const { oldIndex, newIndex } = e;
        let resultData = arrayMove(currentVariation, oldIndex, newIndex);
        for (let i = 1; i <= resultData.length; i++) {
            resultData[i - 1]["position"] = i;
        }
        const updatedVar = [...resultData, ...variation.filter(v => (v["index"] !== index))]

        dispatch(updateReducerAction.update(templateConstant.UPDATE_DOCUMENT_VARIATION, updatedVar))
    }

    //update variation
    const updateVariationField = (e, indx) => {
        let variationTemp = [...currentVariation]
        const { name, value } = e;
        for (let i = 0; i < variationTemp.length; i++) {
            if (i === indx) {
                variationTemp[i][name] = value;
            }
        }
        const updatedVar = [...variationTemp, ...variation.filter(v => ( v["index"] !== index))]
        dispatch(updateReducerAction.update(templateConstant.UPDATE_DOCUMENT_VARIATION, updatedVar))
    }

    //handle delete
    const handleDelete = (indx) => {
        let resultData = [...currentVariation];
        resultData.splice(indx, 1);

        for (let i = 1; i <= resultData.length; i++) {
            resultData[i - 1]["position"] = i;
        }

        const updatedVar = [...resultData, ...variation.filter(v => (v["index"] !== index))]
        dispatch(updateReducerAction.update(templateConstant.UPDATE_DOCUMENT_VARIATION, updatedVar, () => {
            setDeleteAlert(null)
        }))
    }

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <SortableList
                    useDragHandle
                    axis="xy"
                    enableUserSelectHack={false}
                    helperClass="sortable-list-tab"
                    data={variation.filter(i=>(i.index===index))}
                    onSortEnd={onSortEnd}
                    classes={classes}
                    updateVariationField={updateVariationField}
                    setDeleteAlert={setDeleteAlert}
                ></SortableList>
            </Grid>
            <Grid item xs={12} align="right">
                <Typography color="primary" align="right" variant={"body2"} title={templates_page_constants.ADD_VARIATION} className={`${"cursorPointer"} ${classes.grow}`} onClick={() => { addVariation() }}></Typography>
            </Grid>
            {deleteAlert &&
                <AlertDialog
                    fullScreen={false}
                    open={true}
                >
                    <Grid className={'p-4'}>
                        <Typography variant="h6">{`Are you sure do you want to remove the document`}</Typography>
                        <Grid className={'pt-2'} align={"center"}>
                            <Button
                                color={"primary"}
                                className={"mr-1"}
                                onClick={() => { handleDelete(deleteAlert["delIndex"]) }}
                                title={"Continue"}
                            />
                            <Button
                                color={"secondary"}
                                onClick={() => { setDeleteAlert(null) }}
                                title={"Cancel"}
                            />
                        </Grid>
                    </Grid>
                </AlertDialog>
            }
        </React.Fragment>
    )
}
// default props
VariationContainer.defaultProps = {
    classes: {},
    index: -1,
    choiceData: {},
    currentVariation:[]
};

// prop types
VariationContainer.propTypes = {
    classes: PropTypes.object,
    index: PropTypes.number,
    choiceData: PropTypes.object,
    currentVariation:PropTypes.array
};

export default withStyles(TemplatesStyles)(VariationContainer);
