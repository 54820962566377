import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Grid from '@mui/material/Grid';
import { withStyles } from "@mui/styles";

//Styles
import TenantApplicationStyles from "../TenantApplicationStyles"

//import helpers
import { dataPopulator } from "../../../utils"

// Import Components
import { Signature, CheckBox } from "../../../components";

//import actions
import { updateReducerAction } from "../../../redux/actions";

//import constants
import { tenantAppConstant } from "../../../redux/constants";

const Criteria = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes } = props;

    const introRef = useRef(null);

    //get reducer data
    const { appData: { introduction, allSteps, settings } } = useSelector(s => (s.tenantApp))

    useEffect(() => {
        introRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
    }, []);

    //update checkbox
    const handleChangeCheckbox = (e) => {
        let updateData = introduction;
        updateData[e["name"]] = e["value"]
        dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_INTRODUCTION, updateData))
    }

    //update signature
    const handleSignChange = (sign, bs64) => {
        let updateData = introduction;
        updateData["introSignature"] = bs64;
        updateData["sign"] = sign;
        dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_INTRODUCTION, updateData))
    }

    return (
        <Grid item xs={12} style={{ overflowX: "hidden" }}>
            <form>
                <Grid container ref={introRef}>
                    <Grid item xs={12} className={`${"p-1 sm-p-3"} ${classes.criteriaContainer}`}>
                        <Grid className={`${"tenantCardContainer"} ${classes.cardContainer}`}>

                            <Grid item sm={12} className="mb-1">
                                <span dangerouslySetInnerHTML={{ __html: dataPopulator(settings , introduction["content"]) }}></span>
                            </Grid>

                            {introduction["isIntroConfirm"] > 0 &&
                                <Grid item sm={12} className="mb-2 pl5" >
                                    <CheckBox
                                        id={'introCheckbox'}
                                        name={"introCheckbox"}
                                        color="secondary"
                                        label={introduction["introConfirmMsg"]}
                                        checked={introduction["introCheckbox"]}
                                        handleChange={(e) => handleChangeCheckbox({ name: "introCheckbox", value: e.target.checked })}
                                    />
                                </Grid>
                            }

                            {allSteps.indexOf("introductionSign") > -1 &&
                                <Grid item sm={12} className="mb-1" >
                                    <Signature
                                        name={"sign"}
                                        classes={classes}
                                        sigPad={introduction["sign"] ? introduction["sign"] : {}}
                                        signImg={introduction["introSignature"] ? introduction["introSignature"] : ""}
                                        handleSignChange={handleSignChange}
                                    />
                                </Grid>
                            }

                        </Grid>

                    </Grid>
                </Grid>
            </form>
        </Grid>
    );
}

// default props
Criteria.defaultProps = {
    classes: {}
};

// prop types
Criteria.propTypes = {
    classes: PropTypes.object
};

export default withStyles(TenantApplicationStyles)(Criteria);
