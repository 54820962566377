/* eslint-disable dot-notation */
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import {Typography as Core} from '@mui/material';

// Imports Styles
import style from "./style";

/**
 * Typography Component
 * @param {*} props
 */
function TypographyComponent(props) {
  const { classes, required, className, color, variant, children, align, onClick, noWrap, title } = props;
  const clsName = required === true ? `${className} ${classes["required"]}` : className;

  return (
    <Core
      variant={variant}
      className={clsName}
      align={align}
      color={color}
      onClick={onClick}
      noWrap={noWrap}
    >{title}{children}</Core>
  );
}

// default props
TypographyComponent.defaultProps = {
  classes: {},
  variant: "h2",
  className: "",
  children: "",
  color: "textPrimary",
  align: "left",
  noWrap: false,
  required: false,
  onClick: () => {}
};

// prop types
TypographyComponent.propTypes = {
  classes: PropTypes.object,
  variant: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.any,
  color: PropTypes.string,
  align: PropTypes.string,
  noWrap: PropTypes.bool,
  required: PropTypes.bool,
  onClick: PropTypes.func
};

/** Export Component */
export const Typography = withStyles(style)(TypographyComponent);
