import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid';
import { withStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";

//Styles
import TenantApplicationStyles from "../TenantApplicationStyles"

// Import Components
import { Typography, CheckBox } from "../../../components";
import FormFieldsGenerator from "../FieldHelpers/FormFieldsGenerator"

//import actions
import { alertAction, updateReducerAction } from "../../../redux/actions";

//import constants
import { tenantAppConstant } from "../../../redux/constants";

//import helpers
import { dataPopulator } from "../../../utils"

const Payment = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    const payRef = useRef(null);

    useEffect(() => {
        payRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
    }, []);

    //get props
    const { classes } = props;

    //get reducer data
    const { appData : { payment, settings } } = useSelector(s=>(s.tenantApp))

    //change payment form
    const handleChange = (e) =>{
        const regX = /[^a-zA-Z0-9! @ # $ % ^ * ( ) _ \n + = . ,-]/
        if(e["value"] && typeof e["value"] === "string" && regX.test(e["value"])===true){
            dispatch(alertAction.error("Please enter only these accepted characters A-Z 0-9 ! @ # $ % ^ * ( ) _ - + = . ,"))
        }else{
            let updateData = payment ;
            updateData["paymentForm"][e["name"]] = {...updateData["paymentForm"][e["name"]],value : e["value"]} 
            dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_PAYMENT, updateData))
        }
    }

    //update checkbox
    const handleChangeCheckbox = (e) =>{
        let updateData = payment ;
        updateData[e["name"]] = e["value"] 
        dispatch(updateReducerAction.update(tenantAppConstant.UPDATE_PAYMENT, updateData))
    }

    return (
        <Grid item xs={12} style={{ overflowX: "hidden" }}>
            <form>
                <Grid container ref={payRef}>
                    <Grid item xs={12} className={"p-1 sm-p-3"}>

                        <Grid className={`${"pb-7 tenantCardContainer"} ${classes.cardContainer}`}>
                            <Grid item sm={12} className="sm-mb-3">
                                <Typography variant="h5" className={classes.AppliactionHeadLine}>{"Payment"}</Typography>
                                <span style ={{"whiteSpace": "break-spaces"}} dangerouslySetInnerHTML={{ __html: `${payment && payment["content"] ? dataPopulator(settings,payment["content"]) : ""}` }}></span>
                                {/* <Typography variant="body2" color="textSecondary" className={classes.AppliactionHeadLine}>{dataPopulator(settings,payment["content"])}</Typography> */}
                            </Grid>
                            <Grid item sm={12} className="mt-2">
                                <Grid container className={"sm-spacing15"}>
                                    {payment && payment['paymentForm'] && Object.keys(payment['paymentForm']).map((item,indx)=>( 
                                        <Grid item sm={6} xs={12} key={item+indx}>
                                            <Typography variant="body1">{payment['paymentForm'][item]["label"] ? `${payment['paymentForm'][item]["label"]} ${payment['paymentForm'][item]["required"] ? '*' : ''}` : "label"}</Typography>
                                            <FormFieldsGenerator
                                                item={item}
                                                indx={indx}
                                                contents={payment['paymentForm'][item] || {}}
                                                handleChange={handleChange}
                                                data={payment['paymentForm'] || {}}
                                                validataForm={payment["validataForm"]}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item sm={12} className="mt-1">
                                <CheckBox
                                    id={'paymentCheckbox'}
                                    name={"paymentCheckbox"}
                                    color="secondary"
                                    label={payment["paymentConfirmMsg"]}
                                    checked={payment["paymentCheckbox"]}
                                    handleChange={(e) => handleChangeCheckbox({name:"paymentCheckbox",value: e.target.checked})}
                                /> 
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </form>
        </Grid>
    );
}

// default props
Payment.defaultProps = {
    classes: {}
};

// prop types
Payment.propTypes = {
    classes: PropTypes.object
};

export default withStyles(TenantApplicationStyles)(Payment);
