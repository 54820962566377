import * as apihelper from "../../utils/apiHelper";

/**
 * Export All settings Service Functions
 */
export const settingsService = {
    getProfile,
    getApplication,
    getConfiguration,
    saveProfile,
    saveApplication,
    saveConfiguration
};

/**
 * get profile data
 */
async function getProfile(id) {
    const response = await apihelper.getRequest(`settings/getProfile/` + id);
    return response;
}

/**
 * get application data
 */
async function getApplication() {
    const response = await apihelper.getRequest(`settings/getApplication`);
    return response;
}

/**
* get Configuration data
*/
async function getConfiguration() {
    const response = await apihelper.getRequest(`settings/getConfiguration`);
    return response;
}

/**
 * save profile data
 */
async function saveProfile(data) {
    const response = await apihelper.postRequest(`settings/saveProfile`,data );
    return response;
}

/**
 * save application data
 */
async function saveApplication(data) {
    const response = await apihelper.postRequest(`settings/saveApplication`, data);
    return response;
}

/**
* save Configuration data
*/
async function saveConfiguration(data) {
    const response = await apihelper.postRequest(`settings/saveConfiguration`,data);
    return response;
}
