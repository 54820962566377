export const validateData = (variation, choices) => {
    let choicesError = false;
    for (let i = 0; i < choices.length; i++) {
        if (!choices[i]['name'] || isNaN(choices[i]['position'])) {
            choicesError = true
        }
    }

    let variationError = false;
    for (let i = 0; i < variation.length; i++) {
        if (!variation[i]['text'] || isNaN(variation[i]['position'])) {
            variationError = true;
        }
    }

    return { choiceValidation: choicesError, variationValidation: variationError }
}