import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import propTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { withStyles } from "@mui/styles";

//Import Styles and images
import ServicesStyles from '../ServicesStyles'
import { MoreIcon } from '../../../assets/svg'

// Import Components
import { Button, Typography, AlertDialog, CheckBox, LazyLoader } from "../../../components";

// Import Actions
import { serviceAction, updateReducerAction } from "../../../redux/actions";

// Import Config Constants
import { serviceConstant } from "../../../redux/constants";

// Import Config Layout
import { servicesCardLimit } from "../../../config/layout";

function ServiceChords(props) {

    //get props
    const { classes, editOpenDrawer } = props;

    let cardLimit = servicesCardLimit();
    const cardSize = Math.floor(12 / (cardLimit / 5))

    //define dispatch
    const dispatch = useDispatch();

    //set ref for scroll
    const scrollContent = useRef(null)

    //get reducer data
    let { getAll: { data, page }, applications, checkBoxSelect } = useSelector((state) => state.service);
    let { lazyLoading } = useSelector((state) => state.UI);

    //open popup inside each chord
    const [anchorEl, setAnchorEl] = useState(null);
    const [editId, setEditId] = useState(null);
    const [deleteAlert, setDeleteAlert] = useState(null);
    const [showScrollTopBTN, setShowScrollTopBTN] = useState(false)

    //open popup
    const handleClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setEditId(id)
    };

    //close popup
    const handleClose = () => {
        setAnchorEl(null);
        setEditId(null)
    };

    //edit user
    const handleEditService = () => {
        if (editId) {
            const selectedservice = data.filter(obj => (obj.id === editId))
            dispatch(updateReducerAction.update(serviceConstant.ADD_EDIT_SERVICE_REDUCER_UPDATE, selectedservice[0], () => {
                setAnchorEl(null);
                setEditId(null)
                editOpenDrawer(editId)
            }));
        }
    }

    //delete service
    const handleDelete = () => {
        if (editId) {
            let dataObj = data.filter(i => (i.id === editId))
            if (dataObj.length === 1) {
                dispatch(serviceAction.remove({ id: dataObj[0].id }, () => {
                    dispatch(serviceAction.getAll({ limit: cardLimit, skip: 1, search: page.search, sort: page.sort }));
                    setAnchorEl(null);
                    setDeleteAlert(null)
                }));
            }
        }
    }

    //handle delete popup function
    const handleDeletePopup = (item) => {
        const selectedservice = data.filter(obj => (obj.id === editId))
        dispatch(serviceAction.getApplicationCount({ id: selectedservice[0].id }, () => {
            setDeleteAlert({ item: selectedservice[0] })
        }));
    }

    //scroll event
    const getScrollData = () => {
        const isBottom = parseInt(scrollContent.current.scrollHeight - scrollContent.current.scrollTop) === (scrollContent.current.clientHeight) || parseInt(scrollContent.current.scrollHeight - scrollContent.current.scrollTop) === (scrollContent.current.clientHeight-1) || parseInt(scrollContent.current.scrollHeight - scrollContent.current.scrollTop) === (scrollContent.current.clientHeight+1);
        // const currentVal=scrollContent.current.scrollTop;
        if (!lazyLoading && isBottom && page.count > data.length) {
            //get neext data
            dispatch(serviceAction.getAll({ limit: page.limit, skip: page.skip + 1, search: page.search, sort: page.sort, loader: true }));
        }

        if (scrollContent.current.scrollTop > 150 && showScrollTopBTN===false){
            setShowScrollTopBTN(true)
        }
        else if(scrollContent.current.scrollTop < 150 && showScrollTopBTN===true){
            setShowScrollTopBTN(false)
        }
    }

    /**
   * Handle checkbox Change
   * @param {*} event 
   */
    const handleCheckboxChange = (event) => {
        if (event.target.checked === false) {
            delete checkBoxSelect[event.target.name]
        }
        else {
            checkBoxSelect[event.target.name] = event.target.checked
        }
        dispatch(updateReducerAction.update(serviceConstant.CHECKBOX_REDUCER_UPDATE, checkBoxSelect, () => { }));
    }

    return (
        <React.Fragment>
            <Grid item className={classes.usersRightTiles} ref={scrollContent} onScroll={() => { getScrollData() }}>
                <Grid container>
                    <Grid item sm={12} >
                        <Grid container spacing={2} >
                            {data && data.map((itm, ind) => (
                                    <Grid className="animate__animated animate__fadeInUp animate__delay-0.8s" xs={cardSize} item key={ind}>
                                        <Grid className={classes.serviceTile}>
                                            <Grid container justifyContent="space-between" className="p-2">
                                                <Grid>
                                                    <CheckBox
                                                        id={ind}
                                                        name={`${itm.id}`}
                                                        checked={checkBoxSelect[`${itm.id}`]}
                                                        handleChange={(e) => handleCheckboxChange(e)}
                                                    />
                                                </Grid>
                                                <Grid className={classes.mrAuto}>
                                                <Typography variant="body1">{itm.code}</Typography>
                                                </Grid>
                                                <Grid>
                                                    <Grid container>
                                                        <Grid className={classes.servicePriceChip}><Typography variant="subtitle1" color="primary">{itm.fee}</Typography></Grid>
                                                        <Grid className={classes.moreContainerTop}>
                                                            <IconButton
                                                                className={classes.tileMoreIcon}
                                                                onClick={(e) => { handleClick(e, itm.id) }}
                                                                size="large"><MoreIcon /></IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid className={classes.serviceTileContainer}>
                                                <Typography variant="h5" align="center" className={`${"oneLineEllip transCapital serviceCardsTxtWidth marginAuto pb15"}`}>{itm.company}</Typography>
                                                <Typography variant="subtitle1" align="center" className={`${"oneLineEllip serviceCardsTxtWidth marginAuto pb15"}`} color="textSecondary">{itm.full_name}</Typography>
                                                <Typography variant="body1" align="center" className={`${"oneLineEllip serviceCardsTxtWidth marginAuto pb15"}`} color="textSecondary">{itm.product}</Typography>
                                                <Typography variant="body1" align="center" className={"oneLineEllip serviceCardsTxtWidth marginAuto"} color="textSecondary">{itm.temp_name}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                            ))}
                            <LazyLoader />
                        </Grid>
                        <Menu
                            id={`service-menu`}
                            key={`jdfn-n sdj`}
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            elevation={2}
                        >
                            <MenuItem onClick={() => { handleEditService() }}>Edit Service</MenuItem>
                            <MenuItem onClick={() => { handleDeletePopup() }}>Delete Service</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
                {showScrollTopBTN && <IconButton
                    onClick={()=>{scrollContent.current.scrollTo(0, 0)}}
                    className={"toTop"}
                    size="large"><KeyboardArrowUpIcon /></IconButton>}
            </Grid>
            {deleteAlert &&
                <AlertDialog
                    fullScreen={false}
                    open={true}
                >
                    <Grid className={'p-4'}>
                        {applications > 0 ?
                            <Typography variant="h6">{`An application is mapped to this service, You cannot delete it`}</Typography> :
                            <Typography variant="h6">{`Are you sure you want to delete this service`}</Typography>
                        }
                        <Grid className={'pt-2'} align={"center"}>
                            {applications === 0 &&
                                <Button
                                    color={"primary"}
                                    className={"mr-1"}
                                    onClick={() => { handleDelete() }}
                                    title={"Yes"}
                                />
                            }
                            <Button
                                color={"secondary"}
                                onClick={() => { setAnchorEl(null); setDeleteAlert(null) }}
                                title={applications > 0 ? "Close" : "No"}
                            />
                        </Grid>
                    </Grid>
                </AlertDialog>
            }
        </React.Fragment>
    );
}

// default props
ServiceChords.defaultProps = {
    classes: {}
};

// prop types
ServiceChords.propTypes = {
    classes: propTypes.object
};

// export default UserList;
export default withStyles(ServicesStyles)(ServiceChords);