/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

import Services from "./Services";

// Import History
import history from "../../config/history";

// service router
export default function ServicesRouter(props) {
    // get props
    const { match, location } = props;
    return (
        <Switch>
            <Route
                exact
                path={match.url}
                render={(props) => {
                    const isReset = location && location.search === "?reset=true" ? true : false;
                    if (isReset) {
                        history.push({ pathname: `/services` });
                        return;
                    } else {
                        return (
                            <Services
                                match={match}
                                {...props}
                            />
                        );
                    }
                }}
            />
        </Switch>
    );
};

//default props
ServicesRouter.defaultProps = {
    match: {},
    classes: {}
};

/**
 * Bind Component Property Types
 */
ServicesRouter.propTypes = {
    match: PropTypes.object,
    classes: PropTypes.object
};
