/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

import Submissions from "./Submissions";


function SubmissionsRouter(props) {
  const { match } = props;
  return (
    <Switch>
      <Route exact path={`${match.url}`} render={props => {
        return (
          <Submissions
            match={match}
            {...props}
          />
        );
      }} />
    </Switch>
  );
};

//default props
SubmissionsRouter.defaultProps = {
  match: {},
  classes: {}
};

/**
 * Bind Component Property Types
 */
SubmissionsRouter.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object
};

/**
 * Export Component
 */
export default SubmissionsRouter;
