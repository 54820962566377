import bannerImg from '../../assets/images/bannerImg.png'

const AuthenticationStyles = () => ({
    root: {
        height: '100%'
    },
    bannerImg: {
        backgroundImage: `url(${bannerImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    container: {
        height: '100vh',
        // maxWidth: 550,
        margin: 'auto',
        '& .MuiInputBase-root': {
            height: 48,
            minHeight: 48
        },
        alignContent: "space-between",
        paddingTop: "40px",
        paddingBottom: "40px"
    },
    loginContainer: {
        height: 'calc(100% - 525px)',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingLeft:"70px",
        paddingRight:"70px",
    },
    //Login
    loginBtn: {
        // minWidth: "154px"
        transition: 'all 0.8s'
    },
    contactForm: {
        // height: 'calc(100vh - 324px)',
        // overflowY: 'auto',
        '& .MuiInputBase-root, & .MuiTextField-root': {
            height: "auto !important",
        },
        '& .MuiTextField-root': {
            marginBottom: 10
        },
    },
    messageContainer: {
        minHeight: "69px !important",
        '& .MuiTextField-root': {
            minHeight: "69px !important"
        },
        '& .MuiInputBase-root': {
            minHeight: "69px !important"
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '15px !important'
        },
        '& textarea': {
            overflowY: 'auto !important',
            maxHeight: '48px !important'
        }
    },
    passwordContainer: {
        position: 'relative'
    },
    vsibleIcon: {
        top: '51px',
        right: '14px',
        position: 'absolute',
        fill: '#cccccc',
        cursor: "pointer"
    },
    vsibleIconReset: {
        top: '45px',
        right: '32px',
        position: 'absolute',
        fill: '#cccccc',
        cursor: "pointer"
    },
    bottomContainer: {
        background: "#fafafa",
        padding:"30px 40px"
    },
    imageCont: {
        height: "80px",
        width: "80px",
        background: "#f1f1f1",
        borderRadius: "100px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    imageContOuter:{
        borderRadius:"100px",
        border:"2px solid #f1f1f1",
        padding:"8px"
    }
})
export default AuthenticationStyles;