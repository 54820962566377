import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@mui/styles"; 
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'; 
import Avatar from '@mui/material/Avatar'; 
import AppBar from '@mui/material/AppBar'; 
import Toolbar from '@mui/material/Toolbar'; 

// Import History
import history from "../../config/history";

// Import Style
import HeaderStyle from './HeaderStyle';

// Import Images
import AllianceLogoIcon from '../../assets/images/logo_icon.png';
import { AllianceAMaskIcon } from "../../assets/svg"
import AvatarPhoto from '../../assets/images/1.jpg';
import { ChevronLeftIcon, LogoutIcon, MenuSettingsIcon, ProfileIcon } from '../../assets/svg';
import settingsIcon from '../../assets/images/svg/settings.svg';
import profileIcon from '../../assets/images/svg/profile.svg';
import logoutIcon from '../../assets/images/svg/logout.svg';

//import componentes
import { AlertDialog, Typography, Button as Btn, NetworkDetector, SesionTime } from '../../components'

//Import Actions
import { authAction, updateReducerAction, settingsAction } from "../../redux/actions";

//import constants
import { userConstant, templateConstant, applicationConstant, serviceConstant, directoryConstant, auditConstant } from "../../redux/constants";

const Header = props => {
  // Define Dispatch
  const dispatch = useDispatch();

  //get props
  const { classes, match, user } = props;

  //set state
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [logoutAlert, setLogoutAlert] = React.useState(false)

  //get reducer data
  const { currentUser, logInUser ,logInUserLogo:{logo}} = useSelector(state => state.user);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickUrl = pathName => {
    if (pathName && pathName.indexOf("templates") > -1) {
      dispatch(updateReducerAction.update(templateConstant.RESET_SEARCH, {}, () => {
        props.history.push(pathName);
      }));
    } else if (pathName && pathName.indexOf("users") > -1) {
      dispatch(updateReducerAction.update(userConstant.UPDATE_VIEW_TYPE_AND_SEARCH, 'left'));
      props.history.push(pathName);
    } else if (pathName && pathName.indexOf("services") > -1) {
      dispatch(updateReducerAction.update(serviceConstant.UPDATE_VIEW_TYPE_AND_SEARCH, 'left'));
      props.history.push(pathName);
    } else if (pathName && pathName.indexOf("application") > -1) {
      dispatch(updateReducerAction.update(applicationConstant.UPDATE_VIEW_TYPE_AND_SEARCH, 'left'));
      props.history.push(pathName);
    } else if (pathName && pathName.indexOf("directory") > -1) {
      dispatch(updateReducerAction.update(directoryConstant.UPDATE_VIEW_TYPE_AND_SEARCH, 'left'));
      props.history.push(pathName);
    } else if (pathName && pathName.indexOf("audit") > -1) {
      dispatch(updateReducerAction.update(auditConstant.UPDATE_SEARCH_TYPE, ''));
      props.history.push(pathName);
    } else {
      props.history.push(pathName);
    }
  };

  /**
   * handle back to Admin
   */
  const handleBackToAdmin = () => {
    localStorage.removeItem("id");
    localStorage.removeItem("role");
    dispatch(updateReducerAction.update(userConstant.CURRENT_LOGIN_UPDATE, logInUser, () => {
      history.push(`/users?reset=true`);
      dispatch(authAction.getProfileLogo())
    }));
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  //logout application
  const logout = () => {
    localStorage.removeItem("id");
    localStorage.removeItem("role");
    localStorage.removeItem("staySignIn");
    dispatch(authAction.logout());
    handleClose()
  }

  //when the user click the logo
  const logoClick = () => {
    if ((currentUser.role === "admin" || currentUser.role === "client")) {
      history.push(`/users?reset=true`);
    } else {
      history.push(`/submissions`);
    }
  }

  //handle settings button click
  const handleSettingsClick = () => {
    history.push(`/settings`)
    handleClose()
  }

  return (
    <React.Fragment>
      {/* <NetworkDetector /> */}
      <SesionTime timeOut={3600000} onTimeout={logout} />
      <AppBar position="static">
        <Toolbar>
          <Grid>
            <Button className={classes.headerLogo} color="inherit" onClick={() => { logoClick() }}>
              <img src={AllianceLogoIcon} alt="Alliance 2020" />
            </Button>
            <Button
              className={
                match.url.includes('/submissions')
                  ? classes.menuItemactive
                  : classes.menuItem
              }
              color="inherit"
              onClick={() => handleClickUrl('/submissions')}
            >
              Submissions
          </Button>
            {(currentUser.role === "admin" || currentUser.role === "client") &&
              <React.Fragment>
                <Button
                  className={
                    match.url.includes('/users')
                      ? classes.menuItemactive
                      : classes.menuItem
                  }
                  color="inherit"
                  onClick={() => handleClickUrl('/users?reset=true')}
                >
                  Users
              </Button>
              </React.Fragment>
            }
            <Button
              className={
                match.url.includes('/services')
                  ? classes.menuItemactive
                  : classes.menuItem
              }
              color="inherit"
              onClick={() => handleClickUrl('/services?reset=true')}
            >
              Services
          </Button>
            {currentUser.role === "admin" &&
              <React.Fragment>
                <Button
                  className={
                    match.url.includes('/templates')
                      ? classes.menuItemactive
                      : classes.menuItem
                  }
                  color="inherit"
                  onClick={() => handleClickUrl('/templates/document')}
                >
                  Templates
          </Button>
              </React.Fragment>
            }
            {currentUser.role === "admin" &&
              <React.Fragment>
                <Button
                  className={
                    match.url.includes('/directory')
                      ? classes.menuItemactive
                      : classes.menuItem
                  }
                  color="inherit"
                  onClick={() => handleClickUrl('/directory?reset=true')}
                >
                  Directory
              </Button>
              </React.Fragment>
            }
            {currentUser.role !== "admin" && <Button
              className={
                match.url.includes('/application')
                  ? classes.menuItemactive
                  : classes.menuItem
              } color="inherit"
              onClick={() => handleClickUrl('/application')}
            >
              Application
            </Button>}
            <Button
              className={
                match.url.includes('/audit')
                  ? classes.menuItemactive
                  : classes.menuItem
              } color="inherit"
              onClick={() => handleClickUrl('/audit?reset=true')}
            >
              Audit Logs
          </Button>
          </Grid>
          <Grid className={classes.grow} />
          <Grid>
            {currentUser.id !== logInUser.id &&
              <Button
                className={classes.backToLogin}
                color="primary"
                onClick={() => handleBackToAdmin()}
              >
                <ChevronLeftIcon />
              Back to Admin
          </Button>
            }
            <Button
              color="inherit"
              className={classes.avatarMenubutton}
              aria-controls="profile-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              {logo ? <img src={logo} className={classes.uploadImgPopup} alt={"img"} /> : <AllianceAMaskIcon />}
            </Button>
            <Menu
              className={classes.menuList}
              id="profile-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {/* <MenuItem onClick={handleClose} className="link"><ProfileIcon className={`${"menuIcon"} ${classes.menuIcon}`} />Profile</MenuItem> */}
              <MenuItem onClick={() => handleSettingsClick()} className="link"><MenuSettingsIcon className={`${"menuIcon"} ${classes.menuIcon}`} />Settings</MenuItem>
              <MenuItem onClick={() => { setLogoutAlert(true); setAnchorEl(null) }} className="link"><LogoutIcon className={`${"menuIcon"} ${classes.menuIcon}`} />Logout</MenuItem>
            </Menu>
          </Grid>
        </Toolbar>
      </AppBar>
      {logoutAlert &&
        <AlertDialog
          fullScreen={false}
          open={true}
        >
          <Grid className={'p-4'}>
            <Typography variant="h6">{"Are you sure you want to logout"}</Typography>
            <Grid className={'pt-2'} align={"center"}>
              <Btn
                color={"primary"}
                className={"mr-1"}
                onClick={() => { logout() }}
                title={"Yes"}
              />
              <Btn
                color={"secondary"}
                onClick={() => { setLogoutAlert(false) }}
                title={"No"}
              />
            </Grid>
          </Grid>
        </AlertDialog>
      }
    </React.Fragment>
  );
};

// default props
Header.defaultProps = {
  classes: {},
  match: {},
  user: {},
};

// prop types
Header.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
};

/**
 * Export Container
 */
export default withStyles(HeaderStyle)(Header);
