// audit reducer types
export const auditConstant = {

    // To Search AUDITs Details
    AUDIT_SEARCH_REQUEST: "AUDIT_SEARCH_REQUEST",
    AUDIT_SEARCH_SUCCESS: "AUDIT_SEARCH_SUCCESS",
    AUDIT_SEARCH_FAILURE: "AUDIT_SEARCH_FAILURE",

    //get unique log
    LOG_SEARCH_REQUEST: "LOG_SEARCH_REQUEST",
    LOG_SEARCH_SUCCESS: "LOG_SEARCH_SUCCESS",
    LOG_SEARCH_FAILURE: "LOG_SEARCH_FAILURE",

    //update view and search in chird view
    UPDATE_SEARCH_TYPE: 'UPDATE_SEARCH_TYPE',

}