/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

import Settings from "./Settings";


export default function SettingsRouter(props) {
    const { match } = props;
    return (
        <Switch>
            <Route exact path={`${match.url}`} render={props => {
                return (
                    <Settings
                        match={match}
                        {...props}
                    />
                );
            }} />
        </Switch>
    );
};

//default props
SettingsRouter.defaultProps = {
    match: {},
    classes: {}
};

/**
 * Bind Component Property Types
 */
SettingsRouter.propTypes = {
    match: PropTypes.object,
    classes: PropTypes.object
};

/**
 * Export Component
 */
//export default UserRouter;
