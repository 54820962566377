import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from 'react-hook-form';
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//Styles
import TemplatesStyles from "../TemplatesStyles"

// Import Helpers
import validations from "../../../utils/validations";

// Import Components
import { Button, TextField, Typography, CheckBox,Info, Link, TextArea, AutoComplete, TextAreaWithLines } from "../../../components";
import TextareaAutosize from '@mui/material/TextareaAutosize';

// Import Actions
import { updateReducerAction, templateAction, alertAction } from "../../../redux/actions";

// Import Cofig Constants
import { templates_page_constants } from "../../../config/constants";

const AddEditAppTemplate = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes, templateID, openCloseAdd } = props;

    //get reducer data
    const { appTemp, appTempOld, applicationDrawer, appTemplate: { page }, aggrementName, emailName } = useSelector(s => (s.template))

    //set state
    const [aggSel, setAggSel] = useState(appTemp && appTemp.agreement_id ? appTemp.agreement_id.toString() : "");
    const [emailSel, setEmailSel] = useState(appTemp && appTemp.email_temp_id ? appTemp.email_temp_id.toString() : "32");

    //default form vals for adding
    let defaultVals = {
        name: '',
        description: '',
        confirmation: '',
        is_confirm: 0,
        xml: '',
        layout: ''
    }

    useEffect(() => {
        if (!emailSel) {
            setEmailSel('32')
        }
        if (!appTemp.agreement_id && aggSel) {
            setAggSel('')
        }
    }, [appTemp])

    //default form vals for editing
    if (appTemp && Object.keys(appTemp).length) {
        defaultVals = {
            name: appTemp && appTemp.name ? appTemp.name : '',
            description: appTemp && appTemp.description ? appTemp.description : '',
            confirmation: appTemp && appTemp.confirmation ? appTemp.confirmation : '',
            is_confirm: appTemp && appTemp.is_confirm >= 0 ? appTemp.is_confirm : 0,
            xml: appTemp && appTemp.xml ? appTemp.xml : '',
            layout: appTemp && appTemp.layout ? appTemp.layout : ''
        }
    }

    // Set Form Values
    const { register, handleSubmit, errors, control } = useForm({
        mode: 'all',
        reValidateMode: 'all',
        defaultValues: defaultVals
    });

    // handle form submit Form
    const onSubmit = (data) => {
        if (data) {
            if (!aggSel || !emailSel) {
                dispatch(alertAction.error(`${!aggSel ? "Agreement" : 'Email Template'} cannot be blank`));
            } else if (data["name"] && data["name"].length > 100) {
                dispatch(alertAction.error("Name length should be less than 100"));
            } else if (data["description"] && data["description"].length > 255) {
                dispatch(alertAction.error("Description length should be less than 255"));

            } else {
                data['agreement_id'] = aggSel;
                data['email_temp_id'] = emailSel
                if (templateID && appTemp["id"]) {
                    data["id"] = appTemp["id"];
                    dispatch(templateAction.addOrUpdateAppTemplate({ ...data, oldData: appTempOld }, () => {
                        openCloseAdd()
                        dispatch(templateAction.getAllApplication({ limit: 20, skip: 0, search: page.search, sort: page.sort }))

                    }))
                } else {
                    dispatch(templateAction.addOrUpdateAppTemplate({ ...data, oldData: appTempOld }, () => {
                        openCloseAdd()
                        dispatch(templateAction.getAllApplication({ limit: 20, skip: 0, search: page.search, sort: page.sort }))
                    }))
                }
            }
        }
    }

    const onError =(err,e)=>{
        if(err && err["name"] && err["name"]["message"]){
            dispatch(alertAction.error(err["name"]["message"]))
        }
    }

    //Accordion state and changes
    const [expanded, setExpanded] = useState('panel1');
    const handleChange = (panel) => (event, newExpanded) => { setExpanded(newExpanded ? panel : false) };

    //set company selected Dropown
    const getSelectedValue = (arr, val) => {
        const valuee = arr.filter(i => (i.value === val))
        return (valuee[0] ? valuee[0] : { value: "", label: "" })
    }

    // change aggrement template Drop DN
    const aggTemplateMap = (value) => {
        setAggSel(value && value.value ? value.value : value);
    };

    // change aggrement template Drop DN
    const emailTemplateMap = (value) => {
        setEmailSel(value && value.value ? value.value : value);
    };

    return (
        <React.Fragment>
            <Dialog className={classes.addEditTemplate} fullScreen open={applicationDrawer} onClose={openCloseAdd} disableEnforceFocus={true}>
                <form onSubmit={handleSubmit(onSubmit,onError)} noValidate>
                    <Grid container className={`${classes.appTemplateDialog}`}>
                        <Grid container justifyContent="space-between" className={`${classes.dialogHeader} ${"p-2 mb-2"}`} alignItems="center">
                            <Typography variant="h4" title={`${templateID ? "EDIT" : "ADD"} APPLICATION TEMPLATE`}></Typography>
                            <Grid>
                                <Button color="primary" type={'submit'} size="small" title={templateID ? "Update" : "Create"} className="mr-1"></Button>
                                <Button color="secondary" size="small" onClick={openCloseAdd} title={templates_page_constants.CANCEL}></Button>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center" className={`${classes.accordionContainer}`}>
                            <Grid className={classes.accordionConDiv}>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={"expandIcon"} />}
                                        aria-label="Expand"
                                        aria-controls="additional-actions1-content"
                                        id="additional-actions1-header"
                                    >
                                        <Typography variant="h6">{templates_page_constants.GENERAL}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={6}>
                                                        <Typography variant={"body1"}>{templates_page_constants.NAME}<span className={"reqStar"}>*</span></Typography>
                                                        <TextField
                                                            id={"name"}
                                                            name={"name"}
                                                            size={"small"}
                                                            margin={"none"}
                                                            error={errors.name ? true : false}
                                                            helperText={errors.name?.message}
                                                            inputRef={register({
                                                                validate: {
                                                                    ...validations.isRequired('Name cannot be blank')
                                                                }
                                                            })}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant={"body1"}>{templates_page_constants.DESCRIPTION}</Typography>
                                                        <TextField
                                                            id={"description"}
                                                            name={"description"}
                                                            size={"small"}
                                                            margin={"none"}
                                                            inputRef={register()}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant={"body1"}>{templates_page_constants.SELECT_AGREEMENT}<span className={"reqStar"}>*</span></Typography>
                                                        <AutoComplete
                                                            name={'selAggrement'}
                                                            suggestions={aggrementName}
                                                            disabled={false}
                                                            handleAutoComplete={(newValue, name) => { aggTemplateMap(newValue) }}
                                                            value={getSelectedValue(aggrementName, aggSel)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant={"body1"}>{templates_page_constants.SELECT_EMAIL_TEMPLATE}</Typography>
                                                        <AutoComplete
                                                            name={'selEmail'}
                                                            suggestions={emailName}
                                                            disabled={false}
                                                            handleAutoComplete={(newValue, name) => { emailTemplateMap(newValue) }}
                                                            value={getSelectedValue(emailName, emailSel)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant={"body1"}>{templates_page_constants.CONFIRM_MESSAGE}</Typography>
                                                        <TextareaAutosize name={"confirmation"} ref={(e) => { register(e) }} className={classes.allianceTextarea} aria-label="minimum height" minRows={5} placeholder={"Read, acknowledged, and authorized."} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Controller
                                                            name="is_confirm"
                                                            control={control}
                                                            render={props =>
                                                                <CheckBox
                                                                    label={templates_page_constants.IS_VISIBLE}
                                                                    checked={props.value}
                                                                    color="secondary"
                                                                    handleChange={(e) => { props.onChange(e.target.checked); }}
                                                                    inputRef={register()}
                                                                />
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={"expandIcon"} />}
                                        aria-label="Expand"
                                        aria-controls="additional-actions2-content"
                                        id="additional-actions2-header"
                                    >
                                        <Typography variant="h6" title={templates_page_constants.EDIT_XML}></Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid item xs={12}>
                                        <Controller
                                                name="xml"
                                                control={control}
                                                render={props =>
                                                    <TextAreaWithLines
                                                        onChange={e => props.onChange(e.target.value)}
                                                        value={props.value}

                                                    />
                                                }
                                            />
                                            {/* <TextareaAutosize name={"xml"} ref={(e) => { register(e) }} className={classes.allianceTextarea} aria-label="minimum height" rowsMin={8} /> */}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={"expandIcon"} />}
                                        aria-label="Expand"
                                        aria-controls="additional-actions3-content"
                                        id="additional-actions3-header"
                                    >
                                        <Typography variant="h6" className={classes.infoWhite} title={templates_page_constants.EDIT_LAYOUT}><Info>
                                                <p dangerouslySetInnerHTML={{ __html: `${templates_page_constants.LAYOUT_TEXT}` }} className={`${classes.borderline} pb-1`}></p>
                                            </Info></Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid item xs={12}>
                                            
                                            <Controller
                                                name="layout"
                                                control={control}
                                                render={props =>
                                                    <TextAreaWithLines
                                                        onChange={e => props.onChange(e.target.value)}
                                                        value={props.value}

                                                    />
                                                }
                                            />
                                            {/* <TextareaAutosize name={"layout"} ref={(e) => {register(e)}} className={classes.allianceTextarea} aria-label="minimum height" rowsMin={8} /> */}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Dialog>
        </React.Fragment>
    );
}
// default props
AddEditAppTemplate.defaultProps = {
    classes: {},
    templateID: null,
    openCloseAdd: () => { }
};

// prop types
AddEditAppTemplate.propTypes = {
    classes: PropTypes.object,
    templateID: PropTypes.number,
    openCloseAdd: PropTypes.func
};

export default withStyles(TemplatesStyles)(AddEditAppTemplate);
