import React, { useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

//Styles
import SettingsStyles from "./SettingsStyles"

// Import Actions
import { settingsAction, updateReducerAction, userAction} from "../../redux/actions";
import { settingsConstant } from "../../redux/constants";

// Import Components
import { Typography, PageContainer } from "../../components";
import ApplicationTab from './Components/ApplicationTab';
import SystemConfigurationTab from './Components/SystemConfigurationTab';
import ProfileTab from './Components/Profile'

// Import Config Layout
import { getChildContainerTabsHeight } from "../../config/layout";

const height = getChildContainerTabsHeight();


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className="h-100">
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const Settings = (props) => {
    const dispatch = useDispatch()
    const { classes, match } = props;

    const { currentUser } = useSelector(state => state.user);
    const { application } = useSelector(state => state.settings);

    //const [updateButton, setUpdateButton] = useState({"introduction":false, "payment":false})
    let updateButton = {
        "introduction":false, "payment":false
    }

    useEffect(() => {
        if (currentUser.id) {
            dispatch(settingsAction.getProfile(currentUser.id, () => {
                dispatch(settingsAction.getApplication(() => {
                    dispatch(settingsAction.getConfiguration(() => {
                        dispatch(userAction.getRegion());
                    }));
                }));
            }));
        }
    }, [currentUser])

    const [value, setValue] = React.useState(0);

    //handle change
    const handleChange = (event, newValue) => {
        dispatch(updateReducerAction.update(settingsConstant.UPDATE_APPLICATION_REQUEST, application, () => {
            setValue(newValue);
        }));
    };

    //handle rich text change
    const handleTempRichTextChange = (v, key) => {
        updateButton[key] = true
        application[key] = v
    }

    const handleTempPayTextChange = (v, key) =>{
        updateButton[key] = true
        application[key] = v
        dispatch(updateReducerAction.update(settingsConstant.UPDATE_APPLICATION_REQUEST, application, () => {}));
    }

    //update flag reducer
    const handleFlagChange = (key) =>{
        updateButton[key] = false
    }

    return (
        <React.Fragment >
            <Grid item xs={12} >
                <Grid container direction="row" justifyContent="space-between" alignItems="center" className={` ${classes.titlerow}`}>
                    <Grid item>
                        <Typography variant="h3" component="h3"> {"SETTINGS"} </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <AppBar position="static" color="default" className={classes.appBarCls}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="full width tabs example"
                    >
                        <Tab style={{minWidth:"160px",padding: "7px 12px 10px"}} label={<Typography variant="h6" color="TextSecondary">{"Profile"}</Typography>} {...a11yProps(0)} />
                        {(currentUser.role === "admin") &&
                            <Tab style={{minWidth:"160px",padding: "7px 12px 10px"}} label={<Typography variant="h6" color="TextSecondary">{"Application"}</Typography>} {...a11yProps(1)} />
                        }
                        {(currentUser.role === "admin") &&
                            <Tab style={{minWidth:"160px",padding: "7px 12px 10px"}} label={<Typography variant="h6" color="TextSecondary" >{"System Configuration"}</Typography>} {...a11yProps(2)} />
                        }
                    </Tabs>
                </AppBar>

                <Grid item xs={12} >
                    <TabPanel value={value} index={0}>
                        <PageContainer height={height} paddingRight={12}>
                            <ProfileTab  {...props} />
                        </PageContainer>
                    </TabPanel>

                    <TabPanel value={value} index={1} className="h-100">
                        {/* <PageContainer height={height} paddingRight={12}> */}
                            <ApplicationTab {...props} handleTempPayTextChange={handleTempPayTextChange} handleTempRichTextChange={handleTempRichTextChange} application={application} updateButton={updateButton} handleFlagChange={handleFlagChange}/>
                        {/* </PageContainer> */}
                    </TabPanel>

                    <TabPanel value={value} index={2}>
                        {/* <PageContainer height={height} paddingRight={12}> */}
                            <SystemConfigurationTab {...props} />
                        {/* </PageContainer> */}
                    </TabPanel>

                </Grid>
            </Grid>
        </React.Fragment>
    );
}

// default props
Settings.defaultProps = {
    classes: {}
};

// prop types
Settings.propTypes = {
    classes: PropTypes.object
};

export default withStyles(SettingsStyles)(Settings);
