/* eslint-disable camelcase */
import { serviceConstant } from "../constants";

// Set Initial State
const initialState = {
  search: {
    data: [],
    page: {
      limit: 10,
      sFields: [],
      sValues: [],
      count: 0,
      skip: 1,
      sortBy: 'service_name',
      sort: 'asc',
      fieldFilters: {
        headerCols: [],
        fieldCols: [],
        searchCols: []
      }
    }
  },
  checkBoxSelect: {},
  getAll: {
    data: [],
    page: {
      limit: 20,
      skip: 1,
      sort: "desc",
      search: ''
    }
  },
  applications: 0,
  usersSearch: {
    data: [],
    page: {
      limit: 10,
      sFields: [],
      sValues: [],
      count: 0,
      skip: 1,
      sortBy: 'service_name',
      sort: 'asc',
      fieldFilters: {
        headerCols: [],
        fieldCols: [],
        searchCols: []
      }
    }
  },
  createFromScratch: {
    client_id: null,
    page: 0,
    totalPage: [0],
    tabValue: 0,
    documentData: {
      0: {},
    },
    serviceData: ["Introduction", "Criteria", "Application Signature", "Document", "Application timestamp", "Queue Submission", "Payment PDF"],
    serviceCheckedData: ["Application Review"],
    is_template_checkbox: true
  },
  createFromScratchErrorFlags: {
    client_id: false,
    documentData: {
      0: {
        choices: []
      },
    }
  },
  createFromScratchDocuments: {
    data: {},
    checkedData: {}
  },
  applicationLists: [],
  addEditService: {},
  pageViewType: "left"
};

export default function (state = initialState, action) {
  switch (action.type) {

    //update view type and search
    case serviceConstant.UPDATE_VIEW_TYPE_AND_SEARCH:
      return {
        ...state,
        checkBoxSelect: {},
        pageViewType: action.data,
        getAll: { ...state.getAll, page: { ...state.getAll.page, search: '' } },
        search: { ...state.search, page: { ...state.search.page, sFields: [], sValues: [] } }
      };

    // SEARCH SERVICE
    case serviceConstant.SERVICE_SEARCH_REQUEST:
      return {
        ...state
      };
    case serviceConstant.SERVICE_SEARCH_SUCCESS:
      return {
        ...state,
        search: {
          data: action.data.data,
          page: action.data.page
        }
      };
    case serviceConstant.SERVICE_SEARCH_FAILURE:
      return {
        ...state
      };

    // GET ALL USER
    case serviceConstant.SERVICE_GETALL_REQUEST:
      return {
        ...state
      };
    case serviceConstant.SERVICE_GETALL_SUCCESS:

      const page = action.data.page;
      let stateData = [];

      if (page.skip > 1) {
        stateData = [...state.getAll.data, ...action.data.data]
      } else {
        stateData = [...action.data.data]
      }

      return {
        ...state,
        getAll: {
          data: stateData,
          page: action.data.page
        }
      };
    case serviceConstant.SERVICE_GETALL_FAILURE:
      return {
        ...state,
        getAll: initialState.getAll
      };

    //delete service
    case serviceConstant.SERVICE_DELETE_REQUEST:
      return {
        ...state
      };
    case serviceConstant.SERVICE_DELETE_SUCCESS:
      return {
        ...state,
      };
    case serviceConstant.SERVICE_DELETE_FAILURE:
      return {
        ...state,
      };

    //service Aplication count
    case serviceConstant.SERVICE_GET_APPLICATION_COUNT_REQUEST:
      return {
        ...state,
        applications: 0
      };
    case serviceConstant.SERVICE_GET_APPLICATION_COUNT_SUCCESS:
      return {
        ...state,
        applications: action.data.data
      };
    case serviceConstant.SERVICE_GET_APPLICATION_COUNT_FAILURE:
      return {
        ...state,
      };

    // SEARCH SERVICE
    case serviceConstant.SERVICE_SEARCH_FORUSERS_REQUEST:
      return {
        ...state
      };
    case serviceConstant.SERVICE_SEARCH_FORUSERS_SUCCESS:
      return {
        ...state,
        usersSearch: {
          data: action.data.data,
          page: action.data.page
        }
      };
    case serviceConstant.SERVICE_SEARCH_FORUSERS_FAILURE:
      return {
        ...state
      };

    //get template list
    case serviceConstant.GET_TEMPLATE_LIST_REQUEST:
      return {
        ...state,
        applicationLists: []
      };
    case serviceConstant.GET_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        applicationLists: action.data.data
      };
    case serviceConstant.GET_TEMPLATE_LIST_FAILURE:
      return {
        ...state,
      };

    //create SERVICE
    case serviceConstant.CREATE_SERVICE_REQUEST:
      return {
        ...state
      };
    case serviceConstant.CREATE_SERVICE_SUCCESS:
      return {
        ...state,
      };
    case serviceConstant.CREATE_SERVICE_FAILURE:
      return {
        ...state,
      };

    //update SERVICE
    case serviceConstant.UPDATE_SERVICE_REQUEST:
      return {
        ...state
      };
    case serviceConstant.UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
      };
    case serviceConstant.UPDATE_SERVICE_FAILURE:
      return {
        ...state,
      };

    // reducer update
    case serviceConstant.CHECKBOX_REDUCER_UPDATE:
      return {
        ...state,
        checkBoxSelect: action.data
      }

    case serviceConstant.ADD_NEW_PAGE_DOCUMENT:
      return {
        ...state,
        createFromScratch: action.data
      }

    case serviceConstant.UPDATE_ERROR_FLAG_DOCUMENT:
      return {
        ...state,
        createFromScratchErrorFlags: action.data
      }

    //UPDATE FEE
    case serviceConstant.UPDATE_FEE_REQUEST:
      return {
        ...state
      };
    case serviceConstant.UPDATE_FEE_SUCCESS:
      return {
        ...state,
        checkBoxSelect: {}
      };
    case serviceConstant.UPDATE_FEE_FAILURE:
      return {
        ...state,
      };

    //EXCEL DOWNLOAD
    case serviceConstant.EXCEL_DOWNLOAD_REQUEST:
      return {
        ...state
      };
    case serviceConstant.EXCEL_DOWNLOAD_SUCCESS:
      return {
        ...state,
      };
    case serviceConstant.EXCEL_DOWNLOAD_FAILURE:
      return {
        ...state,
      };

    //EXCEL UPLOAD
    case serviceConstant.EXCEL_UPLOAD_REQUEST:
      return {
        ...state
      };
    case serviceConstant.EXCEL_UPLOAD_SUCCESS:
      return {
        ...state,
      };
    case serviceConstant.EXCEL_UPLOAD_FAILURE:
      return {
        ...state,
      };

    case serviceConstant.UPDATE_DOC_STEP_INIT:
      return {
        ...state,
        createFromScratch: {
          ...state.createFromScratch,
          page: 0,
        },
      };

    //CREATE DOCUMENTS FROM SCRATCH
    case serviceConstant.CREATE_DOCUMENTS_FROM_SCRATCH_REQUEST:
      return {
        ...state,
        createFromScratchDocuments: {}
      };
    case serviceConstant.CREATE_DOCUMENTS_FROM_SCRATCH_SUCCESS:
      return {
        ...state,
        createFromScratch: {
          ...state.createFromScratch,
          documentData: action.data.data.documentData
        },
        createFromScratchDocuments: {
          data: action.data.data.documents,
          checkedData: action.data.data.documents,
        }
      };
    case serviceConstant.CREATE_DOCUMENTS_FROM_SCRATCH_FAILURE:
      return {
        ...state,
      };

    //edit servive update
    case serviceConstant.ADD_EDIT_SERVICE_REDUCER_UPDATE:
      return {
        ...state,
        addEditService: action.data
      }

    //update aggrement reducer
    case serviceConstant.UPDATE_AGREEMENT_DOCUMENT:
      return {
        ...state,
        createFromScratchDocuments: action.data
      }

    //update alignment reducer
    case serviceConstant.UPDATE_SERVICE_ALIGNMENT:
      return {
        ...state,
        pageViewType: action.data
      }

    //CREATE AGREEMENT FROM SCRATCH
    case serviceConstant.CREATE_AGREEMENT_FROM_SCRATCH_REQUEST:
      return {
        ...state
      };
    case serviceConstant.CREATE_AGREEMENT_FROM_SCRATCH_SUCCESS:
      return {
        ...state,
        createFromScratch: {
          ...state.createFromScratch,
          agreement_id: action.data.data
        }
      };
    case serviceConstant.CREATE_AGREEMENT_FROM_SCRATCH_FAILURE:
      return {
        ...state,
      };

    //CREATE TEMPLATE FROM SCRATCH
    case serviceConstant.CREATE_TEMPLATE_FROM_SCRATCH_REQUEST:
      return {
        ...state
      };
    case serviceConstant.CREATE_TEMPLATE_FROM_SCRATCH_SUCCESS:
      return {
        ...state,
        createFromScratch: {
          ...state.createFromScratch,
          template_id: action.data.data.template_id,
          emailtemplate_id: action.data.data.emailtemplate_id
        }
      };
    case serviceConstant.CREATE_TEMPLATE_FROM_SCRATCH_FAILURE:
      return {
        ...state,
      };

    default:
      return state;
  }
}
