import React from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from "@mui/styles";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const useStyles = makeStyles({
  root: {
    position: "relative",
    textAlign: "center",
    padding: "100px",
    height: "100%"
  },
  icon: {
    width: "100px",
    height: "100px",
    color: "#54C0FD"
  }
});

function PageExpired() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="height100"
      >
        <Grid item xs>
          <AccessTimeIcon className={classes.icon}></AccessTimeIcon>
          <Typography variant="h2" className="mb-2 txtBold">
            Token Expired
          </Typography>

          <Typography variant="h4">
            Your reset password link expired. Please <a href="#/forgotpassword" >click here</a> to reset again.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default PageExpired;
