import { page_container_heights } from './constants'

export function getRootContainerHeight() {
  return (window.innerHeight - (page_container_heights.HEADER_HEIGHT || 55));
}

export function getChildContainerHeight() {
  return getRootContainerHeight() - 45
}

export function getChildContainerTabsHeight() {
  return getRootContainerHeight() - 85
}

export function getChildContainerUnderBtnTabsHeight() {
  return getRootContainerHeight() - 156
}

export function getTemplatesHeight() {
  return getRootContainerHeight() - 156
}

export function getTableHeight() {
  return getRootContainerHeight() - 144
}

export function getTabsTableHeight() {
  return getRootContainerHeight() - 235
}

export function getTabsAuditTableHeight() {
  return getRootContainerHeight() - 185
}

export function getChildContainerIndividual() {
  return getRootContainerHeight() - 55
}

export function getTableRowSize () {
  return (Math.floor((getTableHeight() - 80) / 37));
}

export function getTabsTableRowSize () {
  return (Math.floor((getTableHeight() - 160) / 40));
}

export function getAuditTableRowSize () {
  return (Math.floor((getTableHeight() - 80) / 39));
}

export function getScrollUp() {
  let width = window.innerWidth - 65;
  let noCard = width / 294;
  return Math.floor(noCard) * 5;
}


export function usersCardLimit() {
  let width = window.innerWidth - 65;
  let noCard = width / 294;
  return Math.floor(noCard) * 5;
}

export function servicesCardLimit() {
  let width = window.innerWidth;
  let noCard = width / 294;
  return Math.floor(noCard) * 5;
}

export function directorysCardLimit() {
  let width = window.innerWidth;
  let noCard = width / 294;
  return Math.floor(noCard) * 5;
}

export function applicationsCardLimit() {
  let width = window.innerWidth;
  let noCard = width / 450;
  return Math.floor(noCard) * 8;
}

export function emailCardLimit() {
  let width = window.innerWidth;
  let noCard = width / 294;
  return Math.floor(noCard) * 5;
}

export function agreementsCardLimit() {
  let width = window.innerWidth;
  let noCard = width / 450;
  return Math.floor(noCard) * 8;
}

export function documentsCardLimit() {
  let width = window.innerWidth;
  let noCard = width / 450;
  return Math.floor(noCard) * 8;
}

export function criteriaCardLimit() {
  let width = window.innerWidth;
  let noCard = width / 450;
  return Math.floor(noCard) * 8;
}