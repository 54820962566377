import React from "react";
import { Switch } from "react-router-dom";

// Imports Routes Authentications Middlewares
import { PublicRoute, PrivateRoute, AuthenticationRoute,CommonRoute, BannerRoute } from "./auth_routes";

// Import Pages
import Login from "./containers/Login";
import RequestAccess from "./containers/RequestAccess";
import ResetPassword from "./containers/ResetPassword";
import ContactUs from "./containers/ContactUs";

import AuditRouter from "./containers/Audit/Audit.Router"
import Users from "./containers/Users/Users.Router";
import ServicesRouter from "./containers/Services/Services.Router";
import Templates from "./containers/Templates/Templates";
import SubmissionsRouter from "./containers/Submissions/Submissions.Router";
import ApplicationRouter from "./containers/Application/Application.Router";
import SettingsRouter from "./containers/Settings/Settings.Router";
import Page404 from "./containers/Page404";
import PageExpired from "./containers/PageExpired";
import PageNotHaveAccess from "./containers/PageNotHaveAccess";
import TenantApplication from "./containers/TenantApplication/TenantApplication"
import DirectoryRouter from "./containers/Directory/Directories.Router";
import DirectoryFrame from "./containers/Directory/DirectoryFrame"
import SubmitSuccess from "./containers/TenantApplication/SubmitSuccess";
import invalidDOB from "./containers/TenantApplication/InvalidDOB"
import Success from "./containers/SuccessPage/Succss"
import Tenant from "./containers/OnlinePages/Tenant"

// Import History
import history from "../src/config/history";
// import { JsonEditor } from "./components/JSONEditor/JsonEditor";

const routes = () => {

    //redirect to page on login
    const isSignedIn=localStorage.getItem('staySignIn')
    //current url
    const pathName=history.location.pathname;

    if (isSignedIn==='true' && pathName.indexOf("/a/")===-1 && pathName.indexOf("/view/")===-1 && pathName.indexOf("/link/")===-1  && pathName.indexOf("directoryList")===-1 && pathName.indexOf("resetpassword")===-1 && pathName.indexOf("requestaccess")===-1 && pathName.indexOf("contactUs")===-1 ) {
        history.push({ pathname: `/users`});
    }

    return (
        <Switch>
            <AuthenticationRoute exact path="/" component={Login} />
            <AuthenticationRoute exact path="/login" component={Login} />
            <AuthenticationRoute exact path="/requestaccess" component={RequestAccess} />
            <AuthenticationRoute exact path="/resetpassword/:hash/:key" component={ResetPassword} />
            {/* <AuthenticationRoute exact path="/contactUs" component={ContactUs} /> */}
            <AuthenticationRoute exact path="/success" component={Success} />

            <BannerRoute exact path="/apply-online/rental" component={Tenant} />
            <BannerRoute exact path="/apply-online/employment" component={Tenant} />

            <PrivateRoute path="/users" component={Users}/>
            <PrivateRoute path="/submissions" component={SubmissionsRouter}/>
            <PrivateRoute path="/application" component={ApplicationRouter}/>
            <PrivateRoute path="/services" component={ServicesRouter} />
            <PrivateRoute path="/templates" component={Templates} />
            <PrivateRoute path="/settings" component={SettingsRouter} />
            <PrivateRoute path="/audit" component={AuditRouter} />
            <PrivateRoute path="/directory" component={DirectoryRouter} />
            {/* <PrivateRoute path="/directories" component={DirectoryRouter} /> */}

            <CommonRoute exact path="/directoryList" component={DirectoryFrame}/>
            
            <PublicRoute exact path="/a/:appId" component={TenantApplication} />
            <PublicRoute exact path="/view/:appId" component={TenantApplication} />
            <PublicRoute exact path="/link/:appId" component={TenantApplication} />
            <PublicRoute exact path="/submitSuccess" component={SubmitSuccess} />

            <PublicRoute exact path="/expired" component={PageExpired} />
            <PublicRoute exact path="/noaccess" component={PageNotHaveAccess} />
            <PublicRoute exact path="/invalidDOB" component={invalidDOB} />
            {/* <PublicRoute exact path="/test" component={JsonEditor} /> */}
            <PublicRoute component={Page404} />

        </Switch>
    );
};

export default routes;
