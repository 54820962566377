import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const SessionTimeComponent = (props) => {

  //get porps
  const { onTimeout,timeOut } = props;

  //set state for timeout
  const [signoutTime, setSignoutTime] = useState(1000 * 60 * 60);

  let logoutTimeout;

  const logout = () => {
    console.log("session timed out");
    onTimeout();
  };

  const setTimeouts = () => {
    logoutTimeout = setTimeout(logout, timeOut);
  };

  const clearTimeouts = () => {
    if (logoutTimeout) clearTimeout(logoutTimeout);
  };

  useEffect(() => {
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
    };

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    setTimeouts();
    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    };
  }, []);

  return <div></div>;
};

SessionTimeComponent.defaultProps = {
  onTimeout: () => {},
  timeOut:3600000
};

SessionTimeComponent.propTypes = {
  onTimeout: PropTypes.func,
  timeOut:PropTypes.number,
};
export const SesionTime = SessionTimeComponent;
