const SelectComponentStyles = (theme) => ({

  selectBox: {
    width: "100%",
    '& .MuiSelect-outlined': {
      padding: 10
    },
    '& .MuiOutlinedInput-root': {
      height: 34,
      minHeight: 34
    }
  },
  // '& .MuiOutlinedInput-notchedOutline legend': {
  //   display: "none !important"
  // },
  '& .MuiOutlinedInput-notchedOutline ': {
    '&legend': {
      display: "none !important",
    },
      marginTop: '5px !important'
  },
  borderColorRed:{
    borderColor :"red"
  }


})
export default SelectComponentStyles