import React from "react";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid';
import Lottie from 'react-lottie'

// Import Components
import { Button, Typography } from "../../components";

//Images
import animationData from '../../assets/images/mailsentAnimation.json'

//history
import history from "../../config/history"

const Success = () => {

    const animateProps = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Grid container alignItems={"center"} alignContent="baseline" justifyContent="center" className={"h-100 "} style={{ backgroundColor: "white" }}>
            <Grid item xs={12} align="center" >
                <Lottie options={animateProps} height={280} width={280} />
                <Typography variant="h4" align="center" style={{ whiteSpace: "nowrap" }} className={"pb-2"}>{"Password reset link has been sent"}</Typography>
                <Typography variant="h6" align="center" className={"pb-2"}>{"kindly check your mail"}</Typography>
            </Grid>
            <Grid item className="mt-2">
                <Button className="mr-2" size="small" color="primary" title={"RESEND LINK"} onClick={() => history.push("/requestaccess")}></Button>
                <Button size="small" color="primary" title={"Back To Login"} onClick={() => history.push("/login")}></Button>

            </Grid>
        </Grid>
    );
}

// default props
Success.defaultProps = {
    classes: {}
};

// prop types
Success.propTypes = {
    classes: PropTypes.object
};

export default Success;