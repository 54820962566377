import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles"; // Assuming you're using the v5 styling solution
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import Grow from '@mui/material/Grow';
import ToggleButton from '@mui/lab/ToggleButton';
import ToggleButtonGroup from '@mui/lab/ToggleButtonGroup';


//import Styles and images
import UsersStyles from "./UsersStyles"
import { TileIcon, ListIcon, SearchIcon, SortIcon, SearchCloseIcon } from '../../assets/svg'

// Import Components
import { Button, Typography, TextField } from "../../components";
import ColumnSettings from './Components/userList/ColumnSettings';
import UserTile from './Components/userTiles/UsersTile';
import UserList from './Components/userList/UserList';

// Import History
import history from "../../config/history";

// Import Cofig Constants
import { user_page_constants } from "../../config/constants";

// Import Actions
import { userAction, updateReducerAction } from "../../redux/actions";

//import constants
import { userConstant } from "../../redux/constants";

// Import Config Layout
import { usersCardLimit } from "../../config/layout";

const Users = (props) => {
  //define dispatch
  const dispatch = useDispatch();

  //get props
  const { classes, match } = props;

  // Set Card Limit and Size
  let cardLimit = usersCardLimit();

  //get reducer data
  let { chordFilter: { rolesSel, companySel }, getAll: { page }, viewType } = useSelector((state) => state.user);
  const { loading } = useSelector(s=>s.UI)

  //Toggle button for list and chord view
  const [search, setSearchValue] = React.useState(page.search ? true : false);
  const [searchData, setSearchData] = React.useState(page.search ? page.search : "");

  //show hide search box
  const enableSearch = () => {
    setSearchValue(!search)
  }

  const hideSearchandClear = () => {
    setSearchValue(!search)
    setSearchData("");
  }

  useEffect(() => {
    if (page.search === "") {
      setSearchData(page.search)
    }
    if (page.search) {
      setSearchValue(true)
    }
  }, [page.search])

  //update search and timeout
  useEffect(() => {
    const delaySet = setTimeout(() => {
      dispatch(userAction.getAll({ limit: cardLimit, skip: 1, rolesSel: rolesSel, companySel: companySel, search: searchData, sort: 'desc' }));
    }, 600)
    return () => clearTimeout(delaySet)
  }, [searchData]);

  //hadle search to get data
  const handleSearch = (e) => { 
    if(!loading){
      setSearchData(e.target.value) 
    }
  }

  //udate view type in reducer
  const setviewType = (type) => {
    if (type) {
      dispatch(updateReducerAction.update(userConstant.UPDATE_VIEW_TYPE, type));
      if (type === "table") {
        history.push(`users?page=true`)
      } else {
        history.push(`/users`)
      }
    }
  }

  //reset and route
  const resetAndRoute = () => {
    //init user data
    dispatch(userAction.resetUserData())
    history.push(`${match.url}/add`)
  }

  //get sort data
  const getSortedData = (sorting) => {
    dispatch(userAction.getAll({ limit: cardLimit, skip: 1, rolesSel: rolesSel, companySel: companySel, search: searchData, sort: sorting }));
  }



  return (
    <React.Fragment>
      <Grid item xs={12} >
        <Grid container direction="row" justifyContent="space-between" alignItems="center" className={`${classes.titlerow}`}>
          <Grid item>
            <Typography variant="h3" component="h3"> {`${user_page_constants.USERS} ${viewType !== "table" && page && page.totalCount >= 0 ? `(${page.totalCount})` : ""}`} </Typography>
          </Grid>
          <Grid item>
            <Grid container  alignItems="center">
              {viewType !== "table" && search &&
                <Grid className={`${classes.searchBoxGrid} ${viewType !== "table" && search && "searchBoxGridShow"}`}>
                  <TextField
                    id="standard-start-adornment"
                    variant="outlined"
                    size="small"
                    margin="none"
                    fullWidth={true}
                    autoFocus
                    placeholder="Search"
                    className={"searchBox"}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{searchData === "" ? <IconButton onClick={enableSearch} style={{ padding: '0px' }} size="large"><SearchIcon className={classes.seachIcon} /></IconButton> : <IconButton onClick={hideSearchandClear} style={{ padding: '0px' }} size="large"><SearchCloseIcon /></IconButton>}</InputAdornment>,
                    }}
                    value={searchData}
                    onChange={(e) => { handleSearch(e) }}
                  />
                </Grid>
              }
              {viewType === "table" ? <ColumnSettings /> : <IconButton className={classes.padding6} onClick={enableSearch} size="large">{!search && <SearchIcon />}</IconButton>}
              {viewType !== "table" &&
                <Grid>
                  {page && page.sort && page.sort === "asc" ?
                    <Tooltip arrow title="Sort By Created Date"><IconButton
                      className={classes.searchIcon}
                      onClick={() => { getSortedData('desc') }}
                      size="large"><SortIcon /></IconButton></Tooltip>
                    :
                    <Tooltip arrow title="Sort By Created Date"><IconButton
                      className={classes.searchIcon}
                      onClick={() => { getSortedData('asc') }}
                      size="large"><SortIcon /></IconButton></Tooltip>
                  }
                </Grid>
              }
              <ToggleButtonGroup
                value={viewType}
                exclusive
                onChange={(event, newAlignment) => { setviewType(newAlignment) }}
                aria-label="text alignment"
                className={`${"ml-1"} ${classes.toggleButton}`}
              >
                <ToggleButton value="left" aria-label="left aligned">
                  <TileIcon />
                </ToggleButton>
                <ToggleButton value="table" aria-label="centered">
                  <ListIcon />
                </ToggleButton>
              </ToggleButtonGroup>
              <Button variant="contained" size="small" color="primary" className={"ml-1"} onClick={() => { resetAndRoute() }} title={user_page_constants.ADD_USER} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      { viewType === "table" ? <UserList {...props} /> : <UserTile {...props} />}
    </React.Fragment>
  );
}

// default props
Users.defaultProps = {
  classes: {}
};

// prop types
Users.propTypes = {
  classes: PropTypes.object
};

export default withStyles(UsersStyles)(Users);
