import React from "react";
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { SmoothProvider } from 'react-smooth-scrolling'


// Import Material Theme
import theme from "../assets/theme";

// Import Routes
import Routes from "../routes";

// Import Components
import { SnackBar, NetworkDetector, PageLoader } from "../components";

function App() {
  return (
    <React.StrictMode>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <Routes />
        </ThemeProvider>
      </StyledEngineProvider>
      <SnackBar />
      <PageLoader />
    </React.StrictMode>
  );
}

export default App;
