import '../../assets/theme/palette';
import palette from '../../assets/theme/palette';
const TemplatesStyles = () => ({
    searchIcon: { padding: 6, marginRight: 10 },
    marginBottom15: { marginBottom: 15 },
    btnBlue: {
        backgroundColor: palette.primary.dary,
        color: "#fff",
        '&:hover': {
            color: "#fff",
            backgroundColor: '#1b88be',
        }
    },
    containerScroll: {
        // height: 'calc(100vh - 125px)',
        overflowY: 'auto',
        paddingLeft: 16,
        paddingTop: '8px !important',
        alignItems: 'baseline'
    },
    containerCriteriaScroll: {
        overflowY: 'auto',
        paddingLeft: 16,
        overflowX: 'hidden',
        paddingTop: '8px !important',
        alignItems: 'baseline'

    },
    titlerow: {
        height: '34px',
        padding: '0 16px',

        // backgroundColor: 'blue',
        // padding: '10px 0',
        '& .MuiToggleButton-root': {
            padding: 7
        }
    },
    selectBox: {
        width: "100%",
        '& .MuiSelect-outlined': {
            padding: 10,
            textAlign: 'left',
            borderRadius: 30
        },
        '& .MuiOutlinedInput-notchedOutline legend': {
            display: "none !important"
        },
        '& .MuiOutlinedInput-notchedOutline': {
            marginTop: "5px !important"
        },
    },
    serviceActionBtn: {
        padding: '12px 16px 0 16px',
        marginBottom: 8
    },
    cardSection: {
        height: '100%',
        width: "100%",
        borderRadius: '4px',
        boxShadow: '0px 0px 16px rgba(0,0,0,0.2)',
        padding: '8px 16px',
    },
    //Application Template
    //Add Application Template
    dialogHeader: {
        background: '#fff',
        // position: 'sticky',
        // top: 0,
        zIndex: '9999'
    },

    addEditTemplate: {
        '& .MuiDialog-paperFullScreen': {
            background: '#F7F7F7 !important'
        }
    },
    accordionConDiv: {
        maxWidth: 1000,
    },
    // ........
    accordionContainer: {
        // maxWidth: 1000,
        // margin: 'auto',
        height: 'calc(100vh - 115px)',
        overflowY: 'auto',
        marginBottom: 20,
        '& .MuiAccordionSummary-root': {
            minHeight: 41,
            background: '#fff',
            '& .MuiIconButton-root': {
                padding: 5
            }
        },
        '& .MuiAccordionSummary-content': {
            margin: '5px 0'
        },
        '& .MuiPaper-elevation1': {
            boxShadow: '0px 0px 8px #d4d1d1',
            // boxShadow: 'none',
            // borderBottom: `1px solid ${palette.greyshades.light2}`,
            borderRadius: 0,
            marginBottom: 20,
            '&:before': {
                top: '0 !important'
            }
        },
        '& .MuiAccordion-root:before': {
            // backgroundColor: palette.primary.main
        },
        '& .MuiAccordion-root.Mui-expanded': {
            marginBottom: 25,
            '& .MuiAccordionSummary-root': {
                '& .MuiTypography-root': {
                    color: '#fff'
                },
            },
            '& .expandIcon': {
                fill: '#fff'
            }
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            borderBottom: `1px solid ${palette.greyshades.light2}`,
            backgroundColor: palette.primary.main,
            '& .infoIcon path': {
                fill: '#fff'
            }
        },
        '& .MuiAccordionDetails-root': {
            padding: '15px 35px',
            background: '#fff',
            '& .searchIcon': {
                width: 15,
                '& path': {
                    fill: "#D2D3D4"
                }
            }
        },
    },
    // .........
    allianceTextarea: {
        borderRadius: 4,
        borderColor: '#D2D3D4',
        padding: 8,
        fontSize: 14,
        fontFamily: 'Open Sans',
        width: '100%',
        '&:hover': {
            borderColor: 'rgb(95 95 95 / 87%)',
        },
        '&:focus': {
            border: `2px solid ${palette.primary.main}`,
            outline: 'transparent !important',
            padding: 7
        }
    },
    //Card
    ApplicationlTile: {
        '&.ApplicationlTile': {
            maxHeight: 118
        },
        background: '#FFFFFF',
        boxShadow: '0px 0px 8px #d4d1d1',
        borderRadius: 4,
        padding: '20px 10px 20px 20px',
        '& .MuiCheckbox-root': {
            padding: 0
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: 0
        }
    },
    AppLeftImage: {
        width: 68,
        height: 78,
        background: palette.greyshades.light3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiTypography-root': {
            color: `${palette.greyshades.light1} !important`
        }
    },
    AppCenterPart: {
        flexBasis: 'calc( 100% - 90px)',
        maxWidth: 'calc( 100% - 90px)',
        padding: '0 15px',
    },
    templateText: {
        marginBottom: 5
    },
    AppRightPart: {

    },
    appTempChip: {
        height: '26px !important',
        overflow: 'hidden',
        width: '100%',
        '& .MuiChip-root': {
            background: palette.greyshades.light3,
            border: `1px solid ${palette.greyshades.light2}`,
            borderRadius: 20,
            padding: '0 8px',
            marginRight: 10,
            height: 26,
            maxWidth: '50%'
        }
    },
    appTemplateDialog: {
        background: palette.greyshades.light3,
        position: 'relative'
    },


    //Email Template
    //Card
    EmailTile: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 8px #d4d1d1',
        borderRadius: 4,
        height: '100%',
        maxHeight: 280,
        // padding: 20,
        '& .MuiCheckbox-root': {
            padding: 0
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: 0
        }
    },
    tileTop: {
        padding: '0px 20px 0px 20px',
        marginTop: -18
    },
    tileBottom: {
        padding: '20px'
    },
    emailTemplateImg: {
        margin: "auto"
    },
    emailTemplateBodyTxt: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: 'webkit-box',
        webkitLineClamp: 3,
        webkitBoxOrient: 'vertical'
    },

    //Agreement
    AgreementTile: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 8px #d4d1d1',
        borderRadius: 4,
        padding: '20px 10px 20px 20px',
        '& .MuiCheckbox-root': {
            padding: 0
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: 0
        }
    },
    AgreementLeftImage: {
        width: 68,
        height: 57,
        background: palette.greyshades.light3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiTypography-root': {
            color: `${palette.greyshades.light1} !important`
        }
    },
    AgreementCenterPart: {
        flexBasis: 'calc( 100% - 90px)',
        maxWidth: 'calc( 100% - 90px)',
        padding: '0 15px',
    },
    AgreementTempChip: {
        width: '100%',
        '& .MuiChip-root': {
            background: palette.greyshades.light3,
            border: `1px solid ${palette.greyshades.light2}`,
            borderRadius: 20,
            padding: '0 8px',
            marginRight: 12,
            // marginBottom: 5,
            maxWidth: 'calc(50% - 24px)',
            height: 26,
            '& .MuiChip-label': {
                paddingLeft: '5px !important'
            }
        }
    },

    allianceDrawer600: {
        '& .MuiDrawer-paper': {
            width: 600
        }
    },

    //Document
    choicesContainer: {
        padding: 20,
        background: palette.greyshades.light3,
        border: `1px solid ${palette.greyshades.light2}`,
        '&:first-child': {
            marginTop: '10px !important'
        }
    },
    settingsTileBgnoneAddDoc: {
        padding: '10px',
        border: `1px solid ${palette.greyshades.light2}`,
        borderRadius: 4,
        backgroundColor: '#fff',
        marginBottom: 12,
        '& .MuiTypography-root': {
            width: '70%',
        },
        '& .MuiSvgIcon-root': {
            width: '10%',
        },
        '& .dragIcon': {
            cursor: "move",
            marginRight: 5
        }
    },
    notifyIcon: {
        width: 28,
        height: 28,
        background: palette.greyshades.light2
    },

    //Criteria Tile
    CriteriaTile: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 8px #d4d1d1',
        borderRadius: 4,
        padding: '20px 7px 20px 20px',
        '& .MuiCheckbox-root': {
            padding: 0
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: 0
        }
    },
    CriteriaLeftImage: {
        width: 45,
        height: 45,
        background: palette.greyshades.light3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiTypography-root': {
            color: `${palette.greyshades.light1} !important`
        }
    },
    CriteriaCenterPart: {
        flexBasis: 'calc( 100% - 70px)',
        maxWidth: 'calc( 100% - 70px)',
        padding: '0 15px',
    },

    //Drag Container
    grow: {
        flexGrow: 1
    },
    AddSettingsTile: {
        padding: '8px 13px 8px 7px',
        border: `1px solid ${palette.greyshades.light2}`,
        borderRadius: 4,
        backgroundColor: palette.greyshades.light3,
        marginBottom: 15,
        minHeight: 42,
        '& .dragIcon': {
            cursor: "move",
            marginRight: 5,
        }
    },
    SettignsTileText: {
        maxWidth: 'calc(100% - 55px)'
    },

    //Drawer
    backDrop: {
        zIndex: 2
    },
    allianceDrawer: {
        '& .MuiDrawer-paper': {
            width: "100%"
        }
    },
    //
    appBarCls: {
        padding: '0 16px 0px',
        backgroundColor: 'transparent'
    },
    textFieldChoices: {
        width: 'calc(100% - 105px)'
    },
    choiceDelete: {
        position: 'absolute',
        right: 15,
        top: 15
    },
    customChipContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center'
    },
    customChip: {
        background: palette.greyshades.light3,
        border: `1px solid ${palette.greyshades.light2}`,
        height: '26px',
        padding: '0px 8px',
        paddingTop: '3px',
        marginRight: '12px',
        borderRadius: '20px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        paddingLeft: '12px',
        paddingRight: '12px',
        textOverflow: 'ellipsis',
        verticalAlign: 'middle',
        fontSize: '13px !important'
    },
    customBadge: {
        '& .MuiBadge-badge': {
            width: 23,
            height: 23,
            borderRadius: 30
        }
    },
    rightPanelContainer: {
        maxHeight: 'calc(100vh - 124px)',
        overflowY: 'auto'
    },
    addEditEMailTemDiv: {
        // maxHeight: 'calc(100vh - 124px)',
        // overflowY: 'auto',
        alignContent: 'baseline'
    },
    emailTempTextEditor: {
        '& .wrapper-class': {
            height: '210px !important'
        }
    },
    infoWhite: {

    }

})

export default TemplatesStyles;