import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Badge, Grid, IconButton, Menu, MenuItem, Slide, Tooltip } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { withStyles } from "@mui/styles";

//Styles and Images
import TemplatesStyles from "../TemplatesStyles"
import { SortIcon, MoreIcon } from '../../../assets/svg'

// Import Components
import { Typography, SearchBar, AlertDialog, Button, ToolTip, LazyLoader } from "../../../components";
import AddEditAgreement from "./AddEditAgreement";
import NoRecordsFound from "../../Users/Components/NoRecordsFound";

// Import Actions
import { templateAction } from "../../../redux/actions";

//import helpers
import { nameLogoGeneration } from '../../../utils'

// Import Config Layout
import { getChildContainerUnderBtnTabsHeight, agreementsCardLimit } from "../../../config/layout";

const Agreement = (props) => {
  //define dispatch
  const dispatch = useDispatch();

  // Set Card Limit and Size
  const cardLimit = agreementsCardLimit();
  const cardSize = Math.floor(12 / (cardLimit / 8))

  //get container height 
  const height = getChildContainerUnderBtnTabsHeight();

  //set ref for scroll
  const scrollAgreement = useRef(null)

  //get props
  const { classes } = props;

  //get reducer data
  const { agreement, agreementTemplate: { data, page } } = useSelector(s => (s.template))
  let { lazyLoading, loading } = useSelector((state) => state.UI);

  //get data oninit
  useEffect(() => {
    dispatch(templateAction.getAllAgreement({ limit: cardLimit, skip: 0, search: page.search, sort: page.sort }))
  }, [dispatch])

  // set state
  const [anchorEl, setAnchorEl] = useState(null);
  const [templateID, setTemplateID] = useState(null);
  const [deleteAlert, setDeleteAlert] = useState(null);
  const [extaChip, setExtraChip] = useState([])
  const [open, setOpen] = useState(false);
  const [showScrollTopBTN, setShowScrollTopBTN] = useState(false)

  //extra documents show
  const handleClickOpen = (event, chipData) => {
    setOpen(event.currentTarget);
    chipData.splice(0, 2)
    setExtraChip(chipData)
  };

  //extra documents hide
  const handleClickClose = () => {
    setOpen(false);
    setExtraChip([])
  };

  //handle more option change
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setTemplateID(id)
  };

  //close more options
  const handleClose = () => {
    setAnchorEl(null);
    setTemplateID(null)
  };

  //scroll event
  const getScrollData = () => {
    const isBottom = parseInt(scrollAgreement.current.scrollHeight - scrollAgreement.current.scrollTop) === (scrollAgreement.current.clientHeight) || parseInt(scrollAgreement.current.scrollHeight - scrollAgreement.current.scrollTop) === (scrollAgreement.current.clientHeight - 1) || parseInt(scrollAgreement.current.scrollHeight - scrollAgreement.current.scrollTop) === (scrollAgreement.current.clientHeight + 1);
    if (!lazyLoading && isBottom && data.length < page.count) {
      //get next data
      dispatch(templateAction.getAllAgreement({ limit: page.limit, skip: page.skip + page.limit, search: page.search, sort: page.sort, loader: true }))
    }

    if (scrollAgreement.current.scrollTop > 150 && showScrollTopBTN === false) {
      setShowScrollTopBTN(true)
    }
    else if (scrollAgreement.current.scrollTop < 150 && showScrollTopBTN === true) {
      setShowScrollTopBTN(false)
    }
  }

  //get sort data
  const getSortedData = (sorting) => {
    dispatch(templateAction.getAllAgreement({ limit: cardLimit, skip: 0, search: page.search, sort: sorting }))
  }

  //edit template
  const editAgreement = () => {
    if (templateID) {
      dispatch(templateAction.getOneAgreement({ id: templateID }, () => {
        setAnchorEl(null);
      }))
    }
  }

  //delete template
  const deleteAgreement = () => {
    if (templateID) {
      dispatch(templateAction.deleteAgreement({ id: templateID }, () => {
        dispatch(templateAction.getAllAgreement({ limit: cardLimit, skip: 0, search: page.search, sort: page.sort }))
        handleClose()
        setDeleteAlert(null)
      }))
    }
  }

  //get search data
  const intiSearch = (value) => {
    dispatch(templateAction.getAllAgreement({ limit: cardLimit, skip: 0, search: value, sort: page.sort }))
  }

  const bindDocuments = (itm, indx) => {
    let docName = itm && itm.docName ? itm.docName : '';
    let description = itm && itm.description ? itm.description : '';
    let docNameArr = docName.split(',') || [];
    let descriptionArr = description.split(',') || [];
    let docnamess = []
    docNameArr.map((value, index) => {
      if (descriptionArr[index]) {
        docnamess.push(`${value}(${descriptionArr[index]})`)
      } else {
        docnamess.push(value)
      }
    })
    let view = 2;

    return (
      <React.Fragment>
        {docNameArr && docNameArr.map((docNm, ind) => (
          <React.Fragment>
            {/* {ind < view && <ToolTip title={docNm}><Chip label={docNm ? docNm : ""} deleteIcon={<ChipCloseIcon />} /></ToolTip>} */}
            {ind < view && <ToolTip title={docnamess[ind]}><Grid className={classes.customChip}>{docNm ? docNm : ""}</Grid></ToolTip>}
          </React.Fragment>
        ))}
        {docNameArr.length > 2 && docNameArr.length !== view &&
          <Badge className={`${classes.customBadge} ${"ml-1 cursorPointer"}`} color="primary" badgeContent={`+${(docNameArr.length) - 2}`} onClick={(e) => { handleClickOpen(e, docNameArr) }} />
        }
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between" className={classes.serviceActionBtn} alignItems="center">
          <Grid item>
            <Typography variant="h6" color="TextSecondary" component="h3"> {`Count ${page && page.count >= 0 ? `(${page.count})` : ""}`} </Typography>
          </Grid>
          <Grid>
            <Grid container alignItems="center">
              <SearchBar className={"mr-1"} value={page.search} loading={loading} intiSearch={(v) => { intiSearch(v) }} />
              <Grid className={"mr-1"}>
                {page && page.sort && page.sort === "asc" ?
                  <Tooltip arrow title="Sort By Created Date"><IconButton
                    className={classes.searchIcon}
                    onClick={() => { getSortedData('desc') }}
                    size="large"><SortIcon /></IconButton></Tooltip>
                  :
                  <Tooltip arrow title="Sort By Created Date"><IconButton
                    className={classes.searchIcon}
                    onClick={() => { getSortedData('asc') }}
                    size="large"><SortIcon /></IconButton></Tooltip>
                }
              </Grid>
              <AddEditAgreement />
            </Grid>
          </Grid>
        </Grid>
        {data && data.length > 0 ?
          <Grid
            container
            spacing={0}
            className={classes.containerScroll}
            style={{ height: height, paddingRight: 12 }}
            alignContent="baseline"
            ref={scrollAgreement}
            onScroll={() => { getScrollData() }}
          >
            <Grid container spacing={2}>

              {data && data.map((itm, indx) => (
                <Grid className="animate__animated animate__fadeInUp animate__delay-0.8s" item sm={cardSize}>
                  <Grid className={classes.AgreementTile}>
                    <Grid container alignItems="center">
                      <Grid item className={classes.AgreementLeftImage}>
                        <Typography color="textSecondary" variant="h3">{nameLogoGeneration(itm.aggName ? itm.aggName : "")}</Typography>
                      </Grid>
                      <Grid item className={classes.AgreementCenterPart}>
                        <Grid container direction="column">
                          <Typography variant="h6" align="left" className={`${"transCapital oneLineEllip mb-1"}`}>{itm.aggName ? itm.aggName : ""}</Typography>
                          <Grid className={classes.customChipContainer}>
                            {bindDocuments(itm, indx)}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className={classes.AppRightPart}>
                        <IconButton
                          style={{ padding: 0 }}
                          onClick={(e) => { handleClick(e, itm.id) }}
                          size="large"><MoreIcon /></IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <LazyLoader />
            </Grid>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => { editAgreement() }}>Edit</MenuItem>
              <MenuItem onClick={() => { setDeleteAlert({ dataa: data.filter(i => (i.id === templateID)) }) }}>Delete</MenuItem>
            </Menu>
            <Menu
              id="simple-menu"
              anchorEl={open}
              keepMounted
              open={Boolean(open)}
              onClose={handleClickClose}
            >
              {extaChip && extaChip.length > 0 && extaChip.map((i) => (
                <MenuItem>{i}</MenuItem>
              ))}
            </Menu>
          </Grid>
          : !loading ? <NoRecordsFound /> : null}
        {showScrollTopBTN && <IconButton
          onClick={() => { scrollAgreement.current.scrollTo(0, 0) }}
          className={"toTop"}
          size="large"><KeyboardArrowUpIcon /></IconButton>}
      </Grid>
      {deleteAlert &&
        <AlertDialog
          fullScreen={false}
          open={true}
        >
          <Grid className={'p-4'}>
            <Typography variant="h6">{deleteAlert.dataa && deleteAlert.dataa[0] && deleteAlert.dataa[0]['templateMap'] > 0 ? `The agreement is mapped to template, you cannot delete it` : `Are you sure you want to delete`}</Typography>
            <Grid className={'pt-2'} align={"center"}>
              {deleteAlert.dataa && deleteAlert.dataa[0] && !(deleteAlert.dataa[0]['templateMap']) &&
                <Button
                  color={"primary"}
                  className={"mr-1"}
                  onClick={() => { deleteAgreement() }}
                  title={"Continue"}
                />
              }
              <Button
                color={"secondary"}
                onClick={() => { handleClose(); setDeleteAlert(null) }}
                title={"Cancel"}
              />
            </Grid>
          </Grid>
        </AlertDialog>
      }
    </React.Fragment>
  );
}

// default props
Agreement.defaultProps = {
  classes: {}
};

// prop types
Agreement.propTypes = {
  classes: PropTypes.object
};

export default withStyles(TemplatesStyles)(Agreement);