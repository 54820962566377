
import palette from '../../assets/theme/palette'
const style = (theme) => ({
  root: {
    background: palette.greyshades.light3,
    width: "100%",
    overflowY: "auto",
    padding: '16px 16px 16px'
  }
});

export default style;
