import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import PropTypes from "prop-types";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { withStyles } from "@mui/styles";
import Drawer from '@mui/material/Drawer';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';

//Styles & Images
import TemplatesStyles from "../TemplatesStyles"
import { CloseIcon } from '../../../assets/svg'

// Import Components
import { Button, TextField, Typography, RichTextEdit } from "../../../components";

// Import Actions
import { updateReducerAction,templateAction, alertAction } from "../../../redux/actions";

// Import Helpers
import validations from "../../../utils/validations";
import { richTextTester } from '../../../utils'

//import constant
import { templateConstant } from '../../../redux/constants'

// Import Cofig Constants
import { templates_page_constants } from "../../../config/constants";

const AddEditCriteria = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes } = props;

    //get reduer data
    const { criteriaDrawer:drawer,criteria,criteriaOld,criteriaTemplate:{page} } = useSelector(s=>(s.template))

    //set state for rich text value
    const [richText,setRichText]=useState(criteria && criteria.description ? criteria.description : '')
    const [descText,setDescText]=useState(criteria && criteria.description ? criteria.description : '')

    //open close add edit popup
    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        dispatch(updateReducerAction.update(templateConstant.UPDATE_CRITERIA_DRAWER,open))
    };

    // Set Form Values
    const { register, handleSubmit, errors, setValue, clearErrors } = useForm({
        mode: 'all',
        reValidateMode: 'all',
        defaultValues: {
            name:'',
        }
    });

    //update form data on change
    useEffect(() => {
        if (criteria) {
          setValue("name", criteria.name ? criteria.name : '')
          clearErrors("name");
        }
        setRichText(criteria && criteria.description ? criteria.description : '')
        setDescText(criteria && criteria.description ? criteria.description : '')

    }, [criteria]);

    // Submit Form
    const onSubmit = (data) => {
        if(data){
            if(richTextTester(richText) === true){
                dispatch(alertAction.error("Please enter description"));
            } else if (data["name"] && data["name"].length > 255) {
                dispatch(alertAction.error("Name length should be less than 255"));
            }else{
                data['id']=criteria && criteria.id ? criteria.id:null;
                data['description']=richText
                dispatch(templateAction.addOrUpdateCriteria({...data,oldData:criteriaOld},()=>{
                    dispatch(templateAction.getAllCriteria({limit:20,skip:0,search:page.search,sort:page.sort}))
                }))
            }
        }
    }

    return (
        <React.Fragment>
            <Backdrop className={classes.backDrop} onClick={()=>{toggleDrawer(false)}} open={drawer} />
            <Button color="primary" title={"+ ADD CRITERIA"} onClick={toggleDrawer(true)}></Button>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Drawer anchor='right' role="presentation" variant="persistent" open={drawer} onClose={toggleDrawer(false)} className={classes.allianceDrawer600}>
                <Grid container alignItems="center" justifyContent="space-between" className={"pl-2 pt-1 pr-2 pb-2"}>
                    <Typography variant="h4" title={criteria && criteria.id ? 'EDIT CRITERIA' :'ADD CRITERIA'}></Typography>
                    <IconButton onClick={toggleDrawer(false)} size="large"><CloseIcon /></IconButton>
                </Grid>
                <Grid container className="pl-2 pr-2 pb-2">
                        <Grid container justifyContent="space-between" spacing={3}>
                            <Grid item sm={12}>
                                <Typography variant="body1">{templates_page_constants.NAME}<span className={"reqStar"}>*</span></Typography>
                                <TextField
                                    id={'name'}
                                    name="name"
                                    margin="none"
                                    placeholder={templates_page_constants.TEMPLATE_NAME}
                                    error={errors.name ? true : false}
                                    helperText={errors.name?.message}
                                    inputRef={register({
                                        validate: {
                                            ...validations.isRequired('Name cannot be blank')
                                        }
                                    })}
                                />
                            </Grid>
                            <Grid item sm={12} className={`${classes.emailTempTextEditor} ${"bgGray"}`}>
                                <RichTextEdit
                                    value={descText ? descText :""}
                                    onChange={(v)=>{setRichText(v)}}
                                />
                            </Grid>
                        </Grid>
                </Grid>
                <Grid container className="mt-4 p-2">
                    <Button color="primary" size="small" type={"submit"} title={criteria.id?'Update':'Create'} className="mr-1"></Button>
                    <Button color="secondary" size="small" onClick={toggleDrawer(false)} title={templates_page_constants.CANCEL}></Button>
                </Grid>
            </Drawer>
            </form>
        </React.Fragment>
    );
}
// default props
AddEditCriteria.defaultProps = {
    classes: {},
};

// prop types
AddEditCriteria.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(TemplatesStyles)(AddEditCriteria);
