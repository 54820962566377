export const tenantAppConstant = {
  // Get Application By Id
  APP_GET_ONE_REQUEST: "APP_GET_ONE_REQUEST",
  APP_GET_ONE_SUCCESS: "APP_GET_ONE_SUCCESS",
  APP_GET_ONE_FAILURE: "APP_GET_ONE_FAILURE",

  //submit Application By Id
  APP_SUBMIT_REQUEST: "APP_SUBMIT_REQUEST",
  APP_SUBMIT_SUCCESS: "APP_SUBMIT_SUCCESS",
  APP_SUBMIT_FAILURE: "APP_SUBMIT_FAILURE",

  //update active step
  UPDATE_ACTIVE_STEP: 'UPDATE_ACTIVE_STEP',

  //update completed steps
  UPDATE_COMPLETED_STEP: "UPDATE_COMPLETED_STEP",

  //update introduction
  UPDATE_INTRODUCTION: "UPDATE_INTRODUCTION",

  //update criteria
  UPDATE_CRITERIA: "UPDATE_CRITERIA",

  UPDATE_DISCLOSURE_DISABLE: "UPDATE_DISCLOSURE_DISABLE",

  //update payment
  UPDATE_PAYMENT: "UPDATE_PAYMENT",

  //update application
  UPDATE_APPLICATION: "UPDATE_APPLICATION",

  //application form validation
  UPDATE_APPLICATION_VALIDATION: "UPDATE_APPLICATION_VALIDATION",

  //payment form validation
  UPDATE_PAYMENT_VALIDATION: "UPDATE_PAYMENT_VALIDATION",

  //update disclosure
  UPDATE_DISCLOSURE: "UPDATE_DISCLOSURE",

  //update disclosure steps
  UPDATE_DISCLOSURE_STEP: "UPDATE_DISCLOSURE_STEP",

  //UPDATE_SETTINGS
  UPDATE_SETTINGS: "UPDATE_SETTINGS",

  //update DOB
  UPDATE_DOB: "UPDATE_DOB",

  UPDATE_DISC_COND: "UPDATE_DISC_COND",

  UPDATE_CARD_TOKEN:"UPDATE_CARD_TOKEN",

  //submit Application By Id
  CHECK_PASS_REQUEST: "CHECK_PASS_REQUEST",
  CHECK_PASS_SUCCESS: "CHECK_PASS_SUCCESS",
  CHECK_PASS_FAILURE: "CHECK_PASS_FAILURE",

  UPDATE_APPLICATION_CONFIRM:"UPDATE_APPLICATION_CONFIRM"
};
