import * as apihelper from "../../utils/apiHelper";

/**
 * Export All Authentication Service Functions
 */
export const auditService = {
  search,
  getValues
};

/**
 * Call audit Get All Search Service
 */
async function search(skip, limit, sortBy, sort, sFields, sValues, currentUID, currentPage) {
  const response = await apihelper.postRequest(`audit/search?skip=${skip}&limit=${limit}&sortBy=${sortBy}&sort=${sort}`, { sFields, sValues, currentUID, currentPage});
  return response;
}

/**
 * get current log
 */
 async function getValues(data) {
  const response = await apihelper.postRequest(`audit/log`, data);
  return response;
}
