import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import CloseIcon from '@mui/icons-material/Close';

import styles from './styles'

/**
 * SwitchComponent
 * @param {*} props
 */

function SearchComponent (props) {

  const { reset, timeOut, loading } = props;
  const [value, setValue] = useState(props.value);
  const [userChange, setUserChange] = useState(false);
  const debouncedSearchTerm = useDebounce(value, timeOut);

  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm) {
        props.onInputChangeRequest(props.field, value, userChange);
      } else if (props.value) {
        props.onInputChangeRequest(props.field, value, userChange);
      } else {
        setValue("");
        props.handleClearSearch();
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  useEffect(() => {
    if (props.value) {
        props.onInputChangeRequest(props.field, value, userChange);
    }
    if (reset) {
      setValue("");
    }
  }, [reset]);

  // Handle Clear Text
  const handleClearSearch = () => {
    setValue("");
    props.handleClearSearch();
  };
  const { classes, placeholder, className, showClearIcon, fullWidth, maxLength, ariaLabel } = props;

  return (
    <Input
      className={className ? `${classes.root} ${className}` : classes.root}
      value={value}
      onChange={e => {
        if(!loading){
          setValue(e.target.value)
          setUserChange(true)
        }
        // props.onInputChangeRequest(props.field, value, userChange)
      }}
      placeholder={placeholder}
      disableUnderline={true}
      fullWidth={fullWidth}
      onKeyPress={(e) => {
        const enterOrSpace =
          e.key === "Enter" ||
          e.which === 13
        if (enterOrSpace) {
          e.preventDefault();
          props.onEnter(props.field, value, userChange)
        }
      }}
      inputProps={{
        "aria-label": ariaLabel || "Search",
        className: (showClearIcon && value.length > 0) ? classes.inputRootWithValue : classes.inputRootWithOutValue,
        maxLength: maxLength
      }}
      endAdornment={(showClearIcon && value.length > 0) && (
        <InputAdornment position="end">
          <IconButton
            className={classes.closeicon}
            aria-label="search"
            onClick={handleClearSearch}
            edge={"start"}
            size="large">
          <CloseIcon />
            {/* <img src={CloseImg} className={`${"peTabDelete"}`} alt="Close"/>  */}
          </IconButton>
        </InputAdornment>
      )
      }
    />
  );
}

// default props
SearchComponent.defaultProps = {
    label: "",
    value: "",
    placeholder: "",
    field: "",
    reset: false,
    classes: {},
    className: "",
    showClearIcon: false,
    maxLength: 60,
    fullWidth: false,
    timeOut: 650,
    onInputChangeRequest: () => { },
    onEnter: () => { },
    handleClearSearch: () => { },
    loading:false
};

// prop types
SearchComponent.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    field: PropTypes.string,
    reset: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    className: PropTypes.any,
    showClearIcon: PropTypes.bool,
    maxLength: PropTypes.number,
    timeOut: PropTypes.number,
    fullWidth: PropTypes.bool,
    onInputChangeRequest: PropTypes.func.isRequired,
    handleClearSearch: PropTypes.func,
    ariaLabel: "Search",
    loading:PropTypes.bool

};

export const Search = withStyles(styles)(SearchComponent)


// Hook
function useDebounce (value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
  
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
  
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
  
    return debouncedValue;
  }
