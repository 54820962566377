import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import classNames from 'classnames';


//Styles
import ServicesStyles from '../../ServicesStyles'

//Images
import { DragIcon, CheckSelectedIcon, DeleteIcon, BellIcon } from '../../../../assets/svg'

// Import Components
import { Button, TextField, Typography, CheckBox, Link, RichTextEdit } from "../../../../components";

// Import Helpers
import validations from "../../../../utils/validations";

// Import Cofig Constants
import { user_page_constants } from "../../../../config/constants";

const Content = (props) => {

    const { classes, handleRichTextChange, richText, errorFlags, page, createFromScratch} = props;

    return (
        <div
            role="tabpanel"
            id={`simple-tabpanel-1`}
            aria-labelledby={`simple-tab-1`}
        >
            <Grid container alignContent={"baseline"} style={{ alignContent: 'baseline' }}>
                <Grid item sm={12} align="left" className={classes.contentTab}>
                    <Grid className={errorFlags.documentData[page]&& errorFlags.documentData[page].richText? classes.error:""}>
                        <RichTextEdit
                            value={createFromScratch.documentData[page].richText ? createFromScratch.documentData[page].richText : ""}
                            onChange={(v) => { handleRichTextChange(v, page) }}
                        />
                    </Grid>
                    { errorFlags.documentData[page] && errorFlags.documentData[page].richText &&
                    <span style={{ color:'red' }}>{"Content cannot be blank"}</span>
                    }
                </Grid>
            </Grid>
        </div>
    );
}

// default props
Content.defaultProps = {
    classes: {}
};

// prop types
Content.propTypes = {
    classes: PropTypes.object
};

export default withStyles(ServicesStyles)(Content);
