import { alertConstant, authConstant, userConstant,loaderConstant } from "../constants";
import { authService } from "../services";
import { baseAction } from "../actions";

// Import History
import history from "../../config/history";

const { request, success, failure } = baseAction;

// Export All Auth Actions Functions
export const authAction = {
  login,
  logout,
  forgotPassword,
  resetPassword,
  contactUs,
  getProfileData,
  getProfileDataCurrent,
  checkResetTokenExpiry,
  getProfileLogo,
  getCompanyAddress
};

/**
 * Request Login
 */
function getProfileLogo(callback=()=>{}) {
  return (dispatch) => {
    dispatch(request(userConstant.LOGIN_LOGO_REQUEST));
    authService.getProfileLogo().then((data) => {
        dispatch(success(userConstant.LOGIN_LOGO_SUCCESS, data));
        callback()
      },(error) => {
        dispatch(failure(userConstant.LOGIN_LOGO_FAILURE, error));
      }
    );
  };
}

/**
 * Request Login
 */
 function getProfileData(callback=()=>{}) {
  return (dispatch) => {
    dispatch(request(userConstant.LOGIN_USER_REQUEST));
    authService.getProfileData().then(
      (data) => {
        dispatch(success(userConstant.LOGIN_USER_SUCCESS, data));
        callback()
      },
      (error) => {
        dispatch(failure(userConstant.LOGIN_USER_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

/**
 * Request Login
 */
function getProfileDataCurrent(callback=()=>{}) {
  return (dispatch) => {
    dispatch(request(userConstant.CURRENT_USER_REQUEST));
    authService.getProfileDataCurrent().then(
      (data) => {
        dispatch(success(userConstant.CURRENT_USER_SUCCESS, data));
        callback()
      },
      (error) => {
        dispatch(failure(userConstant.CURRENT_USER_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

/**
 * Request Login
 *
 * @param {*} username - username
 * @param {*} password - password
 */
function login(params, callback) {
  return (dispatch) => {
    localStorage.removeItem("id");
    localStorage.removeItem("role");
    dispatch(request(authConstant.LOGIN_REQUEST));
    dispatch(request(userConstant.LOGIN_USER_REQUEST));
    authService.login(params).then(
      (data) => {
        dispatch(success(authConstant.LOGIN_SUCCESS, data));
        dispatch(success(userConstant.LOGIN_USER_SUCCESS, data));
        
        // Redirect to roles based screen
        if(data && data.data && data.data.role==="Manager" ){
          history.push(`/submissions`);
        }else{
          history.push(`/users`);
        }
        callback()
      },
      (error) => {
        dispatch(failure(userConstant.LOGIN_USER_FAILURE, error));

        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(failure(authConstant.LOGIN_FAILURE, error));
      }
    );
  };
}

/**
 * Request Logout
 */
function logout() {
  return (dispatch) => {
    dispatch(request(authConstant.LOGOUT_REQUEST));
    authService.logout().then(() => {
        dispatch(success(authConstant.LOGOUT_SUCCESS));
        // Redirect to login
        history.push(`/`);
      }, error => {
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(failure(authConstant.LOGOUT_FAILURE, error));
      })
  };
}

/**
 * Request Forgot Password
 * @param {*} email E mail string to forgot password
 * @returns
 */
function forgotPassword(params,callBack=()=>{}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(authConstant.FORGOT_PASSWORD_REQUEST, params));
    authService.forgotPassword(params).then(
      (data) => {
        dispatch(success(authConstant.FORGOT_PASSWORD_SUCCESS, data));
        // dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack()
      },
      (error) => {
        dispatch(failure(authConstant.FORGOT_PASSWORD_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Request Reset Password
 * @param {*} data
 * @param {*} callBack
 */
function resetPassword(params,successCallBack=()=>{}) {
  return dispatch => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    authService.resetPassword(params).then(
        data => {
          dispatch(success(authConstant.RESET_PASSWORD_SUCCESS, data));
          dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
          dispatch(request(loaderConstant.LOADING_FALSE));
          successCallBack()
        },
        error => {
          dispatch(failure(authConstant.RESET_PASSWORD_FAILURE, error));
          dispatch(failure(alertConstant.ALERT_ERROR, error));
          dispatch(request(loaderConstant.LOADING_FALSE));
        }
      );
  };
}

/**
 * Send Contact Us 
 * @param {*} data 
 * @param {*} props 
 */
function contactUs(params ,succesCallback=()=>{}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(authConstant.CONTACT_US_REQUEST, params));
    authService.contactUs(params).then(
      (data) => {
        dispatch(success(authConstant.CONTACT_US_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        succesCallback()
      },
      (error) => {
        dispatch(failure(authConstant.CONTACT_US_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * check password expiry
 */
function checkResetTokenExpiry(data) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(authConstant.TOKEN_CHECK_REQUEST));
    authService.checkResetTokenExpiry(data).then(
      (data) => {
        dispatch(success(authConstant.TOKEN_CHECK_SUCCESS, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(authConstant.TOKEN_CHECK_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}


function getCompanyAddress() {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(authConstant.GET_ADDRESS_REQUEST));
    authService.getCompanyAddress().then((data) => {
        dispatch(success(authConstant.GET_ADDRESS_SUCCESS, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
      },(error) => {
        dispatch(failure(authConstant.GET_ADDRESS_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}