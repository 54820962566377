import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {  useSelector } from "react-redux";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// Import Images
import CalendarIcon from '../../assets/images/svg/calendar.svg';
import { Grid } from '@mui/material';

// Styles (optional)
const useStyles = (theme) => ({
  borderColorRed: {
    borderColor: 'red',
  }
});


const StyledDatePicker = styled(MobileDatePicker)(({ theme, name }) => {

  // Add your conditional logic based on name or active Step
  return {
    '& .MuiInputBase-root': {
      cursor: 'pointer',
    },
    '& .date-picker-custom': {
      '&.required': {
        borderColor: 'red',
      },
    },
  };
});

function DatePickerComponent(props) {
  const {
    classes,
    value,
    error,
    name,
    mask,
    placeholder,
    disableFuture,
    disablePast,
    fullWidth,
    label,
    disabled,
    size,
    className,
    validators,
    errorMessages,
    validations,
    onBlur,
    onOpen,
    onClose,
    format,
    style,
    helperText,
    notRequired,
    panel,
  } = props;
  //console.log("propssss,",props)
  const customClass =
    validators && validators.length > 0 && !notRequired
      ? `${className} required`
      : className;

  const handleChange = (date) => {
    let d = date;
    if (d !== 'Invalid Date') {
      // if (format === 'MM/YY' || format === 'MM/YYYY') {
      //   d = new Date(new Date(d).setDate(15));
      // } else {
        d = new Date(new Date(d).setHours(13, 0, 0, 0));
      // }
    }

    props.handleChange({ name: props.name, value: d });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledDatePicker
          label={label}
          name={name}
          format ={format}
          views={(format === 'MM/YY' || format === 'MM/YYYY') ? ['month', 'year'] : ['year', 'month', 'day']}
          value={value ? dayjs(value) : null}
          onChange={handleChange}
          fullWidth={fullWidth}
          mask={mask}
          disableFuture={disableFuture}
          disablePast={disablePast}
          disabled={disabled}
          className={`${fullWidth === true ? 'width-100' : 'width-auto'} ${customClass} date-picker-custom importField ${error ? classes.borderColorRed : ''}`}
          slotProps={{
            textField: {
              InputProps: {
                placeholder:placeholder,
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarMonthIcon style={{color: "rgba(0, 0, 0, 0.54)"}}/>
                  </InputAdornment>
                ),
              },
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              helperText={helperText}
              error={error}
              onBlur={onBlur}
              className={customClass}
              size={size}
              style={style}
            />
          )}
          onBlur={onBlur}
          onOpen={onOpen}
          onClose={onClose}
          autoComplete="off"
          {...validations}
        />
    </LocalizationProvider>
  );
}

// default props
DatePickerComponent.defaultProps = {
  name: '',
  value: '',
  label: '',
  fullWidth: '',
  disabled: false,
  className: 'cursor-pointer',
  errorMessages: [],
  validators: [],
  onOpen: () => { },
  onClose: () => { },
  handleChange: () => { },
  validations: {},
  onBlur: () => { },
  format: 'MM/DD/YYYY',
  style: {},
  helperText: '',
  notRequired: false,
  size: 'small',
  placeholder: '__/__/____',
  mask: '__/__/____',
  disableFuture: false,
  disablePast: false,
  error: false,
  classes: {},
};

// prop types
DatePickerComponent.propTypes = {
  name: PropTypes.any,
  value: PropTypes.any,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  errorMessages: PropTypes.array,
  validators: PropTypes.array,
  helperText: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  placeholder: PropTypes.string,
  mask: PropTypes.string,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  error: PropTypes.bool,
  classes: PropTypes.object,
};

export const DatePickr = DatePickerComponent;
