import React, { useState, useRef } from "react";
import Grid from '@mui/material/Grid';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import PropTypes from 'prop-types';

//Styles
import './style.css'

function TextAreaWithLineNumbers({ maxRow, minRow, label, ...rest }) {

    return (
        <TextareaAutosize
            {...rest}
            minRows={minRow}
            maxRows={maxRow}
            aria-label={label}
            className={'txtAreaNumbers'}

        />
    );
}

TextAreaWithLineNumbers.defaultProps = {
    name: "",
    maxRow: 8,
    minRow: 8,
    label: "",
    value: "",
    onChange: () => { }
}
TextAreaWithLineNumbers.propTypes = {
    name: PropTypes.string,
    maxRows: PropTypes.number,
    minRow: PropTypes.number,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
}

export const TextAreaWithLines = TextAreaWithLineNumbers;
