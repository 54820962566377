import palette from '../../assets/theme/palette'
const style = () => ({
    checkBoxCls: {
        // '& .MuiSvgIcon-root': {
        //     color: "red"
        // }
        '& .MuiCheckbox-root': {
            color: '#cfcfcf'
        },
        '& .MuiCheckbox-colorPrimary.Mui-checked': {
            color: `${palette.primary.main} !important`
        },
        '& .MuiCheckbox-colorSecondary.Mui-checked': {
            color: `#12A8DE !important`
        }
    }
})
export default style