import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Grid from '@mui/material/Grid'; 
import Paper from '@mui/material/Paper';

//Styles
import DirectoryStyles from "../DirectoriesStyles"

// Import Components
import { Button as Btn, Table, Typography, AlertDialog, PageContainer } from "../../../components";

// Import Cofig Constants
import { directoryConstant } from "../../../redux/constants";
import { directory_page_constants } from "../../../config/constants"

import { getTableHeight, getTableRowSize } from "../../../config/layout"

// Import Actions
import { directoryAction, updateReducerAction, userAction } from "../../../redux/actions";
import history from "../../../config/history";

const DirectorysList = (props) => {

  //define dispatch
  const dispatch = useDispatch();

  const { classes } = props;

  //set state
  const [deleteAlert, setDeleteAlert] = useState(null)

  const height = getTableHeight();
  const tableRowSize = getTableRowSize();

  //get reducer data
  const { search: { page, data } } = useSelector(state => state.directory);
  const { currentUser: { preference, id: currentUID } } = useSelector(state => state.user);
  const { loading } = useSelector(s => s.UI)

  //get selected directory fields
  const selectHeaders = preference && preference.directory && preference.directory.select ? preference.directory.select : directory_page_constants.HEADER_DEFAULT_SELECTED
  const sortHeaders = preference && preference.directory && preference.directory.sort ? preference.directory.sort : directory_page_constants.HEADER_DEFAULT
  let selectedField = [];
  let selectedHeaders = [];

  // hide associate column
  if (selectHeaders && selectHeaders.includes("Associate")) {
    selectHeaders.splice(selectHeaders.indexOf("Associate"), 1)
  }

  if (selectHeaders && sortHeaders) {
    sortHeaders.map(i => {
      const isFound = selectHeaders.filter(h => (i === h))
      if (isFound.length) {
        selectedHeaders.push(i === "Card Id" ? "Card ID" : i )
        selectedField.push(directory_page_constants.HEADER_MAP_CONSTANT[i])
      }
    })
  }

  useEffect(() => {
    let { sFields, sValues } = page;
    if (typeof sFields === 'undefined')
      sFields = []
    if (typeof sValues === 'undefined')
      sValues = []
    dispatch(directoryAction.search(page.skip, tableRowSize, "directory_name", "asc", sFields, sValues));
  }, [dispatch]);

  //Handle Table pagination Event
  const onChangePageTable = (skip, limit) => {
    const { sFields, sValues, sortBy, sort } = page;
    dispatch(directoryAction.search(skip, limit, sortBy, sort, sFields, sValues));
  };

  //Handle Table Sort Request Event
  const onSortRequest = (sortBy, sort) => {
    dispatch(directoryAction.search(page.skip, page.limit, sortBy, sort, page.sFields, page.sValues));
  };

  //handle detlete user on yes click in alert
  const deleteAction = () => {
    const { field, itemId, item } = deleteAlert
    dispatch(directoryAction.remove({ id: item.id }, () => {
      dispatch(directoryAction.search(1, page.limit, "create_time", "desc", page.sFields, page.sValues));
      setDeleteAlert(null)
    }));
  }

  //Button actions
  const handelActionButtonClick = (field, itemId, item) => {
    switch (field) {
      case "EDIT":
        dispatch(directoryAction.getOne({ id: itemId }, () => {
          history.push({ pathname: `/directory/edit/${itemId}` });
        }));
        break;
      case "DELETE":
        setDeleteAlert({ field: field, itemId: itemId, item: item })
        break;
      default:
        break;
    }
  }

  //update preference on submit click
  const updatePreference = (sort) => {
    let updatedData = [...sort]
    const allHeaders = preference && preference.directory && preference.directory.sort ? preference.directory.sort : [];
    allHeaders.map(i => {
      if (updatedData.indexOf(i) === -1) {
        updatedData.push(i)
      }
    })
    dispatch(userAction.updatePreference({ id: currentUID, preference: { ...preference, "directory": { "sort": updatedData } } }, () => { }));
  }

  //Handle Table Search Input Change Event
  const oninputChangeRequest = (searchField, searchKey) => {
    let { sFields, sValues } = page;
    if (sFields.indexOf(searchField) >= 0) {
      if (searchKey === "") {
        const index = sFields.indexOf(searchField);
        sValues.splice(index, 1);
        sFields.splice(index, 1);
      } else {
        sValues[sFields.indexOf(searchField)] = searchKey;
      }
    } else {
      sFields.push(searchField);
      sValues.push(searchKey);
    }
    dispatch(directoryAction.search(1, page.limit, page.sortBy, page.sort, page.sFields, page.sValues));
  };

  return (
    <React.Fragment>
      {/* <PageContainer height={height} paddingRight={12}> */}
      <Grid item xs={12} className={`${"mt-15"} ${classes.directorysTable}`}>
        <Paper elevation={3} className={classes.cardSection} >
          <Table
            height={height}
            classes={classes}
            data={data}
            header={selectedHeaders}
            field={selectedField}
            search={selectedField}
            sortBy={page.sortBy}
            sort={page.sort}
            onInputChangeRequest={oninputChangeRequest}
            onChangePagination={onChangePageTable}
            onSortRequest={onSortRequest}
            handelActionButtonClick={handelActionButtonClick}
            updatePreference={updatePreference}
            page={Number(page.skip)}
            count={page.count}
            actions={['EDIT', 'DELETE']}
            sFields={page.sFields}
            sValues={page.sValues}
            rowsPerPage={page.limit}
            screenSizeRowPerPage={tableRowSize}
            loading={loading}
          >
          </Table>
        </Paper>
      </Grid>
      {/* </PageContainer>  */}
      {deleteAlert &&
        <AlertDialog
          fullScreen={false}
          open={true}
        >
          <Grid className={'p-4'}>
            <Typography variant="h6">{`Are you sure you want to delete this directory`}</Typography>
            <Grid className={'pt-2'} align={"center"}>
              <Btn
                color={"primary"}
                className={"mr-1"}
                onClick={() => { deleteAction() }}
                title={"Yes"}
              />
              <Btn
                color={"secondary"}
                onClick={() => { setDeleteAlert(null) }}
                title={"No"}
              />
            </Grid>
          </Grid>
        </AlertDialog>
      }
    </React.Fragment>
  );
}

// default props
DirectorysList.defaultProps = {
  classes: {}
};

// prop types
DirectorysList.propTypes = {
  classes: PropTypes.object
};

export default withStyles(DirectoryStyles)(DirectorysList);
