import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@mui/styles";
import { Drawer, Backdrop, IconButton, Grid } from '@mui/material';
import arrayMove from "array-move";

import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";

//Styles and images
import UsersStyles from '../../UsersStyles'
import { EditTableIcon, DragIcon, CloseIcon } from '../../../../assets/svg'

//Components
import { Button, Typography, CheckBox } from "../../../../components";

//Constants
import { user_page_constants } from "../../../../config/constants"

// Import Actions
import { userAction } from "../../../../redux/actions";

//Sortable Item
const SortableItem = SortableElement(props => {
    return (
        props.children
    );
});

//Drag Handle
const DragHandle = SortableHandle((props) => {

    return (<DragIcon className={"cla"} draggable={false}></DragIcon>);
})

//create sortable list for drag and drop
const SortableList = SortableContainer(props => {

    //get props
    const { data, classes, checkedData, updateCheckedData } = props;

    return (
        <React.Fragment>
            <Grid container  className={`${classes.columnSttingsBody} ${"pl-2 pr-2"}`}>
                {data && data.map((item, index) => (
                    <SortableItem
                        key={`sort-item-${index}`}
                        index={index}
                    >
                        <Grid container alignItems="center" className={classes.settingsTile}>
                            <DragHandle />
                            <Typography variant={"body2"} title={item} className={classes.grow}></Typography>
                            {/* <CheckSelectedIcon className={classes.checkIcon} /> */}
                            <CheckBox
                                className={"settingSTileCheck"}
                                checked={checkedData.indexOf(item) > -1}
                                handleChange={() => { updateCheckedData(item, index) }}
                                icon
                                checkedIcon
                            />
                        </Grid>
                    </SortableItem>
                ))}
            </Grid>
        </React.Fragment>
    )
})


function ColumnSettings(props) {
    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes } = props;

    //get reducer data
    const { currentUser: { preference }, currentUser } = useSelector(state => state.user);

    //get selected user fields
    const selectedHeaders = preference && preference.users && preference.users.select ? preference.users.select : user_page_constants.USER_LIST_HEADER;
    const rowData = preference && preference.users && preference.users.sort ? preference.users.sort : user_page_constants.USER_LIST_HEADER;

    //set state
    const [drawer, setDrawer] = useState(false);
    const [data, setData] = useState(rowData);
    const [checkedData, setCheckedData] = useState(selectedHeaders);

    //show hide column settings
    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setDrawer(open);
    };

    useEffect(()=>{
        setData(rowData);
        setCheckedData(selectedHeaders)
    },[preference])

    //chakne row index on sort
    const onSortEnd = (e) => {
        const { oldIndex, newIndex } = e;
        const resultData = arrayMove(data, oldIndex, newIndex);
        setData(resultData)
    }

    //update checked data
    const updateCheckedData = (com, ind) => {
        const compIndex = checkedData.indexOf(com);
        if (compIndex === -1) {
            const updateData = [...checkedData, com]
            setCheckedData(updateData)
        } else {
            const updateData = [...checkedData]
            updateData.splice(compIndex, 1)
            setCheckedData(updateData)
        }
    }

    //update preference on submit click
    const updatePreference = () => {
        setDrawer(false)
        dispatch(userAction.updatePreference({ id: currentUser.id, preference: { ...preference, "users": { "sort": data, "select": checkedData } } }, () => {}));
    }

    return (
        <React.Fragment>
            <Backdrop className={classes.backDrop} onClick={toggleDrawer(false)} open={drawer} />
            <IconButton onClick={toggleDrawer(true)} className={classes.padding5} size="large"><EditTableIcon /></IconButton>
            <Drawer anchor='right' role="presentation" variant="persistent" open={drawer} onClose={toggleDrawer(false)} className={classes.allianceDrawer}>

                <Grid container alignItems="center" justifyContent="space-between" className={"pl-2 pt-1 pr-2 pb-2" }>
                    <Typography variant="h4" title={user_page_constants.COLUMN_SETTINGS}></Typography>
                    <IconButton onClick={toggleDrawer(false)} className={classes.padding5} size="large"><CloseIcon /></IconButton>
                </Grid>
                <SortableList
                    useDragHandle
                    axis="xy"
                    enableUserSelectHack={false}
                    helperClass="sortable-list-tab"
                    data={data}
                    onSortEnd={onSortEnd}
                    checkedData={[...checkedData]}
                    updateCheckedData={updateCheckedData}
                    classes={classes}
                ></SortableList>
                <Grid container className="pl-2 pt-2 pr-2 pb-2">
                    <Button color="primary" size="small" title={user_page_constants.APPLY} onClick={() => updatePreference()} className="mr-1"></Button>
                    <Button color="secondary" size="small" title={user_page_constants.CANCEL} onClick={toggleDrawer(false)}></Button>
                </Grid>
            </Drawer>
        </React.Fragment>
    );
}

// default props
ColumnSettings.defaultProps = {
    classes: {},
    drawer: false,
    toggleDrawer: () => { },
};

// prop types
ColumnSettings.propTypes = {
    classes: PropTypes.object,
    drawer: PropTypes.bool,
    toggleDrawer: PropTypes.func
};

// export default UserList;
export default withStyles(UsersStyles)(ColumnSettings);