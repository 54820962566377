import { alertConstant, applicationConstant, loaderConstant } from "../constants";
import { applicationService } from "../services";
import { baseAction } from "./baseAction";

const { request, success, failure } = baseAction;

// Export All Auth Actions Functions
export const applicationAction = {
  getAll,
  search,
  getApp,
  createApp,
  remove,
  resetAppData,
  updateApp,
  updateIntro,
  updateCriteria,
  updatePayment,

  getServiceName,
  getCriteriaName,
  getManagerName,
  getClientCommon,

  sendInvitation,
  getLink
};

/**
 * All APPLICATION details
 */
function getAll(params) {
  return (dispatch) => {
    if (params && !params["loader"]) {
      dispatch(request(loaderConstant.LOADING_TRUE));
    } else {
      dispatch(request(loaderConstant.LAZY_LOADING_TRUE));
    }
    dispatch(request(applicationConstant.APP_GETALL_REQUEST));
    applicationService.getAll(params).then(
      (data) => {
        dispatch(success(applicationConstant.APP_GETALL_SUCCESS, data));
        if (params && !params["loader"]) {
          dispatch(request(loaderConstant.LOADING_FALSE));
        } else {
          dispatch(request(loaderConstant.LAZY_LOADING_FALSE));
        }
      },
      (error) => {
        dispatch(failure(applicationConstant.APP_GETALL_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        if (params && !params["loader"]) {
          dispatch(request(loaderConstant.LOADING_FALSE));
        } else {
          dispatch(request(loaderConstant.LAZY_LOADING_FALSE));
        }
      }
    );
  };
}

/**
 * Get All APPLICATION
 */
function search(skip, limit, sortBy, sort, sFields, sValues) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(applicationConstant.APP_SEARCH_REQUEST));
    applicationService.search(skip, limit, sortBy, sort, sFields, sValues).then(
      (data) => {
        dispatch(success(applicationConstant.APP_SEARCH_SUCCESS, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(applicationConstant.APP_SEARCH_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}
/**
 * Get APPLICATION details
 */
function getApp(params, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(applicationConstant.APP_GET_ONE_REQUEST));
    applicationService.getApp(params).then(
      (data) => {
        dispatch(success(applicationConstant.APP_GET_ONE_SUCCESS, data));
        successCallback();
      },
      (error) => {
        dispatch(failure(applicationConstant.APP_GET_ONE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

/**
 * Delete particular APPLICATION
 */
function remove(data, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(applicationConstant.APP_DELETE_REQUEST));
    applicationService.remove(data).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(success(applicationConstant.APP_DELETE_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        successCallback()
      },
      (error) => {
        dispatch(failure(applicationConstant.APP_DELETE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Create new APPLICATION
 */
function createApp(params, callback = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(applicationConstant.CREATE_APP_REQUEST));
    applicationService.createApp(params).then(
      (data) => {
        dispatch(success(applicationConstant.CREATE_APP_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callback(data)
      },
      (error) => {
        dispatch(failure(applicationConstant.CREATE_APP_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Update existing APPLICATION
 */
function updateApp(params, callback = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(applicationConstant.UPDATE_APP_REQUEST));
    applicationService.updateApp(params).then(
      (data) => {
        dispatch(success(applicationConstant.UPDATE_APP_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callback()
      },
      (error) => {
        dispatch(failure(applicationConstant.UPDATE_APP_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Update APPLICATION introduction
 */
function updateIntro(params, callback = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(applicationConstant.UPDATE_APP_INTRO_REQUEST));
    applicationService.updateIntro(params).then(
      (data) => {
        dispatch(success(applicationConstant.UPDATE_APP_INTRO_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callback()
      },
      (error) => {
        dispatch(failure(applicationConstant.UPDATE_APP_INTRO_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Update APPLICATION payment
 */
function updatePayment(params, callback = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(applicationConstant.UPDATE_APP_PAY_REQUEST));
    applicationService.updatePayment(params).then(
      (data) => {
        dispatch(success(applicationConstant.UPDATE_APP_PAY_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callback()
      },
      (error) => {
        dispatch(failure(applicationConstant.UPDATE_APP_PAY_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Update APPLICATION Criteria
 */
function updateCriteria(params, callback = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(applicationConstant.UPDATE_APP_CRIT_REQUEST));
    applicationService.updateCriteria(params).then(
      (data) => {
        dispatch(success(applicationConstant.UPDATE_APP_CRIT_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callback()
      },
      (error) => {
        dispatch(failure(applicationConstant.UPDATE_APP_CRIT_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

//reset app data to init state
function resetAppData() {
  return { type: applicationConstant.RESET_APP_DATA }
}

/**
 * get services name
 */
function getCriteriaName() {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(applicationConstant.GET_CRITERIA_NAME_REQUEST));
    applicationService.getCriteriaName().then(
      (data) => {
        dispatch(success(applicationConstant.GET_CRITERIA_NAME_SUCCESS, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(applicationConstant.GET_CRITERIA_NAME_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * GET SERVICE NAME
 */
function getServiceName() {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(applicationConstant.GET_SERVICE_NAME_REQUEST));
    applicationService.getServiceName().then(
      (data) => {
        dispatch(success(applicationConstant.GET_SERVICE_NAME_SUCCESS, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(applicationConstant.GET_SERVICE_NAME_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * GET MANAGER NAME
 */
function getManagerName() {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(applicationConstant.GET_MANAGER_NAME_REQUEST));
    applicationService.getManagerName().then(
      (data) => {
        dispatch(success(applicationConstant.GET_MANAGER_NAME_SUCCESS, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(applicationConstant.GET_MANAGER_NAME_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * GET CLIENT COMMON FLAG
 */
function getClientCommon(params, callback = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(applicationConstant.GET_C_COMMON_REQUEST));
    applicationService.getClientCommon(params).then(
      (data) => {
        dispatch(success(applicationConstant.GET_C_COMMON_SUCCESS, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callback(data)
      },
      (error) => {
        dispatch(failure(applicationConstant.GET_C_COMMON_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * SEND EMAIL INVITATION
 */
function sendInvitation(params, callback = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(applicationConstant.SEND_INV_REQUEST));
    applicationService.sendInvitation(params).then(
      (data) => {
        dispatch(success(applicationConstant.SEND_INV_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callback()
      },
      (error) => {
        dispatch(failure(applicationConstant.SEND_INV_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * GET APP LINK
 */
function getLink(params, callback = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(applicationConstant.GET_LINK_REQUEST));
    applicationService.getLink(params).then(
      (data) => {
        dispatch(success(applicationConstant.GET_LINK_SUCCESS, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callback(data)
      },
      (error) => {
        dispatch(failure(applicationConstant.GET_LINK_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}