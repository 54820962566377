const styles = (theme) => ({

    root: {
        border: '1px solid',
        width: '200px',
        minHeight: '100px'
    },
    sigContainer: {
        border: '1px solid',
        width: '40%',
        height: '40%',
        // margin: '0 auto',
        marginLeft:"25px",
        background_color: '#fff'
      },
      sigPad: {
        width: '100%',
        height: '100%'
      },
      signImg: {
        border: '1px solid',
        width: '80%',
        height: '80%',
        margin: '0 auto',
        background_color: '#fff'
      },
      tryAgainDiv: {
        display: 'inline-block',
        marginTop: 15
      },
      tryAgainBth: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
          '& .ReloadIconGrey path': {
            fill: '#12A8DE'
          }
        }
      }

})
export default styles