export const templateConstant = {

  //get all email templates
  GET_EMAIL_TEMPLATE_REQUEST: "GET_EMAIL_TEMPLATE_REQUEST",
  GET_EMAIL_TEMPLATE_SUCCESS: "GET_EMAIL_TEMPLATE_SUCCESS",
  GET_EMAIL_TEMPLATE_FAILURE: "GET_EMAIL_TEMPLATE_FAILURE",

  //get one email templates to edit
  GET_ONE_EMAIL_REQUEST: "GET_ONE_EMAIL_REQUEST",
  GET_ONE_EMAIL_SUCCESS: "GET_ONE_EMAIL_SUCCESS",
  GET_ONE_EMAIL_FAILURE: "GET_ONE_EMAIL_FAILURE",

  //delete one email templates
  DELETE_ONE_EMAIL_REQUEST: "DELETE_ONE_EMAIL_REQUEST",
  DELETE_ONE_EMAIL_SUCCESS: "DELETE_ONE_EMAIL_SUCCESS",
  DELETE_ONE_EMAIL_FAILURE: "DELETE_ONE_EMAIL_FAILURE",

  //update email drawer
  UPDATE_EMAIL_DRAWER: 'UPDATE_EMAIL_DRAWER',

  //add or edit email template
  ADD_UPDATE_EMAIL_REQUEST: "ADD_UPDATE_EMAIL_REQUEST",
  ADD_UPDATE_EMAIL_SUCCESS: "ADD_UPDATE_EMAIL_SUCCESS",
  ADD_UPDATE_EMAIL_FAILURE: "ADD_UPDATE_EMAIL_FAILURE",



  //get all APP templates
  GET_APP_TEMPLATE_REQUEST: "GET_APP_TEMPLATE_REQUEST",
  GET_APP_TEMPLATE_SUCCESS: "GET_APP_TEMPLATE_SUCCESS",
  GET_APP_TEMPLATE_FAILURE: "GET_APP_TEMPLATE_FAILURE",

  //get one APP templates to edit
  GET_ONE_APP_REQUEST: "GET_ONE_APP_REQUEST",
  GET_ONE_APP_SUCCESS: "GET_ONE_APP_SUCCESS",
  GET_ONE_APP_FAILURE: "GET_ONE_APP_FAILURE",

  //delete one APP templates
  DELETE_ONE_APP_REQUEST: "DELETE_ONE_APP_REQUEST",
  DELETE_ONE_APP_SUCCESS: "DELETE_ONE_APP_SUCCESS",
  DELETE_ONE_APP_FAILURE: "DELETE_ONE_APP_FAILURE",

  //update APP drawer
  UPDATE_APP_DRAWER: 'UPDATE_APP_DRAWER',

  //add or edit APP template
  ADD_UPDATE_APP_REQUEST: "ADD_UPDATE_APP_REQUEST",
  ADD_UPDATE_APP_SUCCESS: "ADD_UPDATE_APP_SUCCESS",
  ADD_UPDATE_APP_FAILURE: "ADD_UPDATE_APP_FAILURE",



  //get all email template name
  GET_EMAIL_NAME_REQUEST: "GET_EMAIL_NAME_REQUEST",
  GET_EMAIL_NAME_SUCCESS: "GET_EMAIL_NAME_SUCCESS",
  GET_EMAIL_NAME_FAILURE: "GET_EMAIL_NAME_FAILURE",

  //get all disclosure template name
  GET_DISCLOSURE_NAME_REQUEST: "GET_DISCLOSURE_NAME_REQUEST",
  GET_DISCLOSURE_NAME_SUCCESS: "GET_DISCLOSURE_NAME_SUCCESS",
  GET_DISCLOSURE_NAME_FAILURE: "GET_DISCLOSURE_NAME_FAILURE",

  //get all aggrement name
  GET_AGGREMENT_NAME_REQUEST: "GET_AGGREMENT_NAME_REQUEST",
  GET_AGGREMENT_NAME_SUCCESS: "GET_AGGREMENT_NAME_SUCCESS",
  GET_AGGREMENT_NAME_FAILURE: "GET_AGGREMENT_NAME_FAILURE",



  //get all CRITERIA templates
  GET_CRITERIA_REQUEST: "GET_CRITERIA_REQUEST",
  GET_CRITERIA_SUCCESS: "GET_CRITERIA_SUCCESS",
  GET_CRITERIA_FAILURE: "GET_CRITERIA_FAILURE",

  //get one CRITERIA templates to edit
  GET_ONE_CRITERIA_REQUEST: "GET_ONE_CRITERIA_REQUEST",
  GET_ONE_CRITERIA_SUCCESS: "GET_ONE_CRITERIA_SUCCESS",
  GET_ONE_CRITERIA_FAILURE: "GET_ONE_CRITERIA_FAILURE",

  //delete one CRITERIA templates
  DELETE_ONE_CRITERIA_REQUEST: "DELETE_ONE_CRITERIA_REQUEST",
  DELETE_ONE_CRITERIA_SUCCESS: "DELETE_ONE_CRITERIA_SUCCESS",
  DELETE_ONE_CRITERIA_FAILURE: "DELETE_ONE_CRITERIA_FAILURE",

  //update CRITERIA drawer
  UPDATE_CRITERIA_DRAWER: 'UPDATE_CRITERIA_DRAWER',

  //add or edit criteria template
  ADD_UPDATE_CRITERIA_REQUEST: "ADD_UPDATE_CRITERIA_REQUEST",
  ADD_UPDATE_CRITERIA_SUCCESS: "ADD_UPDATE_CRITERIA_SUCCESS",
  ADD_UPDATE_CRITERIA_FAILURE: "ADD_UPDATE_CRITERIA_FAILURE",



  //get all AGREEMENT templates
  GET_AGREEMENT_REQUEST: "GET_AGREEMENT_REQUEST",
  GET_AGREEMENT_SUCCESS: "GET_AGREEMENT_SUCCESS",
  GET_AGREEMENT_FAILURE: "GET_AGREEMENT_FAILURE",

  //get one AGREEMENT templates to edit
  GET_ONE_AGREEMENT_REQUEST: "GET_ONE_AGREEMENT_REQUEST",
  GET_ONE_AGREEMENT_SUCCESS: "GET_ONE_AGREEMENT_SUCCESS",
  GET_ONE_AGREEMENT_FAILURE: "GET_ONE_AGREEMENT_FAILURE",

  //delete one AGREEMENT templates
  DELETE_ONE_AGREEMENT_REQUEST: "DELETE_ONE_AGREEMENT_REQUEST",
  DELETE_ONE_AGREEMENT_SUCCESS: "DELETE_ONE_AGREEMENT_SUCCESS",
  DELETE_ONE_AGREEMENT_FAILURE: "DELETE_ONE_AGREEMENT_FAILURE",

  //update AGREEMENT drawer
  UPDATE_AGREEMENT_DRAWER: 'UPDATE_AGREEMENT_DRAWER',
  UPDATE_AGGREMENT_VIEW: 'UPDATE_AGGREMENT_VIEW',

  //update add edit agreement data
  UPDATE_AGREEMENT_ARR_DATA: 'UPDATE_AGREEMENT_ARR_DATA',

  //update agreement name
  UPDATE_AGREEMENT_NAME: 'UPDATE_AGREEMENT_NAME',

  //add or edit AGREEMENT template
  ADD_UPDATE_AGREEMENT_REQUEST: "ADD_UPDATE_AGREEMENT_REQUEST",
  ADD_UPDATE_AGREEMENT_SUCCESS: "ADD_UPDATE_AGREEMENT_SUCCESS",
  ADD_UPDATE_AGREEMENT_FAILURE: "ADD_UPDATE_AGREEMENT_FAILURE",

  //reset page search
  RESET_SEARCH: "RESET_SEARCH",



  //get all document templates
  GET_DOCUMENT_REQUEST: "GET_DOCUMENT_REQUEST",
  GET_DOCUMENT_SUCCESS: "GET_DOCUMENT_SUCCESS",
  GET_DOCUMENT_FAILURE: "GET_DOCUMENT_FAILURE",

  //get one document templates to edit
  GET_ONE_DOCUMENT_REQUEST: "GET_ONE_DOCUMENT_REQUEST",
  GET_ONE_DOCUMENT_SUCCESS: "GET_ONE_DOCUMENT_SUCCESS",
  GET_ONE_DOCUMENT_FAILURE: "GET_ONE_DOCUMENT_FAILURE",

  //delete one document templates
  DELETE_ONE_DOCUMENT_REQUEST: "DELETE_ONE_DOCUMENT_REQUEST",
  DELETE_ONE_DOCUMENT_SUCCESS: "DELETE_ONE_DOCUMENT_SUCCESS",
  DELETE_ONE_DOCUMENT_FAILURE: "DELETE_ONE_DOCUMENT_FAILURE",

  //update document drawer
  UPDATE_DOCUMENT_DRAWER: 'UPDATE_DOCUMENT_DRAWER',

  //add or edit document template
  ADD_UPDATE_DOCUMENT_REQUEST: "ADD_UPDATE_DOCUMENT_REQUEST",
  ADD_UPDATE_DOCUMENT_SUCCESS: "ADD_UPDATE_DOCUMENT_SUCCESS",
  ADD_UPDATE_DOCUMENT_FAILURE: "ADD_UPDATE_DOCUMENT_FAILURE",

  //update choices
  UPDATE_DISCLOSURE_CHOICES:'UPDATE_DISCLOSURE_CHOICES',

  //update variation
  UPDATE_DOCUMENT_VARIATION:'UPDATE_DOCUMENT_VARIATION',

};
