import * as apihelper from "../../utils/apiHelper";

/**
 * Export All Template Service Functions
 */
export const templateService = {
    getAllEmail,
    getOneEmail,
    deleteEmail,
    addOrUpdateEmail,

    getAllApplication,
    getOneAppTemplate,
    addOrUpdateAppTemplate,
    deleteAppTemplate,

    getEmailTemplateName,
    getAggrementName,
    getDisclosureName,

    getAllCriteria,
    getOneCriteria,
    deleteCriteria,
    addOrUpdateCriteria,

    getAllAgreement,
    getOneAgreement,
    deleteAgreement,
    addOrUpdateAgreement,

    getAllDocument,
    getOneDocument,
    deleteDocument,
    addOrUpdateDocument,

};

/**
 * Get All Email templates
 */
async function getAllEmail(data) {
    const response = await apihelper.postRequest(`template/getAllEmail`, data);
    return response;
}

/**
 * get one Email template
 */
async function getOneEmail(data) {
    const response = await apihelper.postRequest(`template/getOneEmail`, data);
    return response;
}

/**
 * delete Email templates
 */
async function deleteEmail(data) {
    const response = await apihelper.deleteRequest(`template/deleteEmail`, data);
    return response;
}

/**
 * add or update email templates
 */
async function addOrUpdateEmail(data) {
    const response = await apihelper.postRequest(`template/addUpdateEmail`, data);
    return response;
}

/**
 * Get All Application templates
 */
async function getAllApplication(data) {
    const response = await apihelper.postRequest(`template/getAllApplication`, data);
    return response;
}

/**
 * get one app template
 */
async function getOneAppTemplate(data) {
    const response = await apihelper.postRequest(`template/getOneAppTemplate`, data);
    return response;
}

/**
 * delete app templates
 */
async function deleteAppTemplate(data) {
    const response = await apihelper.deleteRequest(`template/deleteApp`, data);
    return response;
}

/**
 * add or update app templates
 */
async function addOrUpdateAppTemplate(data) {
    const response = await apihelper.postRequest(`template/addUpdateApp`, data);
    return response;
}

/**
 * get Email Template Name
 */
async function getEmailTemplateName() {
    const response = await apihelper.postRequest(`template/emailName`);
    return response;
}

/**
 * get Aggrement Name
 */
async function getAggrementName() {
    const response = await apihelper.postRequest(`template/aggrementName`);
    return response;
}

/**
 * get Disclosure Name
 */
async function getDisclosureName() {
    const response = await apihelper.postRequest(`template/disclosureName`);
    return response;
}

/**
 * Get All criteriatemplates
 */
async function getAllCriteria(data) {
    const response = await apihelper.postRequest(`template/getAllCriteria`, data);
    return response;
}

/**
 * get one criteria template
 */
async function getOneCriteria(data) {
    const response = await apihelper.postRequest(`template/getOneCriteria`, data);
    return response;
}

/**
 * delete criteria templates
 */
async function deleteCriteria(data) {
    const response = await apihelper.deleteRequest(`template/deleteCriteria`, data);
    return response;
}

/**
 * add or update Criteria templates
 */
async function addOrUpdateCriteria(data) {
    const response = await apihelper.postRequest(`template/addUpdateCriteria`, data);
    return response;
}

/**
 * Get All agreement templates
 */
async function getAllAgreement(data) {
    const response = await apihelper.postRequest(`template/getAllAgreement`, data);
    return response;
}

/**
 * get one agreement template
 */
async function getOneAgreement(data) {
    const response = await apihelper.postRequest(`template/getOneAgreement`, data);
    return response;
}

/**
 * delete agreement templates
 */
async function deleteAgreement(data) {
    const response = await apihelper.deleteRequest(`template/deleteAgreement`, data);
    return response;
}

/**
 * add or update agreement templates
 */
async function addOrUpdateAgreement(data) {
    const response = await apihelper.postRequest(`template/addUpdateAgreement`, data);
    return response;
}



/**
 * Get All document templates
 */
async function getAllDocument(data) {
    const response = await apihelper.postRequest(`template/getAllDocument`, data);
    return response;
}

/**
 * get one document template
 */
async function getOneDocument(data) {
    const response = await apihelper.postRequest(`template/getOneDocument`, data);
    return response;
}

/**
 * delete document templates
 */
async function deleteDocument(data) {
    const response = await apihelper.deleteRequest(`template/deleteDocument`, data);
    return response;
}

/**
 * add or update document templates
 */
async function addOrUpdateDocument(data) {
    const response = await apihelper.postRequest(`template/addUpdateDocument`, data);
    return response;
}