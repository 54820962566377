import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { useDispatch } from "react-redux";
import { withStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

//Styles
import TemplatesStyles from "./TemplatesStyles"

// Import History
import history from "../../config/history";

// Import Components
import { Typography } from "../../components";
import TabRouter from './Tab.Router'

// Import Cofig Constants
import { templates_page_constants } from "../../config/constants";

//Import Actions
import { updateReducerAction } from "../../redux/actions";

//import constants
import { templateConstant } from "../../redux/constants";


//change tab event function
function TabPanel(props) {
    const { children, value, index,match, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className="h-100">
                    {children}
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Templates = (props) => {
    // Define Dispatch
    const dispatch = useDispatch();

    //get props
    const { classes, match, location } = props;

    //handle tab change
    const handleChange = (event, newValue) => {
        //update to default pagination state
        dispatch(updateReducerAction.update(templateConstant.RESET_SEARCH, {}, () => {
            history.push({pathname: newValue})
        }));
    };

    return (
        <React.Fragment >
            <Grid item xs={12} >
                <Grid container direction="row" justifyContent="space-between" alignItems="center" className={` ${classes.titlerow}`}>
                    <Grid item>
                        <Typography variant="h3" component="h3"> {templates_page_constants.TEMPLATES} </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <AppBar position="static" color="default" className={classes.appBarCls}>
                    <Tabs
                        value={location.pathname}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="full width tabs example"
                    >
                      <Tab style={{minWidth:"160px" ,padding: "7px 12px 10px 12px"}} value={`${match.url}/document`}  label={<Typography variant="h6" color="TextSecondary">{"Documents"}</Typography>}  {...a11yProps(0)} />
                        <Tab style={{minWidth:"160px" ,padding: "7px 12px 10px 12px"}}  value={`${match.url}/email`} label={<Typography variant="h6" color="TextSecondary">{"Email Templates"}</Typography>} {...a11yProps(1)} />
                        <Tab style={{minWidth:"160px" ,padding: "7px 12px 10px 12px"}}  value={`${match.url}/agreement`} label={<Typography variant="h6" color="TextSecondary" >{"Agreements"}</Typography>} {...a11yProps(2)} />
                        <Tab style={{minWidth:"160px" ,padding: "7px 12px 10px 12px"}}  value={`${match.url}`} label={<Typography variant="h6" color="TextSecondary">{"Application Templates"}</Typography>} {...a11yProps(3)} />
                        <Tab style={{minWidth:"160px" ,padding: "7px 12px 10px 12px"}}  value={`${match.url}/criteria`} label={<Typography variant="h6" color="TextSecondary" >{"Criteria Templates"}</Typography>} {...a11yProps(4)} />
                    </Tabs>
                </AppBar>
                <Grid item xs={12}>
                    <TabPanel value={0} index={0}>
                        <TabRouter {...props}/>
                    </TabPanel>

                </Grid>
            </Grid>
        </React.Fragment>
    );
}

// default props
Templates.defaultProps = {
    classes: {}
};

// prop types
Templates.propTypes = {
    classes: PropTypes.object
};

export default withStyles(TemplatesStyles)(Templates);
