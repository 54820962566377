export const authConstant = {
  // Login
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  // Logout
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",

  // Forgot Password
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",

  // Reset password
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",

  // Reset password token
  RESET_PASSWORD_TOKEN_REQUEST: "RESET_PASSWORD_TOKEN_REQUEST",
  RESET_PASSWORD_TOKEN_SUCCESS: "RESET_PASSWORD_TOKEN_SUCCESS",
  RESET_PASSWORD_TOKEN_FAILURE: "RESET_PASSWORD_TOKEN_FAILURE",

  // Request Access
  REQUEST_PASSWORD_REQUEST: "REQUEST_PASSWORD_REQUEST",
  REQUEST_PASSWORD_SUCCESS: "REQUEST_PASSWORD_SUCCESS",
  REQUEST_PASSWORD_FAILURE: "REQUEST_PASSWORD_FAILURE",

  // Contact Us
  CONTACT_US_REQUEST: "CONTACT_US_REQUEST",
  CONTACT_US_SUCCESS: "CONTACT_US_SUCCESS",
  CONTACT_US_FAILURE: "CONTACT_US_FAILURE",

  // check reset token
  TOKEN_CHECK_REQUEST: "TOKEN_CHECK_REQUEST",
  TOKEN_CHECK_SUCCESS: "TOKEN_CHECK_SUCCESS",
  TOKEN_CHECK_FAILURE: "TOKEN_CHECK_FAILURE",

  //GET ADDRESS
  GET_ADDRESS_REQUEST: "GET_ADDRESS_REQUEST",
  GET_ADDRESS_SUCCESS: "GET_ADDRESS_SUCCESS",
  GET_ADDRESS_FAILURE: "GET_ADDRESS_FAILURE",
};
