import { alertConstant } from "../constants/alertConstant";

const initialState = {
  message: "",
  type: ""
};

const alertReducer = (state = initialState, action) => {
  if (!action || (action && !action.type)) return state;

  switch (action.type) {
    case alertConstant.ALERT_ERROR:
      return {
        ...state,
        message: action.error && action.error === "Error: Network Error" ? "No Internet Connection" : action.error,
        type: alertConstant.ALERT_ERROR
      };
    case alertConstant.ALERT_INFO:
      return {
        ...state,
        message: action.data,
        type: alertConstant.ALERT_INFO
      };
    case alertConstant.ALERT_SUCCESS:
      return {
        ...state,
        message: action.data,
        type: alertConstant.ALERT_SUCCESS
      };
    case alertConstant.ALERT_CLEAR:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}

export default alertReducer;
