import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid';
import { withStyles } from "@mui/styles"; 
import Visibility from '@mui/icons-material/Visibility'; 
import VisibilityOff from '@mui/icons-material/VisibilityOff';

//Styles
import AuthenticationStyles from "../../layouts/AuthenticationLayout/AuthenticationLayoutStyles"

// Import Helpers
import validations from "../../utils/validations";

// Import Actions
import { authAction } from "../../redux/actions";

// Import Components
import { Button, TextField, Typography, Link } from "../../components";

// Import Cofig Constants
import { common_constants, errorsConstants } from "../../config/constants";

const ResetPassword = (props) => {
  // Define Dispatch
  const dispatch = useDispatch();

  // Get Props
  const { classes, match } = props;

  //get reducer data
  const expiryMsg = useSelector(state => (state.auth.tokenExpiryMsg))
  const { loading } = useSelector(state => (state.UI))

  //handle show Hide password
  const [passDis, setPassDis] = useState(false);
  const [passDis1, setPassDis1] = useState(false);
  const handleToggle = () => { setPassDis(passDis ? false : true) };
  const handleToggle1 = () => { setPassDis1(passDis1 ? false : true) };

  // Set Form Values
  const { register, handleSubmit, errors } = useForm({
    mode: 'all',
    reValidateMode: 'all',
    defaultValues: {
      password: "",
      cpassword: ""
    }
  });

  useEffect(() => {
    //check token expiry
    if (match.params.hash && match.params.key) {
      dispatch(authAction.checkResetTokenExpiry({ hash: decodeURIComponent(match.params.hash), key: match.params.key }));
    }
  }, [dispatch])

  // Submit Form
  const onSubmit = (data, e) => {
    if (data) {
      data = { ...data, hash: decodeURIComponent(match.params.hash), key: match.params.key }
      dispatch(authAction.resetPassword(data, () => {
        e.target.reset()
        props.history.push('/login');
      }));
    }
  }

  return (
    <React.Fragment>
      {(expiryMsg && expiryMsg.isInvalid === false) &&
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h3" title={expiryMsg && expiryMsg.isFirst ? common_constants.CREATE_PASSWORD : common_constants.RESET_PASSWORD}></Typography>
            </Grid>
            <Grid item xs={12} className={classes.passwordContainer}>
              <TextField
                id={'txt_password'}
                name={"password"}
                withImage={true}
                passShow={true}
                imageType={'passwd'}
                type={passDis ? "text" : "password"}
                placeholder={common_constants.PASSWORD}
                error={errors.password ? true : false}
                helperText={errors.password?.message}
                inputRef={register({
                  validate: {
                    ...validations.isRequired(errorsConstants.requiredPassword),
                    ...validations.isPasswordCriteriaMet(errorsConstants.passwordCriteria)
                  }
                })}
              />
              {passDis ? <Visibility onClick={handleToggle} className={classes.vsibleIconReset} /> : <VisibilityOff className={classes.vsibleIconReset} onClick={handleToggle} />}
            </Grid>
            <Grid item xs={12} className={classes.passwordContainer}>
              <TextField
                id={'txt_cpassword'}
                name={"cpassword"}
                withImage={true}
                passShow={true}
                imageType={'passwd'}
                type={passDis1 ? "text" : "password"}
                placeholder={common_constants.CONFIRM_PASSWORD}
                error={errors.cpassword ? true : false}
                helperText={errors.cpassword?.message}
                inputRef={register({
                  validate: {
                    ...validations.isRequired(errorsConstants.requiredPassword),
                    ...validations.isPasswordCriteriaMet(errorsConstants.passwordCriteria)
                  }
                })}
              />
              {passDis1 ? <Visibility onClick={handleToggle1} className={classes.vsibleIconReset} /> : <VisibilityOff className={classes.vsibleIconReset} onClick={handleToggle1} />}
            </Grid>
            <Grid item xs={12} className="mt-2">
              <Grid container spacing={4} alignItems={"center"}>
                <Grid item xs={6}>
                  <Button
                    title={common_constants.RESET_BTN_TEXT}
                    color={"primary"}
                    size={"large"}
                    type="submit"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Grid container alignItems="flex-end">
                    <Grid item xs={12} align="right">
                      <Link
                        href="#/login"
                        variant="subtitle1"
                        color="textSecondary">
                        {common_constants.BACK_TO_SIGNIN}
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </form>
      }
      {expiryMsg && expiryMsg.isInvalid === true &&
        <Grid item xs={12}>
          <Grid container alignItems="flex-end">
            <Grid item xs={12} align="right">
              <Typography variant="h5" title={expiryMsg && expiryMsg.message ? expiryMsg.message : "Token expired"}></Typography>
            </Grid>
            <Grid item xs={12} align="right" className={'mt-3'}>
              <Link
                href="#/login"
                variant="subtitle1"
                color="textSecondary">
                {common_constants.BACK_TO_SIGNIN}
              </Link>
            </Grid>
          </Grid>
        </Grid>
      }
    </React.Fragment>
  );
}

// default props
ResetPassword.defaultProps = {
  classes: {}
};

// prop types
ResetPassword.propTypes = {
  classes: PropTypes.object
};

export default withStyles(AuthenticationStyles)(ResetPassword);
