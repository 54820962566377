/* eslint-disable camelcase */
import { settingsConstant } from "../constants";

// Set Initial State
const initialState = {
    profile: {},
    application: {},
    configuration: {
        company:{},
        tazworks:{},
        email:{},
        bluefin:{},
        square:{},
    }
};

export default function (state = initialState, action) {
    switch (action.type) {

        // GET Profile
        case settingsConstant.GET_PROFILE_REQUEST:
            return {
                ...state
            };
        case settingsConstant.GET_PROFILE_SUCCESS:
            return {
                ...state,
                profile: action.data.data
            };
        case settingsConstant.GET_PROFILE_FAILURE:
            return {
                ...state,
            };

        // GET Application
        case settingsConstant.GET_APPLICATION_REQUEST:
            return {
                ...state
            };
        case settingsConstant.GET_APPLICATION_SUCCESS:
            return {
                ...state,
                application: action.data.data
            };
        case settingsConstant.GET_APPLICATION_FAILURE:
            return {
                ...state,
            };

        // GET configuration
        case settingsConstant.GET_CONFIGURATION_REQUEST:
            return {
                ...state
            };
        case settingsConstant.GET_CONFIGURATION_SUCCESS:
            return {
                ...state,
                configuration: action.data.data
            };
        case settingsConstant.GET_CONFIGURATION_FAILURE:
            return {
                ...state,
            };

        // update profile
        case settingsConstant.UPDATE_PROFILE_REQUEST:
            return {
                ...state,
                profile: action.data
            };

        // update company
        case settingsConstant.UPDATE_COMPANY_REQUEST:
            return {
                ...state,
                configuration: {...state.configuration, company:action.data}
            };

        // update tazworks
        case settingsConstant.UPDATE_TAZWORKS_REQUEST:
            return {
                ...state,
                configuration: {...state.configuration, tazworks:action.data}
            };

        // update email
        case settingsConstant.UPDATE_EMAIL_REQUEST:
            return {
                ...state,
                configuration: {...state.configuration, email:action.data}
            };

        // update bluefin
        case settingsConstant.UPDATE_BLUEFIN_REQUEST:
            return {
                ...state,
                configuration: {...state.configuration, bluefin:action.data}
            };

        //update square
        case settingsConstant.UPDATE_SQUARE_REQUEST:
            return {
                ...state,
                configuration: {...state.configuration, square:action.data}
            };

        // update application
        case settingsConstant.UPDATE_APPLICATION_REQUEST:
            return {
                ...state,
                application: action.data
            };

        // SAVE Profile
        case settingsConstant.SAVE_PROFILE_REQUEST:
            return {
                ...state
            };
        case settingsConstant.SAVE_PROFILE_SUCCESS:
            return {
                ...state
            };
        case settingsConstant.SAVE_PROFILE_FAILURE:
            return {
                ...state,
            };

        // SAVE Application
        case settingsConstant.SAVE_APPLICATION_REQUEST:
            return {
                ...state
            };
        case settingsConstant.SAVE_APPLICATION_SUCCESS:
            return {
                ...state
            };
        case settingsConstant.SAVE_APPLICATION_FAILURE:
            return {
                ...state,
            };

        // SAVE Configuration
        case settingsConstant.SAVE_CONFIGURATION_REQUEST:
            return {
                ...state
            };
        case settingsConstant.SAVE_CONFIGURATION_SUCCESS:
            return {
                ...state
            };
        case settingsConstant.SAVE_CONFIGURATION_FAILURE:
            return {
                ...state,
            };
        
        default:
            return state;
    }
}
