import { alertConstant, serviceConstant, loaderConstant } from "../constants";
import { serviceService } from "../services";
import { baseAction } from "./baseAction";
import { saveAs } from 'file-saver';

// Import History
import history from "../../config/history";

const { request, success, failure } = baseAction;

// Export All service Actions Functions
export const serviceAction = {
  search,
  getAll,
  remove,
  getApplicationCount,
  userServiceSearch,
  getTemplateList,
  updateService,
  createService,
  updateFee,
  downloadExcel,
  uploadExcel,
  addDocumentsFromSearch,
  addAgreementFromScratch,
  addTemplateFromScratch
};

/**
* Get All  service
*/
function search(skip, limit, sortBy, sort, sFields, sValues) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(serviceConstant.SERVICE_SEARCH_REQUEST));
    serviceService.search(skip, limit, sortBy, sort, sFields, sValues).then(
      (data) => {
        dispatch(success(serviceConstant.SERVICE_SEARCH_SUCCESS, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(serviceConstant.SERVICE_SEARCH_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * All service details
 */
function getAll(params) {
  return (dispatch) => {
    if (params && !params["loader"]) {
      dispatch(request(loaderConstant.LOADING_TRUE));
    } else {
      dispatch(request(loaderConstant.LAZY_LOADING_TRUE));
    } dispatch(request(serviceConstant.SERVICE_GETALL_REQUEST));
    serviceService.getAll(params).then(
      (data) => {
        dispatch(success(serviceConstant.SERVICE_GETALL_SUCCESS, data));
        if (params && !params["loader"]) {
          dispatch(request(loaderConstant.LOADING_FALSE));
        } else {
          dispatch(request(loaderConstant.LAZY_LOADING_FALSE));
        }
      },
      (error) => {
        dispatch(failure(serviceConstant.SERVICE_GETALL_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        if (params && !params["loader"]) {
          dispatch(request(loaderConstant.LOADING_FALSE));
        } else {
          dispatch(request(loaderConstant.LAZY_LOADING_FALSE));
        }
      }
    );
  };
}

/**
* Delete particular service
*/
function remove(data, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(serviceConstant.SERVICE_DELETE_REQUEST));
    serviceService.remove(data).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(success(serviceConstant.SERVICE_DELETE_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        successCallback()
      },
      (error) => {
        dispatch(failure(serviceConstant.SERVICE_DELETE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
* get Application Count
*/
function getApplicationCount(data, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(serviceConstant.SERVICE_GET_APPLICATION_COUNT_REQUEST));
    serviceService.getApplicationCount(data).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(success(serviceConstant.SERVICE_GET_APPLICATION_COUNT_SUCCESS, data));
        //dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        successCallback()
      },
      (error) => {
        dispatch(failure(serviceConstant.SERVICE_GET_APPLICATION_COUNT_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
* search Service users tab
*/
function userServiceSearch(skip, limit, sortBy, sort, sFields, sValues, clientId) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(serviceConstant.SERVICE_SEARCH_FORUSERS_REQUEST));
    serviceService.userServiceSearch(skip, limit, sortBy, sort, sFields, sValues, clientId).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(success(serviceConstant.SERVICE_SEARCH_FORUSERS_SUCCESS, data));
        //dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      },
      (error) => {
        dispatch(failure(serviceConstant.SERVICE_SEARCH_FORUSERS_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
* get Template List
*/
function getTemplateList(data) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(serviceConstant.GET_TEMPLATE_LIST_REQUEST));
    serviceService.getTemplateList(data).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(success(serviceConstant.GET_TEMPLATE_LIST_SUCCESS, data));
        //dispatch(success(alertConstant.ALERT_SUCCESS, data.message));        
      },
      (error) => {
        dispatch(failure(serviceConstant.GET_TEMPLATE_LIST_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
* edit existing service
*/
function updateService(params, callback = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(serviceConstant.UPDATE_SERVICE_REQUEST));
    serviceService.updateService(params).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(success(serviceConstant.UPDATE_SERVICE_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        callback()
      },
      (error) => {
        dispatch(failure(serviceConstant.UPDATE_SERVICE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
* create new service
*/
function createService(params, callback) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(serviceConstant.CREATE_SERVICE_REQUEST));
    serviceService.createService(params).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(success(serviceConstant.CREATE_SERVICE_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        callback()
      },
      (error) => {
        dispatch(failure(serviceConstant.CREATE_SERVICE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
* create new service
*/
function updateFee(params, callback) {
  return (dispatch, getState) => {
    const { checkBoxSelect } = getState().service;
    const serviceIds = Object.keys(checkBoxSelect)
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(serviceConstant.UPDATE_FEE_REQUEST));
    serviceService.updateFee({ fee: params.fee, serviceIds }).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(success(serviceConstant.UPDATE_FEE_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        callback()
      },
      (error) => {
        dispatch(failure(serviceConstant.UPDATE_FEE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * download excel
 * @param {*} data 
 */
function downloadExcel(data) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(serviceConstant.EXCEL_DOWNLOAD_REQUEST));
    serviceService.downloadExcel(data).then(
      (data) => {
        if (data && data.data && (data.data.error || data.status === "failed")) {
          dispatch(failure(alertConstant.ALERT_ERROR, data.data.error));
          dispatch(request(loaderConstant.LOADING_FALSE));
        } else {
          const blob = new Blob([data.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, data.filename);
          dispatch(success(alertConstant.ALERT_SUCCESS, "Successfully downloaded"));
          dispatch(request(loaderConstant.LOADING_FALSE));
        }
      },
      (error) => {
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * upload excel
 * @param {*} data 
 */
function uploadExcel(data) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(serviceConstant.EXCEL_UPLOAD_REQUEST));
    serviceService.uploadExcel(data).then(
      (data) => {
        dispatch(success(serviceConstant.EXCEL_UPLOAD_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, "Uploaded Successfully"));
        dispatch(request(loaderConstant.LOADING_FALSE));
        history.push(`/services?reset=true`);
      },
      (error) => {
        dispatch(failure(serviceConstant.EXCEL_UPLOAD_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * create documents from scratch
 */
function addDocumentsFromSearch(documentData, callback) {
  return (dispatch, getState) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(serviceConstant.CREATE_DOCUMENTS_FROM_SCRATCH_REQUEST));
    serviceService.addDocumentsFromSearch(documentData).then(
      (data) => {
        dispatch(success(serviceConstant.CREATE_DOCUMENTS_FROM_SCRATCH_SUCCESS, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callback()
      },
      (error) => {
        dispatch(failure(serviceConstant.CREATE_DOCUMENTS_FROM_SCRATCH_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    )
  }
}

/**
 * add agreement from scratch
 * @param {*} data 
 */
function addAgreementFromScratch(data, callback) {
  return (dispatch, getState) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(serviceConstant.CREATE_AGREEMENT_FROM_SCRATCH_REQUEST));
    serviceService.addAgreementFromScratch(data).then(
      (data) => {
        dispatch(success(serviceConstant.CREATE_AGREEMENT_FROM_SCRATCH_SUCCESS, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callback()
      },
      (error) => {
        dispatch(failure(serviceConstant.CREATE_AGREEMENT_FROM_SCRATCH_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    )
  }
}

/**
 * add template from scratch
 * @param {*} data 
 */
function addTemplateFromScratch(data, callback) {
  return (dispatch, getState) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(serviceConstant.CREATE_TEMPLATE_FROM_SCRATCH_REQUEST));
    serviceService.addTemplateFromScratch(data).then(
      (data) => {
        dispatch(success(serviceConstant.CREATE_TEMPLATE_FROM_SCRATCH_SUCCESS, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callback()
      },
      (error) => {
        dispatch(failure(serviceConstant.CREATE_TEMPLATE_FROM_SCRATCH_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    )
  }
}