import { templateConstant } from "../constants";

// Set Initial State
const initialState = {
  //email
  getAllEmail: {
    data: [],
    page: {
      limit: 20,
      skip: 0,
      search: '',
      sort: 'desc'
    }
  },
  getOneEmail: {},
  getOneEmailOld: {},
  emailDrawer: false,

  //appplication
  appTemplate: {
    data: [],
    page: {
      limit: 20,
      skip: 0,
      search: '',
      sort: 'desc'
    }
  },
  appTemp: {},
  appTempOld: {},
  applicationDrawer: false,

  //criteria
  criteriaTemplate: {
    data: [],
    page: {
      limit: 20,
      skip: 0,
      search: '',
      sort: 'desc'
    }
  },
  criteria: {},
  criteriaOld: {},
  criteriaDrawer: false,

  //agreement
  agreementTemplate: {
    data: [],
    page: {
      limit: 20,
      skip: 0,
      search: '',
      sort: 'desc'
    }
  },
  agreement: { result: [], nameData: {} },
  agreementOld: { result: [], nameData: {} },
  agreementDrawer: false,

  //document
  documentTemplate: {
    data: [],
    page: {
      limit: 20,
      skip: 0,
      search: '',
      sort: 'desc'
    }
  },
  document: { disclosure: {}, choices: [], variation: [] },
  documentOld: { disclosure: {}, choices: [], variation: [] },
  documentDrawer: false,

  emailName: [],
  aggrementName: [],
  disclosureName: [],

};

export default function (state = initialState, action) {
  switch (action.type) {

    //GET all email templates
    case templateConstant.GET_EMAIL_TEMPLATE_REQUEST:
      return {
        ...state
      };
    case templateConstant.GET_EMAIL_TEMPLATE_SUCCESS:

      const page = action.data.page;
      let stateEmailData = [];

      if (page.skip > 0) {
        stateEmailData = [...state.getAllEmail.data, ...action.data.data]
      } else {
        stateEmailData = [...action.data.data]
      }

      return {
        ...state,
        getAllEmail: {
          data: stateEmailData,
          page: action.data.page
        }
      };
    case templateConstant.GET_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        getAllEmail: initialState.getAllEmail
      };

    //get one emai template to edit
    case templateConstant.GET_ONE_EMAIL_REQUEST:
      return {
        ...state
      };
    case templateConstant.GET_ONE_EMAIL_SUCCESS:
      return {
        ...state,
        getOneEmail: action.data.data,
        getOneEmailOld: JSON.parse(JSON.stringify(action.data.data)),
        emailDrawer: true
      };
    case templateConstant.GET_ONE_EMAIL_FAILURE:
      return {
        ...state,
      };

    //update email drauer
    case templateConstant.UPDATE_EMAIL_DRAWER:
      if (action.data) {
        return {
          ...state,
          emailDrawer: action.data
        };
      } else {
        return {
          ...state,
          emailDrawer: action.data,
          getOneEmail: {},
          getOneEmailOld: {}
        };
      }

    //delete email template
    case templateConstant.DELETE_ONE_EMAIL_REQUEST:
      return {
        ...state
      };
    case templateConstant.DELETE_ONE_EMAIL_SUCCESS:
      return {
        ...state,
      };
    case templateConstant.DELETE_ONE_EMAIL_FAILURE:
      return {
        ...state,
      };

    //delete email template
    case templateConstant.ADD_UPDATE_EMAIL_REQUEST:
      return {
        ...state
      };
    case templateConstant.ADD_UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        emailDrawer: false,
        getOneEmail: {},
        getOneEmailOld: {}
      };
    case templateConstant.ADD_UPDATE_EMAIL_FAILURE:
      return {
        ...state,
      };

    //GET all application templates
    case templateConstant.GET_APP_TEMPLATE_REQUEST:
      return {
        ...state
      };
    case templateConstant.GET_APP_TEMPLATE_SUCCESS:

      const appPage = action.data.page;
      let stateAppTemplate = [];

      if (appPage.skip > 0) {
        stateAppTemplate = [...state.appTemplate.data, ...action.data.data]
      } else {
        stateAppTemplate = [...action.data.data]
      }

      return {
        ...state,
        appTemplate: {
          data: stateAppTemplate,
          page: action.data.page
        }
      };
    case templateConstant.GET_APP_TEMPLATE_FAILURE:
      return {
        ...state,
        appTemplate: initialState.appTemplate
      };

    //get one appplication template to edit
    case templateConstant.GET_ONE_APP_REQUEST:
      return {
        ...state
      };
    case templateConstant.GET_ONE_APP_SUCCESS:
      return {
        ...state,
        appTemp: action.data.data,
        appTempOld: JSON.parse(JSON.stringify(action.data.data)),
        applicationDrawer: true
      };
    case templateConstant.GET_ONE_APP_FAILURE:
      return {
        ...state,
      };

    //update app drauer
    case templateConstant.UPDATE_APP_DRAWER:
      if (action.data) {
        return {
          ...state,
          applicationDrawer: action.data
        };
      } else {
        return {
          ...state,
          applicationDrawer: action.data,
          appTemp: {},
          appTempOld: {},
        };
      }

    //delete app template
    case templateConstant.DELETE_ONE_APP_REQUEST:
      return {
        ...state
      };
    case templateConstant.DELETE_ONE_APP_SUCCESS:
      return {
        ...state,
      };
    case templateConstant.DELETE_ONE_APP_FAILURE:
      return {
        ...state,
      };

    //delete app template
    case templateConstant.ADD_UPDATE_APP_REQUEST:
      return {
        ...state
      };
    case templateConstant.ADD_UPDATE_APP_SUCCESS:
      return {
        ...state,
        applicationDrawer: false,
        appTemp: {},
        appTempOld: {}
      };
    case templateConstant.ADD_UPDATE_APP_FAILURE:
      return {
        ...state,
      };


    //get email templates name
    case templateConstant.GET_EMAIL_NAME_REQUEST:
      return {
        ...state
      };
    case templateConstant.GET_EMAIL_NAME_SUCCESS:
      return {
        ...state,
        emailName: action.data.data
      };
    case templateConstant.GET_EMAIL_NAME_FAILURE:
      return {
        ...state,
        emailName: initialState.emailName
      };

    //get aggrement names
    case templateConstant.GET_AGGREMENT_NAME_REQUEST:
      return {
        ...state
      };
    case templateConstant.GET_AGGREMENT_NAME_SUCCESS:
      return {
        ...state,
        aggrementName: action.data.data
      };
    case templateConstant.GET_AGGREMENT_NAME_FAILURE:
      return {
        ...state,
        aggrementName: initialState.aggrementName
      };
    //get aggrement names
    case templateConstant.GET_DISCLOSURE_NAME_REQUEST:
      return {
        ...state
      };
    case templateConstant.GET_DISCLOSURE_NAME_SUCCESS:
      return {
        ...state,
        disclosureName: action.data.data
      };
    case templateConstant.GET_DISCLOSURE_NAME_FAILURE:
      return {
        ...state,
        disclosureName: initialState.disclosureName
      };



    //GET all criteria templates
    case templateConstant.GET_CRITERIA_REQUEST:
      return {
        ...state
      };
    case templateConstant.GET_CRITERIA_SUCCESS:

      const criteriaPage = action.data.page;
      let criteriaTemplate = [];

      if (criteriaPage.skip > 0) {
        criteriaTemplate = [...state.criteriaTemplate.data, ...action.data.data]
      } else {
        criteriaTemplate = [...action.data.data]
      }

      return {
        ...state,
        criteriaTemplate: {
          data: criteriaTemplate,
          page: action.data.page
        }
      };
    case templateConstant.GET_CRITERIA_FAILURE:
      return {
        ...state,
        criteriaTemplate: initialState.criteriaTemplate
      };

    //get one criteria template to edit
    case templateConstant.GET_ONE_CRITERIA_REQUEST:
      return {
        ...state
      };
    case templateConstant.GET_ONE_CRITERIA_SUCCESS:
      return {
        ...state,
        criteria: action.data.data,
        criteriaOld: JSON.parse(JSON.stringify(action.data.data)),
        criteriaDrawer: true
      };
    case templateConstant.GET_ONE_CRITERIA_FAILURE:
      return {
        ...state,
      };

    //update criteria drauer
    case templateConstant.UPDATE_CRITERIA_DRAWER:
      if (action.data) {
        return {
          ...state,
          criteriaDrawer: action.data
        };
      } else {
        return {
          ...state,
          criteriaDrawer: action.data,
          criteriaOld: {},
          criteria: {}
        };
      }

    //delete criteria template
    case templateConstant.DELETE_ONE_CRITERIA_REQUEST:
      return {
        ...state
      };
    case templateConstant.DELETE_ONE_CRITERIA_SUCCESS:
      return {
        ...state,
      };
    case templateConstant.DELETE_ONE_CRITERIA_FAILURE:
      return {
        ...state,
      };

    //add or update criteria template
    case templateConstant.ADD_UPDATE_CRITERIA_REQUEST:
      return {
        ...state
      };
    case templateConstant.ADD_UPDATE_CRITERIA_SUCCESS:
      return {
        ...state,
        criteriaDrawer: false,
        criteria: {},
        criteriaOld: {},
      };
    case templateConstant.ADD_UPDATE_CRITERIA_FAILURE:
      return {
        ...state,
      };

    //GET all agreement templates
    case templateConstant.GET_AGREEMENT_REQUEST:
      return {
        ...state
      };
    case templateConstant.GET_AGREEMENT_SUCCESS:

      const agreementPage = action.data.page;
      let agreementTemplate = [];

      if (agreementPage.skip > 0) {
        agreementTemplate = [...state.agreementTemplate.data, ...action.data.data]
      } else {
        agreementTemplate = [...action.data.data]
      }

      return {
        ...state,
        agreementTemplate: {
          data: agreementTemplate,
          page: action.data.page
        }
      };
    case templateConstant.GET_AGREEMENT_FAILURE:
      return {
        ...state,
        agreementTemplate: initialState.agreementTemplate
      };

    //get one agreement template to edit
    case templateConstant.GET_ONE_AGREEMENT_REQUEST:
      return {
        ...state
      };
    case templateConstant.GET_ONE_AGREEMENT_SUCCESS:
      return {
        ...state,
        agreement: action.data.data,
        agreementOld: JSON.parse(JSON.stringify(action.data.data)),
        agreementDrawer: true
      };
    case templateConstant.GET_ONE_AGREEMENT_FAILURE:
      return {
        ...state,
      };

    //update agreement drauer
    case templateConstant.UPDATE_AGREEMENT_DRAWER:
      if (action.data) {
        return {
          ...state,
          agreementDrawer: action.data
        };
      } else {
        return {
          ...state,
          agreementDrawer: action.data,
          agreement: { result: [], nameData: {} },
          agreementOld: { result: [], nameData: {} },
        };
      }

    //delete agreement template
    case templateConstant.DELETE_ONE_AGREEMENT_REQUEST:
      return {
        ...state
      };
    case templateConstant.DELETE_ONE_AGREEMENT_SUCCESS:
      return {
        ...state,
      };
    case templateConstant.DELETE_ONE_AGREEMENT_FAILURE:
      return {
        ...state,
      };

    //add or update agreement template
    case templateConstant.ADD_UPDATE_AGREEMENT_REQUEST:
      return {
        ...state
      };
    case templateConstant.ADD_UPDATE_AGREEMENT_SUCCESS:
      return {
        ...state,
        agreementDrawer: false,
        agreement: { result: [], nameData: {} },
        agreementOld: { result: [], nameData: {} },
      };
    case templateConstant.ADD_UPDATE_AGREEMENT_FAILURE:
      return {
        ...state,
      };

    //update aggrement view
    case templateConstant.UPDATE_AGGREMENT_VIEW:

      let agreementUpdate = state.agreementTemplate.data || [];
      let viewIndex = action.data.index;
      let viewLen = action.data.len
      for (let i = 0; i < agreementUpdate.length; i++) {
        if (i == viewIndex) {
          agreementUpdate[i]['view'] = viewLen
        }
      }
      return {
        ...state,
        agreementTemplate: {
          data: agreementUpdate,
          page: state.agreementTemplate.page
        }
      };

    //update aggrement array data
    case templateConstant.UPDATE_AGREEMENT_ARR_DATA:

      const newData = action.data;
      return {
        ...state,
        agreement: { ...state.agreement, result: newData }
      }
    case templateConstant.UPDATE_AGREEMENT_NAME:

      const nameDat = action.data
      if (state.agreement.nameData && Object.keys(state.agreement.nameData).length) {
        return {
          ...state,
          agreement: { ...state.agreement, nameData: { ...state.agreement.nameData, name: nameDat } }
        }
      } else {
        return {
          ...state,
          agreement: { ...state.agreement, nameData: { id: null, name: nameDat, agreement_id: null, disclosure_id: null, position: null, title: null } }
        }
      }

    //reset page search
    case templateConstant.RESET_SEARCH:
      return {
        ...state,
        getAllEmail: {
          ...state.getAllEmail,
          page: { ...state.getAllEmail.page, search: '' }
        },
        appTemplate: {
          ...state.appTemplate,
          page: { ...state.appTemplate.page, search: '' }
        },
        criteriaTemplate: {
          ...state.criteriaTemplate,
          page: { ...state.criteriaTemplate.page, search: '' }
        },
        agreementTemplate: {
          ...state.agreementTemplate,
          page: { ...state.agreementTemplate.page, search: '' }
        },
        documentTemplate: {
          ...state.documentTemplate,
          page: { ...state.documentTemplate.page, search: '' }
        },
      }



    //GET all document templates
    case templateConstant.GET_DOCUMENT_REQUEST:
      return {
        ...state
      };
    case templateConstant.GET_DOCUMENT_SUCCESS:

      const documentPage = action.data.page;
      let documentTemplate = [];

      if (documentPage.skip > 0) {
        documentTemplate = [...state.documentTemplate.data, ...action.data.data]
      } else {
        documentTemplate = [...action.data.data]
      }

      return {
        ...state,
        documentTemplate: {
          data: documentTemplate,
          page: action.data.page
        }
      };
    case templateConstant.GET_DOCUMENT_FAILURE:
      return {
        ...state,
        documentTemplate: initialState.documentTemplate
      };

    //get one document template to edit
    case templateConstant.GET_ONE_DOCUMENT_REQUEST:
      return {
        ...state
      };
    case templateConstant.GET_ONE_DOCUMENT_SUCCESS:
      return {
        ...state,
        document: action.data.data,
        documentOld: JSON.parse(JSON.stringify(action.data.data)),
        documentDrawer: true
      };
    case templateConstant.GET_ONE_DOCUMENT_FAILURE:
      return {
        ...state,
        document: initialState.document,
        documentOld: initialState.documentOld,
      };

    //update document drauer
    case templateConstant.UPDATE_DOCUMENT_DRAWER:
      if (action.data) {
        return {
          ...state,
          documentDrawer: action.data
        };
      } else {
        return {
          ...state,
          documentDrawer: action.data,
          document: { disclosure: {}, choices: [], variation: [] },
          documentOld: { disclosure: {}, choices: [], variation: [] },
        };
      }

    //delete document template
    case templateConstant.DELETE_ONE_DOCUMENT_REQUEST:
      return {
        ...state
      };
    case templateConstant.DELETE_ONE_DOCUMENT_SUCCESS:
      return {
        ...state,
      };
    case templateConstant.DELETE_ONE_DOCUMENT_FAILURE:
      return {
        ...state,
      };

    //add or update document template
    case templateConstant.ADD_UPDATE_DOCUMENT_REQUEST:
      return {
        ...state
      };
    case templateConstant.ADD_UPDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentDrawer: false,
        document: { disclosure: {}, choices: [], variation: [] },
        documentOld: { disclosure: {}, choices: [], variation: [] },
      };
    case templateConstant.ADD_UPDATE_DOCUMENT_FAILURE:
      return {
        ...state,
      };

    //update choices
    case templateConstant.UPDATE_DISCLOSURE_CHOICES:
      return {
        ...state,
        documentOld: { ...state.documentOld },
        document: { ...state.document, choices: action.data }
      };

    //update choices
    case templateConstant.UPDATE_DOCUMENT_VARIATION:
      return {
        ...state,
        documentOld: { ...state.documentOld },
        document: { ...state.document, variation: action.data }
      };

    default:
      return state;
  }
}
