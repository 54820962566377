import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import propTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Grid from '@mui/material/Grid';

//Import Styles and images
import ApplicationStyles from "../ApplicationStyles";

// Import Components
import { PageContainer } from "../../../components";
import ApplicationChords from './ApplicationChords'

// Import Actions
import { applicationAction } from "../../../redux/actions";

// Import Config Layout
import { getChildContainerHeight } from "../../../config/layout";
import NoRecordsFound from "../NoRecordsFound";

function ApplicationTile(props) {
  //define dispatch
  const dispatch = useDispatch();

  //get props
  const { classes } = props;

  //get page height
  const height = getChildContainerHeight();

  //get reducer data
  const { getAll: { data,page }, } = useSelector((state) => state.application);
  const { loading } = useSelector(s=>s.UI)

  //get user data
  useEffect(() => {
      dispatch(applicationAction.getAll({limit:20,skip:0,search:page.search,sort:'desc'}));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <PageContainer height={height} paddingRight={12}>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            alignItems="stretch"
            className={"h-100"}
            spacing={2}
            wrap="nowrap"
          >
            {data && data.length>0 ? <ApplicationChords classes={classes} /> :  !loading ? <NoRecordsFound/> : null}
          </Grid>
        </Grid>
      </PageContainer>
    </Grid>
  );
}

// default props
ApplicationTile.defaultProps = {
  classes: {},
};

// prop types
ApplicationTile.propTypes = {
  classes: propTypes.object,
};

// export default applist;
export default withStyles(ApplicationStyles)(ApplicationTile);
