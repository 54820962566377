// particpants reducer types
export const serviceConstant = {

  // To Get All service Details
  SERVICE_GETALL_REQUEST: "SERVICE_GETALL_REQUEST",
  SERVICE_GETALL_SUCCESS: "SERVICE_GETALL_SUCCESS",
  SERVICE_GETALL_FAILURE: "SERVICE_GETALL_FAILURE",

  // To Search service Details
  SERVICE_SEARCH_REQUEST: "SERVICE_SEARCH_REQUEST",
  SERVICE_SEARCH_SUCCESS: "SERVICE_SEARCH_SUCCESS",
  SERVICE_SEARCH_FAILURE: "SERVICE_SEARCH_FAILURE",

  // DELETE Service
  SERVICE_DELETE_REQUEST: "SERVICE_DELETE_REQUEST",
  SERVICE_DELETE_SUCCESS: "SERVICE_DELETE_SUCCESS",
  SERVICE_DELETE_FAILURE: "SERVICE_DELETE_FAILURE",

  // service Aplication count 
  SERVICE_GET_APPLICATION_COUNT_REQUEST: "SERVICE_GET_APPLICATION_COUNT_REQUEST",
  SERVICE_GET_APPLICATION_COUNT_SUCCESS: "SERVICE_GET_APPLICATION_COUNT_SUCCESS",
  SERVICE_GET_APPLICATION_COUNT_FAILURE: "SERVICE_GET_APPLICATION_COUNT_FAILURE",

  // To Search user tab service Details
  SERVICE_SEARCH_FORUSERS_REQUEST: "SERVICE_SEARCH_FORUSERS_REQUEST",
  SERVICE_SEARCH_FORUSERS_SUCCESS: "SERVICE_SEARCH_FORUSERS_SUCCESS",
  SERVICE_SEARCH_FORUSERS_FAILURE: "SERVICE_SEARCH_FORUSERS_FAILURE",

  // To get template list constants
  GET_TEMPLATE_LIST_REQUEST: "GET_TEMPLATE_LIST_REQUEST",
  GET_TEMPLATE_LIST_SUCCESS: "GET_TEMPLATE_LIST_SUCCESS",
  GET_TEMPLATE_LIST_FAILURE: "GET_TEMPLATE_LIST_FAILURE",

  //create new SERVICE
  CREATE_SERVICE_REQUEST: "CREATE_SERVICE_REQUEST",
  CREATE_SERVICE_SUCCESS: "CREATE_SERVICE_SUCCESS",
  CREATE_SERVICE_FAILURE: "CREATE_SERVICE_FAILURE",

  //update existing SERVICE
  UPDATE_SERVICE_REQUEST: "UPDATE_SERVICE_REQUEST",
  UPDATE_SERVICE_SUCCESS: "UPDATE_SERVICE_SUCCESS",
  UPDATE_SERVICE_FAILURE: "UPDATE_SERVICE_FAILURE",

  //update fee
  UPDATE_FEE_REQUEST: "UPDATE_FEE_REQUEST",
  UPDATE_FEE_SUCCESS: "UPDATE_FEE_SUCCESS",
  UPDATE_FEE_FAILURE: "UPDATE_FEE_FAILURE",

  //EXCEL DOWNLOAD CONSTANTS
  EXCEL_DOWNLOAD_REQUEST: "EXCEL_DOWNLOAD_REQUEST",
  EXCEL_DOWNLOAD_SUCCESS: "EXCEL_DOWNLOAD_SUCCESS",
  EXCEL_DOWNLOAD_FAILURE: "EXCEL_DOWNLOAD_FAILURE",

  //EXCEL UPLOAD CONSTANTS
  EXCEL_UPLOAD_REQUEST: "EXCEL_UPLOAD_REQUEST",
  EXCEL_UPLOAD_SUCCESS: "EXCEL_UPLOAD_SUCCESS",
  EXCEL_UPLOAD_FAILURE: "EXCEL_UPLOAD_FAILURE",

  //CREATE DOCUMENTS FROM SCRATCH CONSTANTS
  CREATE_DOCUMENTS_FROM_SCRATCH_REQUEST: "CREATE_DOCUMENTS_FROM_SCRATCH_REQUEST",
  CREATE_DOCUMENTS_FROM_SCRATCH_SUCCESS: "CREATE_DOCUMENTS_FROM_SCRATCH_SUCCESS",
  CREATE_DOCUMENTS_FROM_SCRATCH_FAILURE: "CREATE_DOCUMENTS_FROM_SCRATCH_FAILURE",

  // checkboxSelect reducer update 
  CHECKBOX_REDUCER_UPDATE: "CHECKBOX_REDUCER_UPDATE",

  //add Edit Service update:
  ADD_EDIT_SERVICE_REDUCER_UPDATE: "ADD_EDIT_SERVICE_REDUCER_UPDATE",

  //add documents in scratch
  ADD_NEW_PAGE_DOCUMENT: "ADD_NEW_PAGE_DOCUMENT",

  //update error flag in scratch
  UPDATE_ERROR_FLAG_DOCUMENT: "UPDATE_ERROR_FLAG_DOCUMENT",

  //update aggrement reducer
  UPDATE_AGREEMENT_DOCUMENT: "UPDATE_AGREEMENT_DOCUMENT",

  //update Alignment reducer
  UPDATE_SERVICE_ALIGNMENT: "UPDATE_SERVICE_ALIGNMENT",

  //CREATE Agreements FROM SCRATCH CONSTANTS
  CREATE_AGREEMENT_FROM_SCRATCH_REQUEST: "CREATE_AGREEMENT_FROM_SCRATCH_REQUEST",
  CREATE_AGREEMENT_FROM_SCRATCH_SUCCESS: "CREATE_AGREEMENT_FROM_SCRATCH_SUCCESS",
  CREATE_AGREEMENT_FROM_SCRATCH_FAILURE: "CREATE_AGREEMENT_FROM_SCRATCH_FAILURE",

  //CREATE TEMPLATE FROM SCRATCH CONSTANTS
  CREATE_TEMPLATE_FROM_SCRATCH_REQUEST: "CREATE_TEMPLATE_FROM_SCRATCH_REQUEST",
  CREATE_TEMPLATE_FROM_SCRATCH_SUCCESS: "CREATE_TEMPLATE_FROM_SCRATCH_SUCCESS",
  CREATE_TEMPLATE_FROM_SCRATCH_FAILURE: "CREATE_TEMPLATE_FROM_SCRATCH_FAILURE",

  //update view and search in chird view
  UPDATE_VIEW_TYPE_AND_SEARCH:'UPDATE_VIEW_TYPE_AND_SEARCH',

  UPDATE_DOC_STEP_INIT:"UPDATE_DOC_STEP_INIT",
};
