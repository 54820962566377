import { alertConstant, submissionConstant, loaderConstant } from "../constants";
import { submissionService } from "../services";
import { baseAction } from "./baseAction";

const { request, success, failure } = baseAction;

// Export All Auth Actions Functions
export const submissionAction = {
    search,
    getAttachmentValues,
    remove,
    refundPayment,
    downloadAttachment,
    getRetrySubmission,
    retrySubmission
};

/**
 * Get All Submission
 */
function search(skip, limit, sortBy, sort, sFields, sValues, showRetryNotification = 0) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(submissionConstant.SUBMISSION_SEARCH_REQUEST));
        submissionService.search(skip, limit, sortBy, sort, sFields, sValues).then(
            (data) => {
                dispatch(success(submissionConstant.SUBMISSION_SEARCH_SUCCESS, data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                if (showRetryNotification) {
                    dispatch(success(alertConstant.ALERT_SUCCESS, data.retryTazMessage));
                }
            },
            (error) => {
                dispatch(failure(submissionConstant.SUBMISSION_SEARCH_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}

/**
 * Get old and new Values
 */
 function getAttachmentValues(params,cb = ()=>{}) {
    return (dispatch) => {
      dispatch(request(loaderConstant.LOADING_TRUE));
      dispatch(request(submissionConstant.ATTACHMENT_SEARCH_REQUEST));
      submissionService.getAttachmentValues(params).then(
        (data) => {
            dispatch(request(loaderConstant.LOADING_FALSE));
          dispatch(success(submissionConstant.ATTACHMENT_SEARCH_SUCCESS, data));
          cb(data)
        },
        (error) => {
          dispatch(failure(submissionConstant.ATTACHMENT_SEARCH_FAILURE, error));
          dispatch(failure(alertConstant.ALERT_ERROR, error));
          dispatch(request(loaderConstant.LOADING_FALSE));
        }
      );
    };
  }

/**
 * Delete a submission
 */
function remove(data, successCallback = () => {}) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(submissionConstant.SUBMISSION_DELETE_REQUEST));
        submissionService.remove(data).then(
            (data) => {
                dispatch(request(loaderConstant.LOADING_FALSE));
                dispatch(success(submissionConstant.SUBMISSION_DELETE_SUCCESS, data));
                dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
                successCallback()
            },
            (error) => {
                dispatch(failure(submissionConstant.SUBMISSION_DELETE_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}

/**
 * Refund payment
 */
function refundPayment(data, successCallback = () => {}) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(submissionConstant.SUBMISSION_REFUND_PAYMENT_REQUEST));
        submissionService.refundPayment(data).then(
            (data) => {
                dispatch(request(loaderConstant.LOADING_FALSE));
                dispatch(success(submissionConstant.REFUND_PAYMENT_UPDATED_SUCCESS, data));
                dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
                successCallback()
            },
            (error) => {
                dispatch(failure(submissionConstant.SUBMISSION_REFUND_PAYMENT_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}

/**
 * Download submission attachment
 */
function downloadAttachment(data, successCallback = () => {}) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(submissionConstant.SUBMISSION_REFUND_PAYMENT_REQUEST));
        submissionService.downloadAttachment(data).then(
            (data) => {
                if (data && data.data && (data.data.error || data.status === "failed")) {
                    dispatch(failure(alertConstant.ALERT_ERROR, data.data.error));
                    dispatch(request(loaderConstant.LOADING_FALSE));
                } else {
                    let downloadFile = data.field;
                    let downloadType = ['criteria', 'information'].includes(downloadFile) ? 'application/pdf' : 'application/zip';
                    const bytes = new Uint8Array(data.data.data);
                    const blob = new Blob([bytes], { type: downloadType });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    downloadFile = (downloadFile === 'information') ? 'application' : (downloadFile === 'disclosure') ? 'document' : downloadFile;
                    link.download = `${downloadFile}_${data.id}`;
                    link.click();

                    dispatch(request(loaderConstant.LOADING_FALSE));
                    dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
                }
            },
            (error) => {
                dispatch(failure(submissionConstant.SUBMISSION_ATTACHMENT_DOWNLOAD_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}


/**
 * get retry submission details
 */
function getRetrySubmission(data) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(submissionConstant.GET_RETRY_SUBMISSION_REQUEST));
        submissionService.getRetrySubmission(data).then(
            (data) => {
                dispatch(request(loaderConstant.LOADING_FALSE));
                dispatch(success(submissionConstant.GET_RETRY_SUBMISSION_SUCCESS, data));
            },
            (error) => {
                dispatch(failure(submissionConstant.GET_RETRY_SUBMISSION_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}

/**
 * Retry submission
 */
function retrySubmission(data, successCallback = () => {}) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(submissionConstant.RETRY_SUBMISSION_REQUEST));
        submissionService.retrySubmission(data).then(
            (data) => {
                console.log(data)
                dispatch(request(loaderConstant.LOADING_FALSE));
                if (data.status === 'failed') {
                    dispatch(success(submissionConstant.RETRY_SUBMISSION_SUCCESS, data));
                    // dispatch(failure(alertConstant.ALERT_ERROR, data.message));
                } else {
                    dispatch(success(submissionConstant.RETRY_SUBMISSION_SUCCESS, data));
                    // dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
                }
                successCallback(data)
            },
            (error) => {
                dispatch(failure(submissionConstant.RETRY_SUBMISSION_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}