import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid';
import { withStyles } from "@mui/styles"; 

//Styles
import ServicesStyles from "../ServicesStyles"

// Import Components
import { PageContainer } from "../../../components";
import ServiceChords from "./serviceChord"
import NoRecordsFound from "./NoRecordsFound"

// Import Cofig Constants
import { getChildContainerHeight } from "../../../config/layout"

// Import Actions
import { serviceAction } from "../../../redux/actions";

// Import Config Layout
import { servicesCardLimit } from "../../../config/layout";

const ServicesTile = (props) => {

  const { editOpenDrawer } = props

  //define dispatch
  const dispatch = useDispatch();

  let cardLimit = servicesCardLimit();

  const { loading } = useSelector(s => s.UI)

  const { classes } = props;

  const height = getChildContainerHeight();

  //get reducer data
  let { getAll: { page, data } } = useSelector((state) => state.service);

  //get user data
  useEffect(() => {
    dispatch(serviceAction.getAll({ limit: cardLimit, skip: 1, search: page.search, sort: page.sort }));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <PageContainer height={height} paddingRight={12}>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            alignItems="stretch"
            className={"h-100"}
            spacing={2}
            wrap="nowrap"
          >
            {data && data.length > 0 ? <ServiceChords classes={classes} editOpenDrawer={editOpenDrawer} /> : !loading ? <NoRecordsFound /> : null}
          </Grid>
        </Grid>
      </PageContainer>
    </Grid>
  );
}

// default props
ServicesTile.defaultProps = {
  classes: {}
};

// prop types
ServicesTile.propTypes = {
  classes: PropTypes.object
};

export default withStyles(ServicesStyles)(ServicesTile);
