import '../../assets/theme/palette';
import palette from '../../assets/theme/palette';
const ServicesStyles = () => ({
  searchIcon: { padding: 6, marginRight: 10 },
  marginBottom15: { marginBottom: 15 },
  btnBlue: {
    backgroundColor: `${palette.primary.dary} !important`,
    color: '#fff !important',
    '&.MuiButton-root.Mui-disabled': {
      backgroundColor: `${palette.primary.dary} !important`,
      color: '#fff !important'
    },
    color: "#fff",
    '&:hover': {
      color: "#fff",
      backgroundColor: '#2279a5 !important',
    }
  },
  addAServiceCenter: {
    // height: 'calc(100% - 130px)',
    maxWidth: 1000,
    margin: "auto"
  },
  toggleButton: {
    background: '#fff',
    '& .Mui-selected': {
      backgroundColor: palette.primary.dary,
      '& svg path': {
        fill: '#fff !important'
      },
      '& rect': {
        fill: '#fff !important'
      }
    }
  },
  documentDelete:{
    position: "absolute",
    right: "0",
    top: "6px"
  },
  titlerow: {
    height: '34px',
    padding: '0 16px',
    marginBottom: 4,
    // backgroundColor: 'blue',
    // padding: '10px 0',
    '& .MuiToggleButton-root': {
      padding: 7
    }
  },
  addAServiceTop: {
    height: 65
  },

  cardSection: {
    height: '100%',
    width: "100%",
    borderRadius: '4px',
    boxShadow: '0px 0px 8px #e4e4e4',
    padding: '4px 16px',
    '& .MuiStep-completed': {
      '& .MuiSvgIcon-root': {
        fill: "#3abeff"
      },
      '& .StepperCircle g': {
        stroke: '#3abeff !important'
      },
    }
  },

  addServiceDialog: {
    height: '100%',
    '& .MuiStepper-root': {
      padding: 3,
      width: '35%',
      paddingTop: 8

    },
    '& .MuiStepLabel-label': {
      marginTop: 3,
    }
  },

  settingsTileBgnoneAddDoc: {
    padding: '10px',
    border: `1px solid ${palette.greyshades.light2}`,
    borderRadius: 4,
    backgroundColor: '#fff',
    marginBottom: 12,
    '& .MuiTypography-root': {
      width: '70%',
    },
    '& .MuiSvgIcon-root': {
      width: '10%',
    },
    '& .dragIcon': {
      cursor: "move"
    }
  },
  textFieldChoices: {
    width: 'calc(100% - 105px)'
},

  dragIcon: {
    marginRight: 5,
    cursor: 'pointer',
    fill: palette.greyshades.light1
  },
  widthAuto: {
    '& .MuiSvgIcon-root': {
      width: "auto !important"
    }
  },

  choicesContainer: {
    padding: 20,
    background: palette.greyshades.light3,
    border: `1px solid ${palette.greyshades.light2}`,
  },
  addServiceDialogRoot: {
    '& .MuiDialog-paper': {
      backgroundColor: palette.greyshades.light3
    }
  },

  //Card
  serviceTile: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 8px #d4d1d1',
    borderRadius: 4,
    paddingTop: 1,
    height: 227,
    // padding: 20,
    '& .MuiCheckbox-root': {
      padding: 0
    },
    '& .MuiFormControlLabel-root': {
      marginLeft: 0
    },
  },
  serviceTileContainer: {
    margin: '0 20px 20px 20px',
    overflow: "hidden",
    height: 135
  },
  servicePriceChip: {
    background: '#F7F7F7',
    borderRadius: 30,
    padding: "2px 10px",
    marginRight: 5
  },

  //Drawer
  backDrop: {
    zIndex: 3
  },
  allianceDrawer: {
    '& .MuiDrawer-paper': {
      width: 400
    }
  },
  usersRightTiles: {
    height: 'calc(100vh - 125px)',
    overflowY: 'auto',
    flexGrow: 1,
    transition: 'all 0.5s ease'
  },

  searchBoxGrid: {
    width: 0,
    // position: 'absolute',
    marginRight: '-10px',
    transition: 'all 0.4s ease',
    // right: '512px',
    '& .searchBox': {
      // transition: '0.5s',
      // width: 200,
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: '8px'
      }
    },
    '& .MuiOutlinedInput-root': {
      height: '32px',
      minHeight: 32,
    }
  },

  servicesSettingsTile: {
    padding: '8px 13px 8px 7px',
    border: `1px solid ${palette.greyshades.light2}`,
    borderRadius: 4,
    backgroundColor: palette.greyshades.light3,
    // marginBottom: 15,
    minHeight: 42,
    '& .MuiFormControlLabel-root': {
      marginRight: '0px !important'
    },
    '& .dragIcon': {
      cursor: "move"
    },
    '& .settingSTileCheck': {
      marginRight: 0,
      padding: 0,
      '& .MuiFormControlLabel-root': {
        marginRight: '0px !important'
      }
    }
  },
  cardSectionDialogAddTemplate: {
    background: '#fff',
    boxShadow: '0px 0px 8px #d4d1d1',
    borderRadius: '6px',
    minHeight: 400,
    // height: 420,
    height: 'calc(100vh - 260px)',
    padding: 30,
    overflowY: 'auto !important'
  },
  cardSectionDialog:
  {
    background: '#fff',
    boxShadow: '0px 0px 8px #d4d1d1',
    borderRadius: '6px',
    minHeight: 400,
    // height: 420,
    height: 'calc(100vh - 260px)',
    padding: 20,
    overflowY: 'hidden !important',
    '& .pageScrollVerticle ': {
      height: '100% !important'
    },
    '& .pageScrollVerticle > div ': {
      height: 'calc(100% - 40px) !important'
    },
    '& .pageScrollVerticle > div > div': {
      height: '100% !important'
    },
    '& .pageScrollVerticle > div > div > div': {
      height: '100% !important',
      background: '#f7f7f7',
      padding: '15px 20px',
      overflowY: 'auto',
    },
    '& .pageScrollVerticle': {
      '& .MuiAppBar-colorDefault': {
        backgroundColor: '#fff !important'
      }
    },
    '& .MuiOutlinedInput-multiline': {
      borderRadius: '3px !important'
    },
    '& .createTemChoice': {
      backgroundColor: '#fff !important',
      marginTop: 0
    },
    '& .createTempChoiceTextField': {
      width: 'calc(100% - 86px)',
      height: 'auto !important'
    },
    createTempChoiceText: {
      width: 'calc(100% - 150px)',
      display: 'flex',
      alignItems: 'center'
    }
  },
  contentTab: {
    '& .rdw-editor-main': {
      height: '230px !important'
    },
    '& .rdw-editor-toolbar': {
      backgroundColor: 'transparent'
    },
    '& .rdw-option-wrapper, .rdw-dropdown-wrapper': {
      backgroundColor: 'transparent'
    }
  },
  grow: {
    flexGrow: 1
  },
  notifyIcon: {
    width: 28,
    height: 28,
    padding: 0,
    background: palette.greyshades.light2
  },
  borderNone: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px !important'
    }
  },
  backDrop: {
    zIndex: 3
  },
  allianceDrawer600: {
    '& .MuiDrawer-paper': {
      width: 600
    }
  },

  //Add User
  selectBox: {
    width: "100%",
    '& .MuiSelect-outlined': {
      padding: 10,
      textAlign: 'left',
      borderRadius: 30
    },
    '& .MuiOutlinedInput-notchedOutline legend': {
      display: "none !important"
    },
    '& .MuiOutlinedInput-notchedOutline': {
      marginTop: "5px !important"
    },
  },

  seachIcon: {
    fill: palette.greyshades.light1
  },

  padding6: { padding: 6 },

  moreContainerTop: {
    marginBottom: -18
  },

  tileMoreIcon: {
    marginRight: -12,
    marginTop: -3,
    padding: 5,
    // width: 20,
    // height: 20
  },
  servicesTable: {
    paddingRight: 16,
    paddingLeft: 16,

  },
  selectTemplate: {
    backgroundColor: palette.greyshades.light3,
    border: `1px solid ${palette.greyshades.light2}`,
    padding: 20
  },
  settingsTileBgnone: {
    padding: '13px',
    border: `1px solid ${palette.greyshades.light2}`,
    borderRadius: 4,
    backgroundColor: '#fff',
    '& .MuiTypography-root': {
      width: '70%',
      marginRight: 5
    },
    '& .MuiSvgIcon-root': {
      width: '10%',
    },
    '& .dragIcon': {
      cursor: "move",
      marginRight: 5
    }
  },
  checkIcon: {
    fill: palette.primary.dary
  },
  settingsTile: {
    padding: '8px 13px 8px 7px',
    border: `1px solid ${palette.greyshades.light2}`,
    borderRadius: 4,
    backgroundColor: palette.greyshades.light3,
    marginBottom: 15,
    height: 42,
    '& .MuiFormControlLabel-root': {
      marginRight: '0px !important'
    },
    '& .dragIcon': {
      cursor: "move"
    },
    '& .settingSTileCheck': {
      marginRight: 0,
      padding: 0,
      '& .MuiFormControlLabel-root': {
        marginRight: '0px !important'
      }
    }
  },
  dragContainer: {
    height: 24
  },
  // serviceSettingTileWidth: {
  //   maxWidth: '46% !important',
  //   flexBasis: '46% !important'
  // }
  error:{
    '& .editor-class': {
      border: '1px solid red !important'
    }
  },

  choicesDelete: {
    marginTop: '-50px',
    marginRight: '-50px'
  },
  addClientContainer: {
    display: 'flex',
    alignItems: "center",
    height: '100%'
  },

  updateFeeAlert: {
    '& .MuiAlert-root': {
      alignItems: 'center'
    },
    '& .MuiAlert-standardError': {
      backgroundColor: '#f44336',
      color: '#fff',
      fontSize: 14,
      fontWeight: '500',
      '& .MuiSvgIcon-root': {
        color: '#fff',
      },
      '& .closeIcon': {
        fill: '#fff',
      }
    }
  },
  addEditRtContainer: {
    maxHeight: 'calc(100vh - 160px)',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  agreementDargText: {
    width: 'calc(100% - 10px) !important', 
    paddingRight: '16px !important'
  },
  mrAuto:{
    marginRight: "auto"
  }

})

export default ServicesStyles;