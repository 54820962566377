import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Slide from '@mui/material/Slide';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

//Styles and Images
import TemplatesStyles from "../TemplatesStyles"
import { SortIcon, MoreIcon } from '../../../assets/svg'

// Import Components
import { Button, SearchBar, Typography, AlertDialog, LazyLoader } from "../../../components";
import AddEditCriteria from "./AddEditCriteria";
import NoRecordsFound from "../../Users/Components/NoRecordsFound";

// Import Config Layout
import { getTemplatesHeight, criteriaCardLimit } from "../../../config/layout";

// Import Actions
import { templateAction } from "../../../redux/actions";

//import helpers
import { nameLogoGeneration } from '../../../utils'

const CriteriaTemplates = (props) => {
  //define dispatch
  const dispatch = useDispatch();

  // Set Card Limit and Size
  const cardLimit = criteriaCardLimit();
  const cardSize = Math.floor(12 / (cardLimit / 8));

  //get container height
  const height = getTemplatesHeight();

  //set ref for scroll
  const scrollCreteria = useRef(null)

  //get props
  const { classes } = props;

  //get reducer data
  const { criteria, criteriaTemplate: { data, page } } = useSelector(s => (s.template))
  let { lazyLoading, loading } = useSelector((state) => state.UI);

  //get data oninit
  useEffect(() => {
    dispatch(templateAction.getAllCriteria({ limit: cardLimit, skip: 0, search: page.search, sort: page.sort }))
  }, [dispatch])

  // set state
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [templateID, setTemplateID] = useState(null);
  const [deleteAlert, setDeleteAlert] = useState(null);
  const [showScrollTopBTN, setShowScrollTopBTN] = useState(false)

  //handle more option change
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setTemplateID(id)
  };

  //close more options
  const handleClose = () => {
    setAnchorEl(null);
    setTemplateID(null)
  };

  //scroll event
  const getScrollData = () => {
    const isBottom = parseInt(scrollCreteria.current.scrollHeight - scrollCreteria.current.scrollTop) === (scrollCreteria.current.clientHeight) || parseInt(scrollCreteria.current.scrollHeight - scrollCreteria.current.scrollTop) === (scrollCreteria.current.clientHeight + 1) || parseInt(scrollCreteria.current.scrollHeight - scrollCreteria.current.scrollTop) === (scrollCreteria.current.clientHeight - 1);
    if (!lazyLoading && isBottom && data.length < page.count) {
      //get next data
      dispatch(templateAction.getAllCriteria({ limit: page.limit, skip: page.skip + page.limit, search: page.search, sort: page.sort, loader: true }))
    }

    if (scrollCreteria.current.scrollTop > 150 && showScrollTopBTN === false) {
      setShowScrollTopBTN(true)
    }
    else if (scrollCreteria.current.scrollTop < 150 && showScrollTopBTN === true) {
      setShowScrollTopBTN(false)
    }
  }

  //get sort data
  const getSortedData = (sorting) => {
    dispatch(templateAction.getAllCriteria({ limit: cardLimit, skip: 0, search: page.search, sort: sorting }))
  }

  //edit template
  const editCriteria = () => {
    if (templateID) {
      dispatch(templateAction.getOneCriteria({ id: templateID }, () => {
        setAnchorEl(null);
      }))
    }
  }

  //delete template
  const deleteCriteria = () => {
    if (templateID) {
      dispatch(templateAction.deleteCriteria({ id: templateID }, () => {
        dispatch(templateAction.getAllCriteria({ limit: cardLimit, skip: 0, search: page.search, sort: page.sort }))
        handleClose()
        setDeleteAlert(null)
      }))
    }
  }

  //get search data
  const intiSearch = (value) => {
    dispatch(templateAction.getAllCriteria({ limit: cardLimit, skip: 0, search: value, sort: page.sort }))
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between" className={classes.serviceActionBtn} alignItems="center">
          <Grid item>
            <Typography variant="h6" color="TextSecondary" component="h3"> {`Count ${page && page.count >= 0 ? `(${page.count})` : ""}`} </Typography>
          </Grid>
          <Grid className="pr15">
            <Grid container alignItems="center">
              <SearchBar className={"mr-1"} loading={loading} value={page.search} intiSearch={(v) => { intiSearch(v) }} />
              <Grid className={"mr-1"}>
                {page && page.sort && page.sort === "asc" ?
                  <Tooltip arrow title="Sort By Created Date"><IconButton
                    className={classes.searchIcon}
                    onClick={() => { getSortedData('desc') }}
                    size="large"><SortIcon /></IconButton></Tooltip>
                  :
                  <Tooltip arrow title="Sort By Created Date"><IconButton
                    className={classes.searchIcon}
                    onClick={() => { getSortedData('asc') }}
                    size="large"><SortIcon /></IconButton></Tooltip>
                }
              </Grid>
              <AddEditCriteria />
            </Grid>
          </Grid>
        </Grid>
        {data && data.length > 0 ? <Grid
          container
          spacing={0}
          className={classes.containerCriteriaScroll}
          style={{ height: height, paddingRight: 12 }}
          alignContent="baseline"
          ref={scrollCreteria}
          onScroll={() => { getScrollData() }}
        >
          <Grid container spacing={2}>
            {data && data.map((itm, indx) => (
              <Grid className="animate__animated animate__fadeInUp animate__delay-0.8s" item sm={cardSize}
              // align="center"
              >
                <Grid className={classes.CriteriaTile}>
                  <Grid container alignItems="center">
                    <Grid item className={classes.CriteriaLeftImage}>
                      <Typography color="textSecondary" variant="h5" className="upperCase">{nameLogoGeneration(itm.name ? itm.name : "")}</Typography>
                    </Grid>
                    <Grid item className={classes.CriteriaCenterPart}>
                      <Grid container direction="column">
                        <Typography variant="h6" align="left" className={`${"twoLineEllip transCapital maxHeight2_Lineh6"}`}>{itm.name ? itm.name : ""}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item className={classes.CriteriaRightPart}>
                      <IconButton
                        style={{ padding: 0 }}
                        onClick={(e) => { handleClick(e, itm.id) }}
                        size="large"><MoreIcon /></IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            <LazyLoader />
          </Grid>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => { editCriteria() }}>Edit</MenuItem>
            {templateID && ["1", "2"].indexOf(templateID.toString()) === -1 && <MenuItem onClick={() => { setDeleteAlert({ type: true, dataa: data.filter(i => (i.id === templateID)) }) }}>Delete</MenuItem>}
          </Menu>
        </Grid>
          : !loading ? <NoRecordsFound /> : null}
        {showScrollTopBTN && <IconButton
          onClick={() => { scrollCreteria.current.scrollTo(0, 0) }}
          className={"toTop"}
          size="large"><KeyboardArrowUpIcon /></IconButton>}
      </Grid>
      {deleteAlert &&
        <AlertDialog
          fullScreen={false}
          open={true}
        >
          <Grid className={'p-4'}>
            <Typography variant="h6">{deleteAlert.dataa && deleteAlert.dataa[0] && deleteAlert.dataa[0]['criteria_id'] ? "The criteria is mapped to an application, are you sure you want to delete the criteria ?" : `Are you sure you want to delete`}</Typography>
            <Grid className={'pt-2'} align={"center"}>
              <Button
                color={"primary"}
                className={"mr-1"}
                onClick={() => { deleteCriteria() }}
                title={"Continue"}
              />
              <Button
                color={"secondary"}
                onClick={() => { handleClose(); setDeleteAlert(null) }}
                title={"Cancel"}
              />
            </Grid>
          </Grid>
        </AlertDialog>
      }
    </React.Fragment>
  );
}

// default props
CriteriaTemplates.defaultProps = {
  classes: {}
};

// prop types
CriteriaTemplates.propTypes = {
  classes: PropTypes.object
};

export default withStyles(TemplatesStyles)(CriteriaTemplates);
