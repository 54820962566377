import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from 'react-hook-form';
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import classNames from 'classnames';


//Styles
import ServicesStyles from '../../ServicesStyles'

//Images
import { DragIcon, CheckSelectedIcon, DeleteIcon, BellIcon } from '../../../../assets/svg'

// Import Components
import { Button, Typography, CheckBox, Link, TextAreaComponent, TextFieldWithoutRef, TextField, TextAreaWithLines } from "../../../../components";

// Import Helpers
import validations from "../../../../utils/validations";

// Import Cofig Constants
import { user_page_constants, templates_page_constants } from "../../../../config/constants";
import { serviceConstant } from "../../../../redux/constants";

// Import Actions
import { serviceAction, updateReducerAction } from "../../../../redux/actions";

const Generic = (props) => {

    //define dispatch
    const dispatch = useDispatch();

    const { classes, createFromScratch, page, errorFlags } = props;

    const handleChange = (e) => {
        if (e.target.name === "is_signature" || e.target.name === "is_authorization") {
            createFromScratch.documentData[page][e.target.name] = e.target.checked
        } else {
            createFromScratch.documentData[page][e.target.name] = e.target.value
        }
        dispatch(updateReducerAction.update(serviceConstant.ADD_NEW_PAGE_DOCUMENT, createFromScratch, () => { }));
    }

    return (
        <div
            role="tabpanel"
            id={`simple-tabpanel-0`}
            aria-labelledby={`simple-tab-0`}
        >
            <Grid container alignContent={"baseline"} style={{ alignContent: 'baseline' }}>
                <Grid item sm={12} className="mb15">
                    <Grid container spacing={3}>
                        <Grid item sm={3}>
                            <Typography className="textFieldLabel" variant={"body1"} >{user_page_constants.TITLE}<span className={"reqStar"}>*</span></Typography>
                            <TextFieldWithoutRef
                                name={"title"}
                                margin="none"
                                placeholder={'title'}
                                value={createFromScratch.documentData[page]["title"] ? createFromScratch.documentData[page]["title"] : ""}
                                error={errorFlags.documentData[page].title ? true : false}
                                helperText={errorFlags.documentData[page].title ? "Title cannot be blank" : ""}
                                handleChange={(e) => handleChange(e)}
                            />
                        </Grid>
                        <Grid item sm={9}>
                            <Typography className="textFieldLabel" variant={"body1"}>{user_page_constants.DESCRIPTION}</Typography>
                            <TextFieldWithoutRef
                                name={"description"}
                                margin="none"
                                placeholder={'description'}
                                value={createFromScratch.documentData[page]["description"] ? createFromScratch.documentData[page]["description"] : ""}
                                handleChange={(e) => handleChange(e)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={12} align="left" className="pb-1">
                    <CheckBox
                        name={"is_signature"}
                        label={templates_page_constants.DOC_SIGNATURE}
                        checked={createFromScratch.documentData[page]["is_signature"]}
                        color="secondary"
                        handleChange={handleChange}
                    />
                    <CheckBox
                        name={"is_authorization"}
                        label={templates_page_constants.DOC_AUTHORISATION}
                        checked={createFromScratch.documentData[page]["is_authorization"]}
                        color="secondary"
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item sm={12} align="left" className={"multiLineText pb-1"}>
                    <Typography className="textFieldLabel" title={user_page_constants.CONDITION} variant={"body1"}></Typography>
                    {/* <TextFieldWithoutRef
                        className={classes.borderNone}
                        name={"condition"}
                        size={"small"}
                        margin={"none"}
                        rows={6}
                        multiline={true}
                        value={createFromScratch.documentData[page]["condition"] ? createFromScratch.documentData[page]["condition"] : ""}
                        handleChange={(e) => handleChange(e)}
                    /> */}
                    <TextAreaWithLines
                     minRow="6"
                        name={"condition"}
                        onChange={(e) => handleChange(e)}
                        value={createFromScratch.documentData[page]["condition"] ? createFromScratch.documentData[page]["condition"] : ""}
                    />
                </Grid>
            </Grid></div>
    );
}

// default props
Generic.defaultProps = {
    classes: {}
};

// prop types
Generic.propTypes = {
    classes: PropTypes.object
};

export default withStyles(ServicesStyles)(Generic);
