import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

// Import Authentication Layout
import BannerLayout from "../layouts/BannerLayout/BannerLayout";

// Routes the page without any authentication by the user
const AuthenticationRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    <BannerLayout>
      <Component {...props} />
    </BannerLayout>
  )} />
);

// Define Props Types
AuthenticationRoute.propTypes = {
  component: PropTypes.elementType
};

export default AuthenticationRoute;
