import bannerImg from '../../assets/images/bannerImg.png'

const AuthenticationStyles = () => ({
    root: {
        height: '100%'
    },
    bannerImg: {
        backgroundImage: `url(${bannerImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    container: {
        height: '100vh',
        maxWidth: 400,
        marginTop: '-16px',
        marginLeft:'62px',
        margin: 'auto',
        '& .MuiInputBase-root': {
            height: 48,
            minHeight: 48
        }
    },
    loginContainer: {
        height: 'calc(100% - 295px)',
        display: 'flex',
        alignItems: 'center'
    },
    //Login
    loginBtn: {
        // minWidth: "154px"
        transition: 'all 0.8s'
    },
    contactForm: {
        // height: 'calc(100vh - 324px)',
        // overflowY: 'auto',
        '& .MuiInputBase-root, & .MuiTextField-root': {
            height: "auto !important",
        },
        '& .MuiTextField-root': {
            marginBottom: 10
        },
    },
    messageContainer: {
        minHeight: "69px !important",
        '& .MuiTextField-root': {
            minHeight: "69px !important"
        },
        '& .MuiInputBase-root': {
            minHeight: "69px !important"
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '15px !important',
        },
        '& textarea': {
            overflowY: 'auto !important',
            maxHeight: '48px !important'
        }
    },
    passwordContainer: {
        position: 'relative',
    },
    vsibleIcon: {
        top: '52px',
        right: '15px',
        position: 'absolute',
        fill: '#cccccc !important',
        cursor: "pointer"
    },
    vsibleIconReset: {
        top: '60px',
        right: '15px',
        position: 'absolute',
        fill: '#cccccc !important',
        cursor: "pointer"
    }
})
export default AuthenticationStyles;