import '../../assets/theme/palette';
import palette from '../../assets/theme/palette';
const TenantApplicationStyles = () => ({
    tenantHeader: {
        background: '#fff',
        padding: '10px 20px',
        borderBottom: '1px solid #E6E5E5',
    },
    percentageText: {
        color: palette.primary.dary
    },
    mobileLogo: {
        maxWidth: 170,
        // margin: 'auto',
    },
    logoImage: {
        maxHeight: 42
    },
    mobileHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    mobileStepper: {
        marginTop: -36,
        padding: '8px 20px 8px 13px'
    },
    stepperTitle: {
        marginTop: 15
    },
    addressDrawer: {
        '& .MuiPaper-root': {
            maxWidth: 200,
            padding: 15
        }
    },
    backDrop: {
        zIndex: 0
    },
    tenantFooter: {
        height: 57,
        position: 'fixed',
        bottom: 0,
        padding: '10px 20px',
        width: '100%',
        background: '#fff',
        boxShadow: '0px -6px 6px #0000000F'
    },
    FooterLogo: {
        width: 'auto',
        height: 32
    },
    headerLogoAddress: {
        display: 'flex',
        alignItems: "center"
    },
    addressContainer: {
        paddingLeft: 20,
        borderLeft: '1px solid #E6E5E5'
    },
    cardContainer: {
        maxWidth: 1140,
        width: '100%',
        background: '#fff',
        boxShadow: '0px 0px 6px #00000014',
        borderRadius: '6px',
        margin: 'auto',
        padding: 24,
        marginBottom: 20,
        '& .MuiTextField-root': {
            height: 'auto !important'
        }
    },
    cardContainerRev: {
        padding: "24px 60px !important",
        '@media (max-width: 575px)' : {
            padding: "24px 30px !important",
        }
    },
    appCardContainer: {
        paddingBottom: '65px !important',
    },
    selectBox: {
        width: "100%",
        '& .MuiSelect-outlined': {
            padding: 10,
            textAlign: 'left',
            borderRadius: 30,
            width: "92%"
        },
        '& .MuiOutlinedInput-notchedOutline legend': {
            display: "none !important"
        },
        '& .MuiOutlinedInput-notchedOutline': {
            marginTop: "5px !important"
        },
        '& .MuiListItem-root': {
            minHeight: 30
        }
    },

    //Stepper
    EnduserStepper: {
        padding: '0px !important',
        '& .MuiStepConnector-alternativeLabel': {
            // top: '42px',
            // left: '-100% !important',
            // right: '0% !important',
            // position: 'absolute',
            // cursor: 'pointer',
            display: 'none'
        },
        '& .MuiStepLabel-label': {
            marginTop: '0 !important',
            marginLeft: 10
        },
        '& .MuiStepLabel-labelContainer': {
            width: 'auto'
        },
        '& .MuiStepLabel-root.MuiStepLabel-alternativeLabel': {
            flexDirection: 'row',
            justifyContent: 'center',
            padding: '12px',
            cursor: 'pointer'
        },
        '& .MuiStepButton-root': {
            margin: '-14px -8px',
            padding: '14px 7px',
            borderRight: '1px solid gainsboro'
        },
        '& .MuiStepConnector-active': {
            '& .MuiStepConnector-lineHorizontal': {
                borderColor: palette.primary.dary
            }
        },
        '& .MuiStep-alternativeLabel': {
            borderRight: '1px solid gainsboro',
        },
        '& .MuiStep-completed': {
            '& .MuiStepLabel-label': {
                color: palette.primary.dary
            },
            '& .MuiSvgIcon-root': {
                fill: palette.primary.dary
            }
        },
        '& .MuiStepLabel-label.MuiStepLabel-active': {
            color: palette.primary.dary
        },
        '& .MuiStepIcon-active': {
            fill: palette.primary.dary
        },

    },

    stepperProgess: {
        marginTop: '-3px',
        padding: 0,
        '& .MuiMobileStepper-progress': {
            width: '100%',
            height: 6
        },
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: palette.primary.dary,
        },
        '& .MuiLinearProgress-colorPrimary': {
            backgroundColor: palette.greyshades.light2
        }
    },

    endUserApplicationBody: {
        height: 'calc(100vh - 170px)',
        overflowY: 'auto'
    },
    criteriaContainer: {
        '& .MuiTypography-body1': {
            lineHeight: 1.8
        },
        '& ul': {
            margin: 0,
            paddingInlineStart: '15px',
            marginBlockStart: 0,
            marginBlockEnd: 0
        }
    },
    hintText: {
        color: 'gray',
        fontSize: 11,
        fontStyle: 'italic',
        margin: 'auto',
        marginLeft: '0',
        paddingTop: 2,
        float: 'left'
    },
    closeContainer: {
        position: 'relative'
    },
    closeIconBtn: {
        position: 'absolute',
        right: '15px',
        top: '-13px',
        background: '#fff',
        padding: '2px',
        '&:hover': {
            background: '#fff',
        }
    },
    checkBoxContainer: {
        '& .MuiFormControlLabel-root': {
            alignItems: 'end'
        },
        '& .MuiIconButton-root': {
            padding: 0,
            margin: '0 9px 0px 9px'
        },
    },
    addMoreCheckBox: {
        '& .addMoreCheckBoxDiv': {
            display: 'flex',
            alignItems: 'center'
        },
        '& .MuiSvgIcon-root': {
            marginLeft: '-11px',
            fontSize: '18px'
        },
        '& .MuiButtonBase-root': {
            opacity: 0
        }
    },
    payTop: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    revTitleColor: {
        background: "#e8ebef",
    },
    revTitleTextColor: {
        color: "#1A385E"
    },
    blLine: {
        padding: "2px",
        background: "#12A9E2",
        margin: "14px 5px 14px 0px"
    },
    clrLabel: {
        color: "#777777"
    },
    colorChange: {
        "& .MuiOutlinedInput-root .MuiInputBase-input":{
            color: "#110308"
        }
    }
})

export default TenantApplicationStyles