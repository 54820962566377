import palette from '../../assets/theme/palette'
const PrivateLayoutStyle = () => ({
    container: {
        background: palette.greyshades.light3,
        paddingTop: '16px',
        width: "100%",
        alignContent: 'baseline'
      }    
});

export default PrivateLayoutStyle;
